import {
  PredefinedReadContractConfig,
  useContractRead,
} from '@0xflair/react-common';

import { LicenseVersion } from '../../nft-collections/types/license.types';

export const useLicenseVersion = (config: PredefinedReadContractConfig) => {
  return useContractRead<LicenseVersion>({
    contractFqn: 'common/LicenseExtension',
    functionName: 'licenseVersion',
    ...config,
  });
};
