import {
  Badge,
  Button,
  ContractFqn,
  ContractVersion,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  Spinner,
  useChainInfo,
  useVerificationStatus,
  useVerificationSubmitter,
} from 'flair-sdk';
import { useCallback } from 'react';

type Props = {
  env: Environment;
  chainId?: number;
  contractAddress?: string;
  contractFqn?: ContractFqn;
  contractVersion?: ContractVersion;
  constructorArguments?: any[];
};

export const VerificationAdminSection = ({
  env,
  chainId,
  contractAddress,
  contractFqn,
  contractVersion,
  constructorArguments,
}: Props) => {
  const chainInfo = useChainInfo(chainId);

  const {
    error: verificationError,
    isLoading: verificationLoading,
    sendRequest: verifyContract,
  } = useVerificationSubmitter({
    env,
    chainId,
    contractFqn,
    contractVersion,
    contractAddress: contractAddress,
    constructorArguments,
  });

  const {
    data: verificationStatus,
    isLoading: verificationStatusLoading,
    error: verificationStatusError,
    sendRequest: checkStatus,
  } = useVerificationStatus({
    env,
    chainId,
    contractAddress,
  });

  const noVerificationSubmitted =
    verificationStatusError &&
    (verificationStatusError as any)?.response?.status === 404;

  const recentlyHandled =
    (verificationStatus?.state === 'pending' ||
      verificationStatus?.state === 'submitted' ||
      verificationStatusLoading) &&
    Math.max(
      verificationStatus?.attemptedAt || 0,
      verificationStatus?.processedAt || 0,
      verificationStatus?.submittedAt || 0,
    ) >
      Date.now() - 1000 * 10;

  const verifyAndCheckStatus = useCallback(async () => {
    await verifyContract();
    await checkStatus();
  }, [verifyContract, checkStatus]);

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Verification
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Submit complete source code of your contract on{' '}
          {chainInfo?.blockExplorers?.default.name} for more transparency with
          your users.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Current status
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {!verificationStatus?.state ? (
                <Badge color={'grey'} text="Unverified" />
              ) : null}
              {verificationStatus?.state === 'submitted' ? (
                <Badge color={'blue'} text="Submitted..." />
              ) : null}
              {verificationStatus?.state === 'pending' ? (
                <Badge color={'yellow'} text="In progress..." />
              ) : null}
              {verificationStatus?.state === 'failed' ? (
                <Badge color={'red'} text="Failed" />
              ) : null}
              {verificationStatus?.state === 'succeeded' ? (
                <Badge color={'green'} text="Successfully verified" />
              ) : null}
            </dd>
          </div>
        </dl>
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              View on explorer
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <a
                href={`${chainInfo?.blockExplorers?.default.url}/address/${contractAddress}#code`}
                target={'_blank'}
                rel="noreferrer"
                className="text-indigo-600 hover:text-indigo-700"
              >
                {contractAddress}
              </a>
            </dd>
          </div>
        </dl>
        <div className="py-4 sm:py-5 sm:px-6">
          {verificationStatusError && !noVerificationSubmitted ? (
            <Errors error={verificationStatusError} />
          ) : null}
          {verificationStatus?.processingError ? (
            <Errors error={verificationStatus?.processingError} />
          ) : null}
          {verificationError ? <Errors error={verificationError} /> : null}
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
        <span className="flex flex-grow gap-2 items-center">
          {verificationLoading && (
            <>
              <Spinner /> Verifying...
            </>
          )}
        </span>

        <Button
          className={PRIMARY_BUTTON}
          disabled={Boolean(
            verificationLoading ||
              verificationError ||
              verificationStatus?.state === 'succeeded' ||
              recentlyHandled,
          )}
          text={!verificationStatus?.state ? 'Verify now' : 'Re-verify'}
          onClick={() => verifyAndCheckStatus()}
        />
      </div>
    </div>
  );
};
