import { Environment, useAxiosGet, useLoginJwt } from 'flair-sdk';
import { useMemo } from 'react';

import { FLAIR_AUTH_CLIENTS_BACKEND } from '../constants';
import { AuthClient } from '../types';

type Config = {
  env?: Environment;
  enabled?: boolean;
  chainId?: number;
  contractAddress?: string;
  clientId?: string;
};

export function useAuthClient({
  env = Environment.PROD,
  enabled = true,
  clientId,
}: Config) {
  const loginJwt = useLoginJwt();
  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  const url = `${FLAIR_AUTH_CLIENTS_BACKEND[env]}/v1/auth-clients/${clientId}`;

  return useAxiosGet<AuthClient>({
    url,
    enabled: Boolean(enabled && clientId),
    headers,
  });
}
