import {
  Badge,
  Button,
  ContractVersion,
  Environment,
  Errors,
  SECONDARY_BUTTON,
  Spinner,
  usePreSaleAllowlistChecker,
} from 'flair-sdk';
import { useState } from 'react';

type Props = {
  env?: Environment;
  chainId?: number;
  contractAddress?: string;
  contractVersion?: ContractVersion;
};

export const PreSaleAllowlistChecker = ({
  env,
  chainId,
  contractAddress,
  contractVersion,
}: Props) => {
  const [minterAddress, setMinterAddress] = useState<string>();

  const {
    data,
    error,
    isLoading,
    reCheck: checkAllowlist,
  } = usePreSaleAllowlistChecker({
    env,
    chainId,
    contractVersion,
    contractAddress,
    minterAddress,
  });

  return (
    <div className="flex flex-col gap-2">
      <form className="sm:flex sm:items-center gap-2">
        <div className="w-full sm:max-w-xs">
          <label htmlFor="walletAddress" className="sr-only">
            Address to check
          </label>
          <input
            type="text"
            name="walletAddress"
            id="walletAddress"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="0x0000000000000000000000000000000000000000"
            onChange={(e) => setMinterAddress(e.target.value.trim())}
          />
        </div>
        <Button
          text="Check"
          className={SECONDARY_BUTTON}
          onClick={checkAllowlist}
        />
      </form>

      <div className="flex items-center gap-2">
        {error ? <Errors error={error} /> : null}
        {isLoading ? (
          <>
            <Spinner /> Checking...
          </>
        ) : null}
        {data.isAllowlisted === true ? (
          <Badge color="green" text="Yes, allowlisted" />
        ) : data.isAllowlisted === false ? (
          <Badge color="red" text="Not allowlisted" />
        ) : null}
      </div>
    </div>
  );
};
