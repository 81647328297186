import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

export const browserHistory = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: browserHistory });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
  }),
  middleware: [routerMiddleware],
});

export const history = createReduxHistory(store);
