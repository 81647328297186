import { Signer, utils } from 'ethers';
import {
  Badge,
  Button,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  TokenStream,
  useChainInfo,
  useCryptoCurrency,
  useTokenStreamUpdater,
} from 'flair-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetwork, useSigner, useWaitForTransaction } from 'wagmi';

import { useFlairContractDeployer } from '../../../common/hooks/useFlairContractDeployer';

type Props = {
  env: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
  constructorArguments?: any[];
};

export const DeployAdminSection = ({
  env = Environment.PROD,
  tokenStream,
  refresh,
  constructorArguments,
}: Props) => {
  const { data: signer } = useSigner();
  const { activeChain } = useNetwork();
  const { data } = useCryptoCurrency({
    symbol: activeChain?.nativeCurrency?.symbol,
  });

  const [triedAutoSave, setTriedAutoSave] = useState(false);

  const {
    data: deployData,
    error: deployError,
    isLoading: deployLoading,
    deploymentFee,
    deployContract,
  } = useFlairContractDeployer({
    env,
    contractFqn: tokenStream.presetFqn,
    contractVersion: tokenStream.presetVersion,
    signer: signer as Signer,
  });

  const deployTransaction =
    deployData?.deployTransaction?.hash || tokenStream.deployTransaction;

  const {
    data: txData,
    isLoading: txLoading,
    error: txError,
  } = useWaitForTransaction({
    hash: deployTransaction,
    confirmations: 2,
  });

  const contractAddress =
    deployData?.address ||
    txData?.contractAddress ||
    tokenStream.contractAddress;

  const {
    data: updateData,
    isLoading: updateLoading,
    error: updateError,
    sendRequest: saveStream,
  } = useTokenStreamUpdater(
    {
      _id: tokenStream._id,
      chainId: activeChain?.id as number,
      contractAddress,
      deployTransaction,
    },
    {
      env,
      enabled: false,
    },
  );

  const deploymentFeeFiat = useMemo(() => {
    try {
      return data?.price && deploymentFee
        ? (
            Number(utils.formatEther(deploymentFee?.toString()).toString()) *
            Number(data?.price?.toString()) *
            (activeChain?.testnet ? 0 : 1)
          ).toFixed(2)
        : undefined;
    } catch (error) {
      return undefined;
    }
  }, [activeChain?.testnet, data?.price, deploymentFee]);

  const deployNow = useCallback(() => {
    if (!tokenStream._id) {
      window?.gtag?.('event', 'contract_deployment_attempted', {
        event_category: 'streams',
        event_label: tokenStream.presetFqn,
        section: 'streams',
        preset_fqn: tokenStream.presetFqn,
        preset_version: tokenStream.presetVersion,
        chain_id: activeChain?.id,
        transaction_id: `deploy:stream:${tokenStream._id}`,
        deployment_fee_wei: deploymentFee?.toString(),
        deployment_fee_fiat: deploymentFeeFiat,
      });
    }

    deployContract(...(constructorArguments || []));
  }, [
    tokenStream._id,
    tokenStream.presetFqn,
    tokenStream.presetVersion,
    deployContract,
    constructorArguments,
    activeChain?.id,
    deploymentFee,
    deploymentFeeFiat,
  ]);

  const mustBeSaved = Boolean(
    deployTransaction &&
      contractAddress &&
      !tokenStream.contractAddress &&
      !updateData?._id,
  );

  useEffect(() => {
    if (triedAutoSave) {
      return;
    }

    if (mustBeSaved) {
      if (activeChain?.id && contractAddress) {
        window?.gtag?.('event', 'contract_deployment_successful', {
          event_category: 'streams',
          event_label: tokenStream.presetFqn,
          section: 'streams',
          preset_fqn: tokenStream.presetFqn,
          preset_version: tokenStream.presetVersion,
          chain_id: activeChain?.id,
          testnet: !!activeChain?.testnet,
          contract_address: contractAddress,
          transaction_id: `${activeChain?.id}:${contractAddress}`,
          deployment_fee_wei: deploymentFee?.toString(),
          deployment_fee_fiat:
            data?.price && deploymentFee
              ? Number(deploymentFee?.toString()) *
                Number(data?.price?.toString())
              : undefined,
        });
        window?.gtag?.('event', 'purchase', {
          currency: 'USD',
          transaction_id: `deploy:stream:${tokenStream._id}`,
          value: deploymentFeeFiat,
          items: [
            {
              item_id: `${activeChain?.id}:${tokenStream.presetFqn}`,
              item_name: `${activeChain?.id}:${tokenStream.presetFqn}`,
            },
          ],
        });
      }

      saveStream().then(refresh);
      setTriedAutoSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triedAutoSave,
    mustBeSaved,
    saveStream,
    refresh,
    tokenStream.presetFqn,
    activeChain?.id,
    contractAddress,
    activeChain?.testnet,
  ]);

  const streamChainId = tokenStream.contractAddress
    ? tokenStream.chainId
    : updateData?.chainId
    ? updateData.chainId
    : activeChain?.id;

  const streamChain = useChainInfo(Number(streamChainId));

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Deploy</h3>
        <p className="mt-1 text-sm text-gray-500">
          Whenever you are ready you can deploy the smart contract on the
          blockchain.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {!deployLoading && !txLoading ? (
                contractAddress ? (
                  <Badge color="green" text="Successfully deployed!" />
                ) : (
                  <Badge color="yellow" text="Not deployed yet" />
                )
              ) : (
                ''
              )}
              {deployLoading ? (
                <>
                  <Spinner />
                  <Badge color="blue" text="Deploying..." />
                </>
              ) : deployTransaction &&
                (txLoading ||
                  !txData?.confirmations ||
                  txData.confirmations < 2) ? (
                <>
                  <Spinner />
                  <Badge color="blue" text="Waiting for Tx..." />
                </>
              ) : null}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Network</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {streamChain?.name || `Chain ${streamChainId}`}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Deploy Transaction
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-full truncate">
              {deployTransaction ? (
                <>
                  <small className="text-xs">
                    {deployTransaction.toString()}
                  </small>
                  <br />
                  <a
                    href={`${activeChain?.blockExplorers?.default.url}/tx/${deployTransaction}`}
                    target={'_blank'}
                    className={'text-sm text-indigo-700'}
                    rel="noreferrer"
                  >
                    View on {activeChain?.blockExplorers?.default.name}
                  </a>
                </>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Contract Address
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {contractAddress ? (
                <>
                  <small className="text-xs">{contractAddress}</small>
                  <br />
                  <a
                    href={`${activeChain?.blockExplorers?.default.url}/address/${contractAddress}`}
                    target={'_blank'}
                    className={'text-sm text-indigo-700'}
                    rel="noreferrer"
                  >
                    View on {activeChain?.blockExplorers?.default.name}
                  </a>
                </>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
        </dl>
        <div className="py-4 sm:py-5 sm:px-6">
          {deployError ? (
            <Errors title="Deploy Error" error={deployError} />
          ) : null}
          {txError ? <Errors title="Tx Error" error={txError} /> : null}
          {updateError ? (
            <Errors title="Update Error" error={updateError} />
          ) : null}
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
        <Button
          className={PRIMARY_BUTTON}
          text="Deploy"
          onClick={deployNow}
          disabled={Boolean(contractAddress || txLoading || deployLoading)}
        />

        {mustBeSaved && (
          <Button
            className={SECONDARY_BUTTON}
            text="Save"
            onClick={saveStream}
            disabled={Boolean(tokenStream.contractAddress || updateLoading)}
          />
        )}
      </div>
    </div>
  );
};
