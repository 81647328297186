import {
  Button,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useAdminMinter,
} from 'flair-sdk';
import { useState } from 'react';
import { useAccount } from 'wagmi';

type Props = {
  env?: Environment;
  collection: NftCollection<any>;
  refreshCollection: () => void;
};

export const AdminMintWidget = ({
  env,
  collection,
  refreshCollection,
}: Props) => {
  const { data: account } = useAccount();

  const [toAddress, setToAddress] = useState<string>('');
  const [mintCount, setMintCount] = useState<number>(1);

  const {
    data: mintData,
    error: mintError,
    isLoading: mintLoading,
    mintAsAdmin,
  } = useAdminMinter({
    env,
    chainId: collection.chainId,
    contractAddress: collection.contractAddress,
    contractVersion: collection.presetVersion,
    minterAddress: account?.address,
    toAddress,
    mintCount,
  });

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Mint as admin
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Manually mint tokens as admin, or provide a minting widget for your
          collectors on your site.
        </p>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="toAddress"
              className="block text-sm font-medium text-gray-700"
            >
              To address
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="toAddress"
                id="toAddress"
                autoComplete="walletAddress"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={toAddress}
                onChange={(e) => setToAddress(e.target.value)}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Tokens will be minted to this address.
            </p>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="mintCount"
              className="block text-sm font-medium text-gray-700"
            >
              Number of tokens
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="mintCount"
                id="mintCount"
                autoComplete="nftAmount"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={mintCount}
                onChange={(e) => setMintCount(Number(e.target.value))}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Total number of tokens to mint and send.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
        <div className="flex-1 flex gap-2 items-center">
          {mintLoading ? (
            <>
              <Spinner /> Working...{' '}
            </>
          ) : null}
          {mintData.txReceipt || mintData.txResponse ? (
            <TransactionLink
              txReceipt={mintData.txReceipt}
              txResponse={mintData.txResponse}
            />
          ) : null}
          {!mintData.isOwner && !mintData.hasMinterRole ? (
            <Errors error="Cannot mint because wallet is not owner nor has minter role" />
          ) : null}
          {!mintLoading && mintError ? <Errors error={mintError} /> : null}
        </div>
        <Button
          className={PRIMARY_BUTTON}
          text={'Mint now'}
          onClick={() => mintAsAdmin()}
          disabled={mintLoading}
        />
      </div>
    </div>
  );
};
