import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ethers } from 'ethers';
import {
  Button,
  CryptoAmountField,
  Errors,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  TransactionLink,
} from 'flair-sdk';
import React, { ReactNode, useState } from 'react';

import { ActionButtonDialog } from './ActionButtonDialog';

type Props = {
  buttonLabel?: React.ReactNode;
  buttonDisabled?: boolean;
  buttonClassName?: string;
  dialogTitle: React.ReactNode;
  dialogDescription?: React.ReactNode;
  dialogOpen?: boolean;
  setDialogOpen: (open: boolean) => void;
  inputType: 'price' | 'number' | 'address' | 'datetime' | 'text';
  inputArgs?: any;
  inputLabel?: string;
  inputDefaultValue?: any;
  inputOnSubmit: (value: any) => void;
  data?: {
    txResponse?: ethers.providers.TransactionResponse;
    txReceipt?: ethers.providers.TransactionReceipt;
  };
  loading?: boolean;
  error?: Error | null;
};

export const SingleValueUpdaterButtonDialog = (props: Props) => {
  const {
    buttonLabel,
    buttonDisabled,
    buttonClassName,
    dialogTitle,
    dialogDescription,
    dialogOpen,
    setDialogOpen,
    inputType,
    inputLabel,
    inputDefaultValue,
    inputArgs,
    inputOnSubmit,
    data,
    loading,
    error,
  } = props;

  const [value, setValue] = useState(inputDefaultValue);

  let inputView: ReactNode = '';
  switch (inputType) {
    case 'price':
      inputView = (
        <CryptoAmountField
          label={inputLabel || 'New amount'}
          value={value || '0'}
          onChange={setValue}
          {...inputArgs}
        />
      );
      break;

    case 'number':
      inputView = (
        <div className="w-full">
          <label className="sr-only">{inputLabel || 'New value'}</label>
          <input
            type="number"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            {...inputArgs}
          />
        </div>
      );
      break;

    case 'text':
      inputView = (
        <div className="w-full">
          <label className="sr-only">{inputLabel || 'New value'}</label>
          <input
            type="text"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            {...inputArgs}
          />
        </div>
      );
      break;

    case 'address':
      inputView = (
        <div className="w-full">
          <label className="sr-only">{inputLabel || 'New address'}</label>
          <input
            type="text"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            {...inputArgs}
          />
        </div>
      );
      break;

    case 'datetime':
      inputView = (
        <div className="w-full">
          <label className="sr-only">{inputLabel || 'New date'}</label>
          <input
            type="datetime-local"
            step="60"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            {...inputArgs}
          />
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <ActionButtonDialog
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      buttonLabel={buttonLabel || dialogTitle}
      buttonDisabled={buttonDisabled}
      buttonClassName={buttonClassName}
    >
      <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon
                className="h-6 w-6 text-blue-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-xl leading-6 font-medium text-gray-900"
              >
                {dialogTitle}
              </Dialog.Title>
              <div className="mt-2 text-md">{dialogDescription}</div>
              <div className="mt-4">{inputView}</div>
              <div className="mt-2">
                {loading ? (
                  <div className="flex flex-row items-center gap-2">
                    <Spinner />{' '}
                    {data?.txReceipt?.transactionHash ? (
                      <>Waiting for confirmation...</>
                    ) : data?.txResponse?.hash ? (
                      <>Waiting for tx...</>
                    ) : (
                      <>Waiting for approval...</>
                    )}
                  </div>
                ) : (
                  data?.txReceipt && (
                    <div className="flex gap-1 items-center text-green-900">
                      <CheckCircleIcon className="w-5 h-5 text-green-700" />{' '}
                      Successfully saved
                    </div>
                  )
                )}
                {error && <Errors error={error} />}
                <div className="mt-2">
                  {data?.txReceipt || data?.txResponse ? (
                    <TransactionLink
                      txReceipt={data?.txReceipt}
                      txResponse={data?.txResponse}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse gap-2">
          <Button
            text="Save"
            disabled={loading}
            className={PRIMARY_BUTTON}
            onClick={() => inputOnSubmit(value)}
          />
          <Button
            text="Close"
            className={SECONDARY_BUTTON}
            onClick={() => setDialogOpen(false)}
          />
        </div>
      </div>
    </ActionButtonDialog>
  );
};
