import { FeatureWriteByTagConfig, useFeatureWriteByTag } from 'flair-sdk';

export const useWithdrawRecipientLocker = (
  config: FeatureWriteByTagConfig<{}>,
) => {
  return useFeatureWriteByTag<{}>({
    tag: 'lock_withdraw_recipient',
    ...config,
  });
};
