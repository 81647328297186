import {
  AddressListItem,
  Badge,
  Button,
  classNames,
  Environment,
  Errors,
  ETHEREUM_ADDRESS,
  ETHEREUM_ADDRESS_AND_NUMBER,
  FormSection,
  SECONDARY_BUTTON,
  Spinner,
  Time,
  useAddressList,
} from 'flair-sdk';
import { useLayoutEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import carma from '../../assets/images/integrations/carma.jpeg';
import mintParty from '../../assets/images/integrations/mintparty.jpeg';
import { Pagination } from '../../components/Pagination';
import {
  useAddressListBulkAddressAppender,
  useAddressListBulkAddressDeleter,
  useAddressListBulkItemDeleter,
  useAddressListItems,
} from '../../features/address-lists/hooks';
import { AddedBy } from './components/AddedBy';

type Props = {
  env?: Environment;
};

const integrations = [
  {
    name: 'MintParty',
    handle: 'Collect wallet addresses, Discord and Twitter ID in one click',
    imageUrl: mintParty,
    href: 'https://mintparty.xyz/account?onboarding=flair',
  },
  {
    name: 'Carma Community',
    handle:
      'Create allowlists/run giveaways for your project, all from one place',
    imageUrl: carma,
    href: 'https://www.carma.community/?utm_source=Flair',
  },
];

export const ManageAddressList = ({ env = Environment.PROD }: Props) => {
  const { listId } = useParams();

  const addInputRef = useRef<HTMLTextAreaElement>(null);
  const [newAddressValue, setNewAddressValue] = useState('');
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedItems, setSelectedItems] = useState<AddressListItem[]>([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(1000);

  const inputAddressAndNumbers = [
    ...newAddressValue.matchAll(ETHEREUM_ADDRESS_AND_NUMBER),
  ]
    .map((match) => ({
      address: match[1] as string,
      maxAllowance: parseInt(match[2] as string, 10),
    }))
    .filter((item) => item.address && item.maxAllowance);

  const inputAddresses = [...newAddressValue.matchAll(ETHEREUM_ADDRESS)]
    .map((match) => match[0])
    .filter(
      (address) => !inputAddressAndNumbers.find((a) => a.address === address),
    )
    .filter((address) => Boolean(address))
    .map((address) => ({ address, maxAllowance: 1 }));

  const mergedAddressItems = [...inputAddressAndNumbers, ...inputAddresses];

  const {
    data: addressListData,
    error: addressListError,
    isLoading: addressListLoading,
  } = useAddressList({
    env,
    listId,
  });

  const {
    data: itemsData,
    error: itemsError,
    isLoading: itemsLoading,
    sendRequest: fetchItems,
  } = useAddressListItems({
    env,
    listId,
    skip,
    limit,
  });

  const {
    error: appenderError,
    isLoading: appenderLoading,
    sendRequest: appendItems,
  } = useAddressListBulkAddressAppender({
    env,
    listId,
    addressItems: mergedAddressItems,
  });

  const { isLoading: itemsDeleterLoading, sendRequest: bulkDeleteItems } =
    useAddressListBulkItemDeleter({
      env,
      listId,
      itemIds: selectedItems.map((item) => item._id),
    });

  const { isLoading: addressDeleterLoading, sendRequest: bulkDeleteAddresses } =
    useAddressListBulkAddressDeleter({
      env,
      listId,
      addresses: mergedAddressItems.map((item) => item.address),
    });

  useLayoutEffect(() => {
    const isIndeterminate = Boolean(
      !itemsData ||
        (selectedItems.length > 0 &&
          selectedItems.length < itemsData.items.length),
    );

    setChecked(selectedItems.length === itemsData?.items.length);
    setIndeterminate(isIndeterminate);

    if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
  }, [itemsData, selectedItems]);

  const focusOnInput = () => {
    addInputRef.current?.focus();
  };

  const handleAddressInputKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (event.shiftKey) {
        bulkDeleteAddresses().then(() => fetchItems());
      } else {
        appendItems()
          .then(() => fetchItems())
          .then(() => setSelectedItems([]));
      }

      setNewAddressValue('');
    }
  };

  const handleDeleteBulk = () => {
    bulkDeleteItems()
      .then(() => fetchItems())
      .then(() => setSelectedItems([]));
  };

  const toggleAll = () => {
    setSelectedItems(
      checked || indeterminate || !itemsData?.items ? [] : itemsData.items,
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  if (addressListLoading || itemsLoading) {
    return (
      <div className="p-20 flex gap-2 justify-center items-center">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (addressListError || itemsError) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Errors
          error={
            addressListError || itemsError || 'Could not fetch address list :('
          }
        />
      </div>
    );
  }

  const collectAddressesView = (
    <div className="mt-12">
      <FormSection
        title="Looking to collect addresses?"
        description="Choose one of our partners, collect addresses and paste them in the list above."
      >
        <div>
          <div className="flow-root">
            <ul className="-my-5 divide-y divide-gray-200">
              {integrations.map((integration) => (
                <li key={integration.handle} className="py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={integration.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-md font-bold text-gray-900 truncate">
                        {integration.name}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {integration.handle}
                      </p>
                    </div>
                    <div>
                      <a
                        href={integration.href}
                        target="_blank"
                        className={SECONDARY_BUTTON}
                        rel="noreferrer"
                      >
                        Collect
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </FormSection>
    </div>
  );

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl text-gray-900">
              <Link to={'/lists'} className="text-indigo-600">
                Lists
              </Link>{' '}
              〉<span className="font-semibold">{addressListData?.name}</span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage addresses in this list, by adding, deleting or applying
              them to a collection which is in pre-sale (or closed-sale).
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-2 lg:flex-row md:flex-col sm:flex-col">
            <a
              href={`https://api.flair.finance/v1/address-lists/${addressListData?._id}/export`}
              className={SECONDARY_BUTTON}
              target="_blank"
              rel="noreferrer"
            >
              Export CSV
            </a>
            {/* <Button text="Apply to Pre-sale" className={PRIMARY_BUTTON} /> */}
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {selectedItems.length > 0 && (
                  <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16 gap-1">
                    <Button
                      disabled={itemsDeleterLoading}
                      className={SECONDARY_BUTTON}
                      text={`Delete ${indeterminate ? 'selected' : 'all'}`}
                      onClick={handleDeleteBulk}
                    />
                    {itemsDeleterLoading && <Spinner />}
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                      >
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Added at
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Added by
                      </th>
                      <th
                        scope="col"
                        className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {itemsData?.items.map((item) => (
                      <tr
                        key={item._id}
                        className={
                          selectedItems.includes(item)
                            ? 'bg-gray-50'
                            : undefined
                        }
                      >
                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                          {selectedItems.includes(item) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                            value={item._id}
                            checked={selectedItems.includes(item)}
                            onChange={(e) =>
                              setSelectedItems(
                                e.target.checked
                                  ? [...selectedItems, item]
                                  : selectedItems.filter((p) => p !== item),
                              )
                            }
                          />
                        </td>
                        <td
                          className={classNames(
                            'whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6',
                            selectedItems.includes(item)
                              ? 'text-indigo-600'
                              : 'text-gray-900',
                          )}
                        >
                          {item.address}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {item.maxAllowance && (
                            <Badge
                              color="blue"
                              text={`Max allowance: ${item.maxAllowance}`}
                            />
                          )}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          <Time value={item.createdAt} />
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          <AddedBy value={item.createdVia} />
                        </td>
                        <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {/* <a
                            href="#"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                            <span className="sr-only">, {item.address}</span>
                          </a> */}
                        </td>
                      </tr>
                    ))}
                    <tr key={'pagination'}>
                      <td colSpan={6}>
                        <Pagination
                          isLoading={
                            itemsLoading ||
                            itemsDeleterLoading ||
                            addressDeleterLoading ||
                            addressListLoading
                          }
                          limit={limit}
                          skip={skip}
                          total={itemsData?.total || 0}
                          onChange={(newSkip, newLimit) => {
                            setSkip(newSkip);
                            setLimit(newLimit);
                          }}
                        />
                      </td>
                    </tr>
                    <tr key={'new'}>
                      <td
                        colSpan={6}
                        className="relative group cursor-text w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6"
                        onClick={focusOnInput}
                        onMouseDown={focusOnInput}
                      >
                        <textarea
                          disabled={appenderLoading}
                          ref={addInputRef}
                          rows={3}
                          className="h-full w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Paste one or more new address(es)..."
                          value={newAddressValue}
                          onChange={(e) => setNewAddressValue(e.target.value)}
                          onKeyDown={handleAddressInputKeyDown}
                        ></textarea>
                        <div className="absolute top-1/2 -translate-y-2/4 right-0 pr-10 flex items-center pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm group-focus:text-indigo-600 group-focus:font-bold"
                            id="price-currency"
                          >
                            Press "Enter" to add
                            <br />
                            <span className="text-gray-300">
                              or "Shift + Enter" to remove.
                            </span>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {appenderError && <Errors error={appenderError} />}
                {addressListError && <Errors error={addressListError} />}
              </div>
              {collectAddressesView}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
