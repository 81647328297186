import { BigNumberish } from 'ethers';
import {
  Badge,
  Button,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
} from 'flair-sdk';
import { useEffect, useState } from 'react';

import { useLicenseVersion } from '../../../common/hooks/useLicenseVersion';
import { useLicenseVersionLocker } from '../../../common/hooks/useLicenseVersionLocker';
import { useLicenseVersionLockStatus } from '../../../common/hooks/useLicenseVersionLockStatus';
import { useLicenseVersionUpdater } from '../../../common/hooks/useLicenseVersionUpdater';
import { LicenseVersion } from '../../types/license.types';
import { LicenseSelector } from '../components/LicenseSelector';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const LicenseAdminSection = ({ nftCollection, refresh }: Props) => {
  const [selectedLicense, setSelectedLicense] = useState<BigNumberish>();

  const {
    data: currentLicenseVersion,
    error: currentLicenseVersionError,
    isLoading: currentLicenseVersionLoading,
  } = useLicenseVersion({
    contractAddress: nftCollection.contractAddress,
  });

  const {
    data: licenseVersionUpdater,
    error: licenseVersionUpdaterError,
    isLoading: licenseVersionUpdaterLoading,
    writeAndWait: updateLicenseVersion,
  } = useLicenseVersionUpdater({
    contractAddress: nftCollection.contractAddress,
    licenseVersion: selectedLicense,
  });

  const {
    data: licenseVersionLocked,
    error: licenseVersionLockedError,
    isLoading: licenseVersionLockedLoading,
  } = useLicenseVersionLockStatus({
    contractAddress: nftCollection.contractAddress,
  });

  const {
    data: licenseVersionLocker,
    error: licenseVersionLockerError,
    isLoading: licenseVersionLockerLoading,
    writeAndWait: lockLicenseVersion,
  } = useLicenseVersionLocker({
    contractAddress: nftCollection.contractAddress,
  });

  useEffect(() => {
    if (currentLicenseVersion !== undefined && selectedLicense === undefined) {
      setSelectedLicense(currentLicenseVersion);
    }
  }, [currentLicenseVersion, selectedLicense]);

  const setLicenseView = (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">License</h3>
        <p className="mt-1 text-sm text-gray-500">
          Manage the license for your NFTs, based on{' '}
          <a
            className="text-cyan-700 underline"
            href="https://a16zcrypto.com/introducing-nft-licenses/"
            target={'_blank'}
            rel="noreferrer"
          >
            Can't Be Evil Licenses
          </a>
          .
        </p>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <LicenseSelector
          selected={selectedLicense}
          setSelected={setSelectedLicense}
          disabled={licenseVersionLocked}
        />
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
        <div className="flex-1 flex gap-2 items-center">
          {currentLicenseVersionLoading ? (
            <>
              <Spinner /> Checking current license...
            </>
          ) : licenseVersionUpdaterLoading ? (
            <>
              <Spinner /> Working...
            </>
          ) : null}
          {licenseVersionUpdater.txReceipt ||
          licenseVersionUpdater.txResponse ? (
            <>
              <TransactionLink
                txReceipt={licenseVersionUpdater.txReceipt}
                txResponse={licenseVersionUpdater.txResponse}
              />
            </>
          ) : null}
          {currentLicenseVersionError ? (
            <Errors
              title="currentLicenseVersionError"
              error={currentLicenseVersionError}
            />
          ) : null}
          {licenseVersionUpdaterError ? (
            <Errors
              title="licenseVersionUpdaterError"
              error={licenseVersionUpdaterError}
            />
          ) : null}
        </div>
        <Button
          className={PRIMARY_BUTTON}
          text={'Save license'}
          onClick={() => updateLicenseVersion()}
          disabled={licenseVersionUpdaterLoading || licenseVersionLocked}
        />
      </div>
    </div>
  );

  const freezeLicenseView = (
    <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Lock the license
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          By locking the license, no one can update it anymore; a sign of
          stronger decentralization for your project.{' '}
        </p>
      </div>

      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Current state</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {licenseVersionLockedLoading && (
                <>
                  <Spinner /> Loading...
                </>
              )}
              {!licenseVersionLockedLoading &&
                !licenseVersionLockedError &&
                !licenseVersionLocked && (
                  <Badge
                    color="yellow"
                    text={`License can be changed by owner`}
                  />
                )}
              {!licenseVersionLockedLoading &&
                !licenseVersionLockedError &&
                licenseVersionLocked && (
                  <Badge
                    color="green"
                    text={`License is locked and cannot change`}
                  />
                )}
            </dd>
          </div>
        </dl>
      </div>

      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
        <div className="flex-1 flex gap-2 items-center">
          {currentLicenseVersionLoading ? (
            <>
              <Spinner /> Checking current license...
            </>
          ) : licenseVersionLockerLoading ? (
            <>
              <Spinner /> Working...
            </>
          ) : null}
          {licenseVersionLocker.txReceipt || licenseVersionLocker.txResponse ? (
            <>
              <TransactionLink
                txReceipt={licenseVersionLocker.txReceipt}
                txResponse={licenseVersionLocker.txResponse}
              />
            </>
          ) : null}
          {licenseVersionLockerError ? (
            <Errors error={licenseVersionLockerError} />
          ) : null}
        </div>
        <Button
          className={PRIMARY_BUTTON}
          text={'Lock license forever'}
          onClick={() => lockLicenseVersion()}
          disabled={licenseVersionLockerLoading || licenseVersionLocked}
        />
      </div>
    </div>
  );

  const guideLicenseView = (
    <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
          Guide
        </h3>
        <img src="https://imgur.com/NMTSRxA.png" alt="a16z-licenses" />
        <p className="mt-4 text-xs text-gray-500">
          <b>Copy, Display & Distribute</b> - You can display, distribute, and
          copy your NFT. This right alone does not allow you to modify or adapt
          the NFT.
        </p>
        <p className="mt-4 text-xs text-gray-500">
          <b>Commercial Use</b> – You can use the NFT for commercial purposes
        </p>
        <p className="mt-4 text-xs text-gray-500">
          <b>Modify & Adapt</b> - You can modify and adapt your NFT.
        </p>
        <p className="mt-4 text-xs text-gray-500">
          <b>Sublicense</b> - You can sublicense your rights to the NFT or, if
          permitted to make modifications and adaptations, your modifications
          and adaptations to the NFT.
        </p>
        <p className="mt-4 text-xs text-gray-500">
          <b>Hate Speech Revocation</b> - You cannot use the NFT Media in a way
          that is unlawful, defamatory, harassing, abusive, fraudulent, racist,
          hateful, vulgar, cruel, illegal or obscene, or that promotes any such
          activity.
        </p>
      </div>
    </div>
  );

  return (
    <>
      {setLicenseView}
      {freezeLicenseView}
      {guideLicenseView}
    </>
  );
};
