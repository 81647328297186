import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
  FLAIR_CHAIN_MACHINE_NAMES,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
} from 'flair-sdk';
import { Fragment } from 'react';
import { useNetwork } from 'wagmi';

import { CodeBlock } from './CodeBlock';

type Props = {
  githubUrl?: string;
  manualDeploymentCode?: string;
  manualDeployedContractName?: string;
  setOpen: (state: boolean) => void;
  open: boolean;
};

function normalizeCode(code: string) {
  let replacedCode = code.replaceAll(
    '../extensions/ERC721',
    '@0xflair/evm-contracts/collections/ERC721/extensions/ERC721',
  );

  return replacedCode;
}

export default function CodeBuilder(props: Props) {
  const {
    githubUrl,
    manualDeploymentCode,
    manualDeployedContractName,
    open,
    setOpen,
  } = props;

  const { activeChain } = useNetwork();

  const replacedCode =
    manualDeploymentCode && normalizeCode(manualDeploymentCode);

  const contractCodeSectionView = (
    <div>
      <div className="md:grid md:grid-cols-8 md:gap-6">
        <div className="md:col-span-2">
          <div className="flex flex-col gap-10">
            <div>
              <h3 className="text-2xl font-medium leading-6 text-gray-900">
                Your contract. Your code.
              </h3>
              <p className="mt-4 text-sm text-gray-600">
                This is the actual code getting deployed right from your
                browser, through your wallet, onto the blockchain. No dependency
                on Flair's backend.
              </p>
              <p className="mt-4 text-sm text-gray-600 flex gap-x-4">
                {replacedCode && (
                  <a
                    href={`https://remix.ethereum.org/?#code=${Buffer.from(
                      replacedCode,
                    ).toString(
                      'base64',
                    )}&optimize=true&runs=5&evmVersion=null&version=soljson-v0.8.7+commit.e28d00a7.js`}
                    className={PRIMARY_BUTTON}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Remix
                  </a>
                )}
                {githubUrl && (
                  <a href={githubUrl} className={SECONDARY_BUTTON}>
                    View in Github
                  </a>
                )}
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-medium leading-6 text-gray-900">
                Prefer to manually deploy yourself?
              </h3>
              <p className="mt-4 text-sm text-gray-600">
                Follow instructions below if you prefer to manually deploy your
                contract from your development environment.
              </p>
              <p className="mt-4 text-sm text-gray-600">
                <h3 className="mb-2 text-lg font-light leading-6 text-gray-900">
                  1. Clone{' '}
                  <a
                    href="https://github.com/0xflair/evm-contract-scaffold"
                    target={'_blank'}
                    className={'text-indigo-600'}
                    rel="noreferrer"
                  >
                    evm-contract-scaffold
                  </a>{' '}
                  template
                </h3>
                <div className="sm:rounded-md overflow-hidden">
                  <CodeBlock
                    code={`git clone \\
 https://github.com/0xflair/evm-contract-scaffold`}
                    language="bash"
                  />
                </div>
              </p>
              <p className="mt-4 text-sm text-gray-600">
                <h3 className="mb-2 text-lg font-light leading-6 text-gray-900">
                  2. Install dependencies
                </h3>
                <div className="sm:rounded-md overflow-hidden">
                  <CodeBlock
                    code={`npm install
npm i @0xflair/evm-contracts`}
                    language="bash"
                  />
                </div>
              </p>
              <p className="mt-4 text-sm text-gray-600">
                <h3 className="mb-2 text-lg font-light leading-6 text-gray-900">
                  3. Create contract code in a new file
                </h3>
                <div className="sm:rounded-md overflow-hidden">
                  <CodeBlock
                    code={`vi contracts/${
                      manualDeployedContractName || 'MyContract'
                    }.sol`}
                    language="bash"
                  />
                </div>
              </p>
              <p className="mt-4 text-sm text-gray-600">
                <h3 className="mb-2 text-lg font-light leading-6 text-gray-900">
                  4. Compile the contract
                </h3>
                <div className="sm:rounded-md overflow-hidden">
                  <CodeBlock code={`npm run compile`} language="bash" />
                </div>
              </p>
              <p className="mt-4 text-sm text-gray-600">
                <h3 className="mb-2 text-lg font-light leading-6 text-gray-900">
                  5. Deploy the contract (e.g.{' '}
                  {FLAIR_CHAIN_MACHINE_NAMES[activeChain?.id as 1]
                    ? activeChain?.name
                    : 'Rinkeby Testnet'}
                  )
                </h3>
                <div className="sm:rounded-md overflow-hidden">
                  <CodeBlock
                    code={`npm run deploy:${
                      FLAIR_CHAIN_MACHINE_NAMES[activeChain?.id as 1] ||
                      FLAIR_CHAIN_MACHINE_NAMES[4]
                    }`}
                    language="bash"
                  />
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className={`mt-5 md:mt-0 md:col-span-6`}>
          <div className="sm:rounded-md overflow-hidden">
            <CodeBlock
              code={manualDeploymentCode || '// ...'}
              language="typescript"
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="z-30 absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-30 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="pointer-events-auto w-screen w-full flex flex-col justify-end">
                <div className="flex w-full h-90% flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-4xl font-bold text-gray-900">
                        Inspect the code
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-10 flex-1 px-4 sm:px-6">
                    {contractCodeSectionView}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
