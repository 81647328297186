import { useHasAnyOfFeatures } from '@0xflair/react-common';
import {
  ButtonWithDialog,
  Environment,
  StreamAccountSelectedTicketTokens,
  StreamAccountTotalTicketTokens,
  StreamClaimingSection,
  StreamCommonStatusBar,
  StreamProvider,
  StreamStakingSection,
  StreamTicketTokenSelector,
  WalletDropdown,
} from 'flair-sdk';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useAccount } from 'wagmi';

import { RequireConnect } from '../../../components/ui/RequireConnect';

type Props = {
  env?: Environment;
};

export const TokenStreamClaimingPage = ({ env = Environment.PROD }: Props) => {
  const { data: account } = useAccount();
  const { contractAddress, chainId: chainId_ } = useParams();
  const chainId = Number(chainId_);

  if (!contractAddress) {
    throw new Error('Must provide "contractAddress" in the URL');
  }

  const { data: hasStakingExtension } = useHasAnyOfFeatures({
    env,
    chainId,
    contractAddress,
    tags: ['flair_stream_staking_extension'],
  });

  const { search } = window.location;
  const qs = useMemo(() => new URLSearchParams(search), [search]);
  const walletAddress = qs.has('wallet')
    ? qs.get('wallet')?.toString().length === 42
      ? qs.get('wallet')?.toString()
      : undefined
    : undefined;

  return (
    <StreamProvider
      env={env}
      contractAddress={contractAddress}
      chainId={Number(chainId)}
      walletAddress={walletAddress}
    >
      {({ data: { stream, ticketTokens } }) => (
        <>
          <style type="text/css">{`html,body {background: none transparent !important;}`}</style>
          <div className="flex flex-col items-center p-8">
            <h1 className="flex items-center justify-between text-4xl font-medium text-gray-900">
              {stream?.publicTitle}
            </h1>

            <div className="mt-4">
              <RequireConnect notConnectedView={<></>}>
                <WalletDropdown />
              </RequireConnect>
            </div>

            {account?.address ? (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                You have selected{' '}
                <b>
                  <StreamAccountSelectedTicketTokens />
                </b>{' '}
                from{' '}
                <b>
                  <StreamAccountTotalTicketTokens />
                </b>{' '}
                NFTs you own.{' '}
                {ticketTokens ? (
                  <ButtonWithDialog
                    as={'span'}
                    label={'Change'}
                    dialogTitle={'Select NFTs from your wallet'}
                    className="text-indigo-600 cursor-pointer"
                  >
                    <StreamTicketTokenSelector className="scroll-visible grid justify-center grid-cols-2 max-h-96 overflow-scroll" />
                  </ButtonWithDialog>
                ) : null}
              </div>
            ) : null}

            {hasStakingExtension ? (
              <div className="mt-4 flex flex-col sm:flex-row items-start gap-2">
                <div className="flex flex-col gap-3 p-4">
                  <h2 className="text-2xl font-light text-gray-900 text-center">
                    1. Stake
                  </h2>
                  <div className="bg-gray-50 rounded-lg">
                    <StreamStakingSection />
                  </div>
                </div>
                <div className="flex flex-col gap-3 p-4">
                  <h2 className="text-2xl font-light text-gray-900 text-center">
                    2. Claim
                  </h2>
                  <div className="bg-gray-50 rounded-lg">
                    <StreamClaimingSection />
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <StreamClaimingSection />
              </div>
            )}

            <div className="mt-4">
              <StreamCommonStatusBar />
            </div>

            <div className="mt-4 lg:col-span-12">
              {stream?.publicDescription ? (
                <div className="mt-4">
                  <h2 className="mt-4 text-sm font-medium text-gray-900">
                    Description
                  </h2>

                  <div className="mt-3 prose prose-sm text-gray-500">
                    {stream?.publicDescription}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </StreamProvider>
  );
};
