import { Environment, useAxiosPost } from 'flair-sdk';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { FLAIR_FUNGIBLE_TOKENS_BACKEND } from '../constants';
import { FungibleToken } from '../types';

type UpdaterConfig = {
  env?: Environment;
  enabled?: boolean;
};

export function useFungibleTokenCreator<TConfig extends Record<string, any>>(
  FungibleToken: Partial<FungibleToken<TConfig>>,
  { enabled = false, env = Environment.PROD }: UpdaterConfig,
) {
  const { data: account } = useAccount();

  const params = useMemo(() => {
    return {
      walletAddress: account?.address,
    };
  }, [account?.address]);

  return useAxiosPost<FungibleToken<TConfig>>({
    url: `${FLAIR_FUNGIBLE_TOKENS_BACKEND[env]}/v1/fungible-tokens`,
    data: FungibleToken,
    enabled: Boolean(account?.address && enabled && FungibleToken),
    params,
  });
}
