import { ContractVersion } from '@0xflair/contracts-registry';
import { useContractAbi, useContractWriteAndWait } from '@0xflair/react-common';
import { Provider } from '@ethersproject/providers';
import { Signer } from 'ethers';

import { LicenseVersion } from '../../nft-collections/types/license.types';

type Config = {
  contractVersion?: ContractVersion;
  contractAddress?: string;
  signerOrProvider?: Signer | Provider | null;
};

type ArgsType = [];

export const useLicenseVersionLocker = ({
  contractVersion,
  contractAddress,
  signerOrProvider,
}: Config) => {
  const contractInterface = useContractAbi({
    contractVersion,
    contractFqn: 'common/LicenseExtension',
  });

  return useContractWriteAndWait<ArgsType>({
    contractInterface,
    contractAddress,
    signerOrProvider,
    functionName: 'lockLicenseVersion',
  });
};
