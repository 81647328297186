import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { classNames, Environment } from 'flair-sdk';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  env?: Environment;
};

const presets = [
  {
    id: 1,
    title: 'Revenue & income split via NFTs',
    description:
      'A stream that distributes revenue or income between multiple parties by holding a special NFT.',
    gasUsage: '282277',
    link: '/streams/create/ERC721ShareInstantStream',
  },
  {
    id: 2,
    title: 'Reward for holding NFTs',
    description:
      'Vest any ERC20 token with a specific rate for your NFT holders. Useful for community rewards, or token vesting for team, advisors or investors.',
    gasUsage: '276796',
    link: '/streams/create/ERC721SingleTokenEqualEmissionStream',
  },
  {
    id: 3,
    title: 'Reward for locking NFTs',
    description:
      'Vest any ERC20 token with a specific rate by locking NFTs in a staking contract.',
    gasUsage: '296796',
    link: '/streams/create/ERC721StakingStream',
    disabled: false,
  },
  {
    id: 4,
    title: 'Distribute tokens to NFT holders',
    description:
      'Instantly distribute any token (native or ERC20) to all NFTs so owners can claim.',
    gasUsage: '296796',
    link: '/streams/create/ERC721EqualInstantStream',
    disabled: false,
  },
];

export const CreateTokenStream = ({ env = Environment.PROD }: Props) => {
  const [selectedPreset, setSelectedPreset] = useState();

  return (
    <div className="p-8 relative">
      <RadioGroup value={selectedPreset} onChange={setSelectedPreset}>
        <RadioGroup.Label className="h2 text-4xl font-bold text-gray-900">
          What type of token stream you want to create?
        </RadioGroup.Label>
        <p className="text-gray-600 pt-4 pb-8">
          Select the type of streaming or staking pool you would like to build.
        </p>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {presets.map((preset) => (
            <RadioGroup.Option
              as={Link}
              to={preset.disabled ? '/streams' : preset.link}
              key={preset.id}
              value={preset}
              disabled={preset.disabled}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                  preset.disabled
                    ? 'opacity-60 cursor-not-allowed'
                    : 'bg-white cursor-pointer',
                  'relative  border rounded-lg shadow-sm p-4 flex focus:outline-none',
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <div className="flex-1 flex">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-lg font-bold text-gray-900"
                      >
                        {preset.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-sm text-gray-500 relative"
                      >
                        {preset.description}
                      </RadioGroup.Description>
                      {/* <RadioGroup.Description
                        as="span"
                        className="mt-6 text-xs font-medium text-gray-900"
                      >
                        <b>Gas on {activeChain?.name}:</b> ~
                        <CryptoPrice
                          value={feeData?.gasPrice?.mul(preset.gasUsage)}
                          symbol={
                            activeChain?.nativeCurrency?.symbol as CryptoSymbol
                          }
                          unit={CryptoUnits.WEI}
                        />{' '}
                        USD {activeChain?.testnet ? '(Testnet)' : ''}
                      </RadioGroup.Description> */}
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? 'invisible' : '',
                      'h-5 w-5 text-indigo-600',
                    )}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none',
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
