import { Environment } from 'flair-sdk';

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: Record<string, any>,
    ) => void;
  }
}

export const GA_MEASUREMENT_ID = 'G-NCK76H10KV';

export const FLAIR_DASHBOARD_HOST: { [key in Environment]: string } = {
  [Environment.DEV]: 'http://localhost:8000',
  [Environment.PROD]: 'https://v1.flair.dev',
};

export const dateForDateTimeInputValue = (date: Date) =>
  new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000)
    .toISOString()
    .slice(0, 19);
