import { BeakerIcon } from '@heroicons/react/outline';
import {
  AddressScannerLink,
  ChainBadge,
  classNames,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
} from 'flair-sdk';
import { IoLayersOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { useNftCollections } from '../../features/nft-collections/latest/hooks/common/useNftCollections';

type Props = {
  env?: Environment;
};

export const BrowseNftCollections = ({ env = Environment.PROD }: Props) => {
  const { data, error, isLoading } = useNftCollections({ env, enabled: true });

  if (isLoading) {
    return (
      <div className="p-20 flex gap-2 justify-center items-center">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (error || data === undefined) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Errors error={error || 'Unknown error :('} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="p-20 text-center">
        <IoLayersOutline className="mx-auto h-16 w-16 text-gray-400" />
        <h3 className="mt-6 mb-4 text-2xl font-extrabold text-gray-900">
          Create your first collection
        </h3>
        <p className="mt-1 text-lg text-gray-500">
          A collection of 1-of-1 pieces, or a collection of gaming assets,
          <br />
          or even a randomly-generated 10k PFP collection.
        </p>
        <div className="mt-6 flex gap-2 justify-center">
          <Link
            to={'/collections/create'}
            className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create a collection
          </Link>
        </div>
        <div className="mt-6 flex justify-center">
          <a
            href={`https://docs.flair.finance/sdk/nft-collections`}
            className={'text-indigo-500 flex gap-2 items-center'}
            target="_blank"
            rel="noreferrer"
          >
            <BeakerIcon className="h-5 w-5" aria-hidden="true" />
            Explore SDK
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Collections</h1>
            <p className="mt-2 text-sm text-gray-700">
              Your self-owned NFT collections allow you to issue digital assets
              immutably. You see the code, you own the key, you are the admin.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
            <a
              href={`https://docs.flair.finance/sdk/nft-collections`}
              className={classNames(SECONDARY_BUTTON, 'whitespace-nowrap')}
              target="_blank"
              rel="noreferrer"
            >
              <BeakerIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Explore SDK
            </a>
            <Link
              to="/collections/create"
              className={classNames(PRIMARY_BUTTON, 'whitespace-nowrap')}
            >
              Create a new collection
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Chain
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Manage</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((item) => (
                      <tr key={item._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <Link
                            to={`/collections/${item._id}`}
                            className="text-indigo-700"
                          >
                            {item.config?.collectionName ||
                              item.contractAddress || <i>Untitled</i>}
                            {item.config?.collectionSymbol && (
                              <small className="text-xs">
                                {' '}
                                ({item.config?.collectionSymbol})
                              </small>
                            )}
                          </Link>
                          {!item.config?.title && item.contractAddress && (
                            <>
                              <br />
                              <AddressScannerLink
                                address={item.contractAddress}
                                chainId={item.chainId}
                                className="text-xs text-gray-700"
                              />
                            </>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <ChainBadge chainId={item.chainId} />
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={`/collections/${item._id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Manage
                            <span className="sr-only">
                              , {item.config?.title}
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
