import { ZERO_ADDRESS, ZERO_BYTES32 } from '@0xflair/react-common';
import { utils } from 'ethers';
import {
  Button,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  Tier,
  TransactionLink,
  useChainId,
} from 'flair-sdk';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { TiersTable } from '../components/TiersTable';
import { useNftCollectionUpdater } from '../hooks/common/useNftCollectionUpdater';
import { useCollectionTiersUpdater } from '../hooks/useCollectionTiersUpdater';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const TiersAdminSection = ({ env, nftCollection, refresh }: Props) => {
  const chainId = useChainId(nftCollection.chainId);
  const contractAddress = nftCollection.contractAddress;

  const [tiers, setTiers] = useState<Record<number, Tier>>({});

  useEffect(() => {
    if (!nftCollection.contractAddress && nftCollection.config?.initialTiers) {
      setTiers(nftCollection.config.initialTiers);
    }
  }, [nftCollection.config?.initialTiers, nftCollection.contractAddress]);

  // const merkleLeafWithAllowance = useMerkleLeafAddressWithAllowance({
  //   address: '0x6218c88fa911c38f335cbda3b32205c8704fd721',
  //   maxAllowance: 33,
  // });

  // const {
  //   data: { merkleProof, merkleMetadata, isAllowlisted },
  //   error: allowlistCheckerError,
  //   isLoading: allowlistCheckerLoading,
  // } = useTierSaleAllowlistChecker({
  //   env: Environment.PROD,
  //   chainId: 1,
  //   contractAddress: '0xdf9669a65c5845e472ad3ca83d07605a9d7701b7',
  //   enabled: true,
  //   merkleRoot:
  //     '0xbe18cc1b825890a1b1a4fb1bc8712214e801e1247c4ea0ad65809d99f00abeb5',
  //   tierId: '1',
  //   // minterAddress: '0x6218c88fa911c38f335cbda3b32205c8704fd721',
  //   minterAddress: '0x6218C88Fa911c38f335CBDa3B32205c8704fD721',
  //   // minterAddress: '0x431922e8eDe1cE07ed80C89Ac4C302e23Dc6ba20',
  //   // minterAddress: '0x431922e8eDe1cE07ed80C89Ac4C302e23Dc6ba20'.toLowerCase(),
  // });

  // console.log('allowlistCheckerError === ', allowlistCheckerError);
  // console.log('allowlistCheckerLoading === ', allowlistCheckerLoading);
  // // console.log('merkleLeafWithAllowance === ', merkleLeafWithAllowance);
  // console.log('merkleProof === ', merkleProof);
  // console.log('merkleMetadata === ', merkleMetadata);
  // console.log('isAllowlisted === ', isAllowlisted);

  const {
    error: collectionUpdaterError,
    isLoading: collectionUpdaterLoading,
    sendRequest: saveCollection,
  } = useNftCollectionUpdater(
    {
      _id: nftCollection._id,
      config: {
        ...nftCollection.config,
        initialTiers: tiers,
      },
    },
    {
      enabled: false,
      env,
    },
  );

  const {
    data: collectionTiersUpdaterData,
    error: collectionTiersUpdaterError,
    isLoading: collectionTiersUpdaterLoading,
    updateTiers,
  } = useCollectionTiersUpdater({
    contractAddress,
  });

  const appendTier = useCallback(() => {
    setTiers &&
      setTiers({
        ...tiers,
        [tiers ? Object.keys(tiers).length : 0]: {
          currency: ZERO_ADDRESS,
          start: Math.floor(+new Date() / 1000),
          end: moment().add(30, 'day').unix(),
          price: utils.parseEther('10').toString(),
          maxAllocation: 10000,
          maxPerWallet: 10,
          merkleRoot: ZERO_BYTES32,
          reserved: 0,
        },
      });
  }, [setTiers, tiers]);

  const saveAllTiers = useCallback(() => {
    if (nftCollection.contractAddress) {
      updateTiers({
        tiers,
      }).then(refresh);
    } else {
      saveCollection();
    }
  }, [
    nftCollection.contractAddress,
    updateTiers,
    tiers,
    refresh,
    saveCollection,
  ]);

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Tiers</h3>
          <p className="mt-1 text-sm text-gray-500">
            Define how many tiers you have in your sales.
          </p>
        </div>
        <div className="p-4 bg-gray-50 flex flex-col gap-4">
          <TiersTable
            env={env}
            chainId={chainId}
            contractAddress={contractAddress}
            tiers={tiers}
            setTiers={setTiers}
          />
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-between">
          <div className="flex flex-grow gap-2 items-center">
            {collectionUpdaterLoading && (
              <>
                <Spinner /> Saving...
              </>
            )}
            {collectionTiersUpdaterLoading && (
              <>
                <Spinner /> Saving...
              </>
            )}
            {collectionUpdaterError && (
              <Errors
                title="collectionUpdaterError"
                error={collectionUpdaterError}
              />
            )}
            {collectionTiersUpdaterError && (
              <Errors
                title="collectionTiersUpdaterError"
                error={collectionTiersUpdaterError}
              />
            )}
            {collectionTiersUpdaterData.txReceipt ||
            collectionTiersUpdaterData.txResponse ? (
              <TransactionLink
                txReceipt={collectionTiersUpdaterData.txReceipt}
                txResponse={collectionTiersUpdaterData.txResponse}
              />
            ) : null}
          </div>
          <div className="flex gap-2">
            <Button
              className={SECONDARY_BUTTON}
              text="Add a tier"
              onClick={appendTier}
            />
            <Button
              className={PRIMARY_BUTTON}
              text="Save all tiers"
              onClick={saveAllTiers}
              disabled={Boolean(
                collectionTiersUpdaterLoading || collectionUpdaterLoading,
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};
