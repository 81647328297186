import { Badge } from 'flair-sdk';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  children?: ReactNode;
};

export const UnsupportedFeatureWidget = ({ title, children }: Props) => {
  return (
    <div className={'bg-gray-200 shadow overflow-hidden rounded-lg'}>
      <div className="relative px-4 py-5 sm:px-6">
        <span className="absolute top-1/2 transform -translate-y-1/2 right-6">
          <Badge color="gray" text="Unsupported in this preset" />
        </span>
        <h3 className="text-lg leading-6 font-medium text-gray-700">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{children}</p>
      </div>
    </div>
  );
};
