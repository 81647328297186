import { BigNumber, BigNumberish, BytesLike } from 'ethers';
import {
  Environment,
  Errors,
  Spinner,
  useChainInfo,
  useNftTokensByCollection,
  useStreamShares,
} from 'flair-sdk';
import { Dispatch, SetStateAction, useEffect } from 'react';

type Props = {
  env?: Environment;
  chainId?: number;
  streamAddress?: BytesLike;
  registryAddress?: BytesLike;
  shares?: Record<string, BigNumberish>;
  setSharesForTokens?: Dispatch<SetStateAction<Record<string, BigNumberish>>>;
};

export const TokenSharesTable = ({
  env,
  chainId,
  streamAddress,
  registryAddress,
  shares,
  setSharesForTokens,
}: Props) => {
  const chainInfo = useChainInfo(chainId);
  const totalShares = Object.values(shares || {})?.reduce(
    (acc, cur) => BigNumber.from(acc || 0).add(BigNumber.from(cur || 0)),
    BigNumber.from(0),
  );

  const {
    data: nfts,
    error: nftsError,
    isLoading: nftsLoading,
  } = useNftTokensByCollection({
    env,
    chainId,
    collectionAddress: registryAddress?.toString(),
  });

  const {
    data: streamShares,
    error: streamSharesError,
    isLoading: streamSharesLoading,
  } = useStreamShares({
    env,
    chainId,
    contractAddress: streamAddress?.toString(),
    args: { ticketTokenIds: nfts?.map((nft) => nft.tokenId) },
  });

  useEffect(() => {
    if (
      nfts &&
      nfts.length > 0 &&
      streamShares &&
      streamShares.length > 0 &&
      (!shares || !Object.values(shares).length)
    ) {
      setSharesForTokens &&
        setSharesForTokens(
          streamShares.reduce(
            (
              acc: Record<string, BigNumberish>,
              cur: BigNumberish,
              index: number,
            ) => {
              acc[nfts[index].tokenId] = cur;
              return acc;
            },
            {} as Record<string, BigNumberish>,
          ),
        );
    }
  }, [nfts, setSharesForTokens, shares, streamShares]);

  return (
    <table className="min-w-full">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 whitespace-nowrap"
          >
            Token ID
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
          >
            Current holder
          </th>
          <th
            scope="col"
            className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Shares
          </th>
        </tr>
      </thead>
      <tbody>
        {nfts
          ? nfts.map((nft, index) => (
              <tr key={index.toString()} className="border-b border-gray-200">
                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0 whitespace-nowrap text-center">
                  <a
                    target={'_blank'}
                    href={`${chainInfo?.blockExplorers?.default.url}/token/${registryAddress}?a=${nft.tokenId}#inventory`}
                    className="text-indigo-500"
                    rel="noreferrer"
                  >
                    # {nft.tokenId}
                  </a>
                </td>
                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0 w-full">
                  <a
                    target={'_blank'}
                    href={`${chainInfo?.blockExplorers?.default.url}/token/${registryAddress}?a=${nft.ownerAddress}#inventory`}
                    className="text-indigo-500"
                    rel="noreferrer"
                  >
                    {nft.ownerAddress}
                  </a>
                </td>
                <td className="py-4 px-3 text-right text-sm sm:table-cell">
                  <input
                    type="number"
                    autoComplete="shares"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-24 shadow-sm sm:text-sm border-gray-300 rounded-md"
                    min={0}
                    placeholder="0"
                    disabled={streamSharesLoading}
                    value={shares?.[nft.tokenId]?.toString() || ''}
                    onChange={(event) => {
                      setSharesForTokens &&
                        setSharesForTokens((x) => {
                          return {
                            ...(x || {}),
                            [nft.tokenId]: Math.ceil(
                              Number(event.target.value),
                            ),
                          };
                        });
                    }}
                  />
                </td>
              </tr>
            ))
          : null}
      </tbody>
      <tfoot>
        <tr>
          <td
            colSpan={2}
            className="pl-3 pr-4 pt-4 text-sm text-gray-900 sm:pr-6 md:pr-0"
          >
            <div className="flex gap-2 w-full items-center">
              {nftsLoading && (
                <>
                  <Spinner /> Loading tokens...
                </>
              )}
              {streamSharesLoading && (
                <>
                  <Spinner /> Loading shares...
                </>
              )}
              {nftsError && <Errors title="nftsError" error={nftsError} />}
              {streamSharesError && (
                <Errors title="streamSharesError" error={streamSharesError} />
              )}
            </div>
          </td>
          <td
            colSpan={1}
            className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0 whitespace-nowrap"
          >
            Total Shares: {totalShares?.toString()}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
