import {
  ContractFqn,
  NftCollection,
  useUnorderedForwarderAddress,
} from 'flair-sdk';
import { useMemo } from 'react';

import { NftCollectionConstructorFactoryRegistry } from '../../../registries/constructor-factory-registry';

export const useNftCollectionConstructorArguments = <T extends any[] = any[]>(
  nftCollection?: Partial<NftCollection<any>> | null,
): T => {
  const forwarderAddress = useUnorderedForwarderAddress();

  const constructorArguments = useMemo(() => {
    if (!nftCollection?.presetFqn) {
      return [];
    }

    try {
      const factory =
        NftCollectionConstructorFactoryRegistry[nftCollection.presetFqn] ||
        NftCollectionConstructorFactoryRegistry[
          nftCollection.presetFqn.replace(
            /\/ERC721A/gi,
            '/ERC721',
          ) as ContractFqn
        ];

      if (!factory) {
        return [];
      }

      return factory(nftCollection, {
        forwarderAddress,
      });
    } catch (e) {
      console.warn(
        `Error while constructing arguments for NftCollection: ${e}`,
        nftCollection,
        e,
      );
      return [];
    }
  }, [forwarderAddress, nftCollection]);

  return constructorArguments as T;
};
