import { CheckIcon } from '@heroicons/react/outline';
import {
  Badge,
  Button,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  ZERO_ADDRESS,
} from 'flair-sdk';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNetwork } from 'wagmi';

import { useCollectionMetadataUpdater } from '../../../../../features/nft-collections/latest/hooks/metadata/useCollectionMetadataUpdater';
import { ERC721OneOfOneCollectionCreationState } from '../types';

type Props = {
  env?: Environment;
  className?: string;
  creationState: ERC721OneOfOneCollectionCreationState;
  setCreationState: Dispatch<
    SetStateAction<ERC721OneOfOneCollectionCreationState>
  >;
};

export const Setup = ({
  env,
  className,
  creationState,
  setCreationState,
}: Props) => {
  const { activeChain } = useNetwork();

  const {
    data: collectionMetadata,
    error: collectionMetadataError,
    isLoading: collectionMetadataLoading,
    uploadAndUpdate: uploadNftCollectionMetadata,
  } = useCollectionMetadataUpdater({
    env,
    metadataUpdates: {
      ...creationState,
      additionalMetadataAttributes: {
        // reference: https://docs.opensea.io/docs/contract-level-metadata
        seller_fee_basis_points: Math.round(
          Number(creationState.royaltyPercent) * 100,
        ), // Indicates the % creator fee
        fee_recipient: creationState.royaltyAddress, // Where seller fees will be paid to
      },
    },
    contractAddress: ZERO_ADDRESS,
  });

  useEffect(() => {
    if (
      collectionMetadata.collectionImageUploaderUri &&
      collectionMetadata.collectionImageUploaderUri !==
        creationState.collectionImageUri
    ) {
      setCreationState((e) => ({
        ...e,
        collectionImageUri: collectionMetadata.collectionImageUploaderUri,
      }));
    }
    if (
      collectionMetadata.collectionMetadataUploaderUri &&
      collectionMetadata.collectionMetadataUploaderUri !==
        creationState.collectionMetadataUri
    ) {
      setCreationState((e: any) => ({
        ...e,
        collectionMetadataUri: collectionMetadata.collectionMetadataUploaderUri,
      }));
    }
  }, [
    setCreationState,
    creationState,
    collectionMetadata.collectionImageUploaderUri,
    collectionMetadata.collectionMetadataUploaderUri,
  ]);

  const metadataUploaded =
    collectionMetadata.collectionImageUploaderUri &&
    collectionMetadata.collectionMetadataUploaderUri;

  const readyToDeploy = metadataUploaded;

  useEffect(
    () => {
      if (
        creationState.collectionName &&
        creationState.collectionDescription &&
        creationState.collectionImageFile &&
        !collectionMetadataLoading &&
        !collectionMetadataError &&
        !metadataUploaded
      ) {
        uploadNftCollectionMetadata();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const steps = [
    {
      name: 'Upload collection metadata',
      description: 'Collection metadata must be uploaded to IPFS.',
      status: collectionMetadataLoading
        ? 'current'
        : collectionMetadataError
        ? 'upcoming'
        : 'complete',
      content: (
        <div className="p-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            {collectionMetadataLoading ? (
              <Badge text="Upload In Progress ..." color="yellow" />
            ) : (
              metadataUploaded && (
                <Badge
                  text={`Metadata uploaded to ${collectionMetadata.collectionMetadataUploaderUri}`}
                  color="green"
                />
              )
            )}
            {collectionMetadataError ? (
              <Errors error={collectionMetadataError} />
            ) : null}
            {!creationState.collectionImageFile ? (
              <Badge text="You must choose an image file" color="red" />
            ) : null}
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              {!metadataUploaded && (
                <Button
                  text="Try again"
                  className={PRIMARY_BUTTON}
                  disabled={collectionMetadataLoading}
                  onClick={uploadNftCollectionMetadata}
                />
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'Read to deploy',
      description: `Now your collection is fully ready to be deployed on ${activeChain?.name}.`,
      status: readyToDeploy ? 'complete' : 'upcoming',
      content: <div className="px-4 py-5 sm:p-6"></div>,
    },
  ];

  // scroll to top
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <nav aria-label="Progress" className={className}>
      <ol className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={`relative ${stepIdx !== steps.length - 1 ? 'pb-4' : ''}`}
          >
            <div className="grid grid-cols-8 gap-6">
              <div className="col-span-3">
                {step.status === 'complete' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-green-600"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start group">
                      <span className="h-9 flex items-center">
                        <span className="relative z-5 w-8 h-8 flex items-center justify-center bg-green-600 rounded-full">
                          <CheckIcon
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide">
                          {step.name}
                        </span>
                        <span className="text-sm text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </div>
                  </>
                ) : step.status === 'current' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div
                      className="relative flex items-start group"
                      aria-current="step"
                    >
                      <span
                        className="h-9 flex items-center"
                        aria-hidden="true"
                      >
                        <span className="relative z-5 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                          <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide text-indigo-600">
                          {step.name}
                        </span>
                        <span className="text-sm text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start group">
                      <span
                        className="h-9 flex items-center"
                        aria-hidden="true"
                      >
                        <span className="relative z-5 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full">
                          <span className="h-2.5 w-2.5 bg-transparent rounded-full" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide text-gray-500">
                          {step.name}
                        </span>
                        <span className="text-sm text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="col-span-5">{step.content}</div>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
