import { FeatureType, NftCollection, SmartContract } from 'flair-sdk';
import { CgList, CgLock, CgMathPercent, CgShoppingBag } from 'react-icons/cg';
import {
  HiOutlineFingerPrint,
  HiOutlineIdentification,
  HiOutlinePlay,
  HiOutlineUsers,
} from 'react-icons/hi';
import { IoAppsOutline, IoInfiniteSharp } from 'react-icons/io5';
import { MdOutlineFactCheck } from 'react-icons/md';
import { TbCashBanknote, TbConfetti, TbLicense } from 'react-icons/tb';

import { RolesAdminSection } from '../../common/sections/RolesAdminSection';
import { VerificationAdminSection } from '../../common/sections/VerificationAdminSection';
import { WithdrawAdminSection } from '../../common/sections/WithdrawAdminSection';
import { AdminSection } from '../../common/types';
import { AllowlistAdminSection } from '../latest/sections/AllowlistAdminSection';
import { DeployAdminSection } from '../latest/sections/DeployAdminSection';
import { LicenseAdminSection } from '../latest/sections/LicenseAdminSection';
import { MetadataAdminSection } from '../latest/sections/MetadataAdminSection';
import { MintingAdminSection } from '../latest/sections/MintingAdminSection';
import { PreSaleAdminSection } from '../latest/sections/PreSaleAdminSection';
import { PublicSaleAdminSection } from '../latest/sections/PublicSaleAdminSection';
import { RevealAdminSection } from '../latest/sections/RevealAdminSection';
import { RoyaltyAdminSection } from '../latest/sections/RoyaltyAdminSection';
import { SupplyAdminSection } from '../latest/sections/SupplyAdminSection';
import { TiersAdminSection } from '../latest/sections/TiersAdminSection';
import { TokensAdminSection } from '../latest/sections/TokensAdminSection';

type NftCollectionAdminSectionContext = {
  nftCollection?: NftCollection;
};

export const AdminSectionsRegistry: AdminSection[] = [
  {
    name: 'Deploy',
    href: '#deploy',
    icon: HiOutlinePlay,
    widget: DeployAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.presetFqn);
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(true);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.contractAddress || ctx?.nftCollection?.contractAddress,
      );
    },
  },
  {
    name: 'Tokens',
    href: '#tokens',
    icon: IoAppsOutline,
    widget: TokensAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) =>
            f.type === FeatureType.Interface &&
            f.tag === 'erc721_owner_managed_extension',
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Tiers',
    href: '#tiers',
    icon: CgShoppingBag,
    widget: TiersAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['erc721_tiering_extension', 'mint_by_tier'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Pre Sale',
    href: '#pre-sale',
    icon: CgLock,
    widget: PreSaleAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['erc721_presale_extension', 'presale_price'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Allowlist',
    href: '#allowlist',
    icon: CgList,
    widget: AllowlistAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          [
            'erc721_presale_extension',
            'check_allowlisted_by_merkle_proof',
          ].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Public Sale',
    href: '#public-sale',
    icon: CgShoppingBag,
    widget: PublicSaleAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['erc721_public_sale_extension', 'public_sale_price'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Minting',
    href: '#minting',
    icon: HiOutlineFingerPrint,
    widget: MintingAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) =>
            f.tag.startsWith('erc721_mint_') ||
            [
              'mint_amount_to_address',
              'mint_amount_to_address_as_role',
              'mint_amount_to_address_as_owner',
              'mint_amount_by_merkle_proof',
            ].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Supply',
    href: '#supply',
    icon: IoInfiniteSharp,
    widget: SupplyAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          [
            'max_supply',
            'total_supply',
            'set_max_supply',
            'max_supply_frozen',
            'freeze_max_supply',
          ].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Metadata',
    href: '#metadata',
    icon: HiOutlineIdentification,
    widget: MetadataAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) =>
            (f.type === FeatureType.Interface && f.tag === 'erc721_metadata') ||
            (f.type === FeatureType.Function &&
              f.tag === 'set_collection_metadata'),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Reveal',
    href: '#reveal',
    icon: TbConfetti,
    widget: RevealAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) =>
            (f.type === FeatureType.Interface &&
              f.tag === 'erc721_prefixed_metadata_extension') ||
            (f.type === FeatureType.Function &&
              f.tag === 'set_token_metadata_base_uri'),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Proceeds',
    href: '#proceeds',
    icon: TbCashBanknote,
    widget: ({ env, nftCollection, refresh }) => (
      <WithdrawAdminSection
        env={env}
        key={nftCollection.contractAddress}
        chainId={nftCollection.chainId}
        contractAddress={nftCollection.contractAddress}
        refresh={refresh}
      />
    ),
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) =>
            (f.type === FeatureType.Interface &&
              f.tag === 'erc721_simple_proceeds_extension') ||
            (f.type === FeatureType.Interface &&
              f.tag === 'withdraw_extension') ||
            (f.type === FeatureType.Function && f.tag === 'withdraw'),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'License',
    href: '#license',
    icon: TbLicense,
    widget: LicenseAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['license_extension', 'set_license'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Royalty',
    href: '#royalty',
    icon: CgMathPercent,
    widget: RoyaltyAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['eip2981', 'set_default_royalty'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Roles',
    href: '#roles',
    icon: HiOutlineUsers,
    widget: ({ nftCollection }) => (
      <RolesAdminSection
        contractAddress={nftCollection.contractAddress}
        contractVersion={nftCollection.presetVersion}
        defaultRoleName="MINTER_ROLE"
      />
    ),
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          [
            'oz_access_control',
            'has_role',
            'grant_role',
            'revoke_role',
            'renounce_role',
          ].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return false;
    },
  },
  {
    name: 'Verification',
    href: '#verification',
    icon: MdOutlineFactCheck,
    widget: ({ env, nftCollection, constructorArguments }) => (
      <VerificationAdminSection
        env={env}
        chainId={nftCollection.chainId}
        contractAddress={nftCollection.contractAddress}
        contractFqn={nftCollection.presetFqn}
        contractVersion={nftCollection.presetVersion}
        constructorArguments={constructorArguments}
      />
    ),
    isSupported: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(ctx?.nftCollection?.presetFqn);
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      return Boolean(
        ctx?.nftCollection?.contractAddress && ctx?.nftCollection?.presetFqn,
      );
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: NftCollectionAdminSectionContext,
    ) => {
      // TODO ctx?.nftCollection?.contractVerification?.state === 'succeeded',
      return Boolean(false);
    },
  },
];
