import { ContractVersion } from 'flair-sdk';

import { RoleCheckerForm } from '../../../components/sections/RoleCheckerForm';
import { RoleGranterForm } from '../../../components/sections/RoleGranterForm';
import { RoleRevokerForm } from '../../../components/sections/RoleRevokerForm';

type Props = {
  contractAddress?: string;
  contractVersion?: ContractVersion;
  defaultRoleName?: string;
};

export const RolesAdminSection = ({
  contractAddress,
  contractVersion,
  defaultRoleName,
}: Props) => {
  // TODO Get roles
  // TODO Show current holders of roles

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Roles</h3>
        <p className="mt-1 text-sm text-gray-500">
          In this section you can grant or renounce roles (based on
          Openzeppelin's{' '}
          <a
            href="https://docs.openzeppelin.com/contracts/4.x/access-control"
            target={'_blank'}
            rel="noreferrer"
            className="text-blue-500"
          >
            AccessControl
          </a>
          ).
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Grant a role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <RoleGranterForm
                contractAddress={contractAddress}
                contractVersion={contractVersion}
                defaultRoleName={defaultRoleName}
              />
            </dd>
          </div>
        </dl>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Revoke a role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <RoleRevokerForm
                contractAddress={contractAddress}
                contractVersion={contractVersion}
                defaultRoleName={defaultRoleName}
              />
            </dd>
          </div>
        </dl>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Check a role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <RoleCheckerForm
                contractAddress={contractAddress}
                contractVersion={contractVersion}
                defaultRoleName={defaultRoleName}
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
