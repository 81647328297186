import { BytesLike } from 'ethers';
import {
  Badge,
  CopyButton,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  StreamClaimableAmount,
  StreamClaimButton,
  StreamClaimingProvider,
  StreamClaimingStatusBar,
  StreamClaimTokenSelector,
  StreamProvider,
  StreamTotalClaimed,
  TokenStream,
  useHasAnyOfFeatures,
  useStreamClaimLockedUntil,
} from 'flair-sdk';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { useDashboardHost } from '../../../../hooks/useDashboardHost';
import { dateForDateTimeInputValue } from '../../../../util';
import { useStreamClaimLockedUntilUpdater } from '../hooks/useStreamClaimLockedUntilUpdater';

type Props = {
  env?: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
};

export const ClaimingAdminSection = ({ env, tokenStream, refresh }: Props) => {
  const dashboardHost = useDashboardHost();
  const [receiverAddress, setReceiverAddress] = useState<BytesLike>('');

  const iframeWidgetCode = `<iframe src="${dashboardHost}/public/claim/${tokenStream.chainId}/${tokenStream.contractAddress}" frameborder="0" width="800px" height="800px" allowtransparency="true" />`;

  const { data: supportsLockableClaimExtension } = useHasAnyOfFeatures({
    env,
    chainId: tokenStream.chainId,
    contractAddress: tokenStream.contractAddress,
    tags: [
      'flair_stream_lockable_claim_extension',
      'has_stream_lockable_claim_extension',
      'claim_locked_until',
    ],
  });

  const { data: supportsClaimOnBehalfExtension } = useHasAnyOfFeatures({
    env,
    chainId: tokenStream.chainId,
    contractAddress: tokenStream.contractAddress,
    tags: ['claim_for_owner_by_multiple_tokens_and_erc20_address'],
  });

  const {
    data: claimLockedUntil,
    error: claimLockedUntilError,
    isLoading: claimLockedUntilLoading,
  } = useStreamClaimLockedUntil({
    chainId: tokenStream.chainId,
    contractAddress: tokenStream.contractAddress,
  });

  const {
    data: claimLockedUntilUpdaterData,
    error: claimLockedUntilUpdaterError,
    isLoading: claimLockedUntilUpdaterLoading,
    writeAndWait: setClaimLockedUntil,
  } = useStreamClaimLockedUntilUpdater({
    contractAddress: tokenStream.contractAddress,
  });

  const [claimLockedUntilDialogOpen, setClaimLockedUntilDialogOpen] =
    useState(false);

  return (
    <>
      {supportsLockableClaimExtension ? (
        <div className="flex flex-col gap-8">
          <div className={'bg-white shadow overflow-hidden rounded-lg'}>
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Lock claiming
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                You can control when claiming should be possible.
              </p>
            </div>
            <div className="px-4 py-5 sm:p-0">
              <dl>
                <div className="sm:py-5 grid grid-cols-3 gap-2 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                    Locked until
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                    {!claimLockedUntilLoading ? (
                      Number(claimLockedUntil?.toString()) > 0 ? (
                        <Badge
                          color="blue"
                          text={new Date(
                            Number(claimLockedUntil?.toString()) * 1000,
                          ).toLocaleString()}
                        />
                      ) : (
                        <Badge color="yellow" text="Not locked" />
                      )
                    ) : (
                      '...'
                    )}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                    {claimLockedUntil && (
                      <SingleValueUpdaterButtonDialog
                        dialogTitle={'Update claiming lock'}
                        dialogDescription="Despite how much rewards are being accumulated, users can claim their rewards only after this date."
                        dialogOpen={claimLockedUntilDialogOpen}
                        setDialogOpen={setClaimLockedUntilDialogOpen}
                        inputLabel="Lock claiming until"
                        inputType={'datetime'}
                        inputOnSubmit={(value) => {
                          return setClaimLockedUntil([
                            Math.floor(new Date(value).getTime() / 1000),
                          ])
                            .then(() => setClaimLockedUntilDialogOpen(false))
                            .then(() => refresh());
                        }}
                        inputDefaultValue={dateForDateTimeInputValue(
                          new Date(Number(claimLockedUntil?.toString()) * 1000),
                        )}
                        buttonLabel="Change locked until"
                        buttonDisabled={claimLockedUntilUpdaterLoading}
                        data={claimLockedUntilUpdaterData}
                        loading={claimLockedUntilUpdaterLoading}
                        error={claimLockedUntilUpdaterError}
                      />
                    )}
                  </dd>
                </div>
              </dl>
              <div className="py-4 sm:py-5 sm:px-6">
                {claimLockedUntilError ? (
                  <Errors
                    title="claimLockedUntilError"
                    error={claimLockedUntilError}
                  />
                ) : null}
                {claimLockedUntilUpdaterError ? (
                  <Errors
                    title="claimLockedUntilUpdaterError"
                    error={claimLockedUntilUpdaterError}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {supportsClaimOnBehalfExtension ? (
        <StreamProvider
          chainId={tokenStream.chainId}
          contractAddress={tokenStream.contractAddress as string}
          walletAddress={receiverAddress}
          env={env}
        >
          <StreamClaimingProvider>
            <div className={'bg-white shadow overflow-hidden rounded-lg'}>
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Claim on behalf of
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  You can pay the gas fee and claim the funds from this stream
                  towards any of the stakeholders.
                </p>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <dl>
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                    <dt className="text-sm font-medium text-gray-500">
                      Receiver address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-center">
                      <input
                        type="text"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={receiverAddress?.toString() || ''}
                        disabled={Boolean(false)}
                        onChange={(e) => setReceiverAddress(e.target.value)}
                      />
                    </dd>
                  </div>
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                    <dt className="text-sm font-medium text-gray-500">
                      Which token to claim?
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center gap-2">
                      <StreamClaimTokenSelector />
                    </dd>
                  </div>
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                    <dt className="text-sm font-medium text-gray-500">
                      Available to claim
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center gap-2">
                      <StreamClaimableAmount />
                    </dd>
                  </div>
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
                    <dt className="text-sm font-medium text-gray-500">
                      Total claimed
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <StreamTotalClaimed />
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex gap-1">
                <div className="flex flex-grow gap-2 items-center">
                  <StreamClaimingStatusBar />
                </div>
                <StreamClaimButton className={PRIMARY_BUTTON} />
              </div>
            </div>
          </StreamClaimingProvider>
        </StreamProvider>
      ) : null}

      <div className="flex flex-col gap-8">
        <div className={'bg-white shadow overflow-hidden rounded-lg'}>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Custom website examples
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              To use a custom claiming widget with full control take a look at
              our examples.
            </p>
          </div>
          <div className="px-4 py-5 sm:p-0">
            <dl>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex">
                  Typescript React Example
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
                  <div>View the source code in Github:</div>
                  <div>
                    <a
                      target={'_blank'}
                      href="https://github.com/0xflair/examples/tree/main/react/community-airdrop-reward-stream"
                      rel="noreferrer"
                      className="text-indigo-600"
                    >
                      github.com/0xflair/examples →
                      react/community-airdrop-reward-stream
                    </a>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className={'bg-white shadow overflow-hidden rounded-lg'}>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Pre-built pages
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Provide a claiming widget for your collectors on your site.
            </p>
          </div>
          <div className="px-4 py-5 sm:p-0">
            <dl>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex">
                  Public claiming page
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
                  <div>
                    You can share this link with your collector to claim their
                    rewards.
                  </div>
                  <p className="p-3 font-mono w-full break-all rounded-md border border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">{`${dashboardHost}/public/claim/${tokenStream.chainId}/${tokenStream.contractAddress}`}</p>
                  <div>
                    <Link
                      to={`/public/claim/${tokenStream.chainId}/${tokenStream.contractAddress}`}
                      target={'_blank'}
                      className={PRIMARY_BUTTON}
                    >
                      View public claim page
                    </Link>
                  </div>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex">
                  Public claiming widget
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
                  <div>
                    You can copy this snippet to include claiming widget as an
                    "iframe" in your website.
                  </div>
                  <p className="p-3 font-mono w-full break-all rounded-md border border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    {iframeWidgetCode}
                  </p>
                  <div>
                    <CopyButton content={iframeWidgetCode} />
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};
