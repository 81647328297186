import { classNames } from 'flair-sdk';
import { GoFlame } from 'react-icons/go';

type Props = {
  className?: string;
  noText?: boolean;
};

export const ComingSoonOverlay = ({
  className = 'bg-white',
  noText = false,
}: Props) => {
  return (
    <div
      className={classNames(
        'absolute inset-0 z-10 bg-opacity-90 transition-opacity flex items-center justify-center',
        className,
      )}
    >
      <div className="text-indigo-500 flex justify-center items-center flex-col gap-2">
        <div className="flex items-center gap-2">
          <GoFlame className="h-6 w-6" /> <b>Coming Soon™</b>
        </div>
        {noText ? null : (
          <div className="text-xs">
            Want this feature now? Talk to us on{' '}
            <a
              href={'https://discord.gg/flair'}
              target="_blank"
              className="font-bold"
              rel="noreferrer"
            >
              Discord
            </a>
            ...
          </div>
        )}
      </div>
    </div>
  );
};
