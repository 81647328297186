import { RadioGroup } from '@headlessui/react';
import { BigNumberish } from 'ethers';
import { classNames } from 'flair-sdk';
import { HiOutlineExternalLink } from 'react-icons/hi';

import { LicenseVersion } from '../../types/license.types';

const licenses = [
  {
    id: LicenseVersion.UNLICENSED,
    name: 'No on-chain license',
    description:
      'You can opt-out of on-chain licenses, and put an off-chain custom license on your website.',
    link: '',
  },
  {
    id: LicenseVersion.CBE_CC0,
    name: 'CC0 (“CBE-CC0”) ',
    description:
      'All copyrights are waived under the terms of CC0 1.0 Universal developed by Creative Commons',
    link: 'https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/0',
  },
  {
    id: LicenseVersion.CBE_ECR,
    name: 'Exclusive Commercial Rights with No Creator Retention (“CBE-ECR”)',
    description:
      'Full exclusive commercial rights granted, with no hate speech termination. Creator does not retain any exploitation rights',
    link: 'https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/1',
  },
  {
    id: LicenseVersion.CBE_NECR,
    name: 'Non-Exclusive Commercial Rights (“CBE-NECR”)',
    description:
      'Full non-exclusive commercial rights granted, with no hate speech termination. Creator retains exploitation rights',
    link: 'https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/2',
  },
  {
    id: LicenseVersion.CBE_NECR_HS,
    name: 'Non-Exclusive Commercial Rights with Creator Retention & Hate Speech Termination',
    description:
      'Full non-exclusive commercial rights granted, with hate speech termination. Creator retains exploitation rights',
    link: 'https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/3',
  },
  {
    id: LicenseVersion.CBE_PR,
    name: 'Personal License (“CBE-PR”)',
    description: 'Personal rights granted, without hate speech termination',
    link: 'https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/4',
  },
  {
    id: LicenseVersion.CBE_PR_HS,
    name: 'Personal License with Hate Speech Termination (“CBE-PR-HS”)',
    description: 'Personal rights granted, with hate speech termination',
    link: 'https://7q7win2vvm2wnqvltzauqamrnuyhq3jn57yqad2nrgau4fe3l5ya.arweave.net/_D9kN1WrNWbCq55BSAGRbTB4bS3v8QAPTYmBThSbX3A/5',
  },
];

type Props = {
  selected?: BigNumberish;
  setSelected: (selected: BigNumberish) => void;
  disabled?: boolean;
};

export const LicenseSelector = ({ selected, setSelected, disabled }: Props) => {
  return (
    <RadioGroup
      value={selected?.toString()}
      onChange={(v: any) => v && setSelected(v?.toString())}
      disabled={disabled}
    >
      <RadioGroup.Label className="sr-only"> License version </RadioGroup.Label>
      <div className="-space-y-px rounded-md bg-white">
        {licenses.map((license, licenseIdx) => (
          <RadioGroup.Option
            key={license.name}
            value={license.id.toString()}
            className={({ checked }) =>
              classNames(
                licenseIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                licenseIdx === licenses.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'bg-indigo-50 border-indigo-200' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-indigo-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-900' : 'text-gray-900',
                      'block text-sm font-medium',
                    )}
                  >
                    <a
                      href={license.link}
                      className={classNames(
                        'group flex items-center gap-1 text-sm text-indigo-700',
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {license.name}
                      <HiOutlineExternalLink className="h-4 w-4" />
                    </a>
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-indigo-700' : 'text-gray-500',
                      'block text-sm mt-2',
                    )}
                  >
                    {license.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
