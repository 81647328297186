import { Environment, useAxiosPost, useLoginJwt } from 'flair-sdk';
import { useMemo } from 'react';

import { FLAIR_AUTH_CLIENTS_BACKEND } from '../constants';
import { AuthClient } from '../types';

type UpdaterConfig = {
  env?: Environment;
  enabled?: boolean;
};

export function useAuthClientCreator(
  collection: Partial<AuthClient>,
  { enabled = true, env = Environment.PROD }: UpdaterConfig,
) {
  const loginJwt = useLoginJwt();
  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  return useAxiosPost<AuthClient>({
    url: `${FLAIR_AUTH_CLIENTS_BACKEND[env]}/v1/auth-clients`,
    enabled: Boolean(enabled && loginJwt),
    data: collection,
    headers,
  });
}
