import { Signer, utils } from 'ethers';
import {
  Badge,
  Button,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  useChainInfo,
  useCryptoCurrency,
  useWaitForTransaction,
} from 'flair-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetwork, useSigner } from 'wagmi';

import { useFlairContractDeployer } from '../../common/hooks/useFlairContractDeployer';
import { useFlairFactoryContract } from '../../common/hooks/useFlairFactoryContract';
import { useFungibleTokenUpdater } from '../hooks/useFungibleTokenUpdater';
import { FungibleToken } from '../types';

type Props = {
  env: Environment;
  fungibleToken: FungibleToken<any>;
  refresh: () => void;
  constructorArguments?: any[];
};

export const DeployAdminSection = ({
  env = Environment.PROD,
  fungibleToken,
  refresh,
  constructorArguments,
}: Props) => {
  const { data: signer } = useSigner();
  const { activeChain } = useNetwork();
  const flairFactory = useFlairFactoryContract({
    chainId: activeChain?.id,
  });
  const { data } = useCryptoCurrency({
    symbol: activeChain?.nativeCurrency?.symbol,
  });

  const [triedAutoSaveContractAddress, setTriedAutoSaveContractAddress] =
    useState(false);
  const [triedAutoSaveDeployTransaction, setTriedAutoSaveDeployTransaction] =
    useState(false);

  const {
    data: deployData,
    error: deployError,
    isLoading: deployLoading,
    deploymentFee,
    deployContract,
  } = useFlairContractDeployer({
    env,
    contractFqn: fungibleToken.presetFqn,
    contractVersion: fungibleToken.presetVersion,
    signer: signer as Signer,
  });

  const deployTransaction =
    deployData?.deployTransaction?.hash || fungibleToken.deployTransaction;

  const {
    data: txData,
    isLoading: txLoading,
    error: txError,
  } = useWaitForTransaction({
    hash: deployTransaction,
    confirmations: 2,
  });

  const actualDeployTransaction = txData?.transactionHash || deployTransaction;

  const proxyCreatedContractAddress = useMemo(() => {
    try {
      if (!txData || !flairFactory) return;

      const logs = txData?.logs?.map((l) => {
        try {
          return flairFactory?.interface.parseLog(l);
        } catch (error) {
          return { error, args: [] };
        }
      });

      const event = logs?.find(
        (e: { name?: string } | any) => e.name === 'ProxyCreated',
      );
      const emittedAddress = event?.args?.[1];

      return emittedAddress;
    } catch (e) {
      console.log(e);
    }
  }, [flairFactory, txData]);

  const contractAddress =
    deployData?.address ||
    txData?.contractAddress ||
    proxyCreatedContractAddress ||
    fungibleToken.contractAddress;

  const {
    data: updateData,
    isLoading: updateLoading,
    error: updateError,
    sendRequest: saveFungibleToken,
  } = useFungibleTokenUpdater(
    {
      _id: fungibleToken._id,
      chainId: activeChain?.id as number,
      contractAddress,
      deployTransaction: actualDeployTransaction,
    },
    {
      env,
      enabled: false,
    },
  );

  const deploymentFeeFiat = useMemo(() => {
    try {
      return data?.price && deploymentFee
        ? (
            Number(utils.formatEther(deploymentFee?.toString()).toString()) *
            Number(data?.price?.toString()) *
            (activeChain?.testnet ? 0 : 1)
          ).toFixed(2)
        : undefined;
    } catch (error) {
      return undefined;
    }
  }, [activeChain?.testnet, data?.price, deploymentFee]);

  const deployNow = useCallback(() => {
    if (!fungibleToken._id) {
      window?.gtag?.('event', 'contract_deployment_attempted', {
        event_category: 'tokens',
        event_label: fungibleToken.presetFqn,
        section: 'tokens',
        preset_fqn: fungibleToken.presetFqn,
        preset_version: fungibleToken.presetVersion,
        chain_id: activeChain?.id,
        transaction_id: `deploy:${fungibleToken._id}`,
        deployment_fee_wei: deploymentFee?.toString(),
        deployment_fee_fiat: deploymentFeeFiat,
      });
    }

    deployContract(...(constructorArguments || []));
  }, [
    fungibleToken._id,
    fungibleToken.presetFqn,
    fungibleToken.presetVersion,
    deployContract,
    constructorArguments,
    activeChain?.id,
    deploymentFee,
    deploymentFeeFiat,
  ]);

  const contractAddressMustBeSaved = Boolean(
    contractAddress && !fungibleToken.contractAddress && !updateLoading,
  );

  useEffect(() => {
    if (triedAutoSaveContractAddress) {
      return;
    }

    if (contractAddressMustBeSaved) {
      if (activeChain?.id && contractAddress) {
        window?.gtag?.('event', 'contract_deployment_successful', {
          event_category: 'tokens',
          event_label: fungibleToken.presetFqn,
          section: 'tokens',
          preset_fqn: fungibleToken.presetFqn,
          preset_version: fungibleToken.presetVersion,
          chain_id: activeChain?.id,
          testnet: !!activeChain?.testnet,
          contract_address: contractAddress,
          transaction_id: `${activeChain?.id}:${contractAddress}`,
          deployment_fee_wei: deploymentFee?.toString(),
          deployment_fee_fiat:
            data?.price && deploymentFee
              ? Number(deploymentFee?.toString()) *
                Number(data?.price?.toString())
              : undefined,
        });
        window?.gtag?.('event', 'purchase', {
          currency: 'USD',
          transaction_id: `deploy:token:${fungibleToken._id}`,
          value: deploymentFeeFiat,
          items: [
            {
              item_id: `${activeChain?.id}:${fungibleToken.presetFqn}`,
              item_name: `${activeChain?.id}:${fungibleToken.presetFqn}`,
            },
          ],
        });
      }

      saveFungibleToken().then(refresh);
      setTriedAutoSaveContractAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triedAutoSaveContractAddress,
    contractAddressMustBeSaved,
    saveFungibleToken,
    refresh,
    fungibleToken.presetFqn,
    contractAddress,
  ]);

  const deployTransactionMustBeSaved = Boolean(
    actualDeployTransaction &&
      !fungibleToken.deployTransaction &&
      !updateLoading,
  );

  useEffect(() => {
    if (triedAutoSaveDeployTransaction) {
      return;
    }

    if (deployTransactionMustBeSaved) {
      saveFungibleToken().then(refresh);
      setTriedAutoSaveDeployTransaction(true);
    }
  }, [
    triedAutoSaveContractAddress,
    contractAddressMustBeSaved,
    saveFungibleToken,
    refresh,
    fungibleToken.presetFqn,
    contractAddress,
    triedAutoSaveDeployTransaction,
    deployTransactionMustBeSaved,
  ]);

  const tokenChainId = fungibleToken.contractAddress
    ? fungibleToken.chainId
    : updateData?.chainId
    ? updateData.chainId
    : activeChain?.id;

  const tokenChain = useChainInfo(Number(tokenChainId));

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Deploy</h3>
        <p className="mt-1 text-sm text-gray-500">
          Whenever you are ready you can deploy the smart contract on the
          blockchain.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {!deployLoading && !txLoading ? (
                contractAddress ? (
                  <Badge color="green" text="Successfully deployed!" />
                ) : (
                  <Badge color="yellow" text="Not deployed yet" />
                )
              ) : (
                ''
              )}
              {deployLoading ? (
                <>
                  <Spinner />
                  <Badge color="blue" text="Deploying..." />
                </>
              ) : actualDeployTransaction &&
                txLoading &&
                (!txData?.confirmations || txData.confirmations < 2) ? (
                <>
                  <Spinner />
                  <Badge color="blue" text="Waiting for Tx..." />
                </>
              ) : null}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Network</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tokenChain?.name || `Chain ${tokenChainId}`}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Deploy Transaction
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-full truncate">
              {actualDeployTransaction ? (
                <>
                  <small className="text-xs">
                    {actualDeployTransaction.toString()}
                  </small>
                  <br />
                  <a
                    href={`${activeChain?.blockExplorers?.default.url}/tx/${actualDeployTransaction}`}
                    target={'_blank'}
                    className={'text-sm text-indigo-700'}
                    rel="noreferrer"
                  >
                    View on {activeChain?.blockExplorers?.default.name}
                  </a>
                </>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Contract Address
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {contractAddress ? (
                <>
                  <small className="text-xs">{contractAddress}</small>
                  <br />
                  <a
                    href={`${activeChain?.blockExplorers?.default.url}/address/${contractAddress}`}
                    target={'_blank'}
                    className={'text-sm text-indigo-700'}
                    rel="noreferrer"
                  >
                    View on {activeChain?.blockExplorers?.default.name}
                  </a>
                </>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
        </dl>
        <div className="py-4 sm:py-5 sm:px-6">
          {deployError ? (
            <Errors title="Deploy Error" error={deployError} />
          ) : null}
          {txError ? <Errors title="Tx Error" error={txError} /> : null}
          {updateError ? (
            <Errors title="Update Error" error={updateError} />
          ) : null}
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
        <Button
          className={PRIMARY_BUTTON}
          text="Deploy"
          onClick={deployNow}
          disabled={Boolean(contractAddress || txLoading || deployLoading)}
        />

        {contractAddressMustBeSaved && (
          <Button
            className={SECONDARY_BUTTON}
            text="Save"
            onClick={saveFungibleToken}
            disabled={Boolean(fungibleToken.contractAddress || updateLoading)}
          />
        )}
      </div>
    </div>
  );
};
