export enum LicenseVersion {
  CBE_CC0,
  CBE_ECR,
  CBE_NECR,
  CBE_NECR_HS,
  CBE_PR,
  CBE_PR_HS,
  CUSTOM,
  UNLICENSED,
}
