import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  LATEST_VERSION,
  NftCollection,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  useStickyState,
} from 'flair-sdk';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAccount, useNetwork } from 'wagmi';

import CodeBuilder from '../../../../../components/CodeBuilder';
import { useNftCollectionConstructorArguments } from '../../../../../features/nft-collections/latest/hooks/common/useNftCollectionConstructorArguments';
import { useNftCollectionCreator } from '../../../../../features/nft-collections/latest/hooks/common/useNftCollectionCreator';
import { LicenseVersion } from '../../../../../features/nft-collections/types/license.types';
import {
  ERC721OneOfOneCollectionConfig,
  ERC721OneOfOneCollectionConstructorArguments,
  ERC721OneOfOneCollectionCreationState,
} from '../types';
import {
  generateManualDeploymentCode,
  validateIfCanSetupAndDeploy,
} from '../utils';
import { Form } from './Form';
import { Setup } from './Setup';

const initialState: ERC721OneOfOneCollectionCreationState = {
  collectionName: '',
  collectionSymbol: '',
  collectionImageFile: undefined,
  collectionImagePreview: undefined,
  collectionImageUri: '',
  collectionDescription: '',
  royaltyAddress: '',
  royaltyPercent: '4',
  licenseVersion: LicenseVersion.CBE_PR,
};

type Props = {
  env?: Environment;
};

export const ERC721OneOfOneCollectionCreate = ({
  env = Environment.PROD,
}: Props) => {
  const { data: account } = useAccount();
  const { activeChain } = useNetwork();
  const navigate = useNavigate();

  const [builderOpen, setBuilderOpen] = useState(false);
  const [viewMode, setViewMode] = useState<'form' | 'setup'>('form');

  const [creationState, setCreationState] =
    useStickyState<ERC721OneOfOneCollectionCreationState>(
      initialState,
      'erc721-one-of-one-collection-creation',
      ['collectionImageFile', 'collectionImagePreview'],
    );

  const nftCollection: Partial<NftCollection<ERC721OneOfOneCollectionConfig>> =
    {
      chainId: activeChain?.id as number,
      deployerAddress: account?.address as string,
      presetFqn: 'collections/ERC721/presets/ERC721OneOfOneCollection',
      presetVersion: LATEST_VERSION,
      config: creationState,
    };

  const {
    data: creationData,
    isLoading: creationLoading,
    error: creationError,
    sendRequest: createCollection,
  } = useNftCollectionCreator(nftCollection, { env, enabled: false });

  const [canSetup, canDeploy, reason] =
    validateIfCanSetupAndDeploy(creationState);

  const toggleViewMode = useCallback(() => {
    setViewMode(viewMode === 'form' ? 'setup' : 'form');
  }, [viewMode]);

  const constructorArguments =
    useNftCollectionConstructorArguments<ERC721OneOfOneCollectionConstructorArguments>(
      nftCollection,
    );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id) {
      window?.gtag?.('event', 'submitted_creation_form', {
        event_category: 'collections',
        event_label: creationData.presetFqn,
      });

      navigate('/collections/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <CodeBuilder
        manualDeployedContractName="MyCollection"
        manualDeploymentCode={generateManualDeploymentCode(
          creationState,
          constructorArguments,
        )}
        open={builderOpen}
        setOpen={setBuilderOpen}
      />

      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New 1-of-1 collection
          </h3>
        </div>
      </div>

      {viewMode === 'form' && (
        <Form
          creationState={creationState}
          setCreationState={setCreationState}
        />
      )}

      {viewMode === 'setup' && (
        <div className="py-6 sm:px-6 lg:px-8 min-h-full mb-10">
          <Setup
            env={env}
            creationState={creationState}
            setCreationState={setCreationState}
          />
        </div>
      )}

      <div className="sticky bottom-0 z-2 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex items-center">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setBuilderOpen(true)}
            >
              Inspect the code
            </button>
          </div>
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {reason && viewMode === 'form' && !canSetup ? (
              <Errors error={reason} />
            ) : null}
            {reason && viewMode === 'setup' && !canDeploy ? (
              <Errors error={reason} />
            ) : null}
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {viewMode === 'form' && (
              <Button
                text="Prepare"
                disabled={!canSetup}
                onClick={toggleViewMode}
              />
            )}
            {viewMode === 'setup' && !creationLoading && !creationData?._id && (
              <Button
                className={SECONDARY_BUTTON}
                text="Back to edit"
                onClick={toggleViewMode}
              />
            )}
            {viewMode === 'setup' && !creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Save now"
                disabled={creationLoading || !canDeploy}
                onClick={() =>
                  createCollection().then((response) => {
                    if (response?.data._id)
                      navigate('/collections/' + response?.data._id);
                  })
                }
              />
            )}
            {creationData?._id && (
              <Link
                to={`/collections/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to collection
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
