import {
  Button,
  Environment,
  Errors,
  NftCollection,
  normalizeIpfsUrl,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useCollectionMetadata,
  useCollectionMetadataUri,
} from 'flair-sdk';
import { useEffect, useState } from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';

import {
  CollectionMetadataForm,
  CollectionMetadataFormState,
} from '../components/CollectionMetadataForm';
import { useCollectionMetadataUpdater } from '../hooks/metadata/useCollectionMetadataUpdater';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const MetadataAdminSection = ({ env, nftCollection }: Props) => {
  const contractAddress = nftCollection.contractAddress;
  const contractVersion = nftCollection.presetVersion;

  const [initialLoad, setInitialLoad] = useState(false);
  const [metadataFormState, setMetadataFormState] =
    useState<CollectionMetadataFormState>({
      collectionName: '',
      collectionSymbol: '',
      collectionDescription: '',
    });

  const {
    data: metadataUri,
    error: metadataUriError,
    isLoading: metadataUriLoading,
  } = useCollectionMetadataUri({
    contractAddress,
    contractVersion,
  });

  const {
    data: currentMetadata,
    error: currentMetadataError,
    isLoading: currentMetadataLoading,
  } = useCollectionMetadata({
    contractAddress,
    contractVersion,
  });

  const {
    data: { txReceipt, txResponse },
    error: collectionMetadataUpdaterError,
    isLoading: collectionMetadataUpdaterLoading,
    uploadAndUpdate: uploadAndSetNftCollectionMetadata,
  } = useCollectionMetadataUpdater({
    env,
    contractAddress: nftCollection.contractAddress,
    contractVersion: nftCollection.presetVersion,
    metadataUpdates: {
      additionalMetadataAttributes: currentMetadata || {},
      ...metadataFormState,
    },
  });

  useEffect(() => {
    if (initialLoad || !currentMetadata) return;

    setInitialLoad(true);
    setMetadataFormState({
      collectionName: currentMetadata.name,
      collectionDescription: currentMetadata.description,
      collectionImageUri: currentMetadata.image,
      collectionImagePreview: normalizeIpfsUrl(currentMetadata.image),
    });
  }, [currentMetadata, initialLoad]);

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Collection Metadata
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Update collection title and description to show on marketplaces like
          OpenSea.
        </p>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <CollectionMetadataForm
          disabled={
            collectionMetadataUpdaterLoading ||
            currentMetadataLoading ||
            metadataUriLoading
          }
          state={metadataFormState}
          setState={setMetadataFormState}
        />
        {collectionMetadataUpdaterError && (
          <Errors error={collectionMetadataUpdaterError} />
        )}
        {currentMetadataError && <Errors error={currentMetadataError} />}
        {metadataUriError && <Errors error={metadataUriError} />}
      </div>
      {metadataUri && (
        <div className="bg-white px-4 py-5 border-t border-b border-gray-200 sm:px-6 text-xs text-gray-400 flex gap-1">
          <span>Current contractURI():</span>
          <a
            href={normalizeIpfsUrl(metadataUri || '')}
            target={'_blank'}
            className="text-gray-500 flex gap-1"
            rel="noreferrer"
          >
            {metadataUri} <HiOutlineExternalLink className="inline w-4 h-4" />
          </a>
        </div>
      )}
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
        <div className="flex-1 flex gap-2 items-center">
          {metadataUriLoading ? (
            <>
              <Spinner /> Checking current metadata...
            </>
          ) : currentMetadataLoading ? (
            <>
              <Spinner /> Fetching current metadata...
            </>
          ) : collectionMetadataUpdaterLoading ? (
            <>
              <Spinner /> Working...
            </>
          ) : null}
          {txReceipt || txResponse ? (
            <>
              <TransactionLink txReceipt={txReceipt} txResponse={txResponse} />
            </>
          ) : null}
        </div>
        <Button
          className={PRIMARY_BUTTON}
          text={'Set metadata'}
          onClick={uploadAndSetNftCollectionMetadata}
          disabled={collectionMetadataUpdaterLoading}
        />
      </div>
    </div>
  );
};
