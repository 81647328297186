import { keccak256, toUtf8Bytes } from 'ethers/lib/utils';
import { Badge, Spinner, useOzHasRole, ZERO_ADDRESS } from 'flair-sdk';

import { RoleCheckerForm } from '../../../components/sections/RoleCheckerForm';
import { RoleGranterForm } from '../../../components/sections/RoleGranterForm';
import { RoleRevokerForm } from '../../../components/sections/RoleRevokerForm';
import { FungibleToken } from '../types';

type Props = {
  fungibleToken: FungibleToken<any>;
  refresh: () => void;
};

export const LockingAdminSection = ({ fungibleToken, refresh }: Props) => {
  const contractAddress = fungibleToken.contractAddress;
  const contractVersion = fungibleToken.presetVersion;

  const { data: globalTransferRole, isLoading: globalTransferRoleLoading } =
    useOzHasRole({
      contractAddress,
      contractVersion,
      address: ZERO_ADDRESS,
      role: keccak256(toUtf8Bytes('TRANSFER_ROLE')),
    });

  // TODO Get roles
  // TODO Show current holders of roles

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Transfer Locking
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            In this section you can grant or renounce ability to transfer (based
            on Openzeppelin's{' '}
            <a
              href="https://docs.openzeppelin.com/contracts/4.x/access-control"
              target={'_blank'}
              rel="noreferrer"
              className="text-indigo-500 hover:text-indigo-700"
            >
              AccessControl
            </a>
            ).
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Global transfer state
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {globalTransferRoleLoading ? (
                  <Spinner />
                ) : Boolean(globalTransferRole) ? (
                  <Badge color="green" text="Everyone can transfer" />
                ) : (
                  <Badge
                    color="yellow"
                    text="Only allowed addresses can transfer"
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Un-lock transfers for everyone
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <RoleGranterForm
                  contractAddress={contractAddress}
                  contractVersion={contractVersion}
                  buttonLabel={'Allow all transfers'}
                  defaultAddress={ZERO_ADDRESS}
                  defaultRoleName="TRANSFER_ROLE"
                  hideRole={true}
                  hideAddress={true}
                />
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Lock transfers for everyone
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <RoleRevokerForm
                  contractAddress={contractAddress}
                  contractVersion={contractVersion}
                  buttonLabel={'Disable all transfers'}
                  defaultAddress={ZERO_ADDRESS}
                  defaultRoleName="TRANSFER_ROLE"
                  hideRole={true}
                  hideAddress={true}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Individual Locking
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            In this section you can grant or renounce ability to transfer for
            specific contracts or wallets.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Allow transfers for
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <RoleGranterForm
                  contractAddress={contractAddress}
                  contractVersion={contractVersion}
                  buttonLabel={'Un-lock transfers'}
                  defaultRoleName="TRANSFER_ROLE"
                  hideRole={true}
                />
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Lock transfers for
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <RoleRevokerForm
                  contractAddress={contractAddress}
                  contractVersion={contractVersion}
                  buttonLabel={'Lock transfers'}
                  defaultRoleName="TRANSFER_ROLE"
                  hideRole={true}
                />
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Check ability to transfer
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <RoleCheckerForm
                  contractAddress={contractAddress}
                  contractVersion={contractVersion}
                  defaultRoleName="TRANSFER_ROLE"
                  hideRole={true}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
