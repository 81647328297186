import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  LATEST_VERSION,
  PRIMARY_BUTTON,
  Spinner,
  useLoginJwt,
  useStickyState,
  useTokenStreamCreator,
} from 'flair-sdk';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { TokenSharesTable } from '../../../features/token-streams/latest/components/TokenSharesTable';
import { ERC721ShareInstantStreamConfig } from '../../../features/token-streams/latest/types/ERC721ShareInstantStream.types';

export type ERC721ShareInstantStreamCreationState = {
  name?: string;
  nameDifferent?: boolean;
  publicTitle?: string;
} & ERC721ShareInstantStreamConfig;

type Props = {
  env?: Environment;
};

const initialState: ERC721ShareInstantStreamCreationState = {
  name: '',
  publicTitle: '',
  nameDifferent: false,
  ticketToken: '',
  initialLockedUntilTimestamp: '0',
  initialClaimLockedUntil: '0',
  initialShares: {},
};

export const CreateERC721ShareInstantStream = ({
  env = Environment.PROD,
}: Props) => {
  const loginJwt = useLoginJwt();
  const navigate = useNavigate();
  const { activeChain } = useNetwork();

  const [creationState, setCreationState] =
    useStickyState<ERC721ShareInstantStreamCreationState>(
      initialState,
      'erc721-share-instant-stream-creation',
      ['initialShares'],
    );

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: create,
  } = useTokenStreamCreator<ERC721ShareInstantStreamConfig>(
    {
      presetFqn: 'streams/ERC721/presets/ERC721ShareInstantStream',
      presetVersion: LATEST_VERSION,
      name: creationState.name,
      publicTitle: creationState.publicTitle,
      config: creationState,
    },
    {
      env,
      enabled: false,
      loginJwt,
    },
  );

  const metadataSectionView = (
    <FormSection title="General" description="Information about your stream.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="publicTitle"
            className="block text-sm font-medium text-gray-700"
          >
            Title{' '}
            <span className="text-gray-500 text-xs font-light">(optional)</span>
          </label>
          <input
            type="text"
            name="publicTitle"
            id="publicTitle"
            autoComplete="publicTitle"
            placeholder="Flair Treasury Income"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.publicTitle}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                publicTitle: event.target.value,
                ...(!x.nameDifferent ? { name: event.target.value } : {}),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            An optional public name possibly visible in wallets and
            marketplaces.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="privateName"
            className="block text-sm font-medium text-gray-700"
          >
            Private Name
          </label>
          <input
            type="text"
            name="privateName"
            id="privateName"
            autoComplete="privateName"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Flair Income 2022"
            value={creationState.name}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                name: event.target.value,
                nameDifferent: event.target.value !== x.publicTitle,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A private name only to visible you in the dashboard.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const registrySectionView = (
    <FormSection
      title="Stakeholder Registry"
      description={
        <div className="flex flex-col gap-4">
          <p>
            Define which ERC721 collection must be used as your stakeholders
            registry. You can use any ERC721 collection, even a PFP collection.
          </p>
          <p>
            Each token in this ERC721 collection can have a specified share
            amount defined below. This means <b>current owner of the NFTs</b>{' '}
            can claim their share from this stream.
          </p>
        </div>
      }
    >
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="registryAddress"
            className="block text-sm font-medium text-gray-700"
          >
            Stakeholder registry address (ERC721)
          </label>
          <input
            type="text"
            name="registryAddress"
            id="registryAddress"
            autoComplete="nftAddress"
            placeholder="0x..."
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.ticketToken?.toString() || ''}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                ticketToken: event.target.value,
              }))
            }
          />
        </div>
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Do you need to create a simple ERC721 as stakeholder registry?
          </label>
          <p className="mt-2 text-sm text-gray-500">
            Create a{' '}
            <Link
              to={`/collections/create/ERC721StakeholderRegistry`}
              className="text-indigo-600"
            >
              new stakeholder registry now
            </Link>
            .
          </p>
        </div>
      </div>
    </FormSection>
  );

  const sharesSectionView = (
    <FormSection
      title="Shares"
      description={
        <div className="flex flex-col gap-4">
          <p>Define initial shares in this stream.</p>
          <p>
            <b>For example</b>, you can define token #1 (i.e. seat #1) must
            receive 40% and token #2 must receive 60% of all income received by
            this stream.
          </p>
        </div>
      }
    >
      <TokenSharesTable
        env={env}
        chainId={activeChain?.id}
        registryAddress={creationState.ticketToken?.toString()}
        shares={creationState.initialShares}
        setSharesForTokens={(newShares) => {
          const initialShares =
            typeof newShares === 'function'
              ? newShares(creationState.initialShares || {})
              : newShares;

          setCreationState((x) => ({
            ...x,
            initialShares,
          }));
        }}
      />
    </FormSection>
  );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id) {
      window?.gtag?.('event', 'submitted_creation_form', {
        event_category: 'streams',
        event_label: creationData.presetFqn,
        transaction_id: `creation:${creationData._id}`,
      });

      navigate('/streams/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New share-based stream
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            A stream to distribute income and revenue with multiple
            stakeholders.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 mb-20">
        {metadataSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-8">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {registrySectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-8">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {sharesSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Create"
                disabled={creationLoading}
                onClick={create}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/streams/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the stream
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
