import { TransactionReceipt } from '@ethersproject/providers';
import * as ethers from 'ethers';
import { extractHumanReadableError } from 'flair-sdk';

import {
  ERC721TieredSalesCollectionConfig,
  ERC721TieredSalesCollectionConstructorArguments,
} from '../../../../features/nft-collections/types/ERC721TieredSalesCollection.types';
import { ERC721TieredSalesCollectionCreationState } from './types';

export const isValidAddress = (address?: string) => {
  if (!address) {
    return false;
  }

  try {
    ethers.utils.getAddress(address);
  } catch (error) {
    return false;
  }

  return true;
};

export function validateIfCanSetupAndDeploy(
  creationState: ERC721TieredSalesCollectionCreationState,
  deployError?: Error | undefined,
  deployLoading?: boolean | undefined,
  txError?: Error | undefined,
  txLoading?: boolean | undefined,
  txData?: TransactionReceipt,
): [canSetup: boolean, canDeploy: boolean, reason: string | undefined] {
  if (deployError) {
    return [true, true, extractHumanReadableError(deployError)];
  }

  if (deployLoading || txLoading) {
    return [false, false, undefined];
  }

  if (txError) {
    return [true, true, extractHumanReadableError(txError)];
  }

  if (txLoading) {
    return [false, false, undefined];
  }

  if (txData && txData?.transactionHash) {
    return [false, false, undefined];
  }

  if (!creationState.collectionName) {
    return [false, false, 'Collection name is required'];
  }

  if (!creationState.collectionSymbol) {
    return [false, false, 'Collection symbol is required'];
  }

  if (!creationState.maxSupply) {
    return [false, false, 'Maximum supply is required'];
  }
  if (Number(creationState.maxSupply) < 1) {
    return [false, false, 'Maximum supply must be greater than 0'];
  }

  if (!creationState.collectionImageFile) {
    return [false, false, 'Collection image is required'];
  }

  if (!creationState.placeholderImageFile) {
    return [false, false, 'Pre-reveal image is required'];
  }

  if (
    !creationState.royaltyAddress ||
    !isValidAddress(creationState.royaltyAddress)
  ) {
    return [false, false, 'Provide a valid royalty address'];
  }

  if (
    !creationState.royaltyPercent ||
    Number(creationState.royaltyPercent) < 0 ||
    Number(creationState.royaltyPercent) > 90
  ) {
    return [false, false, 'Royalty % must be between 0 and 90 maximum'];
  }

  if (!creationState.collectionImageUri) {
    return [true, false, 'Collection image must be uploaded to IPFS'];
  }

  if (!creationState.collectionMetadataUri) {
    return [true, false, 'Collection metadata must be uploaded to IPFS'];
  }

  if (!creationState.placeholderImageUri) {
    return [true, false, 'Placeholder image must be uploaded to IPFS'];
  }

  if (!creationState.placeholderMetadataUri) {
    return [true, false, 'Placeholder image must be uploaded to IPFS'];
  }

  return [true, true, undefined];
}

export function generateManualDeploymentCode(
  config: ERC721TieredSalesCollectionConfig,
  constructorArguments: ERC721TieredSalesCollectionConstructorArguments,
) {
  const manualDeploymentCode = `// SPDX-License-Identifier: MIT
pragma solidity ^0.8.9;

import "@0xflair/evm-contracts/collections/ERC721/presets/ERC721TieredSalesCollection.sol";

contract MyCollection is ERC721TieredSalesCollection {
  constructor()
    ERC721TieredSalesCollection(
      Config({
        name: "${constructorArguments[0]?.name}",
        symbol: "${constructorArguments[0]?.symbol}",
        contractURI: "${constructorArguments[0]?.contractURI}",
        placeholderURI: "${constructorArguments[0]?.placeholderURI}",
        tokenURIPrefix: "${constructorArguments[0]?.tokenURIPrefix}",
        maxSupply: uint256(${constructorArguments[0]?.maxSupply}),
        tiers: ${JSON.stringify(constructorArguments[0]?.tiers)},
        proceedsRecipient: address(${
          constructorArguments[0]?.proceedsRecipient
        }),
        defaultRoyaltyAddress: address(${
          constructorArguments[0]?.defaultRoyaltyAddress
        }),
        defaultRoyaltyBps: ${constructorArguments[0]?.defaultRoyaltyBps},
        trustedForwarder: ${constructorArguments[0]?.trustedForwarder}
      })
    )
  {}
}`;

  return manualDeploymentCode;
}
