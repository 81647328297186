import { PredefinedReadContractConfig, useContractRead } from 'flair-sdk';

export const useManagementPowerRevoked = (
  config: PredefinedReadContractConfig,
) => {
  return useContractRead<boolean>({
    contractFqn: 'collections/ERC721/extensions/ERC721OwnerManagedExtension',
    functionName: 'managementPowerRevoked',
    ...config,
  });
};
