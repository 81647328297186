import {
  CryptoUnits,
  CryptoValue,
  Environment,
  Spinner,
  TokenStream,
  useChainId,
  useChainInfo,
  useERC20BalanceOf,
  useERC20Symbol,
  useERC721Symbol,
  useERC721TotalSupply,
  useStreamTotalClaimed,
  useTokenBalances,
  ZERO_ADDRESS,
} from 'flair-sdk';
import { Link } from 'react-router-dom';
import { useBalance } from 'wagmi';

import { AddressLink } from '../../../../components/ui/AddressLink';
import { TokenStreamBalance } from '../components/TokenStreamBalance';

type Props = {
  env?: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
};

export const TokensAdminSection = ({ env, tokenStream, refresh }: Props) => {
  const chainId = useChainId(tokenStream.chainId);
  const chainInfo = useChainInfo(chainId);
  const contractAddress = tokenStream.contractAddress;
  const contractVersion = tokenStream.presetVersion;

  const { data: claimTokenSymbol } = useERC20Symbol({
    contractAddress: tokenStream?.config?.primaryClaimToken,
  });

  const { data: erc721TotalSupply } = useERC721TotalSupply({
    contractAddress: tokenStream?.config?.ticketToken,
  });

  const { data: erc721Symbol } = useERC721Symbol({
    contractAddress: tokenStream.config?.ticketToken,
  });

  const { data: erc20StreamSupply } = useERC20BalanceOf({
    contractAddress: tokenStream.config?.primaryClaimToken,
    accountAddress: tokenStream.contractAddress,
  });

  const { data: streamTotalClaimed } = useStreamTotalClaimed({
    contractAddress,
    contractVersion,
  });

  const { data: streamBalances, isLoading: streamBalancesLoading } =
    useTokenBalances({
      env,
      chainId,
      address: contractAddress,
    });

  const { data: streamNativeBalance } = useBalance({
    chainId,
    addressOrName: contractAddress,
  });

  const primaryClaimTokenView = (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Primary Claim Token
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          The primary ERC20 token that is distributed amongst the NFT holders in
          this stream.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <AddressLink address={tokenStream?.config?.primaryClaimToken} />
            </dd>
            <dt className="text-sm font-medium text-gray-500">Stream supply</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <CryptoValue
                value={erc20StreamSupply?.toString()}
                symbol={claimTokenSymbol?.toString()}
                unit={CryptoUnits.WEI}
                showPrice={false}
              />
              <Link
                className="text-indigo-600"
                to={{
                  pathname: `/streams/${tokenStream._id}`,
                  search: `#top-up`,
                }}
              >
                Top-up now
              </Link>
            </dd>
            <dt className="text-sm font-medium text-gray-500">
              Stream total claimed
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <CryptoValue
                value={streamTotalClaimed}
                symbol={claimTokenSymbol?.toString()}
                unit={CryptoUnits.WEI}
                showPrice={false}
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );

  const ticketTokenView = (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          NFT Token
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          This the contract address of your ERC721. Only holders of this
          collection can claim from this stream.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <AddressLink address={tokenStream.config?.ticketToken} />
            </dd>
            <dt className="text-sm font-medium text-gray-500">Total NFTs</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {erc721TotalSupply?.toString() ||
                tokenStream.config?.totalTickets?.toString()}{' '}
              {erc721Symbol}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );

  const streamBalanceView = (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Stream balance(s)
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Below are all the tokens sent to this stream, to be shared among NFT
          holders.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <TokenStreamBalance
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b border-gray-200"
            tokenStream={tokenStream}
            tokenBalance={{
              chainId: chainId as number,
              balance: streamNativeBalance?.value?.toString() || '0',
              icon: '',
              name: chainInfo?.nativeCurrency?.name as string,
              symbol: chainInfo?.nativeCurrency?.symbol as string,
              decimals:
                (chainInfo?.nativeCurrency?.decimals.toString() as string) ||
                '18',
              tokenAddress: ZERO_ADDRESS,
              ownerAddress: contractAddress as string,
            }}
          />
          {streamBalances?.map((balance) => (
            <TokenStreamBalance
              key={balance.tokenAddress}
              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b border-gray-200"
              tokenStream={tokenStream}
              tokenBalance={balance}
            />
          ))}
        </dl>
        {streamBalancesLoading && (
          <div className="p-4 flex gap-2 items-center">
            <Spinner /> Fetching balance...
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {ticketTokenView}
      {tokenStream?.config?.primaryClaimToken ? primaryClaimTokenView : null}
      {streamBalanceView}
    </>
  );
};
