import { Dialog, Transition } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import {
  classNames,
  ConnectButton,
  NetworkSelector,
  PRIMARY_BUTTON,
  WalletDropdown,
} from 'flair-sdk';
import { Fragment, ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import logoImage from '../assets/images/logo-light-transparent.svg';

type Props = {
  sideNavigation: {
    name: string;
    href: string;
    icon: React.ReactNode | any;
  }[];
  children: ReactNode;
};

function DashboardSkeleton(props: Props) {
  const { sideNavigation, children } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { activeChain } = useNetwork();
  const location = useLocation();

  /**
   * show deprecation message and banner in case the wallet is not connected
   * or user is connected to an app specific chain like Saga (Chain ID less than 5)
   */
  const deprecationNotice =
    activeChain && activeChain?.id.toString().length <= 5 ? (
      <div className="p-5">
        <div className={classNames('rounded-md bg-yellow-50 p-4')}>
          <div className="">
            <h3 className="text-lg font-bold text-yellow-800">v1 Deprecated</h3>
            <div className="mt-2 text-sm text-yellow-700">
              This is legacy dashboard, please use{' '}
              <a href="https://app.flair.dev" className="font-bold underline">
                v2 Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
    ) : null;

  const deprecationBanner = (
    // activeChain && activeChain?.id.toString().length <= 5 ? (
    <div className="fixed z-10 top-0 left-0 right-0 bg-yellow-100 border-b border-yellow-400">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-yellow-800">
            <span className="md:hidden">New v2 dashboard is live!</span>
            <span className="hidden md:inline">
              We are no longer developing this version of dashboard. Use with
              this in mind.
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <a
                href="https://app.flair.dev"
                className="font-bold text-yellow-800 underline"
              >
                Launch v2 now!
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </span>
          </p>
        </div>
        {/* <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
            <button
              type="button"
              className="flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div> */}
      </div>
    </div>
  );
  // ) : null;

  return (
    <>
      {deprecationBanner}
      <div
        className={classNames(
          // activeChain && activeChain?.id.toString().length <= 5 ? 'mt-12' : '',
          'mt-12 h-full',
        )}
      >
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <Link to="/" className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src={logoImage} alt="Workflow" />
                </Link>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="space-y-1">
                    {/* {deprecationNotice} */}
                    {sideNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          location.pathname.startsWith(item.href)
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-4 py-4 text-base font-medium',
                        )}
                      >
                        <item.icon
                          className={classNames(
                            location.pathname.startsWith(item.href)
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-4 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800 mt-12">
            <Link to="/" className="flex items-center h-16 flex-shrink-0 px-4">
              <img className="h-8 w-auto" src={logoImage} alt="Workflow" />
            </Link>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 space-y-1">
                {/* {deprecationNotice} */}
                {sideNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      location.pathname.startsWith(item.href)
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-4 py-4 text-md font-medium',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        location.pathname.startsWith(item.href)
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col h-full">
          <div className="flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex"></div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Connect Button */}
                <ConnectButton className={PRIMARY_BUTTON}>
                  <div className="flex gap-3">
                    <NetworkSelector />
                    <WalletDropdown />
                  </div>
                </ConnectButton>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default DashboardSkeleton;
