import { Environment } from 'flair-sdk';
import md5 from 'md5';
import { useMemo } from 'react';
import {
  IoLayersOutline,
  IoPeopleOutline,
  IoRainyOutline,
} from 'react-icons/io5';
import { RiCopperCoinLine } from 'react-icons/ri';
import { TbApi } from 'react-icons/tb';
import { Route, Routes, useLocation } from 'react-router';
import { useAccount, useNetwork } from 'wagmi';

import DashboardSkeleton from './components/DashboardSkeleton';
import { RequireConnect } from './components/ui/RequireConnect';
import { RequireLogin } from './components/ui/RequireLogin';
import { usePageTracking } from './hooks/usePageTracking';
import { BrowseAddressLists } from './pages/AddressLists/BrowseAddressLists';
import { CreateAddressList } from './pages/AddressLists/CreateAddressList';
import { ManageAddressList } from './pages/AddressLists/ManageAddressList';
import { BrowseAuthClients } from './pages/AuthClients/BrowseAuthClients';
import { ManageAuthClient } from './pages/AuthClients/ManageAuthClient';
import { RegisterAuthClient } from './pages/AuthClients/RegisterAuthClient';
import { NotFound } from './pages/Common/NotFound/NotFound';
import { Welcome } from './pages/Common/Welcome/Welcome';
import { CreateEscrowCampaign } from './pages/EscrowCampaigns/CreateEscrowCampaign';
import { BrowseFungibleTokens } from './pages/FungibleTokens/BrowseFungibleTokens';
import { CreateFungibleToken } from './pages/FungibleTokens/CreateFungibleToken';
import { ImportFungibleToken } from './pages/FungibleTokens/ImportFungibleToken';
import { ManageFungibleToken } from './pages/FungibleTokens/ManageFungibleToken';
import { CreateERC20BasicToken } from './pages/FungibleTokens/use-cases/CreateERC20BasicToken';
import { CreateERC20LockableToken } from './pages/FungibleTokens/use-cases/CreateERC20LockableToken';
import { BrowseNftCollections } from './pages/NftCollections/BrowseNftCollections';
import { CreateNftCollection } from './pages/NftCollections/CreateNftCollection';
import { ImportNftCollection } from './pages/NftCollections/ImportNftCollection';
import { ManageNftCollection } from './pages/NftCollections/ManageNftCollection';
import { ERC721OneOfOneCollectionCreate } from './pages/NftCollections/use-cases/ERC721OneOfOne/create';
import { CreateERC721SimpleSalesCollection } from './pages/NftCollections/use-cases/ERC721SimpleSales/create';
import { CreateERC721StakeholderRegistry } from './pages/NftCollections/use-cases/ERC721StakeholderRegistry';
import { CreateERC721TieredSalesCollection } from './pages/NftCollections/use-cases/ERC721TieredSales/create';
import { NftCollectionMintingPage } from './pages/Public/NftCollections/NftCollectionMintingPage';
import { TokenStreamClaimingPage } from './pages/Public/TokenStreams/TokenStreamClaimingPage';
import { BrowseTokenStreams } from './pages/TokenStreams/BrowseTokenStreams';
import { CreateTokenStream } from './pages/TokenStreams/CreateTokenStream';
import { ImportTokenStream } from './pages/TokenStreams/ImportTokenStream';
import { ManageTokenStream } from './pages/TokenStreams/ManageTokenStream';
import { CreateERC721EqualInstantStream } from './pages/TokenStreams/use-cases/CreateERC721EqualInstantStream';
import { CreateERC721ShareInstantStream } from './pages/TokenStreams/use-cases/CreateERC721ShareInstantStream';
import { CreateERC721SingleTokenEqualEmissionStream } from './pages/TokenStreams/use-cases/CreateERC721SingleTokenEqualEmissionStream';
import { CreateERC721StakingStream } from './pages/TokenStreams/use-cases/ERC721StakingStream';
import { ToolsPage } from './pages/Tools/ToolsPage';
import { GA_MEASUREMENT_ID } from './util';

const sideNavigation = [
  {
    name: 'Tokens',
    href: '/tokens',
    icon: RiCopperCoinLine,
  },
  {
    name: 'Collections',
    href: '/collections',
    icon: IoLayersOutline,
  },
  {
    name: 'Lists',
    href: '/lists',
    icon: IoPeopleOutline,
  },
  // {
  //   name: 'Escrow',
  //   href: '/escrow',
  //   icon: FiShield,
  // },
  {
    name: 'Streams',
    href: '/streams',
    icon: IoRainyOutline,
  },
  // {
  //   name: 'Renting',
  //   href: '/renting',
  //   icon: AiOutlineSwap,
  // },
  // {
  //   name: 'Clients',
  //   href: '/clients',
  //   icon: TbApi,
  // },
  // {
  //   name: 'Tools',
  //   href: '/tools',
  //   icon: DiCode,
  // },
];

type Props = {
  env?: Environment;
};

function App({ env = Environment.PROD }: Props) {
  const location = useLocation();
  const isPublic = location.pathname.startsWith('/public');
  const { data: account } = useAccount();
  const { activeChain } = useNetwork();

  usePageTracking();

  useMemo(() => {
    if (!activeChain?.id) return null;
    window?.gtag?.('config', GA_MEASUREMENT_ID, {
      active_chain_id: activeChain?.id,
    });
    return activeChain?.id;
  }, [activeChain?.id]);

  useMemo(() => {
    if (!account?.address) return null;

    window?.gtag?.('config', GA_MEASUREMENT_ID, {
      user_id: md5(account?.address),
    });

    window?.gtag?.('event', 'wallet_connect_successful', {
      event_category: 'dashboard',
      chain_id: activeChain?.id,
      transaction_id: `${activeChain?.id}:${account?.address}`,
    });

    return account?.address;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.address]);

  return isPublic ? (
    <Routes>
      <Route path="public">
        <Route
          path="mint/:chainId/:contractAddress"
          element={<NftCollectionMintingPage env={env} />}
        />
        <Route
          path="claim/:chainId/:contractAddress"
          element={<TokenStreamClaimingPage env={env} />}
        />
      </Route>
    </Routes>
  ) : (
    <DashboardSkeleton sideNavigation={sideNavigation}>
      <Routes>
        <Route path="/">
          <Route index element={<Welcome />} />
          <Route path="collections">
            <Route
              index
              element={
                <RequireConnect>
                  <RequireLogin>
                    <BrowseNftCollections env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="import"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ImportNftCollection env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateNftCollection />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721SimpleSales"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721SimpleSalesCollection env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721TieredSales"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721TieredSalesCollection env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721OneOfOne"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ERC721OneOfOneCollectionCreate env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721StakeholderRegistry"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721StakeholderRegistry env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path=":nftCollectionId"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ManageNftCollection env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
          </Route>

          <Route path="lists">
            <Route
              index
              element={
                <RequireConnect>
                  <RequireLogin>
                    <BrowseAddressLists env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateAddressList env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path=":listId"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ManageAddressList env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
          </Route>

          <Route path="escrow">
            <Route
              index
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateEscrowCampaign env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
          </Route>

          <Route path="streams">
            <Route
              index
              element={
                <RequireConnect>
                  <RequireLogin>
                    <BrowseTokenStreams env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="import"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ImportTokenStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateTokenStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721SingleTokenEqualEmissionStream"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721SingleTokenEqualEmissionStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721StakingStream"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721StakingStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721ShareInstantStream"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721ShareInstantStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC721EqualInstantStream"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateERC721EqualInstantStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path=":tokenStreamId"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ManageTokenStream env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
          </Route>

          <Route path="tokens">
            <Route
              index
              element={
                <RequireConnect>
                  <BrowseFungibleTokens env={env} />
                </RequireConnect>
              }
            />
            <Route
              path="create"
              element={
                <RequireConnect>
                  <CreateFungibleToken env={env} />
                </RequireConnect>
              }
            />
            <Route
              path="import"
              element={
                <RequireConnect>
                  <ImportFungibleToken env={env} />
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC20BasicToken"
              element={
                <RequireConnect>
                  <CreateERC20BasicToken env={env} />
                </RequireConnect>
              }
            />
            <Route
              path="create/ERC20LockableToken"
              element={
                <RequireConnect>
                  <CreateERC20LockableToken env={env} />
                </RequireConnect>
              }
            />
            <Route
              path=":fungibleTokenId"
              element={
                <RequireConnect>
                  <ManageFungibleToken env={env} />
                </RequireConnect>
              }
            />
          </Route>

          {/* <Route path="renting">
            <Route
              index
              element={
                <RequireConnect>
                  <RequireLogin>
                    <CreateNftRenting env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
          </Route> */}

          <Route path="clients">
            <Route
              index
              element={
                <RequireConnect>
                  <RequireLogin>
                    <BrowseAuthClients env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path="register"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <RegisterAuthClient env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
            <Route
              path=":clientId"
              element={
                <RequireConnect>
                  <RequireLogin>
                    <ManageAuthClient env={env} />
                  </RequireLogin>
                </RequireConnect>
              }
            />
          </Route>

          <Route path="tools">
            <Route
              index
              element={
                <RequireConnect>
                  <ToolsPage env={env} />
                </RequireConnect>
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </DashboardSkeleton>
  );
}

export default App;
