import {
  Badge,
  Button,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  TransactionLink,
  useTokenMetadataUriFrozen,
  useTokenMetadataUriPrefix,
  useTokenMetadataUriSuffix,
} from 'flair-sdk';
import { useState } from 'react';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { useTokenMetadataUriFreezer } from '../hooks/metadata/useTokenMetadataUriFreezer';
import { useTokenMetadataUriPrefixUpdater } from '../hooks/metadata/useTokenMetadataUriPrefixUpdater';
import { useTokenMetadataUriSuffixUpdater } from '../hooks/metadata/useTokenMetadataUriSuffixUpdater';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const RevealAdminSection = ({ env, nftCollection, refresh }: Props) => {
  const chainId = nftCollection.chainId;
  const contractAddress = nftCollection.contractAddress;

  const [prefixDialogOpen, setPrefixDialogOpen] = useState(false);

  const [suffixDialogOpen, setSuffixDialogOpen] = useState(false);

  const {
    isSupported: tokenMetadataUriPrefixSupported,
    data: tokenMetadataUriPrefix,
    error: tokenMetadataUriPrefixError,
    isLoading: tokenMetadataUriPrefixLoading,
  } = useTokenMetadataUriPrefix({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: tokenMetadataUriSuffixSupported,
    data: tokenMetadataUriSuffix,
    error: tokenMetadataUriSuffixError,
    isLoading: tokenMetadataUriSuffixLoading,
  } = useTokenMetadataUriSuffix({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: tokenMetadataUriPrefixUpdaterSupported,
    data: tokenMetadataUriPrefixUpdaterData,
    error: tokenMetadataUriPrefixUpdaterError,
    isLoading: tokenMetadataUriPrefixUpdaterLoading,
    writeAndWait: setTokenMetadataUriPrefix,
  } = useTokenMetadataUriPrefixUpdater({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: tokenMetadataUriSuffixUpdaterSupported,
    data: tokenMetadataUriSuffixUpdaterData,
    error: tokenMetadataUriSuffixUpdaterError,
    isLoading: tokenMetadataUriSuffixUpdaterLoading,
    writeAndWait: setTokenMetadataUriSuffix,
  } = useTokenMetadataUriSuffixUpdater({
    env,
    chainId,
    contractAddress,
  });

  const {
    data: tokenMetadataUriFreezerData,
    error: tokenMetadataUriFreezerError,
    isLoading: tokenMetadataUriFreezerLoading,
    writeAndWait: freezeTokenMetadataUri,
  } = useTokenMetadataUriFreezer({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: tokenMetadataUriFrozenSupported,
    data: tokenMetadataUriFrozen,
    error: tokenMetadataUriFrozenError,
    isLoading: tokenMetadataUriFrozenLoading,
  } = useTokenMetadataUriFrozen({
    env,
    chainId,
    contractAddress,
  });

  // views
  const revealMetadataView = (
    <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Reveal Metadata
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Update the prefix metadata URI for NFTs in your collection. You can
          get this prefix URI by uploading the JSON of your metadata to an IPFS
          platform like Pinata.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Wanna generate or upload your art?
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <a
                href="https://docs.flair.finance/tutorials/upload-metadata"
                className="text-sm text-indigo-700"
                target={'_blank'}
                rel="noreferrer"
              >
                Check this tutorial about generating and/or uploading art &amp;
                metadata.
              </a>
            </dd>
          </div>
        </dl>
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Current URI Prefix
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {tokenMetadataUriPrefix ? (
                <Badge
                  color="green"
                  text={tokenMetadataUriPrefix as unknown as string}
                />
              ) : tokenMetadataUriPrefixSupported ? (
                <Badge color="yellow" text="Not set yet" />
              ) : (
                <Badge color="gray" text="Unsupported" />
              )}
              {tokenMetadataUriPrefixError && (
                <Errors error={tokenMetadataUriPrefixError} />
              )}
            </dd>
          </div>
        </dl>
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Current URI Suffix
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {tokenMetadataUriSuffix ? (
                <Badge
                  color="green"
                  text={tokenMetadataUriSuffix as unknown as string}
                />
              ) : tokenMetadataUriSuffixSupported ? (
                <Badge color="yellow" text="Not set yet" />
              ) : (
                <Badge color="gray" text="Unsupported" />
              )}
              {tokenMetadataUriSuffixError && (
                <Errors error={tokenMetadataUriSuffixError} />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
        {tokenMetadataUriPrefixUpdaterSupported &&
        (tokenMetadataUriPrefix || !tokenMetadataUriPrefixLoading) ? (
          <SingleValueUpdaterButtonDialog
            dialogTitle={'Set URI prefix (Reveal metadata)'}
            dialogDescription="Updating the prefix URI will reveal the metadata for NFTs in your collection. Prefix must end with a / slash. Token ID will be appended to the end of the prefix URI."
            dialogOpen={prefixDialogOpen}
            setDialogOpen={setPrefixDialogOpen}
            inputLabel="Reveal metadata"
            inputType={'text'}
            inputArgs={{
              placeholder: 'ipfs://Qm..../',
            }}
            inputOnSubmit={(value) =>
              setTokenMetadataUriPrefix({ uriPrefix: value }).then(() => {
                refresh();
                setSuffixDialogOpen(false);
              })
            }
            inputDefaultValue={tokenMetadataUriPrefix || ''}
            buttonClassName={PRIMARY_BUTTON}
            buttonLabel="Reveal metadata"
            buttonDisabled={
              tokenMetadataUriPrefixUpdaterLoading ||
              tokenMetadataUriPrefixLoading ||
              (tokenMetadataUriFrozenSupported && tokenMetadataUriFrozen)
            }
            data={tokenMetadataUriPrefixUpdaterData}
            loading={tokenMetadataUriPrefixUpdaterLoading}
            error={tokenMetadataUriPrefixUpdaterError}
          />
        ) : null}
        {tokenMetadataUriSuffixUpdaterSupported &&
        tokenMetadataUriSuffix !== undefined ? (
          <SingleValueUpdaterButtonDialog
            dialogTitle={'Set suffix'}
            dialogDescription="Updating the URI suffix, which is appended to all token IDs. Usually it is '.json'"
            dialogOpen={suffixDialogOpen}
            setDialogOpen={setSuffixDialogOpen}
            inputLabel="Set suffix"
            inputType={'text'}
            inputArgs={{
              placeholder: '.json',
            }}
            inputOnSubmit={(value) =>
              setTokenMetadataUriSuffix({ uriSuffix: value }).then(() => {
                refresh();
                setSuffixDialogOpen(false);
              })
            }
            inputDefaultValue={tokenMetadataUriSuffix}
            buttonClassName={SECONDARY_BUTTON}
            buttonLabel="Set suffix"
            buttonDisabled={
              tokenMetadataUriSuffixUpdaterLoading ||
              tokenMetadataUriSuffixLoading ||
              (tokenMetadataUriFrozenSupported && tokenMetadataUriFrozen)
            }
            data={tokenMetadataUriSuffixUpdaterData}
            loading={tokenMetadataUriSuffixUpdaterLoading}
            error={tokenMetadataUriSuffixUpdaterError}
          />
        ) : null}
      </div>
    </div>
  );

  const freezeMetadataView = (
    <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Freeze Metadata
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          By freezing the metadata, no one can update the NFTs metadata, a sign
          of stronger decentralization for your project.{' '}
          <a
            href="https://medium.com/coinmonks/should-i-freeze-my-nft-metadata-d50d015f2780"
            className="text-sm text-indigo-700"
            target={'_blank'}
            rel="noreferrer"
          >
            Learn more why you should consider freezing metadata.
          </a>
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Current state</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {tokenMetadataUriFrozenLoading && (
                <>
                  <Spinner /> Loading...
                </>
              )}
              {!tokenMetadataUriFrozenLoading &&
                !tokenMetadataUriFrozenError &&
                tokenMetadataUriFrozenSupported &&
                !tokenMetadataUriFrozen && (
                  <Badge
                    color="yellow"
                    text={`Metadata is not frozen and can be changed by owner`}
                  />
                )}
              {!tokenMetadataUriFrozenLoading &&
                !tokenMetadataUriFrozenError &&
                tokenMetadataUriFrozenSupported &&
                tokenMetadataUriFrozen && (
                  <Badge
                    color="green"
                    text={`Metadata is frozen and cannot change`}
                  />
                )}
              {!tokenMetadataUriFrozenLoading &&
                !tokenMetadataUriFrozenError &&
                !tokenMetadataUriFrozenSupported && (
                  <Badge
                    color="gray"
                    text={`Cannot determine if metadata is frozen`}
                  />
                )}
            </dd>
          </div>
        </dl>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
        <span className="flex flex-grow gap-2 items-center">
          {tokenMetadataUriFreezerLoading && (
            <>
              <Spinner /> Freezing metadata...
            </>
          )}
          {tokenMetadataUriFreezerData.txReceipt ||
          tokenMetadataUriFreezerData.txResponse ? (
            <TransactionLink
              txReceipt={tokenMetadataUriFreezerData.txReceipt}
              txResponse={tokenMetadataUriFreezerData.txResponse}
            />
          ) : null}
          {tokenMetadataUriFreezerError && (
            <Errors error={tokenMetadataUriFreezerError} />
          )}
        </span>
        <Button
          className={PRIMARY_BUTTON}
          text={'Freeze metadata'}
          onClick={() => freezeTokenMetadataUri().then(refresh)}
          disabled={
            tokenMetadataUriFreezerLoading ||
            (tokenMetadataUriFrozenSupported && tokenMetadataUriFrozen)
          }
        />
      </div>
    </div>
  );

  return (
    <>
      {revealMetadataView}
      {freezeMetadataView}
    </>
  );
};
