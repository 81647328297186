import {
  AddressList,
  Badge,
  Button,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  usePreSaleAllowlistMerkleRoot,
  ZERO_BYTES32,
} from 'flair-sdk';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { AddressListSelector } from '../../../../pages/AddressLists/components/AddressListSelector';
import { useAddressListMerkleTreeCreator } from '../../../address-lists/hooks';
import { PreSaleAllowlistChecker } from '../components/PreSaleAllowlistChecker';
import { usePreSaleAllowlistMerkleRootUpdater } from '../hooks/sales/usePreSaleAllowlistMerkleRootUpdater';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const AllowlistAdminSection = ({
  env,
  nftCollection,
  refresh,
}: Props) => {
  const {
    data: preSaleAllowlistMerkleRootUpdaterData,
    error: preSaleAllowlistMerkleRootUpdaterError,
    isLoading: preSaleAllowlistMerkleRootUpdaterLoading,
    writeAndWait: setAllowlistMerkleRoot,
  } = usePreSaleAllowlistMerkleRootUpdater({
    contractAddress: nftCollection.contractAddress,
    contractVersion: nftCollection.presetVersion,
  });

  const {
    data: preSaleAllowlistMerkleRoot,
    error: preSaleAllowlistMerkleRootError,
    isLoading: preSaleAllowlistMerkleRootLoading,
    refetch: preSaleAllowlistMerkleRootRead,
  } = usePreSaleAllowlistMerkleRoot({
    contractAddress: nftCollection.contractAddress,
    contractVersion: nftCollection.presetVersion,
  });

  const [selectedForAllowlist, setSelectedForAllowList] =
    useState<AddressList>();

  const leafMode = 'address-only';
  // const leafMode =
  //   !nftCollection.presetVersion ||
  //   versioning.gte(nftCollection.presetVersion, 'v1.19')
  //     ? 'address-with-allowance'
  //     : 'address-only';

  const {
    isLoading: createMerkleTreeLoading,
    error: createMerkleTreeError,
    sendRequest: createMerkleTreeRequest,
  } = useAddressListMerkleTreeCreator({
    env,
    listId: selectedForAllowlist?._id,
    leafMode,
  });

  const handleApplyAllowlist = useCallback(() => {
    createMerkleTreeRequest().then((response) => {
      if (response?.data) {
        setAllowlistMerkleRoot([response?.data]).then(() => {
          preSaleAllowlistMerkleRootRead();
        });
      }
    });
  }, [
    createMerkleTreeRequest,
    setAllowlistMerkleRoot,
    preSaleAllowlistMerkleRootRead,
  ]);

  const handleDisableAllowlist = useCallback(() => {
    setAllowlistMerkleRoot([ZERO_BYTES32]).then(() => {
      preSaleAllowlistMerkleRootRead();
    });
  }, [setAllowlistMerkleRoot, preSaleAllowlistMerkleRootRead]);

  const isAllowlistMerkleRootSet =
    preSaleAllowlistMerkleRoot?.toString() !== ZERO_BYTES32;

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Allowlist
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Manage allowed addresses to participate in your pre-sale.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                Apply allowlist
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
                <div className="flex flex-col gap-2">
                  <AddressListSelector
                    env={env}
                    setSelected={setSelectedForAllowList}
                    selected={selectedForAllowlist}
                    defaultValue={(list) =>
                      list.name === preSaleAllowlistMerkleRoot?.toString()
                    }
                    buttonLabel="Apply allowlist"
                    buttonDisabled={preSaleAllowlistMerkleRootUpdaterLoading}
                    onButtonClick={handleApplyAllowlist}
                  />
                  {selectedForAllowlist && (
                    <Link
                      to={`/lists/${selectedForAllowlist._id}`}
                      className="text-sm text-indigo-700"
                      target={'_blank'}
                    >
                      Edit addresses in "{selectedForAllowlist.name}"
                    </Link>
                  )}
                </div>
                {preSaleAllowlistMerkleRootUpdaterError ? (
                  <Errors error={preSaleAllowlistMerkleRootUpdaterError} />
                ) : null}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                Current allowlist
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2">
                <span className="flex gap-2 items-center">
                  {preSaleAllowlistMerkleRootLoading ? (
                    <>
                      <Spinner /> Fetching...
                    </>
                  ) : null}
                  {preSaleAllowlistMerkleRootUpdaterLoading ? (
                    <>
                      <Spinner /> Setting...
                    </>
                  ) : null}
                  {createMerkleTreeLoading ? (
                    <>
                      <Spinner /> Generating merkle tree...
                    </>
                  ) : null}
                  {preSaleAllowlistMerkleRootError ? (
                    <Errors
                      title="preSaleAllowlistMerkleRootError"
                      error={preSaleAllowlistMerkleRootError}
                    />
                  ) : null}
                  {createMerkleTreeError ? (
                    <Errors
                      title="createMerkleTreeError"
                      error={createMerkleTreeError}
                    />
                  ) : null}
                  {!isAllowlistMerkleRootSet ? (
                    <Badge text="Not set yet" color="yellow" />
                  ) : (
                    <>
                      <Badge text="Set" color="green" />
                    </>
                  )}
                </span>
                {preSaleAllowlistMerkleRoot &&
                preSaleAllowlistMerkleRoot !== ZERO_BYTES32 ? (
                  <small className="text-xs">
                    (merkle root: {preSaleAllowlistMerkleRoot.toString()})
                  </small>
                ) : null}
                {preSaleAllowlistMerkleRootUpdaterData.txReceipt ||
                preSaleAllowlistMerkleRootUpdaterData.txResponse ? (
                  <TransactionLink
                    txReceipt={preSaleAllowlistMerkleRootUpdaterData.txReceipt}
                    txResponse={
                      preSaleAllowlistMerkleRootUpdaterData.txResponse
                    }
                  />
                ) : null}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                Test address in allowlist
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <PreSaleAllowlistChecker
                  env={env}
                  chainId={nftCollection.chainId}
                  contractAddress={nftCollection.contractAddress}
                  contractVersion={nftCollection.presetVersion}
                />
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
          <Button
            className={PRIMARY_BUTTON}
            text={'Remove current allowlist'}
            onClick={handleDisableAllowlist}
            disabled={Boolean(
              preSaleAllowlistMerkleRootUpdaterLoading ||
                !isAllowlistMerkleRootSet,
            )}
          />
        </div>
      </div>
    </>
  );
};
