import {
  Badge,
  Environment,
  NftCollection,
  useContractDefinition,
  ZERO_BYTES32,
} from 'flair-sdk';
import { useState } from 'react';
import { useNetwork } from 'wagmi';

import { SingleValueUpdaterButtonDialog } from '../../../../../components/ui/SingleValueUpdater';
import { useTrustedForwarderAddress } from '../../../../common/hooks/useTrustedForwarderAddress';
import { useTrustedForwarderUpdater } from '../../../../common/hooks/useTrustedForwarderUpdater';

type Props = {
  env?: Environment;
  collection: NftCollection<any>;
  refreshCollection: () => void;
};

export const BackendMintWidget = ({
  env,
  collection,
  refreshCollection,
}: Props) => {
  const { activeChain } = useNetwork();
  const definition = useContractDefinition({
    contractFqn: 'common/meta-transactions/UnorderedForwarder',
    contractVersion: collection.presetVersion,
  });
  const {
    data: trustedForwarderAddress,
    isLoading: trustedForwarderAddressLoading,
  } = useTrustedForwarderAddress({
    chainId: collection.chainId,
    contractAddress: collection.contractAddress,
  });

  const {
    data: trustedForwarderUpdaterData,
    error: trustedForwarderUpdaterError,
    isLoading: trustedForwarderUpdaterLoading,
    writeAndWait: setTrustedForwarderAddress,
  } = useTrustedForwarderUpdater({
    contractAddress: collection.contractAddress,
    contractVersion: collection.presetVersion,
  });

  const [forwarderDialogOpen, setForwarderDialogOpen] = useState(false);

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Backend minting
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Trustless API to mint NFTs from your backend.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex">
              Node.js example
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
              <div>
                <a
                  href={`https://github.com/0xflair/examples/tree/main/nodejs/express/mint-nft-by-role-meta-transactions`}
                  target={'_blank'}
                  className={'text-indigo-600 font-medium'}
                  rel="noreferrer"
                >
                  github.com/0xflair/examples -
                  mint-nft-by-role-meta-transactions
                </a>
              </div>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Configured trusted forwarder
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-start">
              {trustedForwarderAddress ? (
                <Badge color="blue" text={trustedForwarderAddress} />
              ) : (
                <Badge color="yellow" text={'No trusted forwarder set yet'} />
              )}
              {trustedForwarderAddress !== undefined ? (
                <SingleValueUpdaterButtonDialog
                  dialogTitle={'Change forwarder'}
                  dialogDescription="Updating the trusted forwarder contract. Note that this is a sensitive operation, because a malicious forwarder can take over your contract."
                  dialogOpen={forwarderDialogOpen}
                  setDialogOpen={setForwarderDialogOpen}
                  inputLabel="Update forwarder"
                  inputType={'address'}
                  inputArgs={{
                    placeholder: ZERO_BYTES32,
                  }}
                  inputOnSubmit={(value) =>
                    setTrustedForwarderAddress([value]).then(() => {
                      refreshCollection();
                      setForwarderDialogOpen(false);
                    })
                  }
                  inputDefaultValue={trustedForwarderAddress}
                  buttonLabel="Change forwarder"
                  buttonDisabled={
                    trustedForwarderUpdaterLoading ||
                    trustedForwarderAddressLoading
                  }
                  data={trustedForwarderUpdaterData}
                  loading={trustedForwarderUpdaterLoading}
                  error={trustedForwarderUpdaterError}
                />
              ) : null}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Flair's latest trusted forwarder
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-start">
              {definition &&
              activeChain?.id &&
              definition.address?.[activeChain?.id] ? (
                <>
                  <a
                    href={`${activeChain.blockExplorers?.default.url}/address/${
                      definition.address?.[activeChain?.id]
                    }`}
                    className="inline text-sm text-gray-800 font-bold"
                  >
                    {definition.address?.[activeChain?.id]}
                  </a>{' '}
                  ({activeChain?.name || activeChain.id} chain)
                </>
              ) : (
                <Badge
                  color="yellow"
                  text={`Not supported yet for ${activeChain?.name}`}
                />
              )}
              <p className="mt-2 text-xs text-gray-500">
                You can set this address as your trusted forwarder if you like
                to use Flair's backend minting APIs. This forwarder is
                open-source and only ability is to forward standard
                meta-transactions.
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
