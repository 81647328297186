import { utils } from 'ethers';
import {
  Button,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
} from 'flair-sdk';
import { useState } from 'react';

import { useERC20MintAmountToAddress } from '../hooks/useERC20MintAmountToAddress';

type Props = {
  env?: Environment;
  chainId?: number;
  contractAddress?: string;
  defaultTo?: string;
  hideTo?: boolean;
};

export const ERC20AdminMintForm = ({
  env,
  chainId,
  contractAddress,
  defaultTo = '',
  hideTo = false,
}: Props) => {
  const [to, setTo] = useState<string>(defaultTo);
  const [amount, setAmount] = useState<string>('1');

  const {
    data,
    error,
    isLoading,
    writeAndWait: mint,
  } = useERC20MintAmountToAddress({
    env,
    chainId,
    contractAddress,
    amount:
      amount && !isNaN(Number(amount))
        ? utils.parseEther(amount || '0')
        : undefined,
    to: to,
  });

  return (
    <>
      {!hideTo ? (
        <div className="sm:col-span-3">
          <label
            htmlFor="receiverWallet"
            className="block text-sm font-medium text-gray-700"
          >
            Receiver address
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="receiverAddress"
              id="receiverWallet"
              autoComplete="walletAddress"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="0x0000000000000000000000000000000000000000"
              value={to}
              onChange={(e) => setTo(e.target.value.trim())}
            />
          </div>
        </div>
      ) : null}

      <div className="sm:col-span-3">
        <label
          htmlFor="amount"
          className="block text-sm font-medium text-gray-700"
        >
          Amount
        </label>
        <div className="mt-1">
          <input
            type="number"
            name="amount"
            id="amount"
            autoComplete="tokenAmount"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            min={0}
            placeholder="1"
            value={amount}
            onChange={(e) => setAmount(e.target.value.trim())}
          />
        </div>
      </div>

      <div className="sm:col-span-6 flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Button
            text="Mint"
            className={PRIMARY_BUTTON}
            onClick={() => mint()}
          />
        </div>

        <div className="flex items-center gap-2">
          {error ? <Errors error={error} /> : null}
          {isLoading ? (
            <>
              <Spinner /> Transferring...
            </>
          ) : null}
          {data?.txReceipt || data?.txResponse ? (
            <TransactionLink
              txReceipt={data?.txReceipt}
              txResponse={data?.txResponse}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
