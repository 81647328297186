import { ClipboardListIcon } from '@heroicons/react/outline';
import { Environment, Errors, Spinner } from 'flair-sdk';
import { Link } from 'react-router-dom';

import { useAuthClients } from '../../features/auth-clients/hooks/useAuthClients';

type Props = {
  env?: Environment;
};

export const BrowseAuthClients = ({ env = Environment.PROD }: Props) => {
  const { data, error, isLoading } = useAuthClients({ env });

  if (isLoading) {
    return (
      <div className="p-20 flex gap-2 justify-center items-center">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (error || data === undefined) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Errors error={error || 'Unknown error :('} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="p-20 text-center">
        <ClipboardListIcon className="mx-auto h-16 w-16 text-gray-400" />
        <h3 className="mt-2 mb-4 text-2xl font-extrabold text-gray-900">
          Create your first API Key
        </h3>
        <p className="mt-1 text-lg text-gray-500">
          Get started by creating a new client credential <br />
          to use when calling Flair REST APIs.
        </p>
        <div className="mt-6 flex gap-2 justify-center">
          <Link
            to={'/clients/register'}
            className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Register a client
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Clients</h1>
            <p className="mt-2 text-sm text-gray-700">
              Create and manage your auth client credentials to use when calling
              REST APIs.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2 items-center">
            {/* <a
              href={`https://docs.flair.finance/sdk/auth-clients`}
              className={SECONDARY_BUTTON}
              target="_blank"
              rel="noreferrer"
            >
              <BeakerIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Explore SDK
            </a> */}
            <Link
              to="/lists/create"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Register a new client
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Client ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Registered At
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Manage</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((item) => (
                      <tr key={item._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <Link
                            to={`/clients/${item._id}`}
                            className="text-indigo-700"
                          >
                            {item.name}
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {item._id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.registeredAt
                            ? typeof item.registeredAt === 'number' ||
                              Number(item.registeredAt) > 0
                              ? new Date(
                                  Number(item.registeredAt),
                                ).toISOString()
                              : item.registeredAt
                            : '-'}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={`/clients/${item._id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Manage<span className="sr-only">, {item.name}</span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
