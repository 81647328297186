import {
  Environment,
  Errors,
  TokenStream,
  useStreamMaxStakingTotalDurations,
  useStreamMinStakingDuration,
} from 'flair-sdk';
import { useState } from 'react';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { useStreamMaxStakingTotalDurationsUpdater } from '../hooks/useStreamMaxStakingTotalDurationsUpdater';
import { useStreamMinStakingDurationUpdater } from '../hooks/useStreamMinStakingDurationUpdater';

type Props = {
  env?: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
};

export const StakingAdminSection = ({ env, tokenStream, refresh }: Props) => {
  const {
    data: minStakingDuration,
    error: minStakingDurationError,
    isLoading: minStakingDurationLoading,
  } = useStreamMinStakingDuration({
    chainId: tokenStream.chainId,
    contractAddress: tokenStream.contractAddress,
    contractVersion: tokenStream.presetVersion,
  });
  const {
    data: minStakingDurationUpdaterData,
    error: minStakingDurationUpdaterError,
    isLoading: minStakingDurationUpdaterLoading,
    writeAndWait: setMinStakingDuration,
  } = useStreamMinStakingDurationUpdater({
    contractAddress: tokenStream.contractAddress,
    contractVersion: tokenStream.presetVersion,
  });
  const [minStakingDurationDialogOpen, setMinStakingDurationDialogOpen] =
    useState(false);

  const {
    data: maxStakingTotalDurations,
    error: maxStakingTotalDurationsError,
    isLoading: maxStakingTotalDurationsLoading,
  } = useStreamMaxStakingTotalDurations({
    chainId: tokenStream.chainId,
    contractAddress: tokenStream.contractAddress,
    contractVersion: tokenStream.presetVersion,
  });
  const {
    data: maxStakingTotalDurationsUpdaterData,
    error: maxStakingTotalDurationsUpdaterError,
    isLoading: maxStakingTotalDurationsUpdaterLoading,
    writeAndWait: setMaxStakingTotalDurations,
  } = useStreamMaxStakingTotalDurationsUpdater({
    contractAddress: tokenStream.contractAddress,
    contractVersion: tokenStream.presetVersion,
  });
  const [
    maxStakingTotalDurationsDialogOpen,
    setMaxStakingTotalDurationsDialogOpen,
  ] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className={'bg-white shadow overflow-hidden rounded-lg'}>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Staking restrictions
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Limit how short and how long NFTs can be staked.
            </p>
          </div>
          <div className="px-4 py-5 sm:p-0">
            <dl>
              <div className="sm:py-5 grid grid-cols-3 gap-2 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                  Min staking duration
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                  {!minStakingDurationLoading && minStakingDuration ? (
                    <>
                      {Number(minStakingDuration?.toString()) / (24 * 3600)}{' '}
                      day(s)
                    </>
                  ) : (
                    '...'
                  )}
                </dd>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                  {minStakingDuration && (
                    <SingleValueUpdaterButtonDialog
                      dialogTitle={'Update min staking duration (days)'}
                      dialogDescription="Users cannot unstake their tokens shorter than this amount."
                      dialogOpen={minStakingDurationDialogOpen}
                      setDialogOpen={setMinStakingDurationDialogOpen}
                      inputLabel="New min staking duration (days)"
                      inputType={'number'}
                      inputOnSubmit={(value) => {
                        return setMinStakingDuration([
                          Math.floor(value * 24 * 3600),
                        ])
                          .then(() => setMinStakingDurationDialogOpen(false))
                          .then(() => refresh());
                      }}
                      inputDefaultValue={Math.floor(
                        Number(minStakingDuration.toString()) / (24 * 3600),
                      )}
                      buttonLabel="Change min. duration"
                      buttonDisabled={minStakingDurationUpdaterLoading}
                      data={minStakingDurationUpdaterData}
                      loading={minStakingDurationUpdaterLoading}
                      error={minStakingDurationUpdaterError}
                    />
                  )}
                </dd>
              </div>
              <div className="sm:py-5 grid grid-cols-3 gap-2 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                  Max staking durations
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                  {!maxStakingTotalDurationsLoading &&
                  maxStakingTotalDurations ? (
                    <>
                      {Number(maxStakingTotalDurations?.toString()) /
                        (24 * 3600)}{' '}
                      day(s)
                    </>
                  ) : (
                    '...'
                  )}
                </dd>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                  {maxStakingTotalDurations && (
                    <SingleValueUpdaterButtonDialog
                      dialogTitle={'Update max staking duration (days)'}
                      dialogDescription="Sum total duration of staking, even if NFT is staked and unstaked multiple times."
                      dialogOpen={maxStakingTotalDurationsDialogOpen}
                      setDialogOpen={setMaxStakingTotalDurationsDialogOpen}
                      inputLabel="New max staking duration (days)"
                      inputType={'number'}
                      inputOnSubmit={(value) => {
                        return setMaxStakingTotalDurations([
                          Math.floor(value * 24 * 3600),
                        ])
                          .then(() =>
                            setMaxStakingTotalDurationsDialogOpen(false),
                          )
                          .then(() => refresh());
                      }}
                      inputDefaultValue={Math.floor(
                        Number(maxStakingTotalDurations.toString()) /
                          (24 * 3600),
                      )}
                      buttonLabel="Change max. duration"
                      buttonDisabled={maxStakingTotalDurationsUpdaterLoading}
                      data={maxStakingTotalDurationsUpdaterData}
                      loading={maxStakingTotalDurationsUpdaterLoading}
                      error={maxStakingTotalDurationsUpdaterError}
                    />
                  )}
                </dd>
              </div>
            </dl>
            <div className="py-4 sm:py-5 sm:px-6">
              {minStakingDurationError ? (
                <Errors
                  title="minStakingDurationError"
                  error={minStakingDurationError}
                />
              ) : null}
              {minStakingDurationUpdaterError ? (
                <Errors
                  title="minStakingDurationUpdaterError"
                  error={minStakingDurationUpdaterError}
                />
              ) : null}
              {maxStakingTotalDurationsError ? (
                <Errors
                  title="maxStakingTotalDurationsError"
                  error={maxStakingTotalDurationsError}
                />
              ) : null}
              {maxStakingTotalDurationsError ? (
                <Errors
                  title="maxStakingTotalDurationsError"
                  error={maxStakingTotalDurationsError}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
