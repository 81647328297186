import { FeatureType, SmartContract } from 'flair-sdk';
import { BiLockAlt } from 'react-icons/bi';
import {
  HiOutlineIdentification,
  HiOutlinePlay,
  HiOutlineUsers,
} from 'react-icons/hi';
import { IoSparklesOutline } from 'react-icons/io5';

import { RolesAdminSection } from '../../common/sections/RolesAdminSection';
// import { VerificationAdminSection } from '../../common/sections/VerificationAdminSection';
import { AdminSection } from '../../common/types';
import { DeployAdminSection } from '../sections/DeployAdminSection';
import { LockingAdminSection } from '../sections/LockingAdminSection';
import { MetadataAdminSection } from '../sections/MetadataAdminSection';
import { MintAdminSection } from '../sections/MintAdminSection';
import { FungibleToken } from '../types';

type FungibleTokenAdminSectionContext = {
  fungibleToken?: FungibleToken;
};

export const AdminSectionsRegistry: AdminSection[] = [
  {
    name: 'Deploy',
    href: '#deploy',
    icon: HiOutlinePlay,
    widget: DeployAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(ctx?.fungibleToken?.presetFqn);
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(true);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.contractAddress || ctx?.fungibleToken?.contractAddress,
      );
    },
  },
  {
    name: 'Metadata',
    href: '#metadata',
    icon: HiOutlineIdentification,
    widget: MetadataAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) => f.type === FeatureType.Interface && f.tag === 'erc20',
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(ctx?.fungibleToken?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Mint',
    href: '#mint',
    icon: IoSparklesOutline,
    widget: MintAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['mint_amount_to_address'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(ctx?.fungibleToken?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Locking',
    href: '#locking',
    icon: BiLockAlt,
    widget: LockingAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['erc20_lockable_extension'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(ctx?.fungibleToken?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Roles',
    href: '#roles',
    icon: HiOutlineUsers,
    widget: ({ fungibleToken }) => (
      <RolesAdminSection
        contractAddress={fungibleToken.contractAddress}
        contractVersion={fungibleToken.presetVersion}
        defaultRoleName="MINTER_ROLE"
      />
    ),
    isSupported: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['oz_access_control'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return Boolean(ctx?.fungibleToken?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: FungibleTokenAdminSectionContext,
    ) => {
      return false;
    },
  },
  // {
  //   name: 'Verification',
  //   href: '#verification',
  //   icon: MdOutlineFactCheck,
  //   widget: ({ env, fungibleToken, constructorArguments }) => (
  //     <VerificationAdminSection
  //       env={env}
  //       chainId={fungibleToken.chainId}
  //       contractAddress={fungibleToken.contractAddress}
  //       contractFqn={fungibleToken.presetFqn}
  //       contractVersion={fungibleToken.presetVersion}
  //       constructorArguments={constructorArguments}
  //     />
  //   ),
  //   isSupported: (
  //     smartContract?: SmartContract,
  //     ctx?: FungibleTokenAdminSectionContext,
  //   ) => {
  //     return Boolean(ctx?.fungibleToken?.presetFqn);
  //   },
  //   isEnabled: (
  //     smartContract?: SmartContract,
  //     ctx?: FungibleTokenAdminSectionContext,
  //   ) => {
  //     return Boolean(
  //       ctx?.fungibleToken?.contractAddress && ctx?.fungibleToken?.presetFqn,
  //     );
  //   },
  //   isComplete: (
  //     smartContract?: SmartContract,
  //     ctx?: FungibleTokenAdminSectionContext,
  //   ) => {
  //     return Boolean(
  //       ctx?.fungibleToken?.contractVerification?.state === 'succeeded',
  //     );
  //   },
  // },
];
