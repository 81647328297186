import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  PRIMARY_BUTTON,
  Spinner,
  useLoginJwt,
} from 'flair-sdk';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAddressListCreator } from '../../features/address-lists';

type Props = {
  env?: Environment;
};

type AddressListConfig = Record<string, any>;

type AddressListCreationState = {
  name: string;
  title: string;
  description: string;
  config?: Record<string, any>;
};

const initialState: AddressListCreationState = {
  name: '',
  title: '',
  description: '',
  config: {},
};

export const CreateAddressList = ({ env = Environment.PROD }: Props) => {
  const loginJwt = useLoginJwt();
  const navigate = useNavigate();

  const [creationState, setCreationState] =
    useState<AddressListCreationState>(initialState);
  const [listConfig] = useState<AddressListConfig>({
    criteriaFloorPriceEnabled: false,
    criteriaFloorPriceMin: '2',
  });

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: createAddressList,
  } = useAddressListCreator(
    {
      ...creationState,
      config: listConfig,
    },
    {
      env,
      enabled: false,
      loginJwt,
    },
  );

  const listInfoSectionView = (
    <FormSection
      title="Your List"
      description="General information about your list."
    >
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Private Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            autoComplete="privateName"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Angels Allowlist Round 01"
            value={creationState.name}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                name: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A private name only to visible you in the dashboard.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3 top-2">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            name="title"
            id="title"
            autoComplete="publicTitle"
            placeholder="Flair Angels Allowlist"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.title}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                title: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            An optional public name possibly visible for collectors.
          </p>
        </div>

        <div className="col-span-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <div className="mt-1">
            <textarea
              id="description"
              name="description"
              rows={3}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
              value={creationState.description}
              onChange={(event) =>
                setCreationState((x) => ({
                  ...x,
                  description: event.target.value,
                }))
              }
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            A markdown-enabled description to show to users on the public form,
            if you decide they should submit addresses themselves.
          </p>
        </div>
      </div>
    </FormSection>
  );

  // const listConfigSectionView = (
  //   <>
  //     <FormSection
  //       toggleable={true}
  //       title="Minimum Floor"
  //       description="Addresses must hold NFTs higher than a specific floor price."
  //       enabled={listConfig.criteriaFloorPriceEnabled}
  //       onEnabledChange={(newValue) =>
  //         setListConfig((x) => ({
  //           ...x,
  //           criteriaFloorPriceEnabled: newValue,
  //         }))
  //       }
  //     >
  //       <div className="relative grid grid-cols-6 gap-6">
  //         <ComingSoonOverlay />
  //         <div className="col-span-6 sm:col-span-4">
  //           <CryptoAmountField
  //             label="Min. floor price"
  //             description="Floor price is fetched from OpenSea which currently only supports Ethereum"
  //             value={listConfig.criteriaFloorPriceMin}
  //             onChange={(newValue) =>
  //               setListConfig((x) => ({
  //                 ...x,
  //                 criteriaFloorPriceMin: newValue,
  //               }))
  //             }
  //           />
  //         </div>
  //       </div>
  //     </FormSection>

  //     <div className="hidden sm:block" aria-hidden="true">
  //       <div className="py-5">
  //         <div className="border-t border-gray-200" />
  //       </div>
  //     </div>

  //     <FormSection
  //       toggleable={true}
  //       title="Connect Discord"
  //       description="Require users to connect their discord username."
  //     >
  //       <div className="relative grid grid-cols-6 gap-6">
  //         <ComingSoonOverlay />
  //         <div className="col-span-6 text-sm sm:col-span-6">
  //           This plugin will enforce users to connect their Discord account
  //           before submitting their address.
  //           <hr className="my-4" />
  //           When adding addresses manually from your admin dashboard this part
  //           will be skipped.
  //         </div>
  //       </div>
  //     </FormSection>
  //   </>
  // );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id) {
      navigate('/lists/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New list
          </h3>
        </div>
      </div>

      <div className="max-w-7xl h-full mx-auto py-6 sm:px-6 lg:px-8">
        {listInfoSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Save now"
                disabled={creationLoading}
                onClick={createAddressList}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/lists/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the list
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
