import { BigNumberish, BytesLike } from 'ethers';
import { FeatureWriteByTagConfig, useFeatureWriteByTag } from 'flair-sdk';

type ArgsType = {
  tokenAddresses?: BytesLike[];
  amounts?: BigNumberish[];
};

export const useWithdrawByTokens = (
  config: FeatureWriteByTagConfig<ArgsType>,
) => {
  return useFeatureWriteByTag({
    tag: 'withdraw_by_tokens',
    ...config,
  });
};
