import { ContractFqn } from '@0xflair/contracts-registry';
import { BigNumberish, BytesLike } from 'ethers';
import { TokenStream, useUnorderedForwarderAddress } from 'flair-sdk';

import {
  ERC721CustodialStakingEmissionStreamConfig,
  ERC721CustodialStakingEmissionStreamConstructorArguments,
} from '../latest/types/ERC721CustodialStakingEmissionStream.types';
import {
  ERC721EqualEmissionStreamConfig,
  ERC721EqualEmissionStreamConstructorArguments,
} from '../latest/types/ERC721EqualEmissionStream.types';
import {
  ERC721EqualInstantStreamConfig,
  ERC721EqualInstantStreamConstructorArguments,
} from '../latest/types/ERC721EqualInstantStream.types';
import {
  ERC721LockedStakingEmissionStreamConfig,
  ERC721LockedStakingEmissionStreamConstructorArguments,
} from '../latest/types/ERC721LockedStakingEmissionStream.types';
import {
  ERC721ShareInstantStreamConfig,
  ERC721ShareInstantStreamConstructorArguments,
} from '../latest/types/ERC721ShareInstantStream.types';

type Context = {
  forwarderAddress: ReturnType<typeof useUnorderedForwarderAddress>;
};

export const TokenStreamConstructorFactoryRegistry: Partial<
  Record<
    ContractFqn,
    (tokenStream: Partial<TokenStream<any>>, context: Context) => any[]
  >
> = {
  // Latest
  'streams/ERC721/presets/ERC721ShareInstantStream': (
    tokenStream: Partial<TokenStream<ERC721ShareInstantStreamConfig>>,
    { forwarderAddress }: Context,
  ): ERC721ShareInstantStreamConstructorArguments => {
    return [
      {
        ticketToken: tokenStream.config?.ticketToken as BytesLike,
        lockedUntilTimestamp: tokenStream.config
          ?.initialLockedUntilTimestamp as BigNumberish,
        tokenIds: Object.keys(
          tokenStream.config?.initialShares || {},
        ) as BigNumberish[],
        shares: Object.values(
          tokenStream.config?.initialShares || {},
        ) as BigNumberish[],
        claimLockedUntil:
          (tokenStream.config?.initialClaimLockedUntil as BigNumberish) || '0',
      },
    ];
  },
  'streams/ERC721/presets/ERC721EqualEmissionStream': (
    tokenStream: Partial<TokenStream<ERC721EqualEmissionStreamConfig>>,
    { forwarderAddress }: Context,
  ): ERC721EqualEmissionStreamConstructorArguments => {
    return [
      {
        ticketToken: tokenStream.config?.ticketToken as BytesLike,
        lockedUntilTimestamp: tokenStream.config
          ?.initialLockedUntilTimestamp as BigNumberish,
        totalTickets: tokenStream.config?.initialTotalTickets as BigNumberish,
        emissionRate: tokenStream.config?.initialEmissionRate as BigNumberish,
        emissionTimeUnit: tokenStream.config
          ?.initialEmissionTimeUnit as BigNumberish,
        emissionStart: tokenStream.config?.initialEmissionStart as BigNumberish,
        emissionEnd: tokenStream.config?.initialEmissionEnd as BigNumberish,
        claimLockedUntil:
          (tokenStream.config?.initialClaimLockedUntil as BigNumberish) || '0',
      },
    ];
  },
  'streams/ERC721/presets/ERC721EqualInstantStream': (
    tokenStream: Partial<TokenStream<ERC721EqualInstantStreamConfig>>,
    { forwarderAddress }: Context,
  ): ERC721EqualInstantStreamConstructorArguments => {
    return [
      {
        ticketToken: tokenStream.config?.ticketToken as BytesLike,
        lockedUntilTimestamp: tokenStream.config
          ?.initialLockedUntilTimestamp as BigNumberish,
        totalTickets: tokenStream.config?.initialTotalTickets as BigNumberish,
        claimLockedUntil:
          (tokenStream.config?.initialClaimLockedUntil as BigNumberish) || '0',
      },
    ];
  },
  'streams/ERC721/presets/ERC721LockedStakingEmissionStream': (
    tokenStream: Partial<TokenStream<ERC721LockedStakingEmissionStreamConfig>>,
    { forwarderAddress }: Context,
  ): ERC721LockedStakingEmissionStreamConstructorArguments => {
    return [
      {
        ticketToken: tokenStream.config?.ticketToken as BytesLike,
        lockedUntilTimestamp: tokenStream.config
          ?.initialLockedUntilTimestamp as BigNumberish,
        minStakingDuration: tokenStream.config
          ?.initialMinStakingDuration as BigNumberish,
        maxStakingTotalDurations: tokenStream.config
          ?.initialMaxStakingTotalDurations as BigNumberish,
        emissionRate: tokenStream.config?.initialEmissionRate as BigNumberish,
        emissionTimeUnit: tokenStream.config
          ?.initialEmissionTimeUnit as BigNumberish,
        emissionStart: tokenStream.config?.initialEmissionStart as BigNumberish,
        emissionEnd: tokenStream.config?.initialEmissionEnd as BigNumberish,
        totalTickets: tokenStream.config?.initialTotalTickets as BigNumberish,
        claimLockedUntil:
          (tokenStream.config?.initialClaimLockedUntil as BigNumberish) || '0',
      },
    ];
  },
  'streams/ERC721/presets/ERC721CustodialStakingEmissionStream': (
    tokenStream: Partial<
      TokenStream<ERC721CustodialStakingEmissionStreamConfig>
    >,
    { forwarderAddress }: Context,
  ): ERC721CustodialStakingEmissionStreamConstructorArguments => {
    return [
      {
        ticketToken: tokenStream.config?.ticketToken as BytesLike,
        lockedUntilTimestamp: tokenStream.config
          ?.initialLockedUntilTimestamp as BigNumberish,
        minStakingDuration: tokenStream.config
          ?.initialMinStakingDuration as BigNumberish,
        maxStakingTotalDurations: tokenStream.config
          ?.initialMaxStakingTotalDurations as BigNumberish,
        emissionRate: tokenStream.config?.initialEmissionRate as BigNumberish,
        emissionTimeUnit: tokenStream.config
          ?.initialEmissionTimeUnit as BigNumberish,
        emissionStart: tokenStream.config?.initialEmissionStart as BigNumberish,
        emissionEnd: tokenStream.config?.initialEmissionEnd as BigNumberish,
        totalTickets: tokenStream.config?.initialTotalTickets as BigNumberish,
        claimLockedUntil:
          (tokenStream.config?.initialClaimLockedUntil as BigNumberish) || '0',
      },
    ];
  },
};
