import { useChainInfo } from 'flair-sdk';
import { useNetwork } from 'wagmi';

type Props = {
  chainId?: number;
  address?: string;
};

export const AddressLink = ({ address, chainId }: Props) => {
  const { activeChain } = useNetwork();
  const chainInfo = useChainInfo(chainId || activeChain?.id);

  return address ? (
    <>
      <small className="text-sm">{address}</small>
      <br />
      <a
        href={`${chainInfo?.blockExplorers?.default.url}/address/${address}`}
        target={'_blank'}
        className={'text-sm text-indigo-700'}
        rel="noreferrer"
      >
        View on {chainInfo?.blockExplorers?.default.name}
      </a>
    </>
  ) : (
    <>...</>
  );
};
