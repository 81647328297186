import { ContractVersion } from '@0xflair/contracts-registry';
import { useContractAbi, useContractWriteAndWait } from '@0xflair/react-common';
import { Provider } from '@ethersproject/providers';
import { BigNumberish, BytesLike, Signer } from 'ethers';
import { Tier } from 'flair-sdk';
import { useCallback } from 'react';

type Config = {
  contractVersion?: ContractVersion;
  contractAddress?: string;
  signerOrProvider?: Signer | Provider | null;
  tierId?: BigNumberish;
  tier?: Tier;
};

type ArgsType = [
  tierId: BigNumberish,
  tier: [
    start: BigNumberish,
    end: BigNumberish,
    currency: BytesLike,
    price: BigNumberish,
    maxPerWallet: BigNumberish,
    merkleRoot: BytesLike,
    reserved: BigNumberish,
    maxAllocation: BigNumberish,
  ],
];

function generateArgs(
  tierId?: BigNumberish,
  tier?: Tier,
): ArgsType | undefined {
  return tierId && tier
    ? ([
        tierId,
        [
          tier.start,
          tier.end,
          tier.currency,
          tier.price,
          tier.maxPerWallet,
          tier.merkleRoot,
          tier.reserved,
          tier.maxAllocation,
        ],
      ] as ArgsType)
    : undefined;
}

export const useCollectionTierUpdater = ({
  contractVersion,
  contractAddress,
  signerOrProvider,
  tierId,
  tier,
}: Config) => {
  const contractInterface = useContractAbi({
    contractVersion,
    contractFqn: 'collections/ERC721/extensions/ERC721TieringExtension',
  });

  const result = useContractWriteAndWait<ArgsType>({
    contractInterface,
    functionName:
      'configureTiering(uint256,(uint256,uint256,address,uint256,uint256,bytes32,uint256,uint256))',
    contractAddress,
    signerOrProvider,
    args: generateArgs(tierId, tier),
  });

  const updateTier = useCallback(
    (args?: { tierId: BigNumberish; tier: Tier }) => {
      return result.writeAndWait(
        generateArgs(
          args?.tierId !== undefined ? tierId : tierId || '0',
          args?.tier || tier,
        ),
      );
    },
    [result, tier, tierId],
  );

  return { ...result, updateTier };
};
