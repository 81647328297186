import { BigNumberish } from 'ethers';
import { FeatureWriteByTagConfig, useFeatureWriteByTag } from 'flair-sdk';

type ArgsType = {
  ticketTokenIds?: BigNumberish[];
  shares?: BigNumberish[];
};

export const useStreamSharesUpdater = (
  config: FeatureWriteByTagConfig<ArgsType>,
) => {
  return useFeatureWriteByTag<ArgsType>({
    tag: 'set_shares_for_tokens',
    ...config,
  });
};
