type Props = {
  value?: string;
};

export const AddedBy = ({ value }: Props) => {
  if (value?.startsWith('admin')) {
    return <>Admin</>;
  }

  if (value?.startsWith('submission')) {
    return <>User submission</>;
  }

  return <>-</>;
};
