import { BigNumberish } from 'ethers';
import {
  Badge,
  Button,
  CryptoSymbol,
  CryptoUnits,
  CryptoValue,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useERC721Symbol,
  useERC721TotalSupply,
  useMaxSupply,
} from 'flair-sdk';
import { useState } from 'react';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { useMaxSupplyFreezer } from '../hooks/common/useMaxSupplyFreezer';
import { useMaxSupplyFrozen } from '../hooks/common/useMaxSupplyFrozen';
import { useMaxSupplyUpdater } from '../hooks/common/useMaxSupplyUpdater';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const SupplyAdminSection = ({ env, nftCollection, refresh }: Props) => {
  const chainId = nftCollection.chainId;
  const contractAddress = nftCollection.contractAddress;
  const contractVersion = nftCollection.presetVersion;

  const { data: totalSupply, isLoading: totalSupplyLoading } =
    useERC721TotalSupply({
      contractVersion,
      contractAddress,
    });

  const { data: symbol } = useERC721Symbol({
    contractVersion,
    contractAddress,
  });

  const { data: maxSupply, error: maxSupplyError } = useMaxSupply({
    contractVersion,
    contractAddress,
  });

  const {
    isSupported: maxSupplyFrozenSupported,
    data: maxSupplyFrozen,
    error: maxSupplyFrozenError,
    isLoading: maxSupplyFrozenLoading,
  } = useMaxSupplyFrozen({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: maxSupplyUpdaterSupported,
    data: maxSupplyUpdaterData,
    error: maxSupplyUpdaterError,
    isLoading: maxSupplyUpdaterLoading,
    writeAndWait: setMaxSupply,
  } = useMaxSupplyUpdater({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: maxSupplyFreezerSupported,
    data: freezeMaxSupplyData,
    error: freezeMaxSupplyError,
    isLoading: freezeMaxSupplyLoading,
    writeAndWait: freezeMaxSupply,
  } = useMaxSupplyFreezer({
    env,
    chainId,
    contractAddress,
  });

  const [maxSupplyOpen, setMaxSupplyOpen] = useState(false);

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Supply
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Here you can see and manage collection supply, if not frozen yet.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Current total supply
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {totalSupplyLoading ? (
                  <span className="mr-1">
                    <Spinner />
                  </span>
                ) : null}
                <CryptoValue
                  value={totalSupply?.toString()}
                  unit={CryptoUnits.ETHER}
                  symbol={symbol as CryptoSymbol}
                  showPrice={false}
                />
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Max supply</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-start">
                {maxSupply ? (
                  <CryptoValue
                    value={maxSupply?.toString()}
                    unit={CryptoUnits.ETHER}
                    symbol={symbol as CryptoSymbol}
                    showPrice={false}
                  />
                ) : (
                  '-'
                )}
                {maxSupply && maxSupplyUpdaterSupported && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update max supply'}
                    dialogDescription="This will change how many tokens this collection can mint."
                    dialogOpen={maxSupplyOpen}
                    setDialogOpen={setMaxSupplyOpen}
                    inputLabel="New max supply"
                    inputType={'number'}
                    inputOnSubmit={(value: BigNumberish) =>
                      setMaxSupply({ newMaxSupply: value })
                        .then(() => setMaxSupplyOpen(false))
                        .then(() => refresh())
                    }
                    inputDefaultValue={maxSupply}
                    buttonLabel="Change max supply"
                    buttonDisabled={maxSupplyUpdaterLoading}
                    data={maxSupplyUpdaterData}
                    loading={maxSupplyUpdaterLoading}
                    error={maxSupplyUpdaterError}
                  />
                )}
              </dd>
            </div>
          </dl>
          <div className="py-4 sm:py-5 sm:px-6">
            {maxSupplyError ? <Errors error={maxSupplyError} /> : null}
            {maxSupplyUpdaterError ? (
              <Errors
                title="maxSupplyUpdaterError"
                error={maxSupplyUpdaterError}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Freeze max supply
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            By freezing max supply, you can make sure no one including owners
            cannot mint more than defined max supply.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Current state
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {maxSupplyFrozenLoading && (
                  <>
                    <Spinner /> Loading...
                  </>
                )}
                {!maxSupplyFrozenLoading &&
                  !maxSupplyFrozenError &&
                  maxSupplyFrozenSupported &&
                  !maxSupplyFrozen && (
                    <Badge
                      color="yellow"
                      text={`Max supply can be changed by owner`}
                    />
                  )}
                {!maxSupplyFrozenLoading &&
                  !maxSupplyFrozenError &&
                  maxSupplyFrozenSupported &&
                  maxSupplyFrozen && (
                    <Badge
                      color="green"
                      text={`Max supply is frozen and cannot change`}
                    />
                  )}
                {!maxSupplyFrozenLoading &&
                  !maxSupplyFrozenError &&
                  !maxSupplyFrozenSupported && (
                    <Badge
                      color="gray"
                      text={`Cannot determine if max supply is frozen`}
                    />
                  )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
          <span className="flex flex-grow gap-2 items-center">
            {freezeMaxSupplyLoading && (
              <>
                <Spinner /> Freezing metadata...
              </>
            )}
            {freezeMaxSupplyData.txReceipt || freezeMaxSupplyData.txResponse ? (
              <TransactionLink
                txReceipt={freezeMaxSupplyData.txReceipt}
                txResponse={freezeMaxSupplyData.txResponse}
              />
            ) : null}
            {freezeMaxSupplyError && <Errors error={freezeMaxSupplyError} />}
          </span>
          <Button
            className={PRIMARY_BUTTON}
            text={'Freeze max supply'}
            onClick={() => freezeMaxSupply()}
            disabled={freezeMaxSupplyLoading || !maxSupplyFreezerSupported}
          />
        </div>
      </div>
    </>
  );
};
