import {
  Environment,
  FLAIR_META_TRANSACTIONS_BACKEND,
  useAxiosGet,
  useLoginJwt,
} from 'flair-sdk';
import { useMemo } from 'react';

import { MetaTransactionDocument } from '../auth-clients/types';

type Config = {
  env?: Environment;
  enabled?: boolean;
  clientId?: string;
};

export function useMetaTransactionsByAuthClient({
  env = Environment.PROD,
  enabled = true,
  clientId,
}: Config | undefined = {}) {
  const loginJwt = useLoginJwt();
  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  const url = `${FLAIR_META_TRANSACTIONS_BACKEND[env]}/v1/meta-transactions/by-client/${clientId}`;

  return useAxiosGet<MetaTransactionDocument[]>({
    url,
    enabled: Boolean(enabled && loginJwt),
    headers,
  });
}
