import { Environment, Errors, Spinner } from 'flair-sdk';
import { Link, useParams } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { useAuthClient } from '../../features/auth-clients/hooks/useAuthClient';
import { useMetaTransactionsByAuthClient } from '../../features/meta-transactions/useMetaTransactionsByAuthClient';

type Props = {
  env?: Environment;
};

export const ManageAuthClient = ({ env = Environment.PROD }: Props) => {
  const { clientId } = useParams();

  const { activeChain } = useNetwork();

  const {
    data: authClientData,
    error: authClientError,
    isLoading: authClientLoading,
  } = useAuthClient({
    env,
    clientId,
  });

  const {
    data: metaTransactions,
    error: metaTransactionsError,
    isLoading: metaTransactionsLoading,
  } = useMetaTransactionsByAuthClient({
    env,
    clientId,
  });

  if (authClientLoading) {
    return (
      <div className="p-20 flex gap-2 justify-center items-center">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (authClientError || !authClientData) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Errors error={authClientError || 'Could not fetch auth client :('} />
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl text-gray-900">
              <Link to={'/clients'} className="text-indigo-600">
                Clients
              </Link>{' '}
              〉<span className="font-semibold">{authClientData.name}</span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              View and manage your client credentials.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-2 lg:flex-row md:flex-col sm:flex-col">
            {/* <Button text="Export CSV" className={SECONDARY_BUTTON} />
            <Button text="Apply to Pre-sale" className={PRIMARY_BUTTON} /> */}
          </div>
        </div>

        <div className="mt-8">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-6">
            <section
              aria-labelledby="applicant-information-title"
              className="col-span-1 lg:col-span-2"
            >
              <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                <div className="px-4 py-5 sm:px-6 bg-gray-50">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Credentials
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Use details below when using SDK or APIs.
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Client ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {authClientData._id}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Client Secret
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {authClientData.secret}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Registered on
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {authClientData.registeredAt &&
                          new Date(
                            Number(authClientData.registeredAt) > 0
                              ? Number(authClientData.registeredAt)
                              : authClientData.registeredAt,
                          ).toISOString()}
                      </dd>
                    </div>
                  </dl>
                </div>
                {/* <div>
                  <a
                    href="#"
                    className="block bg-gray-50 text-sm font-medium text-red-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                  >
                    Revoke
                  </a>
                </div> */}
              </div>
            </section>

            <section
              aria-labelledby="applicant-information-title"
              className="col-span-1 lg:col-span-4"
            >
              <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                <div className="px-4 py-5 sm:px-6 bg-gray-50">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Recent meta transactions
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Here is a list of recently submitted meta transactions
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
                        {metaTransactionsLoading ? (
                          <div className="p-20 flex gap-2 justify-center items-center">
                            <Spinner />
                            Loading...
                          </div>
                        ) : metaTransactionsError ? (
                          <div className="p-20 flex gap-2 justify-center items-center">
                            <Errors error={metaTransactionsError} />
                          </div>
                        ) : (
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                >
                                  Chain
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  From ➤ To
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Timestamps
                                </th>
                                <th
                                  scope="col"
                                  className="text-left text-sm font-semibold text-gray-900 relative py-3.5 pl-3 pr-4 sm:pr-6"
                                >
                                  Nonce ➤ TxHash
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {metaTransactions?.map((metaTransaction) => (
                                <tr key={metaTransaction._id}>
                                  <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                    {metaTransaction.chainId}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-900">
                                    {metaTransaction.from.slice(0, 8)}...
                                    <br />➤ {metaTransaction.to.slice(0, 8)}...
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-xs text-gray-500">
                                    <ul>
                                      <li>
                                        Submitted on{' '}
                                        {new Date(
                                          metaTransaction.submittedAt,
                                        ).toISOString()}
                                      </li>
                                      {metaTransaction.attemptedAt && (
                                        <li className="text-blue-800">
                                          Queued on{' '}
                                          {new Date(
                                            metaTransaction.attemptedAt,
                                          ).toISOString()}
                                        </li>
                                      )}
                                      {metaTransaction.processedAt && (
                                        <li className="text-green-800">
                                          Processed on{' '}
                                          {new Date(
                                            metaTransaction.processedAt,
                                          ).toISOString()}
                                        </li>
                                      )}
                                    </ul>
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    <a
                                      href={`${activeChain?.blockExplorers?.default.url}/tx/${metaTransaction.txHash}`}
                                      target={'_blank'}
                                      rel="noreferrer"
                                      className="text-sm text-indigo-500"
                                    >
                                      {metaTransaction.nonce?.slice(0, 6)}...
                                      <br />➤{' '}
                                      {metaTransaction.txHash?.slice(0, 6)}...
                                    </a>
                                    {metaTransaction.processingError && (
                                      <Errors
                                        error={metaTransaction.processingError}
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                              {!metaTransactions?.length && (
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="py-4 text-sm text-gray-400 italic"
                                  >
                                    No meta transactions yet
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <a
                    href="#"
                    className="block bg-gray-50 text-sm font-medium text-indigo-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                  >
                    View all
                  </a>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
