import {
  CryptoUnits,
  CryptoValue,
  TokenBalance,
  TokenStream,
  useStreamTotalClaimed,
  ZERO_ADDRESS,
} from 'flair-sdk';
import { Link } from 'react-router-dom';

import { AddressLink } from '../../../../components/ui/AddressLink';

type Props = {
  className?: string;
  tokenStream?: TokenStream<any>;
  tokenBalance?: TokenBalance;
};

export const TokenStreamBalance = ({
  className,
  tokenStream,
  tokenBalance,
}: Props) => {
  const { data: streamTotalClaimed } = useStreamTotalClaimed({
    chainId: tokenStream?.chainId,
    contractAddress: tokenStream?.contractAddress,
    contractVersion: tokenStream?.presetVersion,
  });

  return (
    <div className={className}>
      <dt className="text-sm font-medium text-gray-500">Token address</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
        {tokenBalance?.tokenAddress === ZERO_ADDRESS ? (
          `Native ${tokenBalance.name}`
        ) : (
          <AddressLink address={tokenBalance?.tokenAddress} />
        )}
      </dd>
      <dt className="text-sm font-medium text-gray-500">Supplied amount</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
        {tokenBalance?.icon && (
          <img
            alt={tokenBalance?.symbol?.toString()}
            src={tokenBalance?.icon}
            className="inline-block h-4 w-4 rounded-xl"
          />
        )}
        <CryptoValue
          value={tokenBalance?.balance?.toString()}
          symbol={tokenBalance?.symbol?.toString()}
          unit={CryptoUnits.WEI}
          showPrice={false}
        />
        {tokenBalance?.tokenAddress !== ZERO_ADDRESS ? (
          <Link
            className="text-indigo-600"
            to={{
              pathname: `/streams/${tokenStream?._id}`,
              search: `?claimToken=${tokenBalance?.tokenAddress}#top-up`,
            }}
          >
            Top-up now
          </Link>
        ) : null}
      </dd>
      <dt className="text-sm font-medium text-gray-500">Claimed amount</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
        <CryptoValue
          value={streamTotalClaimed}
          symbol={tokenBalance?.symbol?.toString()}
          unit={CryptoUnits.WEI}
          showPrice={false}
        />
      </dd>
    </div>
  );
};
