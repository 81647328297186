import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  PRIMARY_BUTTON,
  Spinner,
} from 'flair-sdk';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuthClientCreator } from '../../features/auth-clients/hooks/useAuthClientCreator';

type Props = {
  env?: Environment;
};

type AuthClientCreationState = {
  name: string;
};

const initialState: AuthClientCreationState = {
  name: '',
};

export const RegisterAuthClient = ({ env = Environment.PROD }: Props) => {
  const navigate = useNavigate();

  const [creationState, setCreationState] =
    useState<AuthClientCreationState>(initialState);

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: createAuthClient,
  } = useAuthClientCreator(creationState, {
    env,
    enabled: false,
  });

  const clientInfoSectionView = (
    <FormSection
      title="Info"
      description="General information about your API client credentials."
    >
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Friendly Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            autoComplete="privateName"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="My Awesome App"
            required
            value={creationState.name}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                name: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A internal friendly name only to visible you in the dashboard.
          </p>
        </div>
      </div>
    </FormSection>
  );

  useEffect(() => {
    if (creationData && creationData._id) {
      navigate('/clients/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New client
          </h3>
        </div>
      </div>

      <div className="max-w-7xl h-full mx-auto py-6 sm:px-6 lg:px-8">
        {clientInfoSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Save now"
                disabled={creationLoading}
                onClick={createAuthClient}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/clients/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the client
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
