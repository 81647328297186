import { keccak256, toUtf8Bytes } from 'ethers/lib/utils';
import {
  Button,
  ContractVersion,
  Errors,
  SECONDARY_BUTTON,
  Spinner,
  TransactionLink,
  useOzRoleGranter,
} from 'flair-sdk';
import { useState } from 'react';

type Props = {
  chainId?: number;
  contractAddress?: string;
  contractVersion?: ContractVersion;
  buttonLabel?: string;
  defaultAddress?: string;
  defaultRoleName?: string;
  hideAddress?: boolean;
  hideRole?: boolean;
};

export const RoleGranterForm = ({
  chainId,
  contractAddress,
  contractVersion,
  buttonLabel = 'Grant',
  defaultAddress = '',
  defaultRoleName = 'MINTER_ROLE',
  hideAddress = false,
  hideRole = false,
}: Props) => {
  const [roleName, setRoleName] = useState<string>(defaultRoleName);
  const [address, setAddress] = useState<string>(defaultAddress);

  const {
    data,
    error,
    isLoading,
    writeAndWait: grantRole,
  } = useOzRoleGranter({
    chainId,
    contractVersion,
    contractAddress,
    address,
    role: keccak256(toUtf8Bytes(roleName)),
  });

  return (
    <div className="flex flex-col gap-4">
      {!hideAddress || !hideRole ? (
        <div className="sm:flex sm:items-center gap-2">
          {!hideAddress && (
            <input
              type="text"
              name="walletAddress"
              id="walletAddress"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="0x0000000000000000000000000000000000000000"
              value={address}
              onChange={(e) => setAddress(e.target.value.trim())}
            />
          )}
          {!hideRole && (
            <input
              type="text"
              name="roleName"
              id="roleName"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="MINTER_ROLE"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value.trim())}
            />
          )}
        </div>
      ) : null}

      <div className="flex items-center gap-2">
        <Button
          text={buttonLabel}
          className={SECONDARY_BUTTON}
          onClick={() => grantRole()}
        />
      </div>

      <div className="flex items-center gap-2">
        {error ? <Errors error={error} /> : null}
        {isLoading ? (
          <>
            <Spinner /> Granting...
          </>
        ) : null}
        {data?.txReceipt || data?.txResponse ? (
          <TransactionLink
            txReceipt={data?.txReceipt}
            txResponse={data?.txResponse}
          />
        ) : null}
      </div>
    </div>
  );
};
