import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  PRIMARY_BUTTON,
  Spinner,
  useStickyState,
} from 'flair-sdk';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTokenStreamImporter } from '../../features/token-streams/latest/hooks/useTokenStreamImporter';

export type ERC721TokenStreamImportingState = {
  chainId?: number;
  contractAddress?: string;
};

type Props = {
  env?: Environment;
};

const initialState: ERC721TokenStreamImportingState = {
  chainId: 1,
  contractAddress: '',
};

export const ImportTokenStream = ({ env = Environment.PROD }: Props) => {
  const navigate = useNavigate();

  const [importingState, setImportingState] =
    useStickyState<ERC721TokenStreamImportingState>(
      initialState,
      'erc721-basic-stream-importing',
    );

  const {
    data: importingData,
    error: importingError,
    isLoading: importingLoading,
    sendRequest: create,
  } = useTokenStreamImporter({
    ...importingState,
    env,
    enabled: false,
  });

  const listInfoSectionView = (
    <FormSection title="General" description="Information about your token.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="chainId"
            className="block text-sm font-medium text-gray-700"
          >
            Chain ID
          </label>
          <input
            type="number"
            name="chainId"
            id="chainId"
            autoComplete="chainId"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="137"
            min={1}
            value={importingState.chainId}
            onChange={(event) =>
              setImportingState((x) => ({
                ...x,
                chainId: Number(event.target.value),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Chain ID of the token contract. Use 1 for Ethereum, 4 for Rinkeby,
            137 for Polygon, etc.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="contractAddress"
            className="block text-sm font-medium text-gray-700"
          >
            Contract Address
          </label>
          <input
            type="text"
            name="contractAddress"
            id="contractAddress"
            autoComplete="tokenAddress"
            placeholder="0x....................."
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={importingState.contractAddress}
            onChange={(event) =>
              setImportingState((x) => ({
                ...x,
                contractAddress: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">Stream contract address.</p>
        </div>
      </div>
    </FormSection>
  );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (importingData && importingData._id) {
      navigate('/streams/' + importingData._id);
    }
  }, [navigate, importingData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Import existing stream
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            You can import Flair streams to use and manage it in your dashboard.
          </p>
        </div>
      </div>

      <div className="max-w-7xl h-full mx-auto py-6 sm:px-6 lg:px-8 mb-20">
        {listInfoSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {importingError ? <Errors error={importingError} /> : null}
            {importingLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!importingData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Import"
                disabled={importingLoading}
                onClick={create}
              />
            )}
            {importingData?._id && (
              <Link
                to={`/streams/${importingData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the stream
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
