import { BigNumberish, BytesLike } from 'ethers';
import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  LATEST_VERSION,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  useStickyState,
} from 'flair-sdk';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

import { MultipleMintByAmountTable } from '../../../features/nft-collections/latest/components/MultipleMintByAmountTable';
import { useNftCollectionCreator } from '../../../features/nft-collections/latest/hooks/common/useNftCollectionCreator';
import { useNftCollectionUpdater } from '../../../features/nft-collections/latest/hooks/common/useNftCollectionUpdater';
import { ERC721ManagedPrefixedCollectionConfig } from '../../../features/nft-collections/types/ERC721ManagedPrefixedCollection.types';

export type ERC721StakeholderRegistryCreationState = {
  collectionName?: string;
  collectionSymbol?: string;
  initialHolders: BytesLike[];
  initialAmounts: BigNumberish[];
};

type Props = {
  env?: Environment;
};

const initialState: ERC721StakeholderRegistryCreationState = {
  collectionName: 'My Team Stakeholders',
  collectionSymbol: 'MTS',
  initialHolders: [],
  initialAmounts: [],
};

export const CreateERC721StakeholderRegistry = ({
  env = Environment.PROD,
}: Props) => {
  const navigate = useNavigate();
  const { data: account } = useAccount();

  const [creationState, setCreationState] =
    useStickyState<ERC721StakeholderRegistryCreationState>(
      initialState,
      'erc721-stakeholder-registry-creation',
    );
  const [triedToUpdateAndNavigate, setTriedToUpdateAndNavigate] =
    useState(false);

  const newCollection: Partial<NftCollection> = useMemo(
    () => ({
      presetFqn: 'collections/ERC721/presets/ERC721ManagedPrefixedCollection',
      presetVersion: LATEST_VERSION,
      config: {
        ...creationState,
        collectionBaseUri: `https://api.flair.finance/v1/dynamic-media/stakeholder-registry/nft-metadata/unknown/`,
        collectionMetadataUri: `https://api.flair.finance/v1/dynamic-media/stakeholder-registry/collection-metadata/unknown`,
        royaltyAddress: account?.address,
        royaltyPercent: '0',
        maxSupply:
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      },
    }),
    [creationState, account],
  );

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: create,
  } = useNftCollectionCreator<ERC721ManagedPrefixedCollectionConfig>(
    newCollection,
    {
      env,
      enabled: false,
    },
  );

  const updatedCollection = useMemo(
    () => ({
      _id: creationData?._id,
      config: {
        ...(creationData?.config || {}),
        collectionBaseUri: `https://api.flair.finance/v1/dynamic-media/stakeholder-registry/nft-metadata/${creationData?._id}/`,
        collectionMetadataUri: `https://api.flair.finance/v1/dynamic-media/stakeholder-registry/collection-metadata/${creationData?._id}`,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creationData?._id],
  );

  const {
    data: updateData,
    error: updateError,
    isLoading: updateLoading,
    sendRequest: update,
  } = useNftCollectionUpdater(updatedCollection, { env });

  useEffect(() => {
    if (creationState.initialHolders.length === 0) {
      setCreationState((x) => ({
        ...x,
        initialHolders: [account?.address as BytesLike],
        initialAmounts: [1 as BigNumberish],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const metadataSectionView = (
    <FormSection title="General" description="Information about your token.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            autoComplete="publicTitle"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Flair Team"
            value={creationState.collectionName}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                collectionName: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A human-readable name for your token. Visible on public explorers
            and scanners.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Symbol
          </label>
          <input
            type="text"
            name="symbol"
            id="symbol"
            autoComplete="symbol"
            placeholder="FTEAM"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.collectionSymbol}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                collectionSymbol: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Ticker symbol for your ERC721 token.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const stakeholdersSectionView = (
    <FormSection
      title="Stakeholders"
      description={
        <div className="flex flex-col gap-4">
          <p>
            List of initial stakeholders and how many NFTs you want to mint for
            them.
          </p>
          <p>
            Later you can create different streams (of revenue or profit
            sharing) and give each NFT a certain percentage. Therefore in many
            cases 1 NFT per stakeholder would suffice.
          </p>
          <p>
            You as registry owner (owner of this ERC721 contract) have the power
            to transfer these NFTs until you give up this power. You can do that
            after registry is created.
          </p>
        </div>
      }
    >
      <MultipleMintByAmountTable
        walletTitle="Stakeholder address"
        amountTitle="NFTs to mint"
        defaultAmount={'1'}
        hideAmount={true}
        state={{
          addresses: creationState.initialHolders,
          amounts: creationState.initialAmounts,
        }}
        setState={(x) => {
          let currentState: any;
          if (typeof x === 'function') {
            currentState = x({
              addresses: creationState.initialHolders,
              amounts: creationState.initialAmounts,
            });
          } else {
            currentState = x;
          }

          setCreationState((y) => ({
            ...y,
            initialHolders: currentState.addresses,
            initialAmounts: currentState.amounts,
          }));
        }}
      />
    </FormSection>
  );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id && !triedToUpdateAndNavigate) {
      setTriedToUpdateAndNavigate(true);

      window?.gtag?.('event', 'submitted_creation_form', {
        event_category: 'collections',
        event_label: creationData.presetFqn,
        transaction_id: `creation:${creationData._id}`,
      });

      update().then(() => {
        navigate('/collections/' + creationData._id);
      });
    }
  }, [
    navigate,
    creationData,
    update,
    updateData?._id,
    updateLoading,
    updateData,
    updateError,
    triedToUpdateAndNavigate,
  ]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New stakeholder registry
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            A collection of special NFTs to issue to stakeholders such your
            team, advisors, investors. For the purpose of giving each certain
            privilege.
          </p>
        </div>
      </div>

      <div className="max-w-7xl h-full mx-auto py-6 sm:px-6 lg:px-8 mb-20">
        {metadataSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {stakeholdersSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {updateError ? <Errors error={updateError} /> : null}

            {creationLoading || updateLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Create"
                disabled={creationLoading}
                onClick={create}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/collections/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the collection
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
