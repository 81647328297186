import { TokenStream, useUnorderedForwarderAddress } from 'flair-sdk';
import { useMemo } from 'react';

import { TokenStreamConstructorFactoryRegistry } from '../../registries/constructor-factory-registry';

export const useTokenStreamConstructorArguments = <T extends any[] = any[]>(
  tokenStream?: Partial<TokenStream<any>> | null,
): T => {
  const forwarderAddress = useUnorderedForwarderAddress();

  const constructorArguments = useMemo(() => {
    if (!tokenStream?.presetFqn) {
      return [];
    }

    try {
      const factory =
        TokenStreamConstructorFactoryRegistry[tokenStream.presetFqn];

      if (!factory) {
        console.warn(
          `Factory method not defined for constructing arguments for TokenStream: `,
          tokenStream,
        );
        return [];
      }

      return factory(tokenStream, {
        forwarderAddress,
      });
    } catch (e) {
      console.warn(
        `Error while constructing arguments for TokenStream: ${e}`,
        tokenStream,
        e,
      );
      return [];
    }
  }, [forwarderAddress, tokenStream]);

  return constructorArguments as T;
};
