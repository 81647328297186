import { BigNumberish } from 'ethers';
import {
  AddressList,
  Badge,
  Button,
  classNames,
  CryptoAmountField,
  CryptoUnits,
  Environment,
  Errors,
  SECONDARY_BUTTON,
  Spinner,
  Tier,
  TransactionLink,
  useTierSaleTotalSupply,
  ZERO_BYTES32,
} from 'flair-sdk';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { AddressListSelector } from '../../../../pages/AddressLists/components/AddressListSelector';
import { useAddressListMerkleTreeCreator } from '../../../address-lists/hooks/useAddressListMerkleTreeCreator';
import { useCollectionTierUpdater } from '../hooks/useCollectionTierUpdater';

const getDateString = (date?: Date) => {
  const newDate = date ? new Date(date) : new Date();
  return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1);
};

type Props = {
  env?: Environment;
  chainId?: number;
  contractAddress?: string;
  tierId?: BigNumberish;
  tier?: Tier;
  setTier?: (tier: Tier) => void;
  removeTier?: (tierId: BigNumberish) => void;
};

export const TierCard = ({
  env,
  chainId,
  contractAddress,
  tierId,
  tier,
  setTier,
  removeTier,
}: Props) => {
  const {
    data: collectionTierUpdaterData,
    error: collectionTierUpdaterError,
    isLoading: collectionTierUpdaterLoading,
    updateTier,
  } = useCollectionTierUpdater({
    tierId,
    contractAddress,
    tier,
  });

  const {
    data: tierTotalMints,
    error: tierTotalMintsError,
    isLoading: tierTotalMintsLoading,
  } = useTierSaleTotalSupply({
    chainId,
    contractAddress,
    tierId,
  });

  const [selectedForAllowlist, setSelectedForAllowList] =
    useState<AddressList>();

  const {
    isLoading: createMerkleTreeLoading,
    error: createMerkleTreeError,
    sendRequest: createMerkleTreeRequest,
  } = useAddressListMerkleTreeCreator({
    env,
    listId: selectedForAllowlist?._id,
  });

  const handleApplyAllowlist = useCallback(() => {
    createMerkleTreeRequest().then((response) => {
      if (response?.data) {
        tier && setTier && setTier({ ...tier, merkleRoot: response.data });
      }
    });
  }, [createMerkleTreeRequest, tier, setTier]);

  const handleDisableAllowlist = useCallback(() => {
    tier && setTier && setTier({ ...tier, merkleRoot: ZERO_BYTES32 });
  }, [tier, setTier]);

  return (
    <div className="bg-white shadow sm:rounded-lg w-full">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Tier #{tierId}
        </h3>
        {contractAddress ? (
          <p className="mt-1 max-w-2xl text-sm text-gray-700 flex gap-2">
            Total minted: {tierTotalMints?.toString() || '...'}{' '}
            {tierTotalMintsLoading && <Spinner />}
          </p>
        ) : null}
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <CryptoAmountField
              label={'Price'}
              value={tier?.price || '0'}
              unit={CryptoUnits.WEI}
              onChange={(newValue) => {
                setTier &&
                  tier &&
                  setTier({ ...tier, price: newValue?.toString() });
              }}
            />
            <p className="mt-2 text-sm text-gray-500">
              Price of each NFT in native currency like ETH or MATIC or AVAX
              (ERC20 coming soon).
            </p>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-700">
              Max. allocation
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={tier?.maxAllocation?.toString() || ''}
                onChange={(e) => {
                  setTier &&
                    tier &&
                    setTier({ ...tier, maxAllocation: e.target.value });
                }}
              />
              <p className="mt-2 text-sm text-gray-500">
                Total number NFTs can be minted from this tier. This is just a
                maximum cap so multiple tiers can have same max allocation.
              </p>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-700">Start</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <input
                type="datetime-local"
                name="startDate"
                id="startDate"
                autoComplete="startDate"
                step="60"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={
                  tier?.start
                    ? getDateString(
                        new Date(Number(tier?.start?.toString() || '0') * 1000),
                      )
                    : ''
                }
                onChange={(e) => {
                  setTier &&
                    tier &&
                    setTier({
                      ...tier,
                      start: new Date(e.target.value).getTime() / 1000,
                    });
                }}
              />
              <p className="mt-2 text-sm text-gray-500">
                When users can start minting this tier. You can always change
                this even after deploying.
              </p>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-700">Finish</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <input
                type="datetime-local"
                name="endDate"
                id="endDate"
                autoComplete="endDate"
                step="60"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={
                  tier?.end
                    ? getDateString(
                        new Date(Number(tier?.end?.toString() || '0') * 1000),
                      )
                    : ''
                }
                onChange={(e) => {
                  setTier &&
                    tier &&
                    setTier({
                      ...tier,
                      end: new Date(e.target.value).getTime() / 1000,
                    });
                }}
              />
              <p className="mt-2 text-sm text-gray-500">
                When minting from this tier finishes, no matter how many is
                minted. You can always change this even after deploying.
              </p>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-700">
              Max per-wallet
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={tier?.maxPerWallet?.toString() || ''}
                onChange={(e) => {
                  setTier &&
                    tier &&
                    setTier({ ...tier, maxPerWallet: e.target.value });
                }}
              />
              <p className="mt-2 text-sm text-gray-500">
                This is maximum NFTs any single wallet can mint, if wallet has
                an allocation according to their allowlist, lower number will be
                used.
              </p>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-700">Min. reserved</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={tier?.reserved?.toString() || ''}
                onChange={(e) => {
                  setTier &&
                    tier &&
                    setTier({ ...tier, reserved: e.target.value });
                }}
              />
              <p className="mt-2 text-sm text-gray-500">
                How many NFTs at least must be reserved for this tier. This
                prevents mints from other tiers to use this allocation.
              </p>
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-700">Allowlist</dt>
            <dd className="mt-1 text-sm text-gray-900 flex flex-col gap-2">
              <span className="text-sm">
                Merkle root:{' '}
                {tier?.merkleRoot?.toString() === ZERO_BYTES32 ? (
                  <Badge color={'yellow'} text={'No allowlist set'} />
                ) : (
                  <Badge
                    color="blue"
                    text={tier?.merkleRoot?.toString() || '...'}
                  />
                )}
              </span>
              <div className="flex flex-col gap-2">
                <AddressListSelector
                  env={env}
                  setSelected={setSelectedForAllowList}
                  selected={selectedForAllowlist}
                  defaultValue={(list) =>
                    list.name === tier?.merkleRoot?.toString()
                  }
                  buttonLabel="Generate root"
                  onButtonClick={handleApplyAllowlist}
                >
                  <Button
                    text={'Reset'}
                    className={SECONDARY_BUTTON}
                    onClick={handleDisableAllowlist}
                  />
                  {selectedForAllowlist && (
                    <Link
                      to={`/lists/${selectedForAllowlist._id}`}
                      className={SECONDARY_BUTTON}
                      target={'_blank'}
                    >
                      Edit addresses
                    </Link>
                  )}
                  {createMerkleTreeLoading && <Spinner />}
                </AddressListSelector>
              </div>
              <p className="mb-2 text-sm text-gray-500">
                Optionally you can limit minting from this tier to a specific
                allowlist. Note that each address in the list can have a
                dedicated allocation which is defined when you create the list
                and import addresses.{' '}
                {contractAddress && (
                  <>
                    You must click on "Save changes" below after setting or
                    removing the allowlist, so it applies on chain.
                  </>
                )}
              </p>
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dd className="mt-4 text-sm text-gray-900 flex justify-between items-center">
              <div className="flex items-center gap-2">
                {tier?.isSavedOnChain ? (
                  <Badge color={'gray'} text={'Stored on chain'} />
                ) : (
                  <Badge color={'gray'} text={'Not yet on chain'} />
                )}
                {collectionTierUpdaterLoading && (
                  <div className="flex items-center gap-2">
                    <Spinner />{' '}
                    {collectionTierUpdaterData?.txReceipt ||
                    collectionTierUpdaterData?.txResponse
                      ? 'Saving...'
                      : 'Preparing...'}
                  </div>
                )}
                {collectionTierUpdaterData?.txReceipt ||
                collectionTierUpdaterData?.txResponse ? (
                  <TransactionLink
                    txReceipt={collectionTierUpdaterData?.txReceipt}
                    txResponse={collectionTierUpdaterData?.txResponse}
                  />
                ) : null}
                {collectionTierUpdaterError && (
                  <Errors
                    title="collectionTierUpdaterError"
                    error={collectionTierUpdaterError}
                  />
                )}
                {tierTotalMintsError && (
                  <Errors
                    title="tierTotalMintsError"
                    error={tierTotalMintsError}
                  />
                )}
                {createMerkleTreeError && (
                  <Errors
                    title="createMerkleTreeError"
                    error={createMerkleTreeError}
                  />
                )}
              </div>
              <div className="flex items-center gap-2">
                {!tier?.isSavedOnChain && (
                  <Button
                    text={'Remove'}
                    className={classNames(
                      SECONDARY_BUTTON,
                      'text-sm px-2 py-1',
                    )}
                    onClick={() => removeTier && tierId && removeTier(tierId)}
                  />
                )}
                {contractAddress && (
                  <Button
                    text={'Save changes'}
                    className={classNames(
                      SECONDARY_BUTTON,
                      'text-sm px-2 py-1',
                    )}
                    onClick={() => updateTier()}
                  />
                )}
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
