import { BigNumber, BigNumberish, BytesLike } from 'ethers';
import { Button, classNames, SECONDARY_BUTTON } from 'flair-sdk';
import { ReactNode } from 'react';

type State = {
  addresses: BytesLike[];
  amounts: BigNumberish[];
};

type Props = {
  walletTitle?: ReactNode;
  amountTitle?: ReactNode;
  defaultAddress?: BytesLike;
  defaultAmount?: BigNumberish;
  hideAmount?: boolean;
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
};

export const MultipleMintByAmountTable = ({
  walletTitle = 'Wallet',
  amountTitle = 'Amount',
  defaultAddress = '',
  defaultAmount = '0',
  hideAmount = false,
  state,
  setState,
}: Props) => {
  const totalAmounts = state.amounts?.reduce(
    (acc, cur) => BigNumber.from(acc || 0).add(BigNumber.from(cur || 0)),
    BigNumber.from(0),
  );

  return (
    <table className="min-w-full">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
          >
            {walletTitle}
          </th>
          {hideAmount ? null : (
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              {amountTitle}
            </th>
          )}
          <th
            scope="col"
            className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
          ></th>
        </tr>
      </thead>
      <tbody>
        {state.addresses
          ? state.addresses.map((address, index) => (
              <tr key={index.toString()} className="border-b border-gray-200">
                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0 w-full">
                  <input
                    type="text"
                    autoComplete="walletAddress"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    placeholder="0x..."
                    value={address.toString()}
                    onChange={(event) => {
                      setState((x) => {
                        if (!x.addresses) {
                          x.addresses = [];
                        }

                        x.addresses[index] = event.target.value;

                        return {
                          ...x,
                          addresses: [...x.addresses],
                        };
                      });
                    }}
                  />
                </td>
                {hideAmount ? null : (
                  <td className="py-4 px-3 text-right text-sm sm:table-cell">
                    <input
                      type="number"
                      autoComplete="nftAmount"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-24 shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="0"
                      value={state.amounts?.[index].toString()}
                      onChange={(event) => {
                        setState((x) => {
                          if (!x.amounts) {
                            x.amounts = [];
                          }

                          x.amounts[index] = event.target.value
                            ? Math.ceil(Number(event.target.value))
                            : '';

                          return {
                            ...x,
                            amounts: x.amounts,
                          };
                        });
                      }}
                    />
                  </td>
                )}
                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                  <Button
                    className={classNames(
                      SECONDARY_BUTTON,
                      'inline-block m-0 p-0',
                    )}
                    text={'Remove'}
                    onClick={() => {
                      setState((x) => {
                        x.addresses?.splice(index, 1);
                        x.amounts?.splice(index, 1);

                        return {
                          ...x,
                          addresses: x.addresses,
                          amounts: x.amounts,
                        };
                      });
                    }}
                  />
                </td>
              </tr>
            ))
          : null}
      </tbody>
      <tfoot>
        <tr>
          <th
            scope="row"
            className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
          >
            <Button
              className={classNames(SECONDARY_BUTTON, 'inline-block m-0 p-0')}
              text={'Add item'}
              onClick={() => {
                setState((x) => {
                  if (!x.addresses) {
                    x.addresses = [];
                  }
                  if (!x.amounts) {
                    x.amounts = [];
                  }

                  return {
                    ...x,
                    addresses: [...x.addresses, defaultAddress],
                    amounts: [...x.amounts, defaultAmount],
                  };
                });
              }}
            />
          </th>
          <td
            colSpan={2}
            className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
          >
            Total: {totalAmounts?.toString()}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
