import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { BytesLike, ethers } from 'ethers';
import {
  Button,
  Errors,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  TransactionLink,
} from 'flair-sdk';
import React, { useState } from 'react';

import { ActionButtonDialog } from '../../../../components/ui/ActionButtonDialog';

type Props = {
  buttonClassName?: string;
  buttonDisabled?: boolean;
  dialogOpen?: boolean;
  setDialogOpen: (open: boolean) => void;
  defaultReceiver?: BytesLike;
  defaultPercent?: number;
  inputOnSubmit: (receiver: string, percent: number) => void;
  data?: {
    txResponse?: ethers.providers.TransactionResponse;
    txReceipt?: ethers.providers.TransactionReceipt;
  };
  loading?: boolean;
  error?: Error | null;
};

export const DefaultRoyaltyUpdaterButtonDialog = (props: Props) => {
  const {
    buttonClassName = PRIMARY_BUTTON,
    buttonDisabled,
    dialogOpen,
    setDialogOpen,
    defaultReceiver,
    defaultPercent,
    inputOnSubmit,
    data,
    loading,
    error,
  } = props;

  const [receiver, setReceiver] = useState(defaultReceiver?.toString() || '');
  const [percent, setPercent] = useState<string>(
    defaultPercent?.toString() || '0',
  );

  return (
    <ActionButtonDialog
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      buttonLabel={'Change royalty'}
      buttonDisabled={buttonDisabled}
      buttonClassName={buttonClassName}
    >
      <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon
                className="h-6 w-6 text-blue-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-xl leading-6 font-medium text-gray-900"
              >
                Change default royalty
              </Dialog.Title>
              <div className="mt-2 text-md">
                You can change the default collection's royalty settings. These
                will apply to Rarible, OpenSea, LooksRare and any marketplace
                that supports ERC2981
              </div>
              <div className="mt-4">
                <label
                  htmlFor="mintCount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Percent
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    min={0}
                    max={100}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={percent}
                    onChange={(e) => setPercent(e.target.value)}
                  />
                </div>
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Receiver
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={receiver?.toString() || ''}
                    onChange={(e) => setReceiver(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-2">
                {loading && (
                  <div className="flex flex-row items-center gap-2">
                    <Spinner />{' '}
                    {data?.txReceipt?.transactionHash ? (
                      <>Waiting for confirmation...</>
                    ) : data?.txResponse?.hash ? (
                      <>Waiting for tx...</>
                    ) : (
                      <>Waiting for approval...</>
                    )}
                  </div>
                )}
                {error && <Errors error={error} />}
                <div className="mt-2">
                  {data?.txReceipt || data?.txResponse ? (
                    <TransactionLink
                      txReceipt={data?.txReceipt}
                      txResponse={data?.txResponse}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse gap-2">
          <Button
            text="Save"
            disabled={loading}
            className={PRIMARY_BUTTON}
            onClick={() => inputOnSubmit(receiver, Number(percent))}
          />
          <Button
            text="Cancel"
            className={SECONDARY_BUTTON}
            onClick={() => setDialogOpen(false)}
          />
        </div>
      </div>
    </ActionButtonDialog>
  );
};
