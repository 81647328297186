import { ExclamationIcon } from '@heroicons/react/outline';
import { BigNumberish, BytesLike } from 'ethers';
import {
  Badge,
  Button,
  Environment,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useERC721TransferFrom,
  useNftTokensByCollection,
} from 'flair-sdk';
import { useCallback, useEffect, useState } from 'react';

import { TokenAddressTable } from '../components/TokenAddressTable';
import { useManagementPowerRevoked } from '../hooks/useManagementPowerRevoked';
import { useRevokeManagementPower } from '../hooks/useRevokeManagementPower';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const TokensAdminSection = ({ env, nftCollection, refresh }: Props) => {
  const contractAddress = nftCollection.contractAddress;
  const contractVersion = nftCollection.presetVersion;

  const {
    data: nfts,
    error: nftsError,
    isLoading: nftsLoading,
    sendRequest: nftsRefresh,
  } = useNftTokensByCollection({
    env,
    chainId: nftCollection.chainId,
    collectionAddress: contractAddress,
  });

  const {
    data: transferData,
    error: transferError,
    isLoading: transferLoading,
    writeAndWait: transferFrom,
  } = useERC721TransferFrom({
    contractAddress,
    contractVersion,
  });

  const {
    data: managementPowerRevoked,
    error: managementPowerRevokedError,
    isLoading: managementPowerRevokedLoading,
  } = useManagementPowerRevoked({
    contractAddress,
    contractVersion,
  });

  const {
    data: revokeManagementPowerData,
    error: revokeManagementPowerError,
    isLoading: revokeManagementPowerLoading,
    writeAndWait: revokeManagementPower,
  } = useRevokeManagementPower({
    contractAddress,
    contractVersion,
  });

  const [tableState, setTableState] = useState<{
    tokens: BigNumberish[];
    addresses: BytesLike[];
  }>({
    tokens: [],
    addresses: [],
  });

  useEffect(() => {
    if (nfts && nfts.length > 0 && !tableState.addresses.length) {
      setTableState({
        tokens: nfts.map((t) => t.tokenId),
        addresses: nfts.map((t) => t.ownerAddress),
      });
    }
  }, [nfts, tableState]);

  const handleTransfer = useCallback(
    async (index: number, token: BigNumberish, address: BytesLike) => {
      transferFrom([nfts?.[index].ownerAddress, address, token]);
    },
    [nfts, transferFrom],
  );

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Revoke management power
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            You can revoke the admin ability to transfer NFTs on behalf of
            current owners, to establish trust.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Current state
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-start">
                {managementPowerRevokedLoading && (
                  <>
                    <Spinner /> Loading...
                  </>
                )}
                {!managementPowerRevokedLoading &&
                  !managementPowerRevokedError &&
                  !managementPowerRevoked && (
                    <Badge
                      color="green"
                      text={`You have the power to transfer NFTs on behalf of current owners`}
                    />
                  )}
                {!managementPowerRevokedLoading &&
                  !managementPowerRevokedError &&
                  managementPowerRevoked && (
                    <Badge
                      color="yellow"
                      text={`No power to transfer NFTs on behalf of current owners`}
                    />
                  )}
                {managementPowerRevokedError && (
                  <Errors error={managementPowerRevokedError} />
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
          <div className="flex-1 flex gap-2 items-center">
            {revokeManagementPowerLoading ? (
              <>
                <Spinner /> Working...
              </>
            ) : null}
            {revokeManagementPowerError && (
              <Errors error={revokeManagementPowerError} />
            )}
            {revokeManagementPowerData.txReceipt ||
            revokeManagementPowerData.txResponse ? (
              <>
                <TransactionLink
                  txReceipt={revokeManagementPowerData.txReceipt}
                  txResponse={revokeManagementPowerData.txResponse}
                />
              </>
            ) : null}
          </div>
          <Button
            className={PRIMARY_BUTTON}
            text={'Revoke power'}
            onClick={() => revokeManagementPower().then(refresh)}
            disabled={revokeManagementPowerLoading || managementPowerRevoked}
          />
        </div>
      </div>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Tokens
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            As manager of this collection you can transfer the tokens to any
            address, unless management power is revoked.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <TokenAddressTable
            state={tableState}
            setState={setTableState}
            addressTitle="Owner"
            tokenTitle="Token ID"
            actionTitle="Transfer"
            actionDisabled={transferLoading || managementPowerRevoked}
            handleAction={handleTransfer}
          />
          {!nftsLoading && !nftsError && !nfts?.length ? (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    No tokens found
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      If this is a recently deployed collection, you need to
                      wait few minutes until blockchain data is synced.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
          <div className="flex-1 flex gap-2 items-center">
            {transferLoading ? (
              <>
                <Spinner /> Transferring...
              </>
            ) : nftsLoading ? (
              <>
                <Spinner /> Loading current NFTs...
              </>
            ) : null}
            {transferData.txReceipt || transferData.txResponse ? (
              <>
                <TransactionLink
                  txReceipt={transferData.txReceipt}
                  txResponse={transferData.txResponse}
                />
              </>
            ) : null}
            {nftsError ? <Errors title="nftsError" error={nftsError} /> : null}
            {transferError ? (
              <Errors title="transferError" error={transferError} />
            ) : null}
          </div>
          <Button
            className={PRIMARY_BUTTON}
            text={'Refresh'}
            onClick={() => nftsRefresh()}
            disabled={nftsLoading}
          />
        </div>
      </div>
    </>
  );
};
