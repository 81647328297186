import { ZERO_ADDRESS } from '@0xflair/react-common';
import { utils } from 'ethers';
import {
  Button,
  Environment,
  FormSection,
  SECONDARY_BUTTON,
  Tier,
  ZERO_BYTES32,
} from 'flair-sdk';
import moment from 'moment';
import { SetStateAction, useCallback } from 'react';

import { CollectionMetadataForm } from '../../../../../features/nft-collections/latest/components/CollectionMetadataForm';
import { LicenseSelector } from '../../../../../features/nft-collections/latest/components/LicenseSelector';
import { PlaceholderMetadataForm } from '../../../../../features/nft-collections/latest/components/PlaceholderMetadataForm';
import { TiersTable } from '../../../../../features/nft-collections/latest/components/TiersTable';
import { ERC721TieredSalesCollectionCreationState } from '../types';

type Props = {
  env?: Environment;
  creationState: ERC721TieredSalesCollectionCreationState;
  setCreationState: (
    config: SetStateAction<ERC721TieredSalesCollectionCreationState>,
  ) => void;
};

export const Form = (props: Props) => {
  const { env, creationState, setCreationState } = props;

  const appendTier = useCallback(() => {
    setCreationState({
      ...creationState,
      initialTiers: {
        ...creationState.initialTiers,
        [creationState.initialTiers
          ? Object.keys(creationState.initialTiers).length
          : 0]: {
          currency: ZERO_ADDRESS,
          start: Math.floor(+new Date() / 1000),
          end: moment().add(30, 'day').unix(),
          price: utils.parseEther('10').toString(),
          maxAllocation: 10000,
          maxPerWallet: 10,
          merkleRoot: ZERO_BYTES32,
          reserved: 0,
        },
      },
    });
  }, [setCreationState, creationState]);

  const collectionInfoSectionView = (
    <FormSection
      title="Your Collection"
      description="Add general information about your collection to show in marketplaces"
    >
      <CollectionMetadataForm
        state={creationState}
        setState={setCreationState}
      />
    </FormSection>
  );

  const tokensSectionView = (
    <FormSection
      title="Tokens"
      description="Tell us about the tokens in the collection"
    >
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3 top-2">
          <label
            htmlFor="collection-symbol"
            className="block text-sm font-medium text-gray-700"
          >
            Symbol
          </label>
          <input
            type="text"
            name="collection-symbol"
            id="collection-symbol"
            autoComplete="symbol"
            placeholder="ANGEL"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.collectionSymbol}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                collectionSymbol: event.target.value,
              }))
            }
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="max-supply"
            className="block text-sm font-medium text-gray-700"
          >
            Max Supply
          </label>
          <input
            type="text"
            name="max-supply"
            id="max-supply"
            autoComplete="nftSupply"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="10000"
            value={creationState.maxSupply}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                maxSupply: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Maximum number of tokens that can ever exist in your collection
          </p>
        </div>
      </div>
    </FormSection>
  );

  const placeholderInfoSectionView = (
    <FormSection
      title="Pre-reveal placeholder"
      description="Before revealing metadata for your tokens what image and name to show on marketplaces"
    >
      <PlaceholderMetadataForm
        state={creationState}
        setState={setCreationState}
      />
    </FormSection>
  );

  const tiersSectionView = (
    <FormSection
      title="Tiers"
      description="Define multiple tiers for your collection sales. You can change the tier configuration even after collection is deployed."
    >
      <div className="flex flex-col gap-4">
        {creationState.initialTiers &&
        Object.keys(creationState.initialTiers).length > 0 ? (
          <TiersTable
            env={env}
            tiers={creationState.initialTiers}
            setTiers={(x) => {
              let currentState: Record<number, Tier>;
              if (typeof x === 'function') {
                currentState = x(creationState.initialTiers || {});
              } else {
                currentState = x;
              }

              setCreationState((y) => ({
                ...y,
                initialTiers: currentState,
              }));
            }}
          />
        ) : (
          <span className="text-sm text-gray-500">No tiers configured yet</span>
        )}
        <div>
          <Button
            className={SECONDARY_BUTTON}
            text="Add a tier"
            onClick={appendTier}
          />
        </div>
      </div>
    </FormSection>
  );

  const royaltySectionView = (
    <FormSection
      title="Royalty"
      description="Add royalty fees for secondary sales on OpenSea, Rarible, and LooksRare (via ERC2981)"
    >
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="royalty-address"
            className="block text-sm font-medium text-gray-700"
          >
            Royalty Receiver
          </label>
          <input
            type="text"
            name="royalty-address"
            id="royalty-address"
            autoComplete="walletAddress"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="0x...."
            value={creationState.royaltyAddress}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                royaltyAddress: event.target.value,
              }))
            }
          />
        </div>

        <div className="col-span-6">
          <label
            htmlFor="royalty-percentage"
            className="block text-sm font-medium text-gray-700"
          >
            Royalty %
          </label>
          <input
            type="number"
            min={0}
            max={100}
            name="royalty-percentage"
            id="royalty-percentage"
            autoComplete="percent"
            placeholder="4"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.royaltyPercent}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                royaltyPercent: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            How much royalty to receive on each sale in famous marketplaces?
            Note: royalty config might not be respected by the marketplace if
            they choose to do so
          </p>
        </div>
      </div>
    </FormSection>
  );

  const licenseSectionView = (
    <FormSection
      title="License"
      description="Choose which license you want to put for your NFT assets. You can change this later, and lock it forever whenever you want."
    >
      <LicenseSelector
        selected={creationState.licenseVersion}
        setSelected={(l) => {
          setCreationState((x) => ({
            ...x,
            licenseVersion: l,
          }));
        }}
      />
    </FormSection>
  );

  return (
    <>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {collectionInfoSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {tokensSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {placeholderInfoSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {tiersSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {royaltySectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {licenseSectionView}
      </div>
    </>
  );
};
