import { ImageField } from 'flair-sdk';

export type CollectionMetadataFormState = {
  collectionName?: string;
  collectionSymbol?: string;
  collectionDescription?: string;
  collectionImageFile?: File;
  collectionImagePreview?: string;
  collectionImageUri?: string;
};

type Props = {
  disabled?: boolean;
  state: CollectionMetadataFormState;
  setState: React.Dispatch<React.SetStateAction<CollectionMetadataFormState>>;
};

export const CollectionMetadataForm = ({
  disabled,
  state,
  setState,
}: Props) => {
  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="collection-name"
          className="block text-sm font-medium text-gray-700"
        >
          Collection Name
        </label>
        <input
          type="text"
          name="collection-name"
          id="collection-name"
          autoComplete="publicTitle"
          disabled={disabled}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
          placeholder="Flair Angels"
          value={state.collectionName}
          onChange={(event) =>
            setState((x) => ({
              ...x,
              collectionName: event.target.value,
            }))
          }
        />
      </div>

      <ImageField
        className="col-span-6"
        label={'Collection Image'}
        description={'Main image shown on marketplaces like OpenSea'}
        disabled={disabled}
        imageFile={state.collectionImageFile}
        imagePreview={state.collectionImagePreview}
        setImageFile={(value: File) =>
          setState((x) => ({ ...x, collectionImageFile: value }))
        }
        setImagePreview={(value: string) =>
          setState((x) => ({ ...x, collectionImagePreview: value }))
        }
      />

      <div className="col-span-6">
        <label
          htmlFor="collection-description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <div className="mt-1">
          <textarea
            id="collection-description"
            name="collection-description"
            disabled={disabled}
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md disabled:opacity-50"
            placeholder=""
            value={state.collectionDescription}
            onChange={(event) =>
              setState((x) => ({
                ...x,
                collectionDescription: event.target.value,
              }))
            }
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          Brief description to show on OpenSea when viewing each NFT
        </p>
      </div>
    </div>
  );
};
