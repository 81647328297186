import { BigNumberish } from 'ethers';
import { FeatureWriteByTagConfig, useFeatureWriteByTag } from 'flair-sdk';

type ArgsType = {
  newMaxSupply?: BigNumberish;
};

export const useMaxSupplyUpdater = (
  config: FeatureWriteByTagConfig<ArgsType>,
) => {
  return useFeatureWriteByTag<ArgsType>({
    tag: 'set_max_supply',
    ...config,
  });
};
