import { BytesLike } from '@ethersproject/bytes';
import { FeatureWriteByTagConfig, useFeatureWriteByTag } from 'flair-sdk';

type ArgsType = {
  recipient?: BytesLike;
};

export const useWithdrawRecipientUpdater = (
  config: FeatureWriteByTagConfig<ArgsType>,
) => {
  return useFeatureWriteByTag<ArgsType>({
    tag: 'set_withdraw_recipient',
    ...config,
  });
};
