import { Environment, Errors, Spinner, Tier, useSaleTiers } from 'flair-sdk';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { TierCard } from './TierCard';

type Props = {
  env?: Environment;
  chainId?: number;
  contractAddress?: string;
  tiers?: Record<number, Tier>;
  setTiers?: Dispatch<SetStateAction<Record<number, Tier>>>;
};

export const TiersTable = ({
  env,
  chainId,
  contractAddress,
  tiers,
  setTiers,
}: Props) => {
  const {
    data: collectionTiers,
    error: collectionTiersError,
    isLoading: collectionTiersLoading,
  } = useSaleTiers({
    env,
    chainId,
    contractAddress,
  });

  useEffect(() => {
    if (
      collectionTiers &&
      Object.values(collectionTiers).length > 0 &&
      (!tiers || !Object.values(tiers).length)
    ) {
      setTiers && setTiers(collectionTiers);
    }
  }, [collectionTiers, setTiers, tiers]);

  const entries = tiers ? Object.entries(tiers) : [];

  return (
    <div className="flex flex-col items-center gap-4">
      {contractAddress && collectionTiersLoading ? <Spinner /> : null}
      {collectionTiersError && (
        <Errors title="collectionTiersError" error={collectionTiersError} />
      )}
      {!collectionTiersLoading && entries.length === 0 ? (
        <span className="text-sm text-gray-600">No tiers yet</span>
      ) : (
        entries.map(([tierId, tier], index) => (
          <TierCard
            key={tierId}
            env={env}
            chainId={chainId}
            contractAddress={contractAddress}
            tierId={tierId}
            tier={tier}
            setTier={(tier: Tier) => {
              setTiers && setTiers({ ...tiers, [tierId]: tier });
            }}
            removeTier={(tierId) => {
              const newTiers = { ...tiers };
              delete newTiers[Number(tierId.toString())];
              setTiers && setTiers(newTiers);
            }}
          />
        ))
      )}
    </div>
  );
};
