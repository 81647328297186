import { ACTION_BUTTON, Errors } from '@0xflair/react-ui';
import { LinkIcon } from '@heroicons/react/solid';
import { LoginButton, useLoginContext } from 'flair-sdk';
import React, { ReactNode } from 'react';
import { useConnect } from 'wagmi';

type Props = {
  as?: keyof JSX.IntrinsicElements;
  notLoggedInView?: ReactNode;
  children?: ReactNode;
};

export const RequireLogin = (props: Props) => {
  const { notLoggedInView, children } = props;
  const {
    state: { data: signedInWallet, error },
  } = useLoginContext();

  const { activeConnector } = useConnect();

  if (!signedInWallet) {
    return notLoggedInView ? (
      <>{notLoggedInView}</>
    ) : (
      <div className="flex items-center justify-center h-full">
        {activeConnector && activeConnector.id === 'safe' ? (
          <div className="text-center">
            <LinkIcon
              className="mx-auto h-8 w-8 text-gray-400"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Gnosis support is coming soon
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              This section is not yet supported on Gnosis Safe.
            </p>
          </div>
        ) : (
          <>
            <div className="text-center">
              <LinkIcon
                className="mx-auto h-8 w-8 text-gray-400"
                aria-hidden="true"
              />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                OK, now login...
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Please login using your wallet first, then you can see this
                section.
              </p>
              {error && (
                <div className="mt-1 text-sm">
                  <Errors error={error} />
                </div>
              )}
              <div className="mt-6">
                <LoginButton label="Login" className={ACTION_BUTTON} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return <></>;
};
