import { Environment, useAxiosPut } from 'flair-sdk';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { FLAIR_FUNGIBLE_TOKENS_BACKEND } from '../constants';
import { FungibleToken } from '../types';

type Config = {
  env?: Environment;
  enabled?: boolean;
  chainId?: number;
  contractAddress?: string;
};

export function useFungibleTokenImporter({
  chainId,
  contractAddress,
  enabled = false,
  env = Environment.PROD,
}: Config) {
  const { data: account } = useAccount();

  const params = useMemo(() => {
    return {
      walletAddress: account?.address,
    };
  }, [account?.address]);

  return useAxiosPut<FungibleToken<any>>({
    url: `${
      FLAIR_FUNGIBLE_TOKENS_BACKEND[env]
    }/v1/fungible-tokens?${Object.keys(params)
      .map((key) => key + '=' + (params as any)[key])
      .join('&')}`,
    data: {
      chainId,
      contractAddress,
    },
    enabled: Boolean(account?.address && enabled && chainId && contractAddress),
    // params,
  });
}
