import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GA_MEASUREMENT_ID } from '../util';

export const usePageTracking = (
  trackingId: string | undefined = GA_MEASUREMENT_ID,
) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!window.gtag) return;
    if (!trackingId) {
      return;
    }

    window.gtag('event', 'page_view', {
      page_title: pathname,
      page_location: window.location.href,
      page_path: pathname,
      send_to: trackingId,
    });
  }, [trackingId, pathname, hash]);
};
