import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { classNames } from 'flair-sdk';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const presets = [
  {
    id: 1,
    title: 'Simple NFT Sales',
    description:
      'Simple collection with pre-sale and public sale. For example game characters or a PFP collection.',
    gasUsage: '898228',
    link: '/collections/create/ERC721SimpleSales',
  },
  {
    id: 2,
    title: 'Tiered NFT Sales',
    description:
      'Collection sales with multiple tiers of pricing, multiple allowlists, multiple start and end of campaigns. Higher gas than simple sales.',
    gasUsage: '797470',
    link: '/collections/create/ERC721TieredSales',
  },
  {
    id: 3,
    title: 'Stakeholder Registry',
    description:
      'A collection of special NFTs to issue to stakeholders such as your team, advisors, investors. For the purpose of giving a share in a (income or profit) stream.',
    gasUsage: '865782',
    link: '/collections/create/ERC721StakeholderRegistry',
  },
  {
    id: 4,
    title: 'Digital Asset Collection',
    description:
      'A collection that only owner can mint NFTs with different metadata. Mainly useful for minting NFTs from your backend like your game or e-commerce.',
    gasUsage: '650432',
    link: '/collections/create/ERC721OneOfOne',
  },
];

export const CreateNftCollection = () => {
  const [selectedPreset, setSelectedPreset] = useState();

  return (
    <div className="p-8">
      <RadioGroup value={selectedPreset} onChange={setSelectedPreset}>
        <RadioGroup.Label className="h2 text-4xl font-bold text-gray-900">
          What type of collection?
        </RadioGroup.Label>
        <p className="text-gray-600 pt-4 pb-8">
          Select the type of collection you would like to create.
        </p>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {presets.map((preset) => (
            <RadioGroup.Option
              as={Link}
              to={preset.link}
              key={preset.id}
              value={preset}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                  'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <div className="flex-1 flex">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-lg font-bold text-gray-900"
                      >
                        {preset.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-sm text-gray-500"
                      >
                        {preset.description}
                      </RadioGroup.Description>
                      {/* <RadioGroup.Description
                        as="span"
                        className="mt-6 text-xs font-medium text-gray-900"
                      >
                        <b>Gas on {activeChain?.name}:</b> ~
                        <CryptoPrice
                          value={feeData?.gasPrice?.mul(preset.gasUsage)}
                          symbol={
                            activeChain?.nativeCurrency?.symbol as CryptoSymbol
                          }
                          unit={CryptoUnits.WEI}
                        />{' '}
                        USD {activeChain?.testnet ? '(Testnet)' : ''}
                      </RadioGroup.Description> */}
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? 'invisible' : '',
                      'h-5 w-5 text-indigo-600',
                    )}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none',
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
