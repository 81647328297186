import { Environment, useAxiosPost } from '@0xflair/react-common';
import { useLoginJwt } from '@0xflair/react-wallet';
import { BytesLike } from 'ethers';
import { FLAIR_ADDRESS_LISTS_BACKEND } from 'flair-sdk';
import { useMemo } from 'react';

type Config = {
  env?: Environment;
  enabled?: boolean;
  listId?: string;
  leafMode?: 'address-only' | 'address-with-allowance';
};

export function useAddressListMerkleTreeCreator({
  env = Environment.PROD,
  enabled = false,
  listId,
  leafMode = 'address-with-allowance',
}: Config) {
  const loginJwt = useLoginJwt();
  const url = `${FLAIR_ADDRESS_LISTS_BACKEND[env]}/v1/address-list-merkle-trees?leafMode=${leafMode}`;

  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  const data = useMemo(() => {
    return {
      listId,
    };
  }, [listId]);

  return useAxiosPost<BytesLike>({
    url,
    data,
    enabled: Boolean(enabled && loginJwt && listId),
    headers,
  });
}
