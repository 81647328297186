import { Environment, SmartContract } from 'flair-sdk';
import { useMemo } from 'react';

import { AdminSection } from '../types';

type Config<TContext = any> = {
  env?: Environment;
  smartContract?: SmartContract;
  context?: TContext | null;
  registry: AdminSection[];
};

export function useSupportedAdminSections<TContext = any>({
  smartContract,
  context,
  registry,
}: Config<TContext>) {
  const supportedSections = useMemo(
    () =>
      registry.filter((section) => {
        return section.isSupported(smartContract, context);
      }),
    [registry, smartContract, context],
  );

  return supportedSections;
}
