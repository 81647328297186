import { Environment, useAxiosPatch } from 'flair-sdk';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { FLAIR_FUNGIBLE_TOKENS_BACKEND } from '../constants';
import { FungibleToken } from '../types';

type UpdaterConfig = {
  env?: Environment;
  enabled?: boolean;
};

export function useFungibleTokenUpdater<TConfig extends Record<string, any>>(
  fungibleToken: Partial<FungibleToken<TConfig>>,
  { enabled = true, env = Environment.PROD }: UpdaterConfig,
) {
  const { data: account } = useAccount();

  const params = useMemo(() => {
    return {
      walletAddress: account?.address,
    };
  }, [account?.address]);

  return useAxiosPatch<FungibleToken<TConfig>>({
    url: `${FLAIR_FUNGIBLE_TOKENS_BACKEND[env]}/v1/fungible-tokens/${
      fungibleToken._id
    }?${Object.keys(params)
      .map((key) => key + '=' + (params as any)[key])
      .join('&')}`,
    enabled: Boolean(enabled && account?.address && fungibleToken?._id),
    data: fungibleToken,
    // params,
  });
}
