import {
  CopyButton,
  Environment,
  NftCollection,
  PRIMARY_BUTTON,
} from 'flair-sdk';
import { Link } from 'react-router-dom';

import { useDashboardHost } from '../../../../../hooks/useDashboardHost';

type Props = {
  env?: Environment;
  collection: NftCollection<any>;
  refreshCollection: () => void;
};

export const PublicMintWidget = ({
  env,
  collection,
  refreshCollection,
}: Props) => {
  const dashboardHost = useDashboardHost();

  const iframeWidgetCode = `<iframe src="${dashboardHost}/public/mint/${collection.chainId}/${collection.contractAddress}" frameborder="0" width="800px" height="800px" allowtransparency="true" />`;

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Pre-built minting
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Provide a minting widget for your collectors on your site.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex">
              Pre-built Page
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
              <div>
                You can share this link with your collector to mint when sale is
                active.
              </div>
              <p className="p-3 font-mono w-full break-all rounded-md border border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">{`${dashboardHost}/public/mint/${collection.chainId}/${collection.contractAddress}`}</p>
              <div>
                <Link
                  to={`/public/mint/${collection.chainId}/${collection.contractAddress}`}
                  target={'_blank'}
                  className={PRIMARY_BUTTON}
                >
                  View public minting page
                </Link>
              </div>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex">
              Pre-built Widget
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 justify-center">
              <div>
                You can copy this snippet to include minting widget as an
                "iframe" in your website.
              </div>
              <p className="p-3 font-mono w-full break-all rounded-md border border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                {iframeWidgetCode}
              </p>
              <div>
                <CopyButton content={iframeWidgetCode} />
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
