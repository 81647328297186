import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { classNames, Environment } from 'flair-sdk';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const presets = [
  {
    id: 1,
    title: 'Basic Token',
    description:
      'A standard ERC20 with ability to mint, burn and pause. Uses roles for access control.',
    gasUsage: '219097',
    link: '/tokens/create/ERC20BasicToken',
  },
  {
    id: 2,
    title: 'Lockable Token',
    description:
      'A standard ERC20 with ability to lock token transfers only allowed for certain addresses. Uses roles for access control.',
    gasUsage: '219263',
    link: '/tokens/create/ERC20LockableToken',
  },
];

type Props = {
  env?: Environment;
};

export const CreateFungibleToken = ({ env = Environment.PROD }: Props) => {
  const [selectedPreset, setSelectedPreset] = useState();

  return (
    <div className="p-8">
      <RadioGroup value={selectedPreset} onChange={setSelectedPreset}>
        <RadioGroup.Label className="h2 text-4xl font-bold text-gray-900">
          What type of token?
        </RadioGroup.Label>
        <p className="text-gray-600 pt-4 pb-8">
          Select the type of token you would like to deploy. You will be the
          owner of the contract.
        </p>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {presets.map((preset) => (
            <RadioGroup.Option
              as={Link}
              to={preset.link}
              key={preset.id}
              value={preset}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                  'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <div className="flex-1 flex">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-lg font-bold text-gray-900"
                      >
                        {preset.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-sm text-gray-500"
                      >
                        {preset.description}
                      </RadioGroup.Description>
                      {/* <RadioGroup.Description
                        as="span"
                        className="mt-6 text-xs font-medium text-gray-900"
                      >
                        <b>Gas on {activeChain?.name}:</b> ~
                        <CryptoPrice
                          value={feeData?.gasPrice?.mul(preset.gasUsage)}
                          symbol={
                            activeChain?.nativeCurrency?.symbol as CryptoSymbol
                          }
                          unit={CryptoUnits.WEI}
                        />{' '}
                        USD {activeChain?.testnet ? '(Testnet)' : ''}
                      </RadioGroup.Description> */}
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? 'invisible' : '',
                      'h-5 w-5 text-indigo-600',
                    )}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none',
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
