import { keccak256, toUtf8Bytes } from 'ethers/lib/utils';
import {
  Badge,
  Button,
  ContractVersion,
  Errors,
  SECONDARY_BUTTON,
  Spinner,
  useOzHasRole,
} from 'flair-sdk';
import { useState } from 'react';

type Props = {
  chainId?: number;
  contractAddress?: string;
  contractVersion?: ContractVersion;
  defaultRoleName?: string;
  hideRole?: boolean;
};

export const RoleCheckerForm = ({
  chainId,
  contractAddress,
  contractVersion,
  defaultRoleName = 'MINTER_ROLE',
  hideRole = false,
}: Props) => {
  const [roleName, setRoleName] = useState<string>(defaultRoleName);
  const [address, setAddress] = useState<string>('');

  const {
    data,
    error,
    isLoading,
    refetch: checkRole,
  } = useOzHasRole({
    chainId,
    contractVersion,
    contractAddress,
    address,
    role: keccak256(toUtf8Bytes(roleName)),
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="sm:flex sm:items-center gap-2">
        <input
          type="text"
          name="walletAddress"
          id="walletAddress"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          placeholder="0x0000000000000000000000000000000000000000"
          value={address}
          onChange={(e) => setAddress(e.target.value.trim())}
        />
        {!hideRole && (
          <input
            type="text"
            name="roleName"
            id="roleName"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="MINTER_ROLE"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value.trim())}
          />
        )}
      </div>

      <div className="flex items-center gap-2">
        <Button
          text="Check"
          className={SECONDARY_BUTTON}
          onClick={() => checkRole()}
        />
      </div>

      <div className="flex items-center gap-2">
        {error ? <Errors error={error} /> : null}
        {isLoading ? (
          <>
            <Spinner /> Checking...
          </>
        ) : null}
      </div>

      <div className="flex items-center gap-2">
        {data !== undefined ? (
          <Badge
            text={
              data ? `Has role ${roleName}` : `Does not have role ${roleName}`
            }
            color={data ? 'green' : 'yellow'}
          />
        ) : null}
      </div>
    </div>
  );
};
