import { Environment, useAxiosPost } from '@0xflair/react-common';
import {
  ContractFqn,
  ContractVersion,
  FLAIR_ADDRESS_LISTS_BACKEND,
  useLoginJwt,
} from 'flair-sdk';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { Price } from '../types';

type Config = {
  env?: Environment;
  enabled?: boolean;
  chainId?: number;
  contractFqn?: ContractFqn;
  contractVersion?: ContractVersion;
};

export function usePricingContractDeploymentFee({
  env = Environment.PROD,
  enabled = true,
  chainId,
  contractFqn,
  contractVersion,
}: Config) {
  const loginJwt = useLoginJwt();
  const { data: account } = useAccount();
  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);
  const params = useMemo(() => {
    return {
      walletAddress: account?.address,
    };
  }, [account?.address]);
  const data = useMemo(() => {
    return {
      chainId,
      contractFqn,
      contractVersion,
    };
  }, [chainId, contractFqn, contractVersion]);

  return useAxiosPost<Price>({
    url: `${FLAIR_ADDRESS_LISTS_BACKEND[env]}/v1/pricing/contract-deployment`,
    data,
    enabled: Boolean(enabled && chainId),
    headers,
    params,
  });
}
