import { ContractFqn } from '@0xflair/contracts-registry';

import { ERC20BasicTokenConfig } from '../../../pages/FungibleTokens/use-cases/CreateERC20BasicToken';
import { ERC20LockableTokenConfig } from '../../../pages/FungibleTokens/use-cases/CreateERC20LockableToken';
import { FungibleToken } from '../types/index';

export const FungibleTokenConstructorFactoryRegistry: Partial<
  Record<ContractFqn, (fungibleToken: FungibleToken) => any[]>
> = {
  'tokens/presets/ERC20BasicToken': (
    fungibleToken: FungibleToken<ERC20BasicTokenConfig>,
  ) => {
    return [
      {
        name: fungibleToken?.config?.name,
        symbol: fungibleToken?.config?.symbol,
      },
    ];
  },
  'tokens/presets/ERC20LockableToken': (
    fungibleToken: FungibleToken<ERC20LockableTokenConfig>,
  ) => {
    return [
      {
        name: fungibleToken?.config?.name,
        symbol: fungibleToken?.config?.symbol,
      },
    ];
  },
};
