import { Dialog, Transition } from '@headlessui/react';
import { classNames, SECONDARY_BUTTON } from 'flair-sdk';
import React, { Fragment, useRef } from 'react';

type Props = {
  dialogOpen?: boolean;
  setDialogOpen: (open: boolean) => void;
  buttonLabel: React.ReactNode;
  buttonDisabled?: boolean;
  children?: React.ReactNode;
  buttonClassName?: string;
};

export const ActionButtonDialog = (props: Props) => {
  const {
    dialogOpen,
    setDialogOpen,
    children,
    buttonLabel,
    buttonDisabled,
    buttonClassName,
  } = props;
  const activeButtonRef = useRef(null);

  return (
    <>
      {
        <button
          disabled={buttonDisabled}
          className={
            buttonClassName || classNames(SECONDARY_BUTTON, 'text-sm px-2 py-1')
          }
          onClick={() => setDialogOpen(true)}
        >
          {buttonLabel}
        </button>
      }
      <Transition.Root show={dialogOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-20 inset-0 overflow-y-auto"
          initialFocus={activeButtonRef}
          open={dialogOpen}
          onClose={setDialogOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
