import {
  AddressListItem,
  Environment,
  FLAIR_ADDRESS_LISTS_BACKEND,
  useAxiosGet,
  useLoginJwt,
} from 'flair-sdk';
import { useMemo } from 'react';

type Config = {
  env?: Environment;
  enabled?: boolean;
  listId?: string;
  skip?: number;
  limit?: number;
};

export function useAddressListItems({
  env = Environment.PROD,
  enabled = true,
  listId,
  skip,
  limit,
}: Config) {
  const loginJwt = useLoginJwt();
  const url = `${FLAIR_ADDRESS_LISTS_BACKEND[env]}/v1/address-lists/${listId}/items`;

  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  const params = useMemo(() => {
    return {
      skip,
      limit,
    };
  }, [skip, limit]);

  return useAxiosGet<{ total: number; items: AddressListItem[] }>({
    url,
    params,
    enabled: Boolean(enabled && headers && loginJwt && listId),
    headers,
  });
}
