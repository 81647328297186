import { BigNumberish, BytesLike } from 'ethers';
import { Button, classNames, SECONDARY_BUTTON } from 'flair-sdk';
import { ReactNode } from 'react';

type State = {
  tokens: BigNumberish[];
  addresses: BytesLike[];
};

type Props = {
  tokenTitle?: ReactNode;
  addressTitle?: ReactNode;
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  actionTitle?: ReactNode;
  actionDisabled?: boolean;
  handleAction?: (
    index: number,
    token: BigNumberish,
    address: BytesLike,
  ) => Promise<void> | void;
};

export const TokenAddressTable = ({
  tokenTitle = 'Token',
  addressTitle = 'Wallet',
  actionTitle,
  actionDisabled,
  handleAction,
  state,
  setState,
}: Props) => {
  return (
    <table className="min-w-full">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 whitespace-nowrap w-0"
          >
            {tokenTitle}
          </th>
          <th
            scope="col"
            className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
          >
            {addressTitle}
          </th>
          <th
            scope="col"
            className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
          ></th>
        </tr>
      </thead>
      <tbody>
        {state.addresses
          ? state.addresses.map((address, index) => (
              <tr key={index.toString()} className="border-b border-gray-200">
                <td className="py-4 px-3 text-right text-sm sm:pl-6 md:pl-0 sm:table-cell">
                  #{state.tokens?.[index].toString()}
                </td>
                <td className="py-4 pl-4 pr-3 text-sm">
                  <input
                    type="text"
                    autoComplete="walletAddress"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    placeholder="0x..."
                    value={address?.toString()}
                    onChange={(event) => {
                      setState((x) => {
                        if (!x.addresses) {
                          x.addresses = [];
                        }

                        x.addresses[index] = event.target.value;

                        return {
                          ...x,
                          addresses: [...x.addresses],
                        };
                      });
                    }}
                  />
                </td>
                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                  <Button
                    className={classNames(
                      SECONDARY_BUTTON,
                      'inline-block m-0 p-0',
                    )}
                    text={actionTitle}
                    disabled={actionDisabled}
                    onClick={() => {
                      handleAction?.(index, state.tokens?.[index], address);
                    }}
                  />
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );
};
