import {
  ChainBadge,
  classNames,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
} from 'flair-sdk';
import { RiCopperCoinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { useFungibleTokens } from '../../features/fungible-tokens/hooks/useFungibleTokens';

type Props = {
  env?: Environment;
};

export const BrowseFungibleTokens = ({ env = Environment.PROD }: Props) => {
  const { data, error, isLoading } = useFungibleTokens({ env });

  if (isLoading) {
    return (
      <div className="p-20 flex gap-2 justify-center items-center">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (error || data === undefined) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Errors error={error || 'Unknown error :('} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="p-20 text-center">
        <RiCopperCoinLine className="mx-auto h-16 w-16 text-gray-400" />
        <h3 className="mt-6 mb-4 text-2xl font-extrabold text-gray-900">
          Create your first token
        </h3>
        <p className="mt-1 text-lg text-gray-500">
          Tokens (a.k.a ERC20) can be anything from reputation points,
          <br /> a gaming currency, or representing a real-world asset.
        </p>
        <div className="mt-6 flex gap-2 justify-center">
          <Link
            to={'/tokens/import'}
            className={
              'inline-flex items-center whitespace-nowrap px-6 py-3 border border-gray-300 rounded-md shadow-sm text-md font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed text-md'
            }
          >
            Import an ERC20
          </Link>
          <Link
            to={'/tokens/create'}
            className={
              'inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            }
          >
            Create a token
          </Link>
        </div>
        <div className="mt-6 flex justify-center">
          {/* <a
            href={`https://docs.flair.finance/sdk/fungible-tokens`}
            className={'text-indigo-500 flex gap-2 items-center'}
            target="_blank"
            rel="noreferrer"
          >
            <BeakerIcon className="h-5 w-5" aria-hidden="true" />
            Explore SDK
          </a> */}
        </div>
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Tokens</h1>
            <p className="mt-2 text-sm text-gray-700">
              Create tokens for any purpose.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2 items-center">
            {/* <a
              href={`https://docs.flair.finance/sdk/fungible-tokens`}
              className={classNames(SECONDARY_BUTTON, 'whitespace-nowrap')}
              target="_blank"
              rel="noreferrer"
            >
              <BeakerIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Explore SDK
            </a> */}
            <Link
              to="/tokens/import"
              className={classNames(SECONDARY_BUTTON, 'whitespace-nowrap')}
            >
              Import an ERC20
            </Link>
            <Link
              to="/tokens/create"
              className={classNames(PRIMARY_BUTTON, 'whitespace-nowrap')}
            >
              Create a token
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Symbol
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Decimals
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Chain
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Manage</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((item) => (
                      <tr key={item._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <Link
                            to={`/tokens/${item._id}`}
                            className="text-indigo-700"
                          >
                            {item.smartContract?.info?.name ||
                              item.config?.name}
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {item.smartContract?.info?.symbol ||
                            item.config?.symbol}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.smartContract?.info?.decimals ||
                            item.config?.decimals}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          <ChainBadge chainId={item.chainId} />
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={`/tokens/${item._id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Manage
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
