import { FeatureType, SmartContract, TokenStream } from 'flair-sdk';
import { BsBatteryCharging } from 'react-icons/bs';
import { HiOutlinePlay, HiOutlineTicket, HiOutlineUsers } from 'react-icons/hi';
import { IoHourglassOutline } from 'react-icons/io5';
import { MdOutlineAccessTime } from 'react-icons/md';
import { RiHandCoinLine } from 'react-icons/ri';
import { TbCashBanknote } from 'react-icons/tb';

import { RolesAdminSection } from '../../common/sections/RolesAdminSection';
import { WithdrawAdminSection } from '../../common/sections/WithdrawAdminSection';
import { AdminSection } from '../../common/types';
import { ClaimingAdminSection } from '../latest/sections/ClaimingAdminSection';
import { DeployAdminSection } from '../latest/sections/DeployAdminSection';
import { EmissionAdminSection } from '../latest/sections/EmissionAdminSection';
import { ShareholdersAdminSection } from '../latest/sections/ShareholdersAdminSection';
import { StakingAdminSection } from '../latest/sections/StakingAdminSection';
import { TokensAdminSection } from '../latest/sections/TokensAdminSection';
import { TopUpAdminSection } from '../latest/sections/TopUpAdminSection';

type TokenStreamAdminSectionContext = {
  tokenStream?: TokenStream;
};

export const AdminSectionsRegistry: AdminSection[] = [
  // Common
  {
    name: 'Deploy',
    href: '#deploy',
    icon: HiOutlinePlay,
    widget: DeployAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.presetFqn);
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(true);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.contractAddress || ctx?.tokenStream?.contractAddress,
      );
    },
  },

  // Latest
  {
    name: 'Shares',
    href: '#shares',
    icon: HiOutlineUsers,
    widget: ShareholdersAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          [
            'has_stream_share_split_extension',
            'total_shares',
            'shares_by_token',
            'set_shares_for_tokens',
            'get_shares_by_tokens',
          ].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Tokens',
    href: '#tokens',
    icon: HiOutlineTicket,
    widget: TokensAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['flair_stream_multi_token_base'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Top-up',
    href: '#top-up',
    icon: BsBatteryCharging,
    widget: TopUpAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Emission',
    href: '#emission',
    icon: IoHourglassOutline,
    widget: EmissionAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          ['flair_stream_emission_release_extension'].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Staking',
    href: '#staking',
    icon: MdOutlineAccessTime,
    widget: StakingAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          f.tag.startsWith('flair_stream_staking_extension'),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  {
    name: 'Claiming',
    href: '#claiming',
    icon: RiHandCoinLine,
    widget: ClaimingAdminSection,
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) => f.tag.startsWith('flair_stream')),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },

  // Common
  {
    name: 'Roles',
    href: '#roles',
    icon: HiOutlineUsers,
    widget: ({ tokenStream }) => (
      <RolesAdminSection
        contractAddress={tokenStream.contractAddress}
        contractVersion={tokenStream.presetVersion}
        defaultRoleName="MINTER_ROLE"
      />
    ),
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find((f) =>
          [
            'oz_access_control',
            'has_role',
            'grant_role',
            'revoke_role',
            'renounce_role',
          ].includes(f.tag),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(ctx?.tokenStream?.contractAddress);
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return false;
    },
  },
  {
    name: 'Emergency',
    href: '#emergency',
    icon: TbCashBanknote,
    widget: ({ env, tokenStream, refresh }) => (
      <WithdrawAdminSection
        env={env}
        key={tokenStream.contractAddress}
        chainId={tokenStream.chainId}
        contractAddress={tokenStream.contractAddress}
        refresh={refresh}
      />
    ),
    isSupported: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.features?.find(
          (f) =>
            (f.type === FeatureType.Interface &&
              f.tag === 'withdraw_extension') ||
            (f.type === FeatureType.Function && f.tag === 'withdraw'),
        ),
      );
    },
    isEnabled: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(
        smartContract?.contractAddress || ctx?.tokenStream?.contractAddress,
      );
    },
    isComplete: (
      smartContract?: SmartContract,
      ctx?: TokenStreamAdminSectionContext,
    ) => {
      return Boolean(false);
    },
  },
  // {
  //   name: 'Verification',
  //   href: '#verification',
  //   icon: MdOutlineFactCheck,
  //   widget: ({ env, tokenStream, constructorArguments }) => {
  //     return (
  //       <VerificationAdminSection
  //         env={env}
  //         chainId={tokenStream.chainId}
  //         contractAddress={tokenStream.contractAddress}
  //         contractFqn={tokenStream.presetFqn}
  //         contractVersion={tokenStream.presetVersion}
  //         constructorArguments={constructorArguments}
  //       />
  //     );
  //   },
  //   isSupported: (
  //     smartContract?: SmartContract,
  //     ctx?: TokenStreamAdminSectionContext,
  //   ) => {
  //     return Boolean(ctx?.tokenStream?.presetFqn);
  //   },
  //   isEnabled: (
  //     smartContract?: SmartContract,
  //     ctx?: TokenStreamAdminSectionContext,
  //   ) => {
  //     return Boolean(
  //       ctx?.tokenStream?.contractAddress && ctx?.tokenStream?.presetFqn,
  //     );
  //   },
  //   isComplete: (
  //     smartContract?: SmartContract,
  //     ctx?: TokenStreamAdminSectionContext,
  //   ) => {
  //     // TODO ctx?.tokenStream?.contractVerification?.state === 'succeeded',
  //     return Boolean(false);
  //   },
  // },
];
