import { Environment, useAxiosGet, useLoginJwt } from 'flair-sdk';
import { useMemo } from 'react';

import { FLAIR_AUTH_CLIENTS_BACKEND } from '../constants';
import { AuthClient } from '../types';

type Config = {
  env?: Environment;
  enabled?: boolean;
};

export function useAuthClients({
  env = Environment.PROD,
  enabled = true,
}: Config | undefined = {}) {
  const loginJwt = useLoginJwt();
  const url = `${FLAIR_AUTH_CLIENTS_BACKEND[env]}/v1/auth-clients`;

  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  return useAxiosGet<AuthClient[]>({
    url,
    enabled: Boolean(enabled && loginJwt),
    headers,
  });
}
