import { Environment, NftCollection } from 'flair-sdk';
import { AiFillGithub } from 'react-icons/ai';
import { HiOutlineExternalLink } from 'react-icons/hi';

type Props = {
  env?: Environment;
  collection: NftCollection<any>;
  refreshCollection: () => void;
};

export const MintExamplesDeploy = ({ env, collection }: Props) => {
  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Custom minting page
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Deploy to Vercel for your own NFT minting page with custom URL.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex gap-2 items-center">
              {/* Vercel Deploy */}
              <a
                href={`https://vercel.com/new/clone?repository-url=https%3A%2F%2Fgithub.com%2F0xflair%2Fexamples%2Ftree%2Fmain%2Freact%2Fcustom-nft-minting-sales&env=REACT_APP_COLLECTION_CHAIN_ID,REACT_APP_COLLECTION_CONTRACT_ADDRESS&envDescription=You%20can%20find%20your%20collection's%20%22Contract%20Address%22%20by%20clicking%20on%20%22Learn%20more%22.%20Also%20set%20the%20%22Chain%20ID%22%20based%20on%20the%20contract%20chain.%20E.x%20use%201%20for%20Eth%20mainnet%2C%204%20for%20Rinkeby%20testnet%2C%20137%20for%20Polygon%20mainnet%2C%20etc.&envLink=https%3A%2F%2Fv1.flair.dev%2Fcollections%2F${collection._id}%23deploy&repository-name=my-awesome-project&demo-title=Deployed%20Collection%20(Demo)&demo-description=This%20is%20a%20demo%20of%20a%20deployed%20collection%20on%20Vercel&demo-url=https%3A%2F%2Fflair-nft-collection.vercel.app%2F&demo-image=https%3A%2F%2Fimgur.com%2FKesd0bz.png`}
                target="_blank"
                rel="noreferrer"
              >
                <img src="https://vercel.com/button" alt="Deploy with Vercel" />
              </a>
              {/* Heroku Deploy */}
              {/* <a
                href="https://heroku.com/deploy"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://www.herokucdn.com/deploy/button.svg"
                  alt="Deploy"
                />
              </a> */}
              {/* Check source-code */}
              <a
                href="https://github.com/0xflair/examples/tree/main/react/custom-nft-minting-sales"
                className="inline-flex items-center whitespace-nowrap px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillGithub
                  className="-ml-1 mr-1 h-4 w-4"
                  aria-hidden="true"
                />
                View source
              </a>
              <a
                className="text-indigo-500 flex gap-1 items-center"
                href="https://flair-nft-collection.vercel.app/"
                target="_blank"
                rel="noreferrer"
              >
                Sample demo
                <HiOutlineExternalLink className="h-4 w-4" />
              </a>
            </dt>
          </div>
        </dl>
      </div>
    </div>
  );
};
