import '@ethersproject/abstract-provider';

import { Signer } from 'ethers';
import {
  ContractFqn,
  ContractVersion,
  Environment,
  loadContract,
} from 'flair-sdk';
import { useMemo } from 'react';
import { useNetwork } from 'wagmi';

import { useContractDeployer } from './useContractDeployer';
import { usePricingContractDeploymentFee } from './usePricingContractDeploymentFee';
import { useProxyDeployer } from './useProxyDeployer';

type Props = {
  env?: Environment;
  contractFqn?: ContractFqn;
  contractVersion?: ContractVersion;
  signer?: Signer;
};

export const useFlairContractDeployer = <Args extends any[] = any[]>({
  env,
  contractFqn,
  contractVersion,
  signer,
}: Props = {}) => {
  const { activeChain } = useNetwork();

  const contract = useMemo(
    () => contractFqn && loadContract(contractFqn, contractVersion),
    [contractFqn, contractVersion],
  );

  const contractDeployer = useContractDeployer<Args>({
    contractInterface: contract?.artifact.abi,
    contractByteCode: contract?.artifact.bytecode,
    signer,
  });

  const { data: deploymentFee } = usePricingContractDeploymentFee({
    env,
    chainId: activeChain?.id,
    contractFqn,
    contractVersion,
    enabled: Boolean(activeChain?.id && contractFqn && contractVersion),
  });

  const proxyDeployer = useProxyDeployer<Args>({
    contract,
    signer,
    value: deploymentFee?.amountWei,
  });

  const result = (
    proxyDeployer.isSupported ? proxyDeployer : contractDeployer
  ) as (
    | ReturnType<typeof useContractDeployer>
    | ReturnType<typeof useProxyDeployer>
  ) & { deploymentFee?: string };

  result.deploymentFee = deploymentFee?.amountWei;

  return result;
};
