import { ArrowRightIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import {
  classNames,
  Environment,
  Errors,
  Spinner,
  useChainInfo,
  useSmartContract,
  useTokenStream,
} from 'flair-sdk';
import { useEffect, useState } from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { useLocation, useParams } from 'react-router';
import useErrorBoundary from 'use-error-boundary';

import { RequireChain } from '../../components/ui/RequireChain';
import { useSupportedAdminSections } from '../../features/common/hooks/useSupportedAdminSections';
import { AdminSection } from '../../features/common/types';
import { useTokenStreamConstructorArguments } from '../../features/token-streams/latest/hooks/useTokenStreamConstructorArguments';
import { AdminSectionsRegistry } from '../../features/token-streams/registries/admin-sections-registry';

type Props = {
  env?: Environment;
};

export const ManageTokenStream = ({ env = Environment.PROD }: Props) => {
  const location = useLocation();
  const { tokenStreamId } = useParams();

  const [currentSection, setCurrentSection] = useState<AdminSection>();
  const { ErrorBoundary, didCatch, error: caughtError } = useErrorBoundary();

  const {
    data: tokenStream,
    error: tokenStreamError,
    isLoading: tokenStreamLoading,
    sendRequest: refresh,
  } = useTokenStream({
    env,
    streamId: tokenStreamId,
  });

  const chainInfo = useChainInfo(tokenStream?.chainId);

  const {
    data: smartContract,
    error: smartContractError,
    isLoading: smartContractLoading,
  } = useSmartContract({
    env,
    chainId: tokenStream?.chainId,
    contractAddress: tokenStream?.contractAddress,
  });

  const sections = useSupportedAdminSections({
    env,
    smartContract,
    context: { tokenStream },
    registry: AdminSectionsRegistry,
  });

  const constructorArguments = useTokenStreamConstructorArguments(tokenStream);

  useEffect(() => {
    if (!location.hash || !sections || !sections.length) return;
    const newSection = sections?.find((n) => n.href === location.hash);
    if (newSection) {
      setCurrentSection(newSection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, sections]);
  useEffect(() => {
    if (currentSection) return;
    if (sections[0]) {
      setCurrentSection(sections[0]);
    }
  }, [sections, currentSection]);

  if (!tokenStream && (tokenStreamLoading || smartContractLoading)) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (!tokenStream || tokenStreamError || smartContractError) {
    return (
      <div className="p-20 flex justify-center items-center">
        <Errors
          error={tokenStreamError || smartContractError || 'Unknown error :('}
        />
      </div>
    );
  }

  const managementView = (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {smartContract?.info?.name ||
              tokenStream?.name ||
              tokenStream?.publicTitle ||
              'Token Stream'}
          </h3>
          {chainInfo && tokenStream?.contractAddress ? (
            <a
              href={`${chainInfo.blockExplorers?.default.url}/address/${tokenStream?.contractAddress}`}
              target={'_blank'}
              rel="noreferrer"
              className="inline-flex gap-1 items-center px-3.5 py-1.5 rounded-full text-xs font-medium bg-gray-200 text-gray-500"
            >
              {tokenStream?.contractAddress}
              <HiOutlineExternalLink className="h-4 w-4" />
            </a>
          ) : null}
        </div>
      </div>

      <div className="h-full px-4 sm:px-6">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1">
              {sections.map((item) => (
                <a
                  href={
                    item.isEnabled(smartContract, { tokenStream })
                      ? item.href
                      : undefined
                  }
                  key={item.name}
                  className={classNames(
                    item === currentSection
                      ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                      : item.isEnabled(smartContract, { tokenStream })
                      ? 'text-gray-900 hover:text-gray-900 hover:bg-gray-50'
                      : 'text-gray-400 hover:text-gray-400 cursor-not-allowed',
                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                  )}
                  aria-current={item === currentSection ? 'page' : undefined}
                  onClick={() =>
                    item.isEnabled(smartContract, { tokenStream }) &&
                    setCurrentSection(item)
                  }
                >
                  <item.icon
                    className={classNames(
                      item === currentSection
                        ? 'text-indigo-500 group-hover:text-indigo-500'
                        : 'text-gray-400 group-hover:text-gray-400',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  <span className="flex-1 truncate">{item.name}</span>
                  {(item.isComplete?.(smartContract, { tokenStream }) && (
                    <CheckCircleIcon className="h-6 w-6 text-green-700" />
                  )) ||
                    (item === currentSection && (
                      <ArrowRightIcon className="h-6 w-6 self-end" />
                    ))}
                </a>
              ))}
              {smartContract && smartContract.analysisState !== 'succeeded' ? (
                smartContract?.analysisState === 'failed' ? (
                  <div className="group rounded-md px-3 py-2 flex items-center text-sm font-medium gap-3">
                    <Errors error={smartContract.analysisProcessingError} />
                  </div>
                ) : (
                  <div className="group rounded-md px-3 py-2 flex items-center text-sm font-medium gap-3">
                    <Spinner /> Initializing...
                  </div>
                )
              ) : null}
              {tokenStream?.contractAddress &&
                !smartContract &&
                smartContractLoading && (
                  <>
                    <Spinner /> Loading...
                  </>
                )}
            </nav>
          </aside>
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            {currentSection && (
              <currentSection.widget
                env={env}
                constructorArguments={constructorArguments}
                tokenStream={tokenStream}
                refresh={refresh}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="flex-grow">
      {didCatch ? (
        <div className="p-10">
          <h1 className="text-xl font-bold mb-5">
            Error while opening {tokenStream.contractAddress}
          </h1>
          <Errors error={caughtError} />
        </div>
      ) : (
        <ErrorBoundary>
          {tokenStream.contractAddress ? (
            <RequireChain requiredChainId={Number(tokenStream.chainId)}>
              {managementView}
            </RequireChain>
          ) : (
            managementView
          )}
        </ErrorBoundary>
      )}
    </div>
  );
};
