import '@ethersproject/abstract-provider';

import { loadContract } from '@0xflair/contracts-registry';
import { ethers } from 'ethers';
import { useChainId } from 'flair-sdk';
import { useMemo } from 'react';
import { useSigner } from 'wagmi';

type Config = {
  chainId?: number;
};

export const useFlairFactoryContract = ({ chainId }: Config) => {
  const resolvedChainId = useChainId(chainId);
  const { data: signer } = useSigner();

  return useMemo(() => {
    try {
      if (!resolvedChainId) {
        return undefined;
      }

      // TODO resolve the factory contract based on chain config
      // TODO now is hardcoded for zkSync
      const definition = loadContract(
        [280].includes(resolvedChainId)
          ? 'factory/FlairFactoryNewable'
          : 'factory/FlairFactory',
      );
      const address = definition?.address?.[resolvedChainId];

      if (!address || !signer) {
        return undefined;
      }

      return new ethers.Contract(address, definition.artifact.abi, signer);
    } catch (e) {
      console.warn(
        `Could not get flair factory contract for chain ${resolvedChainId}: `,
        e,
      );
      return undefined;
    }
  }, [resolvedChainId, signer]);
};
