import { ContractVersion } from '@0xflair/contracts-registry';
import { useContractAbi, useContractWriteAndWait } from '@0xflair/react-common';
import { Provider } from '@ethersproject/providers';
import { BigNumberish, Signer } from 'ethers';

import { LicenseVersion } from '../../nft-collections/types/license.types';

type Config = {
  contractVersion?: ContractVersion;
  contractAddress?: string;
  signerOrProvider?: Signer | Provider | null;
  licenseVersion?: BigNumberish;
};

type ArgsType = [LicenseVersion];

export const useLicenseVersionUpdater = ({
  contractVersion,
  contractAddress,
  signerOrProvider,
  licenseVersion,
}: Config) => {
  const contractInterface = useContractAbi({
    contractVersion,
    contractFqn: 'common/LicenseExtension',
  });

  return useContractWriteAndWait<ArgsType>({
    contractInterface,
    contractAddress,
    signerOrProvider,
    functionName: 'setLicenseVersion',
    args: (licenseVersion !== undefined
      ? [licenseVersion]
      : [LicenseVersion.CBE_PR]) as ArgsType,
  });
};
