import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { classNames, Spinner } from 'flair-sdk';
import { useCallback } from 'react';

type Props = {
  isLoading: boolean;
  skip: number;
  limit: number;
  total: number;
  onChange: (skip: number, limit: number) => void;
};

export const Pagination = ({
  isLoading,
  skip,
  limit,
  total,
  onChange,
}: Props) => {
  const pageNumbers = Array.from(Array(Math.ceil(total / limit)).keys()).map(
    (i) => i + 1,
  );
  const firstPages = pageNumbers.slice(0, 1);
  const lastPages = pageNumbers.slice(-1);
  const currentPage = Math.floor(skip / limit) + 1;
  const pagesSurroundingCurrentPage = [
    currentPage - 1,
    currentPage,
    currentPage + 1,
  ];
  const pages = [
    ...new Set([...firstPages, ...pagesSurroundingCurrentPage, ...lastPages]),
  ]
    .filter((i) => i > 0 && i <= pageNumbers[pageNumbers.length - 1])
    .sort((a, b) => a - b);

  const goToNextPage = useCallback(() => {
    if (skip + limit < total) {
      onChange(skip + limit, limit);
    } else {
      onChange(total - limit, limit);
    }
  }, [skip, limit, total, onChange]);

  const goToPreviousPage = useCallback(() => {
    if (skip - limit >= 0) {
      onChange(skip - limit, limit);
    } else {
      onChange(0, limit);
    }
  }, [skip, limit, onChange]);

  let previousPageNumber: number;

  return (
    <div className="bg-gray-50 px-4 py-3 flex items-center justify-between sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <span
          onChange={goToPreviousPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </span>
        <span
          onClick={goToNextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </span>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center gap-2">
          <p className="text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">{(skip + 1).toLocaleString()}</span>{' '}
            to{' '}
            <span className="font-medium">
              {Math.min(Number(total), Number(skip + limit)).toLocaleString()}
            </span>{' '}
            of <span className="font-medium">{total.toLocaleString()}</span>{' '}
            results
          </p>
          {isLoading ? <Spinner /> : null}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <span
              onClick={goToPreviousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            {pages.map((pageNumber) => {
              const isFragmented =
                previousPageNumber &&
                Math.abs(pageNumber - previousPageNumber) > 1;

              previousPageNumber = pageNumber;

              return (
                <>
                  {isFragmented ? (
                    <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                      ...
                    </span>
                  ) : null}
                  <span
                    aria-current="page"
                    className={classNames(
                      pageNumber === currentPage
                        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                      'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
                    )}
                    key={pageNumber}
                    onClick={() => {
                      const newSkip = (pageNumber - 1) * limit;
                      const newLimit = limit;
                      onChange(newSkip, newLimit);
                    }}
                  >
                    {pageNumber}
                  </span>
                </>
              );
            })}
            <span
              onClick={goToNextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};
