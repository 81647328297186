import { utils } from 'ethers';
import {
  Button,
  ContractVersion,
  CryptoUnits,
  CryptoValue,
  Errors,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useERC20Transfer,
  ZERO_ADDRESS,
} from 'flair-sdk';
import { useCallback, useState } from 'react';
import {
  useAccount,
  useBalance,
  useSendTransaction,
  useWaitForTransaction,
} from 'wagmi';

type Props = {
  chainId?: number;
  contractAddress?: string;
  contractVersion?: ContractVersion;
  defaultTo?: string;
  hideTo?: boolean;
};

export const ERC20TransferForm = ({
  chainId,
  contractAddress,
  contractVersion,
  defaultTo = '',
  hideTo = false,
}: Props) => {
  const { data: account } = useAccount();

  const [to, setTo] = useState<string>(defaultTo);
  const [amount, setAmount] = useState<string>('1');

  const { data: balance } = useBalance({
    chainId,
    addressOrName: account?.address,
    token: Boolean(contractAddress && contractAddress !== ZERO_ADDRESS)
      ? contractAddress
      : undefined,
    enabled: true,
  });

  const {
    data: transferERC20Data,
    error: transferERC20Error,
    isLoading: transferERC20Loading,
    writeAndWait: transferERC20,
  } = useERC20Transfer({
    contractVersion,
    contractAddress,
    to: to,
    amount:
      amount && !isNaN(Number(amount))
        ? utils.parseEther(amount || '0')
        : undefined,
  });

  const {
    data: transferEtherResponse,
    error: transferEtherError,
    isLoading: transferEtherLoading,
    sendTransactionAsync: transferEther,
  } = useSendTransaction({
    request: {
      from: account?.address,
      to: to,
      value:
        amount && !isNaN(Number(amount))
          ? utils.parseEther(amount || '0')
          : undefined,
    },
  });

  const {
    data: transferEtherReceipt,
    error: transferEtherReceiptError,
    isLoading: transferEtherReceiptLoading,
  } = useWaitForTransaction({
    chainId,
    hash: transferEtherResponse?.hash,
    enabled: Boolean(transferEtherResponse?.hash),
    confirmations: 2,
  });

  const transfer = useCallback(() => {
    if (contractAddress && contractAddress !== ZERO_ADDRESS) {
      transferERC20();
    } else {
      transferEther();
    }
  }, [contractAddress, transferERC20, transferEther]);

  return (
    <>
      {!hideTo ? (
        <div className="sm:col-span-3">
          <label
            htmlFor="receiverWallet"
            className="block text-sm font-medium text-gray-700"
          >
            Receiver address
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="receiverAddress"
              id="receiverWallet"
              autoComplete="walletAddress"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="0x0000000000000000000000000000000000000000"
              value={to}
              onChange={(e) => setTo(e.target.value.trim())}
            />
          </div>
        </div>
      ) : null}

      <div className="sm:col-span-3">
        <label
          htmlFor="amount"
          className="block text-sm font-medium text-gray-700"
        >
          Amount
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="number"
            name="amount"
            id="amount"
            autoComplete="tokenAmount"
            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
            min={0}
            max={balance ? balance.formatted : Infinity}
            placeholder="1"
            value={amount}
            onChange={(e) => setAmount(e.target.value.trim())}
          />
          <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            of{' '}
            <CryptoValue
              value={balance?.value}
              symbol={balance?.symbol?.toString()}
              unit={CryptoUnits.WEI}
              showPrice={false}
            />{' '}
          </span>
        </div>
      </div>

      <div className="sm:col-span-6 flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Button
            text="Transfer"
            className={PRIMARY_BUTTON}
            onClick={() => transfer()}
          />
        </div>

        <div className="flex items-center gap-2">
          {transferERC20Error ? (
            <Errors title="transferERC20Error" error={transferERC20Error} />
          ) : null}
          {transferEtherError ? (
            <Errors title="transferEtherError" error={transferEtherError} />
          ) : null}
          {transferEtherReceiptError ? (
            <Errors
              title="transferEtherReceiptError"
              error={transferEtherReceiptError}
            />
          ) : null}
          {transferERC20Loading ||
          (transferEtherLoading && !transferERC20Data.txResponse) ? (
            <>
              <Spinner /> Transferring {balance?.symbol}...
            </>
          ) : null}
          {transferEtherReceiptLoading || transferERC20Data.txResponse ? (
            <>
              <Spinner /> Waiting for transfer of {balance?.symbol}...
            </>
          ) : null}
          {transferERC20Data?.txReceipt || transferERC20Data?.txResponse ? (
            <TransactionLink
              txReceipt={transferERC20Data?.txReceipt}
              txResponse={transferERC20Data?.txResponse}
            />
          ) : null}
          {transferEtherReceipt || transferEtherResponse ? (
            <TransactionLink
              txReceipt={transferEtherReceipt}
              txResponse={transferEtherResponse}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
