import { Switch } from '@headlessui/react';
import { Signer, utils } from 'ethers';
import {
  Badge,
  Button,
  classNames,
  ContractFqn,
  Environment,
  Errors,
  LATEST_VERSION,
  loadContract,
  NftCollection,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  Spinner,
  useChainInfo,
  useCryptoCurrency,
} from 'flair-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetwork, useSigner, useWaitForTransaction } from 'wagmi';

import { useFlairContractDeployer } from '../../../common/hooks/useFlairContractDeployer';
import { useNftCollectionUpdater } from '../hooks/common/useNftCollectionUpdater';

type Props = {
  env: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
  constructorArguments?: any[];
};

export const DeployAdminSection = ({
  env = Environment.PROD,
  nftCollection,
  refresh,
  constructorArguments,
}: Props) => {
  const { data: signer } = useSigner();
  const { activeChain } = useNetwork();
  const { data } = useCryptoCurrency({
    symbol: activeChain?.nativeCurrency?.symbol,
  });

  const [supportsAzukiVersion, setSupportsAzukiVersion] = useState(false);
  const [usingAzukiVersion, setUsingAzukiVersion] = useState(
    nftCollection.contractAddress
      ? nftCollection.presetFqn.includes('/ERC721A/')
      : true,
  );
  const [triedAutoSave, setTriedAutoSave] = useState(false);

  const contractFqn = useMemo(() => {
    const fqn = usingAzukiVersion
      ? (nftCollection.presetFqn
          .replace(/\/ERC721/gi, '/ERC721A')
          .replace(/\/ERC721AA/gi, '/ERC721A') as ContractFqn)
      : nftCollection.presetFqn;

    try {
      loadContract(fqn, nftCollection.presetVersion);
      setSupportsAzukiVersion(true);

      return fqn;
    } catch (err) {
      setSupportsAzukiVersion(false);
      return nftCollection.presetFqn;
    }
  }, [nftCollection.presetFqn, nftCollection.presetVersion, usingAzukiVersion]);

  const {
    data: deployData,
    error: deployError,
    isLoading: deployLoading,
    deploymentFee,
    deployContract,
  } = useFlairContractDeployer({
    env,
    contractFqn,
    contractVersion: nftCollection.presetVersion,
    signer: signer as Signer,
  });

  const deployTransaction =
    deployData?.deployTransaction?.hash || nftCollection.deployTransaction;

  const {
    data: txData,
    isLoading: txLoading,
    error: txError,
  } = useWaitForTransaction({
    hash: deployTransaction,
    confirmations: 2,
  });

  const contractAddress =
    deployData?.address ||
    txData?.contractAddress ||
    nftCollection.contractAddress;

  const {
    data: updateData,
    isLoading: updateLoading,
    error: updateError,
    sendRequest: saveNftCollection,
  } = useNftCollectionUpdater(
    {
      _id: nftCollection._id,
      chainId: activeChain?.id as number,
      presetFqn: contractFqn,
      presetVersion: LATEST_VERSION,
      contractAddress,
      deployTransaction,
    },
    {
      env,
      enabled: false,
    },
  );

  const deploymentFeeFiat = useMemo(() => {
    try {
      return data?.price && deploymentFee
        ? (
            Number(utils.formatEther(deploymentFee?.toString()).toString()) *
            Number(data?.price?.toString()) *
            (activeChain?.testnet ? 0 : 1)
          ).toFixed(2)
        : undefined;
    } catch (error) {
      return undefined;
    }
  }, [activeChain?.testnet, data?.price, deploymentFee]);

  const deployNow = useCallback(() => {
    if (!nftCollection._id) {
      window?.gtag?.('event', 'contract_deployment_attempted', {
        event_category: 'collections',
        event_label: nftCollection.presetFqn,
        section: 'collections',
        preset_fqn: nftCollection.presetFqn,
        preset_version: nftCollection.presetVersion,
        chain_id: activeChain?.id,
        transaction_id: `deploy:${nftCollection._id}`,
        deployment_fee_wei: deploymentFee?.toString(),
        deployment_fee_fiat: deploymentFeeFiat,
      });
    }

    deployContract(...(constructorArguments || []));
  }, [
    nftCollection._id,
    nftCollection.presetFqn,
    nftCollection.presetVersion,
    deployContract,
    constructorArguments,
    activeChain?.id,
    deploymentFee,
    deploymentFeeFiat,
  ]);

  // TODO Save transaction even if contract address is not there yet
  const mustBeSaved = Boolean(
    deployTransaction &&
      contractAddress &&
      !nftCollection.contractAddress &&
      !updateData?._id,
  );

  useEffect(() => {
    if (triedAutoSave) {
      return;
    }

    if (mustBeSaved) {
      if (activeChain?.id && contractAddress) {
        window?.gtag?.('event', 'contract_deployment_successful', {
          event_category: 'collections',
          event_label: nftCollection.presetFqn,
          section: 'collections',
          preset_fqn: nftCollection.presetFqn,
          preset_version: nftCollection.presetVersion,
          chain_id: activeChain?.id,
          testnet: !!activeChain?.testnet,
          contract_address: contractAddress,
          transaction_id: `${activeChain?.id}:${contractAddress}`,
          deployment_fee_wei: deploymentFee?.toString(),
          deployment_fee_fiat:
            data?.price && deploymentFee
              ? Number(deploymentFee?.toString()) *
                Number(data?.price?.toString())
              : undefined,
        });
        window?.gtag?.('event', 'purchase', {
          currency: 'USD',
          transaction_id: `deploy:collection:${nftCollection._id}`,
          value: deploymentFeeFiat,
          items: [
            {
              item_id: `${activeChain?.id}:${nftCollection.presetFqn}`,
              item_name: `${activeChain?.id}:${nftCollection.presetFqn}`,
            },
          ],
        });
      }

      saveNftCollection().then(refresh);
      setTriedAutoSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triedAutoSave,
    mustBeSaved,
    saveNftCollection,
    refresh,
    nftCollection.presetFqn,
    activeChain?.id,
    contractAddress,
    activeChain?.testnet,
  ]);

  const tokenChainId = nftCollection.contractAddress
    ? nftCollection.chainId
    : updateData?.chainId
    ? updateData.chainId
    : activeChain?.id;

  const tokenChain = useChainInfo(Number(tokenChainId));

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Deploy</h3>
        <p className="mt-1 text-sm text-gray-500">
          Whenever you are ready you can deploy the smart contract on the
          blockchain.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {!deployLoading && !txLoading ? (
                contractAddress ? (
                  <Badge color="green" text="Successfully deployed!" />
                ) : (
                  <Badge color="yellow" text="Not deployed yet" />
                )
              ) : (
                ''
              )}
              {deployLoading ? (
                <>
                  <Spinner />
                  <Badge color="blue" text="Deploying..." />
                </>
              ) : deployTransaction &&
                (txLoading ||
                  !txData?.confirmations ||
                  txData.confirmations < 2) ? (
                <>
                  <Spinner />
                  <Badge color="blue" text="Waiting for Tx..." />
                </>
              ) : null}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Network</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {tokenChain?.name || `Chain ${tokenChainId}`}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Deploy Transaction
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-full truncate">
              {deployTransaction ? (
                <>
                  <small className="text-xs">
                    {deployTransaction.toString()}
                  </small>
                  <br />
                  <a
                    href={`${activeChain?.blockExplorers?.default.url}/tx/${deployTransaction}`}
                    target={'_blank'}
                    className={'text-sm text-indigo-700'}
                    rel="noreferrer"
                  >
                    View on {activeChain?.blockExplorers?.default.name}
                  </a>
                </>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Contract Address
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {contractAddress ? (
                <>
                  <small className="text-xs">{contractAddress}</small>
                  <br />
                  <a
                    href={`${activeChain?.blockExplorers?.default.url}/address/${contractAddress}`}
                    target={'_blank'}
                    className={'text-sm text-indigo-700'}
                    rel="noreferrer"
                  >
                    View on {activeChain?.blockExplorers?.default.name}
                  </a>
                </>
              ) : (
                'N/A'
              )}
            </dd>
          </div>
        </dl>
        <div className="py-4 sm:py-5 sm:px-6">
          {deployError ? (
            <Errors title="Deploy Error" error={deployError} />
          ) : null}
          {txError ? <Errors title="Tx Error" error={txError} /> : null}
          {updateError ? (
            <Errors title="Update Error" error={updateError} />
          ) : null}
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-4">
        <Button
          className={PRIMARY_BUTTON}
          text="Deploy"
          onClick={deployNow}
          disabled={Boolean(contractAddress || txLoading || deployLoading)}
        />

        {supportsAzukiVersion && (
          <Switch.Group as="div" className="flex items-center">
            <Switch
              disabled={Boolean(contractAddress || txLoading || deployLoading)}
              checked={usingAzukiVersion}
              onChange={setUsingAzukiVersion}
              className={classNames(
                usingAzukiVersion ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  usingAzukiVersion ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 flex flex-col gap-1">
              <span className="text-sm font-medium text-gray-900">
                Use Azuki ERC71-A{' '}
              </span>
              <span className="text-xs text-gray-500">Gas-saving on mints</span>
            </Switch.Label>
          </Switch.Group>
        )}

        {mustBeSaved && (
          <Button
            className={SECONDARY_BUTTON}
            text="Save"
            onClick={saveNftCollection}
            disabled={Boolean(nftCollection.contractAddress || updateLoading)}
          />
        )}
      </div>
    </div>
  );
};
