import { TransactionResponse } from '@ethersproject/abstract-provider';
import { TransactionReceipt } from '@ethersproject/providers';
import { BigNumberish } from 'ethers';
import {
  CollectionImage,
  CollectionProvider,
  CollectionSalesMintingSection,
  CollectionTitle,
  Environment,
  WalletDropdown,
} from 'flair-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RequireConnect } from '../../../components/ui/RequireConnect';
import MintSuccessDialog from './MintSuccessDialog';

type Props = {
  env?: Environment;
};

export const NftCollectionMintingPage = ({ env = Environment.PROD }: Props) => {
  const { contractAddress, chainId } = useParams();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successDialogMintCount, setSuccessDialogMintCount] =
    useState<BigNumberish>();
  const [successDialogTxResponse, setSuccessDialogTxResponse] =
    useState<TransactionResponse>();
  const [successDialogTxReceipt, setSuccessDialogTxReceipt] =
    useState<TransactionReceipt>();

  const { search } = window.location;
  const qs = useMemo(() => new URLSearchParams(search), [search]);
  const tier = qs.has('tier')
    ? !isNaN(Number(qs.get('tier')))
      ? Number(qs.get('tier'))
      : undefined
    : undefined;
  const minterAddress = qs.has('minter')
    ? qs.get('minter')?.toString().length === 42
      ? qs.get('minter')?.toString()
      : undefined
    : undefined;

  const onMintSuccess = useCallback(({ mintCount, txResponse, txReceipt }) => {
    setSuccessDialogMintCount(mintCount);
    setSuccessDialogTxResponse(txResponse);
    setSuccessDialogTxReceipt(txReceipt);
    setSuccessDialogOpen(true);
  }, []);

  if (!contractAddress) {
    return <div>Must provide "contractAddress" in the URL</div>;
  }

  return (
    <>
      <style type="text/css">{`html,body {background: none transparent !important;}`}</style>
      <CollectionProvider
        env={env}
        chainId={Number(chainId)}
        contractAddress={contractAddress}
      >
        {({ data: { collection, collectionMetadata } }) => (
          <>
            <MintSuccessDialog
              mintCount={successDialogMintCount}
              txResponse={successDialogTxResponse}
              txReceipt={successDialogTxReceipt}
              open={successDialogOpen}
              setOpen={setSuccessDialogOpen}
            />
            <main className="h-fit max-w-2xl mx-auto lg:max-w-5xl flex items-center p-4">
              <div className="min-w-full lg:grid lg:grid-cols-12 lg:auto-rows-min lg:gap-x-8">
                <div className="lg:col-start-6 lg:col-span-7">
                  {/* Sales Info */}
                  <div className="flex gap-4 sm:items-center sm:justify-between sm:flex-row flex-col">
                    <CollectionTitle
                      as="div"
                      className="text-4xl font-medium text-gray-900"
                    />
                    <RequireConnect notConnectedView={<></>}>
                      <WalletDropdown />
                    </RequireConnect>
                  </div>
                </div>

                {/* Image */}
                <div className="mt-8 lg:mt-0 lg:col-start-1 lg:col-span-5 lg:row-start-1 lg:row-span-3">
                  <div className="flex items-center justify-center overflow-hidden rounded-lg">
                    <CollectionImage className="lg:col-span-2 lg:row-span-2 overflow-hidden rounded-lg w-full" />
                  </div>
                </div>

                <div className="mt-4 lg:col-span-7">
                  <CollectionSalesMintingSection
                    autoDetectEligibleTier={tier === undefined}
                    defaultTier={tier}
                    minterAddress={minterAddress}
                    onMintSuccess={onMintSuccess}
                  />

                  {/* Description */}
                  {collectionMetadata?.description ||
                  collection?.config?.collectionDescription ? (
                    <div className="mt-10">
                      <h2 className="mt-8 text-sm font-medium text-gray-900">
                        Description
                      </h2>

                      <div className="mt-3 prose prose-sm text-gray-500">
                        {collectionMetadata?.description ||
                          collection?.config?.collectionDescription}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </main>
          </>
        )}
      </CollectionProvider>
    </>
  );
};
