import { Environment, useAxiosGet } from 'flair-sdk';

import { FLAIR_FUNGIBLE_TOKENS_BACKEND } from '../constants';
import { FungibleToken } from '../types';

type Config = {
  env?: Environment;
  enabled?: boolean;
  fungibleTokenId?: string;
  chainId?: number;
  contractAddress?: string;
};

export function useFungibleToken({
  env = Environment.PROD,
  enabled = true,
  fungibleTokenId,
  chainId,
  contractAddress,
}: Config) {
  const url = fungibleTokenId
    ? `${FLAIR_FUNGIBLE_TOKENS_BACKEND[env]}/v1/fungible-tokens/${fungibleTokenId}`
    : `${FLAIR_FUNGIBLE_TOKENS_BACKEND[env]}/v1/fungible-tokens/${chainId}/${contractAddress}`;

  return useAxiosGet<FungibleToken>({
    url,
    enabled: Boolean(
      enabled && (fungibleTokenId || (chainId && contractAddress)),
    ),
  });
}
