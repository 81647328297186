import {
  Environment,
  FLAIR_TOKEN_STREAMS_BACKEND,
  NftCollection,
  useAxiosPut,
  useLoginJwt,
} from 'flair-sdk';
import { useMemo } from 'react';

type Config = {
  env?: Environment;
  enabled?: boolean;
  chainId?: number;
  contractAddress?: string;
};

export function useTokenStreamImporter({
  chainId,
  contractAddress,
  enabled = false,
  env = Environment.PROD,
}: Config) {
  const loginJwt = useLoginJwt();
  const headers = useMemo(() => {
    return {
      Authorization: `Bearer ${loginJwt}`,
    };
  }, [loginJwt]);

  return useAxiosPut<NftCollection<any>>({
    url: `${FLAIR_TOKEN_STREAMS_BACKEND[env]}/v1/token-streams`,
    data: {
      chainId,
      contractAddress,
    },
    enabled: Boolean(loginJwt && enabled && chainId && contractAddress),
    headers,
  });
}
