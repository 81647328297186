import { Environment, Errors } from 'flair-sdk';
import { useCallback, useState } from 'react';
import { useSigner } from 'wagmi';

type Props = {
  env?: Environment;
};

export const ToolsPage = ({ env = Environment.PROD }: Props) => {
  const [contractAddress, setContractAddress] = useState('');
  const [valueAmount, setValueAmount] = useState('0');
  const [callData, setCallData] = useState('0x');
  const [error, setError] = useState<Error>();

  const { data: signer } = useSigner();

  const contractWrite = useCallback(async () => {
    try {
      await signer?.sendTransaction({
        to: contractAddress,
        data: callData,
      });
    } catch (error) {
      setError(error as Error);
    }
  }, [callData, contractAddress, signer]);

  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Tools</h1>
            <p className="mt-2 text-sm text-gray-700">
              Miscellaneous utilities for developers helpful during development
              and debugging.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2 items-center">
            {/* Page buttons */}
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Contract Caller
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Call a contract by providing address and hex raw calldata.
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="contractAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contract address
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="contractAddress"
                      id="contractAddress"
                      autoComplete="contractAddress"
                      value={contractAddress}
                      onChange={(e) => setContractAddress(e.target.value)}
                      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="contractAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Value
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="valueAmount"
                      id="valueAmount"
                      autoComplete="valueAmount"
                      value={valueAmount}
                      onChange={(e) => setValueAmount(e.target.value)}
                      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="callData"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Calldata
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="callData"
                      name="callData"
                      rows={3}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      value={callData}
                      onChange={(e) => setCallData(e.target.value)}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Must be hex encoded calldata.
                  </p>
                </div>

                <div className="sm:col-span-4">
                  {error && <Errors error={error} />}
                </div>

                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => contractWrite()}
                  >
                    Write to contract
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
