import { BigNumberish, BytesLike } from 'ethers';
import {
  Button,
  CryptoSymbol,
  CryptoUnits,
  CryptoValue,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  Spinner,
  TokenStream,
  TransactionLink,
  useChainId,
  useERC721Name,
  useERC721Symbol,
  useERC721TotalSupply,
  useStreamTicketToken,
  useTokenStreamUpdater,
} from 'flair-sdk';
import { useCallback, useEffect, useState } from 'react';

import { TokenSharesTable } from '../components/TokenSharesTable';
import { useStreamSharesUpdater } from '../hooks/useStreamSharesUpdater';

type Props = {
  env?: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
};

export const ShareholdersAdminSection = ({
  env,
  tokenStream,
  refresh,
}: Props) => {
  const chainId = useChainId(tokenStream.chainId);
  const contractAddress = tokenStream.contractAddress;

  const [shares, setSharesForTokens] = useState<Record<string, BigNumberish>>(
    {},
  );
  const [ticketTokenAddress, setTicketTokenAddress] = useState<BytesLike>();

  const {
    data: streamTicketTokenAddress,
    error: streamTicketTokenAddressError,
    isLoading: streamTicketTokenAddressLoading,
  } = useStreamTicketToken({
    env,
    chainId,
    contractAddress: tokenStream.contractAddress,
  });

  const { data: registryName } = useERC721Name({
    chainId,
    contractAddress: ticketTokenAddress?.toString(),
  });

  const { data: registrySymbol } = useERC721Symbol({
    chainId,
    contractAddress: ticketTokenAddress?.toString(),
  });

  const { data: registryTotalSupply } = useERC721TotalSupply({
    chainId,
    contractAddress: ticketTokenAddress?.toString(),
  });

  useEffect(() => {
    if (!streamTicketTokenAddressLoading && !ticketTokenAddress) {
      setTicketTokenAddress(
        streamTicketTokenAddress || tokenStream.config?.ticketToken || '',
      );
    }
  }, [
    tokenStream.config?.ticketToken,
    streamTicketTokenAddress,
    streamTicketTokenAddressLoading,
    ticketTokenAddress,
  ]);

  useEffect(() => {
    if (!tokenStream.contractAddress && tokenStream.config?.initialShares) {
      setSharesForTokens(tokenStream.config?.initialShares);
    }
  }, [tokenStream.config?.initialShares, tokenStream.contractAddress]);

  const {
    error: streamUpdaterError,
    isLoading: streamUpdaterLoading,
    sendRequest: saveStream,
  } = useTokenStreamUpdater(
    {
      _id: tokenStream._id,
      config: {
        ...tokenStream.config,
        ticketToken: ticketTokenAddress,
        initialShares: shares,
      },
    },
    {
      enabled: false,
      env,
    },
  );

  const {
    data: streamSharesUpdaterData,
    error: streamSharesUpdaterError,
    isLoading: streamSharesUpdaterLoading,
    writeAndWait: updateStreamShares,
  } = useStreamSharesUpdater({
    env,
    chainId,
    contractAddress,
  });

  const saveShares = useCallback(() => {
    if (tokenStream.contractAddress) {
      updateStreamShares({
        ticketTokenIds: Object.keys(shares),
        shares: Object.values(shares),
      });
    } else {
      saveStream();
    }
  }, [saveStream, shares, tokenStream.contractAddress, updateStreamShares]);

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Registry
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            The ERC721 collection used as shareholders registry. Each NFT can
            get a share as defined below.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <dl>
            <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
              <dt className="text-sm font-medium text-gray-500">
                Registry address (ERC721)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-center">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={ticketTokenAddress?.toString() || ''}
                  disabled={Boolean(tokenStream.contractAddress)}
                  onChange={(e) => setTicketTokenAddress(e.target.value)}
                />
                <p className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-3">
                  You can only change the registry <b>before</b> the stream is
                  deployed, so make sure this is the right ERC721 collection.
                </p>
              </dd>
            </div>
            <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
              <dt className="text-sm font-medium text-gray-500">
                Name (Symbol)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center gap-2">
                {registryName || '-'}{' '}
                <small className="text-gray-500">
                  ({registrySymbol || '-'})
                </small>
              </dd>
            </div>
            <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
              <dt className="text-sm font-medium text-gray-500">Total NFTs</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {registryTotalSupply ? (
                  <CryptoValue
                    value={registryTotalSupply?.toString()}
                    unit={CryptoUnits.ETHER}
                    symbol={registrySymbol as CryptoSymbol}
                    showPrice={false}
                  />
                ) : (
                  '-'
                )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex gap-1">
          <div className="flex flex-grow gap-2 items-center">
            {streamUpdaterLoading && (
              <>
                <Spinner /> Saving...
              </>
            )}
            {streamTicketTokenAddressLoading && (
              <>
                <Spinner /> Loading...
              </>
            )}
            {streamTicketTokenAddressError && (
              <Errors
                title="streamTicketTokenAddressError"
                error={streamTicketTokenAddressError}
              />
            )}
            {streamUpdaterError && (
              <Errors title="streamUpdaterError" error={streamUpdaterError} />
            )}
          </div>
          <Button
            className={PRIMARY_BUTTON}
            text="Change registry"
            onClick={() => saveStream()}
            disabled={Boolean(contractAddress || streamUpdaterLoading)}
          />
        </div>
      </div>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Shares
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Manage share allocations for NFT holders.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <TokenSharesTable
            env={env}
            chainId={chainId}
            streamAddress={contractAddress}
            registryAddress={ticketTokenAddress}
            shares={shares}
            setSharesForTokens={setSharesForTokens}
          />
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex gap-1">
          <div className="flex flex-grow gap-2 items-center">
            {streamUpdaterLoading && (
              <>
                <Spinner /> Saving...
              </>
            )}
            {streamSharesUpdaterLoading && (
              <>
                <Spinner /> Saving...
              </>
            )}
            {streamUpdaterError && (
              <Errors title="streamUpdaterError" error={streamUpdaterError} />
            )}
            {streamSharesUpdaterError && (
              <Errors
                title="streamSharesUpdaterError"
                error={streamSharesUpdaterError}
              />
            )}
            {streamSharesUpdaterData.txReceipt ||
            streamSharesUpdaterData.txResponse ? (
              <TransactionLink
                txReceipt={streamSharesUpdaterData.txReceipt}
                txResponse={streamSharesUpdaterData.txResponse}
              />
            ) : null}
          </div>
          <Button
            className={PRIMARY_BUTTON}
            text="Save shares"
            onClick={saveShares}
            disabled={Boolean(
              streamSharesUpdaterLoading || streamUpdaterLoading,
            )}
          />
        </div>
      </div>
    </>
  );
};
