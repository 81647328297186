import { BeakerIcon } from '@heroicons/react/outline';
import { Badge, classNames } from 'flair-sdk';
import { AiFillGithub } from 'react-icons/ai';
import { HiOutlineExternalLink, HiOutlineLightBulb } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const features = [
  {
    id: 1,
    title: 'Tokens',
    description:
      'Create self-owned tokens that you can sell or reward your community with.',
    badge: {
      color: 'green',
      text: 'ERC20',
    },
    link: '/tokens',
    presets: [
      {
        text: 'Create a simple token',
        link: 'tokens/create/ERC20BasicToken',
      },
      {
        text: 'Create a lockable token',
        link: 'tokens/create/ERC20LockableToken',
      },
    ],
    guides: [
      {
        text: 'Explore SDK',
        link: 'https://docs.flair.finance/sdk/tokens',
        icon: BeakerIcon,
      },
      {
        text: 'Contracts',
        link: 'https://github.com/0xflair/evm-contracts/tree/main/contracts/tokens',
        icon: AiFillGithub,
      },
    ],
  },
  {
    id: 2,
    title: 'Collections',
    description:
      'Your self-owned NFT collections allow you to issue digital assets immutably.',
    badge: {
      color: 'green',
      text: 'ERC721A',
    },
    link: '/collections',
    guides: [
      {
        text: 'Explore SDK',
        link: 'https://docs.flair.finance/sdk/nft-collections/',
        icon: BeakerIcon,
      },
      {
        text: 'Contracts',
        link: 'https://github.com/0xflair/evm-contracts/tree/main/contracts/collections',
        icon: AiFillGithub,
      },
    ],
    presets: [
      {
        text: 'Create a 10k PFP collection',
        link: 'collections/create/ERC721SimpleSales',
      },
      {
        text: 'Create your 1-of-1 art collection',
        link: 'collections/create/ERC721OneOfOne',
      },
      {
        text: 'Launch sales of gaming assets',
        link: 'collections/create/ERC721SimpleSales',
      },
      {
        text: 'Create shareholder NFTs',
        link: 'collections/create/ERC721StakeholderRegistry',
      },
    ],
  },
  {
    id: 3,
    title: 'Streams',
    description:
      'Create token streams or staking pools for holders of your NFT collection.',
    link: '/streams',
    guides: [
      {
        text: 'Explore SDK',
        link: 'https://docs.flair.finance/sdk/token-streams/',
        icon: BeakerIcon,
      },
      {
        text: 'Contracts',
        link: 'https://github.com/0xflair/evm-contracts/tree/main/contracts/streams',
        icon: AiFillGithub,
      },
    ],
    presets: [
      {
        text: 'Reward for holding NFTs',
        link: 'streams/create/ERC721SingleTokenEqualEmissionStream',
      },
      {
        text: 'Reward for locking NFTs',
        link: 'streams/create',
      },
      {
        text: 'Split revenue between parties',
        link: 'streams/create/ERC721ShareInstantStream',
      },
    ],
  },
  {
    id: 4,
    title: 'Lists',
    description:
      'Create allow-lists, mint-lists, or any collection of wallet addresses based on certain criteria.',
    link: '/lists',
    guides: [
      {
        text: 'Explore SDK',
        link: 'https://docs.flair.finance/sdk/address-lists/',
        icon: BeakerIcon,
      },
    ],
    presets: [
      {
        text: 'Create a new allow-list for your NFT collection',
        link: 'lists/create',
      },
    ],
  },
];

const guides = [
  {
    id: 1,
    title: 'Tutorials',
    link: 'https://docs.flair.finance/tutorials',
    icon: HiOutlineLightBulb,
    links: [
      {
        text: 'Launch an NFT Sale',
        link: 'https://docs.flair.finance/tutorials/nft-sale',
      },
      {
        text: 'Upload and reveal the metadata of NFTs',
        link: 'https://docs.flair.finance/tutorials/upload-metadata',
      },
      {
        text: 'Launch staking for your NFTs to reward users',
        link: 'https://docs.flair.finance/tutorials/nft-staking',
      },
      {
        text: 'Share revenue with your team and community',
        link: 'https://docs.flair.finance/tutorials/revenue-sharing',
      },
    ],
  },
  {
    id: 3,
    title: 'Code Examples',
    link: 'https://github.com/0xflair',
    icon: AiFillGithub,
    description: `Dive deep into Flair's open-source smart contracts and other repositories`,
    links: [
      {
        text: 'Custom NFT Minting Sales Page using React',
        link: 'https://github.com/0xflair/examples/tree/main/react/custom-nft-minting-sales',
      },
      {
        text: 'Airdrop rewards to your NFT community',
        link: 'https://github.com/0xflair/examples/tree/main/react/community-airdrop-reward-stream',
      },
      {
        text: 'Mint NFTs from your backend API',
        link: 'https://github.com/0xflair/examples/tree/main/nodejs/express/mint-nft-by-role-meta-transactions',
      },
    ],
  },
];

export const Welcome = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-6">
      <div className="h2 text-6xl font-bold text-gray-900">Welcome</div>
      <p className="text-gray-600 py-4">What do you like to build?</p>
      <div className="mt-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
        <div className="sm:col-span-4">
          <div>
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4">
              {features.map((feature) => (
                <div
                  key={feature.id}
                  className="relative bg-white border rounded-lg shadow-sm px-4 py-5 sm:px-6 focus:outline-none"
                >
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <Link
                          className="mr-3 text-2xl text-gray-900 hover:text-gray-700"
                          to={feature.link}
                        >
                          {feature.title}
                        </Link>
                        {feature.badge ? (
                          <Badge
                            color={feature?.badge.color}
                            text={feature?.badge.text}
                          />
                        ) : null}
                      </div>
                      <div className="flex items-center gap-2">
                        {feature.guides && feature.guides.length
                          ? feature.guides.map((guide) => (
                              <a
                                href={guide.link}
                                className="inline-flex items-center whitespace-nowrap px-3 py-1 border border-gray-300 rounded-md shadow-sm text-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <guide.icon
                                  className="-ml-1 mr-1 h-4 w-4"
                                  aria-hidden="true"
                                />
                                {guide.text}
                              </a>
                            ))
                          : null}
                      </div>
                    </div>
                    <div className="mt-2 text-sm text-gray-500">
                      {feature.description}
                    </div>
                    <div className="mt-2 flex flex-col flex-no-wrap">
                      {feature.presets && feature.presets.length
                        ? feature.presets.map((preset) => (
                            <Link
                              to={preset.link}
                              className={classNames(
                                'mt-3 mr-2 text-md text-indigo-700 group',
                              )}
                            >
                              → {preset.text}
                            </Link>
                          ))
                        : null}
                    </div>
                  </div>
                  <div
                    className="border-indigo-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none',"
                    aria-hidden="true"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sm:col-span-3 flex flex-col gap-10">
          {guides.map((guide) => (
            <div
              key={guide.id}
              className="relative bg-gray-100 px-4 sm:px-6 focus:outline-none"
            >
              <div className="group flex items-center mb-5">
                <guide.icon className="h-5 w-5" aria-hidden="true" />
                <span className="text-xl ml-2">{guide.title}</span>
              </div>
              {guide.links && guide.links.length
                ? guide.links.map((link) => (
                    <a
                      href={link.link}
                      className={classNames(
                        'group flex mt-3 items-center gap-1 mr-2 text-sm text-indigo-700',
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.text}
                      <HiOutlineExternalLink className="h-4 w-4" />
                    </a>
                  ))
                : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
