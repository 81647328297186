import { Environment } from 'flair-sdk';

import { AdminMintForm } from '../components/AdminMintForm';
import { FungibleToken } from '../types';

type Props = {
  env?: Environment;
  fungibleToken: FungibleToken;
  refresh: () => void;
};

export const MintAdminSection = ({ env, fungibleToken, refresh }: Props) => {
  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <dl>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Mint as owner
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Issue new ERC20 tokens as the owner of the contract.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <AdminMintForm
                env={env}
                chainId={fungibleToken.chainId}
                contractAddress={fungibleToken.contractAddress}
              />
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
