import {
  PredefinedReadContractConfig,
  useContractRead,
} from '@0xflair/react-common';

import { LicenseVersion } from '../../nft-collections/types/license.types';

export const useLicenseVersionLockStatus = (
  config: PredefinedReadContractConfig,
) => {
  return useContractRead<boolean>({
    contractFqn: 'common/LicenseExtension',
    functionName: 'licenseVersionLocked',
    ...config,
  });
};
