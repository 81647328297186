import { ContractVersion } from '@0xflair/contracts-registry';
import { useContractAbi, useContractWriteAndWait } from '@0xflair/react-common';
import { Provider } from '@ethersproject/providers';
import { BigNumberish, BytesLike, Signer } from 'ethers';
import { Tier } from 'flair-sdk';
import { useCallback } from 'react';

type Config = {
  contractVersion?: ContractVersion;
  contractAddress?: string;
  signerOrProvider?: Signer | Provider | null;
  tiers?: Record<string | number, Tier>;
};

type ArgsType = [
  tierIds: BigNumberish[],
  tiers: [
    start: BigNumberish,
    end: BigNumberish,
    currency: BytesLike,
    price: BigNumberish,
    maxPerWallet: BigNumberish,
    merkleRoot: BytesLike,
    reserved: BigNumberish,
    maxAllocation: BigNumberish,
  ][],
];

function generateArgs(
  tiers?: Record<string | number, Tier>,
): ArgsType | undefined {
  return tiers
    ? ([
        Object.keys(tiers),
        Object.values(tiers).map((t) => [
          t.start,
          t.end,
          t.currency,
          t.price,
          t.maxPerWallet,
          t.merkleRoot,
          t.reserved,
          t.maxAllocation,
        ]),
      ] as ArgsType)
    : undefined;
}

export const useCollectionTiersUpdater = ({
  contractVersion,
  contractAddress,
  signerOrProvider,
  tiers,
}: Config) => {
  const contractInterface = useContractAbi({
    contractVersion,
    contractFqn: 'collections/ERC721/extensions/ERC721TieringExtension',
  });

  const result = useContractWriteAndWait<ArgsType>({
    contractInterface,
    functionName:
      'configureTiering(uint256[],(uint256,uint256,address,uint256,uint256,bytes32,uint256,uint256)[])',
    contractAddress,
    signerOrProvider,
    args: generateArgs(tiers),
  });

  const updateTiers = useCallback(
    (args?: { tiers?: Record<string | number, Tier> }) => {
      return result.writeAndWait(generateArgs(args?.tiers || tiers));
    },
    [result, tiers],
  );

  return { ...result, updateTiers };
};
