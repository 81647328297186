import { FeatureWriteByTagConfig, useFeatureWriteByTag } from 'flair-sdk';

type ArgsType = {};

export const useWithdraw = (config: FeatureWriteByTagConfig<ArgsType>) => {
  return useFeatureWriteByTag({
    tag: 'withdraw',
    ...config,
  });
};
