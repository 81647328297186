import {
  Badge,
  Button,
  CryptoSymbol,
  CryptoUnits,
  CryptoValue,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useChainInfo,
  usePublicSaleMaxMintPerTx,
  usePublicSalePrice,
  usePublicSaleStatus,
} from 'flair-sdk';
import { useState } from 'react';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { usePublicSaleMaxMintPerTxUpdater } from '../hooks/sales/usePublicSaleMaxMintPerTxUpdater';
import { usePublicSalePriceUpdater } from '../hooks/sales/usePublicSalePriceUpdater';
import { usePublicSaleStatusToggler } from '../hooks/sales/usePublicSaleStatusToggler';

type Props = {
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const PublicSaleAdminSection = ({ nftCollection, refresh }: Props) => {
  const collectionChain = useChainInfo(Number(nftCollection.chainId));
  const contractAddress = nftCollection.contractAddress;
  const contractVersion = nftCollection.presetVersion;

  const {
    data: publicSaleStatus,
    error: publicSaleStatusError,
    isLoading: publicSaleStatusLoading,
  } = usePublicSaleStatus({
    contractVersion,
    contractAddress,
    watch: true,
  });

  const {
    data: publicSalePrice,
    error: publicSalePriceError,
    isLoading: publicSalePriceLoading,
  } = usePublicSalePrice({
    contractVersion,
    contractAddress,
    watch: true,
  });

  const {
    data: publicSaleMaxMintPerTx,
    error: publicSaleMaxMintPerTxError,
    isLoading: publicSaleMaxMintPerTxLoading,
  } = usePublicSaleMaxMintPerTx({
    contractVersion,
    contractAddress,
    watch: true,
  });

  const {
    data: publicSaleMaxMintPerTxUpdaterData,
    error: publicSaleMaxMintPerTxUpdaterError,
    isLoading: publicSaleMaxMintPerTxUpdaterLoading,
    writeAndWait: setPublicSaleSaleMaxMintPerTx,
  } = usePublicSaleMaxMintPerTxUpdater({
    contractVersion,
    contractAddress,
  });

  const {
    data: publicSalePriceUpdaterData,
    error: publicSalePriceUpdaterError,
    isLoading: publicSalePriceUpdaterLoading,
    writeAndWait: setPublicSalePrice,
  } = usePublicSalePriceUpdater({
    contractVersion,
    contractAddress,
  });

  const {
    data: publicSaleTogglerData,
    error: publicSaleTogglerError,
    isLoading: publicSaleTogglerLoading,
    writeAndWait: togglePublicSaleStatus,
  } = usePublicSaleStatusToggler({
    contractVersion,
    contractAddress,
  });

  const [priceDialogOpen, setPriceDialogOpen] = useState(false);
  const [maxPerTxDialogOpen, setMaxPerTxDialogOpen] = useState(false);

  return (
    <div className={'bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Public sale
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Control configuration for your collection public sale.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Public-sale state
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {publicSaleStatusLoading ||
              publicSalePriceLoading ||
              publicSaleTogglerLoading ? (
                <span className="mr-1">
                  <Spinner />
                </span>
              ) : null}
              {publicSaleStatus ? (
                <Badge color="green" text="Active" />
              ) : (
                <Badge color="yellow" text="Not active" />
              )}
              {publicSaleTogglerData.txReceipt ||
              publicSaleTogglerData.txResponse ? (
                <TransactionLink
                  txReceipt={publicSaleTogglerData.txReceipt}
                  txResponse={publicSaleTogglerData.txResponse}
                />
              ) : null}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Public-sale price
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <CryptoValue
                value={publicSalePrice?.toString()}
                unit={CryptoUnits.WEI}
                symbol={collectionChain?.nativeCurrency?.symbol as CryptoSymbol}
              />
              {publicSalePrice && (
                <SingleValueUpdaterButtonDialog
                  dialogTitle={'Update Public-sale Price'}
                  dialogDescription="This will be the price for remaining public-sale mints."
                  dialogOpen={priceDialogOpen}
                  setDialogOpen={setPriceDialogOpen}
                  inputLabel="New public-sale price"
                  inputType={'price'}
                  inputOnSubmit={(value) =>
                    setPublicSalePrice(value)
                      .then(() => setPriceDialogOpen(false))
                      .then(() => refresh())
                  }
                  inputDefaultValue={publicSalePrice}
                  inputArgs={{
                    symbol: collectionChain?.nativeCurrency
                      ?.symbol as CryptoSymbol,
                    unit: CryptoUnits.WEI,
                  }}
                  buttonLabel="Change price"
                  buttonDisabled={publicSalePriceUpdaterLoading}
                  data={publicSalePriceUpdaterData}
                  loading={publicSalePriceUpdaterLoading}
                  error={publicSalePriceUpdaterError}
                />
              )}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex items-center">
              Public-sale max per tx
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              {publicSaleMaxMintPerTx?.toString() || '...'}
              {publicSaleMaxMintPerTxLoading ? <Spinner /> : null} tokens /
              transaction
              {publicSaleMaxMintPerTx && (
                <SingleValueUpdaterButtonDialog
                  dialogTitle={'Update max mint per-wallet'}
                  dialogDescription="This will be applied to future minters and limits number of tokens they can mint during pre-sale."
                  dialogOpen={maxPerTxDialogOpen}
                  setDialogOpen={setMaxPerTxDialogOpen}
                  inputLabel="New per-tx limit"
                  inputType={'number'}
                  inputOnSubmit={(value) =>
                    setPublicSaleSaleMaxMintPerTx(value)
                      .then(() => setMaxPerTxDialogOpen(false))
                      .then(() => refresh())
                  }
                  inputDefaultValue={publicSaleMaxMintPerTx}
                  buttonLabel="Change per-transaction limit"
                  buttonDisabled={publicSaleMaxMintPerTxUpdaterLoading}
                  data={publicSaleMaxMintPerTxUpdaterData}
                  loading={publicSaleMaxMintPerTxUpdaterLoading}
                  error={publicSaleMaxMintPerTxUpdaterError}
                />
              )}
            </dd>
          </div>
        </dl>
        <div className="py-4 sm:py-5 sm:px-6">
          {publicSaleStatusError ? (
            <Errors error={publicSaleStatusError} />
          ) : null}
          {publicSaleMaxMintPerTxError ? (
            <Errors error={publicSaleMaxMintPerTxError} />
          ) : null}
          {publicSalePriceError ? (
            <Errors error={publicSalePriceError} />
          ) : null}
          {publicSaleTogglerError ? (
            <Errors error={publicSaleTogglerError} />
          ) : null}
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
        <Button
          className={PRIMARY_BUTTON}
          text={
            publicSaleStatus ? 'Deactivate Public-sale' : 'Activate Public-sale'
          }
          onClick={() => togglePublicSaleStatus([!publicSaleStatus])}
          disabled={Boolean(publicSaleTogglerLoading)}
        />
      </div>
    </div>
  );
};
