import {
  Badge,
  Button,
  CryptoSymbol,
  CryptoUnits,
  CryptoValue,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useChainInfo,
  usePreSaleMaxMintPerWallet,
  usePreSalePrice,
  usePreSaleStatus,
} from 'flair-sdk';
import { useState } from 'react';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { usePreSaleMaxMintPerWalletUpdater } from '../hooks/sales/usePreSaleMaxMintPerWalletUpdater';
import { usePreSalePriceUpdater } from '../hooks/sales/usePreSalePriceUpdater';
import { usePreSaleStatusToggler } from '../hooks/sales/usePreSaleStatusToggler';

type Props = {
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const PreSaleAdminSection = ({ nftCollection, refresh }: Props) => {
  const collectionChain = useChainInfo(Number(nftCollection.chainId));
  const contractAddress = nftCollection.contractAddress;
  const contractVersion = nftCollection.presetVersion;

  const {
    data: preSaleStatus,
    error: preSaleStatusError,
    isLoading: preSaleStatusLoading,
  } = usePreSaleStatus({
    contractVersion,
    contractAddress,
    watch: true,
  });

  const {
    data: preSalePrice,
    error: preSalePriceError,
    isLoading: preSalePriceLoading,
  } = usePreSalePrice({
    contractVersion,
    contractAddress,
    watch: true,
  });

  const {
    data: preSaleMaxMintPerWallet,
    error: preSaleMaxMintPerWalletError,
    isLoading: preSaleMaxMintPerWalletLoading,
  } = usePreSaleMaxMintPerWallet({
    contractVersion,
    contractAddress,
    watch: true,
  });

  const {
    data: preSaleMaxMintPerWalletUpdaterData,
    error: preSaleMaxMintPerWalletUpdaterError,
    isLoading: preSaleMaxMintPerWalletUpdaterLoading,
    writeAndWait: setPreSaleSaleMaxMintPerWallet,
  } = usePreSaleMaxMintPerWalletUpdater({
    contractVersion,
    contractAddress,
  });

  const {
    data: preSalePriceUpdaterData,
    error: preSalePriceUpdaterError,
    isLoading: preSalePriceUpdaterLoading,
    writeAndWait: setPreSalePrice,
  } = usePreSalePriceUpdater({
    contractVersion,
    contractAddress,
  });

  const {
    data: preSaleTogglerData,
    error: preSaleTogglerError,
    isLoading: preSaleTogglerLoading,
    writeAndWait: togglePreSaleStatus,
  } = usePreSaleStatusToggler({
    contractVersion,
    contractAddress,
  });

  const [priceDialogOpen, setPriceDialogOpen] = useState(false);
  const [maxPerWalletDialogOpen, setMaxPerWalletDialogOpen] = useState(false);

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Pre sale
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Manage state of pre-sale and price for each mint.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                Pre-sale state
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {preSaleStatusLoading ||
                preSalePriceLoading ||
                preSaleTogglerLoading ? (
                  <span className="mr-1">
                    <Spinner />
                  </span>
                ) : null}
                {preSaleStatus ? (
                  <Badge color="green" text="Active" />
                ) : (
                  <Badge color="yellow" text="Not active" />
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                Pre-sale price
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <CryptoValue
                  value={preSalePrice?.toString()}
                  unit={CryptoUnits.WEI}
                  symbol={
                    collectionChain?.nativeCurrency?.symbol as CryptoSymbol
                  }
                  fractionDigits={2}
                />
                {preSalePrice && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update Pre-sale Price'}
                    dialogDescription="This will be the price for remaining pre-sale mints."
                    dialogOpen={priceDialogOpen}
                    setDialogOpen={setPriceDialogOpen}
                    inputLabel="New pre-sale price"
                    inputType={'price'}
                    inputOnSubmit={(value) =>
                      setPreSalePrice(value)
                        .then(() => setPriceDialogOpen(false))
                        .then(() => refresh())
                    }
                    inputDefaultValue={preSalePrice}
                    inputArgs={{
                      symbol: collectionChain?.nativeCurrency
                        ?.symbol as CryptoSymbol,
                      unit: CryptoUnits.WEI,
                    }}
                    buttonLabel="Change price"
                    buttonDisabled={preSalePriceUpdaterLoading}
                    data={preSalePriceUpdaterData}
                    loading={preSalePriceUpdaterLoading}
                    error={preSalePriceUpdaterError}
                  />
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                Pre-sale max per wallet
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {preSaleMaxMintPerWallet?.toString() || '...'}
                {preSaleMaxMintPerWalletLoading ? <Spinner /> : null} tokens
                {preSaleMaxMintPerWallet && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update max mint per-wallet'}
                    dialogDescription="This will be applied to future minters and limits number of tokens they can mint during pre-sale."
                    dialogOpen={maxPerWalletDialogOpen}
                    setDialogOpen={setMaxPerWalletDialogOpen}
                    inputLabel="New per-wallet limit"
                    inputType={'number'}
                    inputOnSubmit={(value) =>
                      setPreSaleSaleMaxMintPerWallet(value)
                        .then(() => setMaxPerWalletDialogOpen(false))
                        .then(() => refresh())
                    }
                    inputDefaultValue={preSaleMaxMintPerWallet}
                    buttonLabel="Change per-wallet limit"
                    buttonDisabled={preSaleMaxMintPerWalletUpdaterLoading}
                    data={preSaleMaxMintPerWalletUpdaterData}
                    loading={preSaleMaxMintPerWalletUpdaterLoading}
                    error={preSaleMaxMintPerWalletUpdaterError}
                  />
                )}
              </dd>
            </div>
          </dl>
          <div className="py-4 sm:py-5 sm:px-6">
            {preSaleStatusError ? <Errors error={preSaleStatusError} /> : null}
            {preSalePriceError ? <Errors error={preSalePriceError} /> : null}
            {preSaleTogglerError ? (
              <Errors error={preSaleTogglerError} />
            ) : null}
            {preSaleMaxMintPerWalletError ? (
              <Errors error={preSaleMaxMintPerWalletError} />
            ) : null}
            {preSaleMaxMintPerWalletUpdaterError ? (
              <Errors error={preSaleMaxMintPerWalletUpdaterError} />
            ) : null}
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
          <span className="flex flex-grow gap-2 items-center">
            {preSaleTogglerLoading && (
              <>
                <Spinner /> Toggling...
              </>
            )}
            {preSaleTogglerData.txReceipt || preSaleTogglerData.txResponse ? (
              <TransactionLink
                txReceipt={preSaleTogglerData.txReceipt}
                txResponse={preSaleTogglerData.txResponse}
              />
            ) : null}
          </span>

          <Button
            className={PRIMARY_BUTTON}
            text={preSaleStatus ? 'Deactivate Pre-sale' : 'Activate Pre-sale'}
            onClick={() => togglePreSaleStatus([!preSaleStatus])}
            disabled={Boolean(preSaleTogglerLoading)}
          />
        </div>
      </div>
    </>
  );
};
