import { ContractFqn } from '@0xflair/contracts-registry';
import { utils } from 'ethers';
import {
  NftCollection,
  useUnorderedForwarderAddress,
  ZERO_ADDRESS,
} from 'flair-sdk';

import {
  ERC721OneOfOneCollectionConfig,
  ERC721OneOfOneCollectionConstructorArguments,
} from '../../../pages/NftCollections/use-cases/ERC721OneOfOne/types';
import {
  ERC721ManagedPrefixedCollectionConfig,
  ERC721ManagedPrefixedCollectionConstructorArguments,
} from '../types/ERC721ManagedPrefixedCollection.types';
import {
  ERC721SimpleSalesCollectionConfig,
  ERC721SimpleSalesCollectionConstructorArguments,
} from '../types/ERC721SimpleSalesCollection.types';
import {
  ERC721TieredSalesCollectionConfig,
  ERC721TieredSalesCollectionConstructorArguments,
} from '../types/ERC721TieredSalesCollection.types';

type Context = {
  forwarderAddress: ReturnType<typeof useUnorderedForwarderAddress>;
};

export const NftCollectionConstructorFactoryRegistry: Partial<
  Record<
    ContractFqn,
    (nftCollection: Partial<NftCollection>, context: Context) => any[]
  >
> = {
  'collections/ERC721/presets/ERC721SimpleSalesCollection': (
    nftCollection: Partial<NftCollection<ERC721SimpleSalesCollectionConfig>>,
    { forwarderAddress }: Context,
  ): ERC721SimpleSalesCollectionConstructorArguments => {
    return [
      {
        name: nftCollection.config?.collectionName as string,
        symbol: nftCollection.config?.collectionSymbol as string,
        contractURI: nftCollection.config?.collectionMetadataUri as string,
        placeholderURI:
          (nftCollection.config?.placeholderMetadataUri as string) || '',
        tokenURIPrefix: (nftCollection.config?.tokenURIPrefix as string) || '',
        maxSupply: Number(nftCollection.config?.maxSupply),
        preSalePrice: utils.parseEther(
          nftCollection.config?.preSalePrice || '0',
        ),
        preSaleMaxMintPerWallet: Number(nftCollection.config?.preSalePerWallet),
        publicSalePrice: utils.parseEther(
          nftCollection.config?.publicSalePrice || '0',
        ),
        publicSaleMaxMintPerTx: Number(nftCollection.config?.publicSalePerTx),
        proceedsRecipient: nftCollection.config?.royaltyAddress || ZERO_ADDRESS,
        defaultRoyaltyAddress:
          nftCollection.config?.royaltyAddress || ZERO_ADDRESS,
        defaultRoyaltyBps: (
          Number(nftCollection.config?.royaltyPercent) * 100
        ).toFixed(0),
        trustedForwarder: ZERO_ADDRESS,
        licenseVersion: nftCollection.config?.licenseVersion || 4,
      },
    ];
  },
  'collections/ERC721/presets/ERC721TieredSalesCollection': (
    nftCollection: Partial<NftCollection<ERC721TieredSalesCollectionConfig>>,
    { forwarderAddress }: Context,
  ): ERC721TieredSalesCollectionConstructorArguments => {
    return [
      {
        name: nftCollection.config?.collectionName as string,
        symbol: nftCollection.config?.collectionSymbol as string,
        contractURI: nftCollection.config?.collectionMetadataUri as string,
        placeholderURI:
          (nftCollection.config?.placeholderMetadataUri as string) || '',
        tokenURIPrefix: (nftCollection.config?.tokenURIPrefix as string) || '',
        maxSupply: Number(nftCollection.config?.maxSupply),
        tiers: nftCollection.config?.initialTiers
          ? Object.values(nftCollection.config?.initialTiers)
          : [],
        proceedsRecipient: nftCollection.config?.royaltyAddress || ZERO_ADDRESS,
        defaultRoyaltyAddress:
          nftCollection.config?.royaltyAddress || ZERO_ADDRESS,
        defaultRoyaltyBps: (
          Number(nftCollection.config?.royaltyPercent) * 100
        ).toFixed(0),
        trustedForwarder: ZERO_ADDRESS,
        licenseVersion: nftCollection.config?.licenseVersion || 4,
      },
    ];
  },
  'collections/ERC721/presets/ERC721ManagedPrefixedCollection': (
    nftCollection: Partial<
      NftCollection<ERC721ManagedPrefixedCollectionConfig>
    >,
    { forwarderAddress }: Context,
  ): ERC721ManagedPrefixedCollectionConstructorArguments => {
    return [
      {
        name: nftCollection.config?.collectionName as string,
        symbol: nftCollection.config?.collectionSymbol as string,
        contractURI: nftCollection.config?.collectionMetadataUri || '',
        tokenURIPrefix: nftCollection.config?.collectionBaseUri || '',
        placeholderURI: nftCollection.config?.placeholderMetadataUri || '',
        maxSupply:
          nftCollection.config?.maxSupply ||
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        initialAmounts: nftCollection.config?.initialAmounts || [],
        initialHolders: nftCollection.config?.initialHolders || [],

        defaultRoyaltyAddress:
          nftCollection.config?.royaltyAddress || ZERO_ADDRESS,
        defaultRoyaltyBps: (
          Number(nftCollection.config?.royaltyPercent || '0') * 100
        ).toFixed(0),

        trustedForwarder: ZERO_ADDRESS,
        licenseVersion: nftCollection.config?.licenseVersion || 4,
      },
    ];
  },
  'collections/ERC721/presets/ERC721OneOfOneCollection': (
    nftCollection: Partial<NftCollection<ERC721OneOfOneCollectionConfig>>,
    { forwarderAddress }: Context,
  ): ERC721OneOfOneCollectionConstructorArguments => {
    return [
      {
        name: nftCollection.config?.collectionName as string,
        symbol: nftCollection.config?.collectionSymbol as string,
        contractURI: nftCollection.config?.collectionMetadataUri as string,
        maxSupply:
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        defaultRoyaltyAddress:
          nftCollection.config?.royaltyAddress || ZERO_ADDRESS,
        defaultRoyaltyBps: (
          Number(nftCollection.config?.royaltyPercent) * 100
        ).toFixed(0),
        trustedForwarder: ZERO_ADDRESS,
        licenseVersion: nftCollection.config?.licenseVersion || 4,
      },
    ];
  },
};
