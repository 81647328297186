import {
  Environment,
  NftCollection,
  Spinner,
  useHasAnyOfFeatures,
} from 'flair-sdk';

import { UnsupportedFeatureWidget } from '../../../../components/UnsupportedFeatureWidget';
import { AdminMintWidget } from './minting/AdminMintWidget';
import { BackendMintWidget } from './minting/BackendMintWidget';
import { MintExamplesDeploy } from './minting/MintExamplesDeploy';
import { OneOfOneMintWidget } from './minting/OneOfOneMintWidget';
import { PublicMintWidget } from './minting/PublicMintWidget';

type Props = {
  env?: Environment;
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const MintingAdminSection = ({
  env,
  nftCollection,
  refresh: refreshCollection,
}: Props) => {
  const { data: hasOneOfOneFeatures } = useHasAnyOfFeatures({
    env,
    chainId: nftCollection.chainId,
    contractAddress: nftCollection.contractAddress,
    tags: ['mint_single_token_to_address_by_uri'],
  });
  const { data: hasSalesFeatures } = useHasAnyOfFeatures({
    env,
    chainId: nftCollection.chainId,
    contractAddress: nftCollection.contractAddress,
    tags: [
      'erc721_public_sale_extension',
      'erc721_presale_extension',
      'mint_amount_to_address',
      'public_sale_price',
      'presale_price',
      'erc721_tiering_extension',
      'mint_by_tier_with_allowance_and_proof',
    ],
  });
  const { data: hasPrivilegedMintingFeatures, isLoading } = useHasAnyOfFeatures(
    {
      env,
      chainId: nftCollection.chainId,
      contractAddress: nftCollection.contractAddress,
      tags: [
        'erc721_mint_owner_extension',
        'erc721_mint_role_based_extension',
        'mint_amount_to_address_as_role',
        'mint_amount_to_address_as_owner',
      ],
    },
  );

  return (
    <div className="flex flex-col gap-8">
      {hasPrivilegedMintingFeatures ? (
        <AdminMintWidget
          env={env}
          collection={nftCollection}
          refreshCollection={refreshCollection}
        />
      ) : isLoading ? (
        <UnsupportedFeatureWidget title="Admin minting">
          <div className="flex gap-2 mt-4">
            <Spinner /> Checking for admin minting capability...
          </div>
        </UnsupportedFeatureWidget>
      ) : null}
      {hasSalesFeatures ? (
        <>
          <MintExamplesDeploy
            env={env}
            collection={nftCollection}
            refreshCollection={refreshCollection}
          />
          <PublicMintWidget
            env={env}
            collection={nftCollection}
            refreshCollection={refreshCollection}
          />
        </>
      ) : isLoading ? (
        <UnsupportedFeatureWidget title="Public Sale Minting">
          <div className="flex gap-2 mt-4">
            <Spinner /> Checking for public minting capability...
          </div>
        </UnsupportedFeatureWidget>
      ) : null}
      {hasOneOfOneFeatures ? (
        <OneOfOneMintWidget
          env={env}
          collection={nftCollection}
          refreshCollection={refreshCollection}
        />
      ) : isLoading ? (
        <UnsupportedFeatureWidget title="1-of-1 Minting">
          <div className="flex gap-2 mt-4">
            <Spinner /> Checking for 1-of-1 minting capability...
          </div>
        </UnsupportedFeatureWidget>
      ) : null}
      <BackendMintWidget
        env={env}
        collection={nftCollection}
        refreshCollection={refreshCollection}
      />
    </div>
  );
};
