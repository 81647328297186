import {
  Badge,
  Errors,
  NftCollection,
  PRIMARY_BUTTON,
  Spinner,
  useDefaultRoyalty,
} from 'flair-sdk';
import { useState } from 'react';

import { DefaultRoyaltyUpdaterButtonDialog } from '../components/DefaultRoyaltyUpdater';
import { useDefaultRoyaltyUpdater } from '../hooks/royalty/useDefaultRoyaltyUpdater';

type Props = {
  nftCollection: NftCollection<any>;
  refresh: () => void;
};

export const RoyaltyAdminSection = ({ nftCollection, refresh }: Props) => {
  const {
    data: defaultRoyalty,
    error: defaultRoyaltyError,
    isLoading: defaultRoyaltyLoading,
  } = useDefaultRoyalty({
    contractVersion: nftCollection.presetVersion,
    contractAddress: nftCollection.contractAddress,
  });

  const {
    data: defaultRoyaltyUpdaterData,
    error: defaultRoyaltyUpdaterError,
    isLoading: defaultRoyaltyUpdaterLoading,
    writeAndWait: setDefaultRoyalty,
  } = useDefaultRoyaltyUpdater({
    contractVersion: nftCollection.presetVersion,
    contractAddress: nftCollection.contractAddress,
  });

  const [defaultRoyaltyDialog, setDefaultRoyaltyDialogOpen] = useState(false);

  return (
    <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Royalty</h3>
        <p className="mt-1 text-sm text-gray-500">
          Set secondary sales royalty for your collection on major marketplaces.
        </p>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex flex-col">
              On-chain royalty
              <small className="font-light text-xs italic">
                (Rarible, LooksRare, ERC2981)
              </small>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-start">
              {defaultRoyaltyLoading && (
                <>
                  <Spinner /> Loading...
                </>
              )}
              <Badge
                color="green"
                text={`${defaultRoyalty.percent}%` || '...'}
              />
              <span className="flex gap-2 items-center">
                Receiver: {defaultRoyalty.receiver || '...'}{' '}
              </span>
              <span>
                {defaultRoyaltyError && <Errors error={defaultRoyaltyError} />}
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              OpenSea royalty
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
              <i className="italic">
                OpenSea keeps secondary sales royalty off-chain, so you must
                update it from their interface.
                <ul className="list-decimal list-inside mt-4 text-gray-400 flex flex-col gap-2">
                  <li>Go to OpenSea https://opensea.io/collections</li>
                  <li>
                    Connect with deployer wallet (
                    <b className="font-bold">{nftCollection.deployerAddress}</b>
                    ).
                  </li>
                  <li>
                    Select your collection, then on top-right click on "Edit",
                    then scroll to "Creator Earnings".
                  </li>
                </ul>
              </i>
            </dd>
          </div>
        </dl>
        <div className="py-4 sm:py-5 sm:px-6"></div>
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse gap-1">
        <DefaultRoyaltyUpdaterButtonDialog
          dialogOpen={defaultRoyaltyDialog}
          setDialogOpen={setDefaultRoyaltyDialogOpen}
          inputOnSubmit={(receiver: string, percent: number) =>
            setDefaultRoyalty([[receiver, Number(percent || '0') * 100]])
              .then(() => setDefaultRoyaltyDialogOpen(false))
              .then(refresh)
          }
          defaultReceiver={defaultRoyalty.receiver}
          defaultPercent={defaultRoyalty.percent}
          buttonClassName={PRIMARY_BUTTON}
          buttonDisabled={defaultRoyaltyUpdaterLoading || defaultRoyaltyLoading}
          data={defaultRoyaltyUpdaterData}
          loading={defaultRoyaltyUpdaterLoading}
          error={defaultRoyaltyUpdaterError}
        />
      </div>
    </div>
  );
};
