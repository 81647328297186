import { Environment, useAxiosGet } from 'flair-sdk';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { FLAIR_FUNGIBLE_TOKENS_BACKEND } from '../constants';
import { FungibleToken } from '../types/index';

type Config = {
  env?: Environment;
  enabled?: boolean;
};

export function useFungibleTokens({
  env = Environment.PROD,
  enabled = true,
}: Config | undefined = {}) {
  const { data: account } = useAccount();

  const params = useMemo(() => {
    return {
      walletAddress: account?.address,
    };
  }, [account?.address]);

  const url = `${FLAIR_FUNGIBLE_TOKENS_BACKEND[env]}/v1/fungible-tokens`;

  return useAxiosGet<FungibleToken[]>({
    url,
    enabled: Boolean(enabled && account?.address),
    params,
  });
}
