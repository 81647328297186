import './index.css';

import { Buffer } from 'buffer';
import { Environment, FlairProvider, LoginProvider } from 'flair-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

// Workaround for a Webpack bug where it removed Buffer
window.Buffer = Buffer;

const appEnv = (process.env.REACT_APP_ENV as Environment) || Environment.PROD;

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <FlairProvider walletProviderProps={{ custodialWallet: true }}>
          <LoginProvider env={appEnv}>
            <App env={appEnv} />
          </LoginProvider>
        </FlairProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('flair-root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
