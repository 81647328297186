import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import {
  AddressList,
  Button,
  classNames,
  Environment,
  SECONDARY_BUTTON,
} from 'flair-sdk';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAddressLists } from '../../../features/address-lists';

type Props = {
  env?: Environment;
  selected?: AddressList;
  setSelected: (addressList: AddressList) => void;
  defaultValue?: (list: AddressList) => boolean;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  onButtonClick?: (selectedAddressList: AddressList) => void;
  children?: React.ReactNode;
};

export const AddressListSelector = ({
  env,
  defaultValue,
  selected,
  setSelected,
  buttonLabel,
  buttonDisabled,
  onButtonClick,
  children,
}: Props) => {
  const { data: addressLists, sendRequest: fetchList } = useAddressLists({
    env,
  });

  useEffect(() => {
    fetchList().then((response) => {
      const defaultSelected =
        response?.data?.find(defaultValue as any) || response?.data?.[0];

      if (defaultSelected) setSelected(defaultSelected);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center gap-2 flex-wrap">
      {addressLists && addressLists?.length > 0 ? (
        <>
          <Listbox
            value={selected}
            onChange={(addressList: AddressList<any>) => {
              setSelected(addressList);
            }}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span className="block truncate">
                      {selected?.name} ({selected?.totalAddressesCount || '0'}{' '}
                      total)
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {addressLists?.map((addressList) => (
                        <Listbox.Option
                          key={addressList._id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-indigo-600'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={addressList}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                )}
                              >
                                {addressList.name} (
                                {addressList.totalAddressesCount || '0'} total)
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {buttonLabel && (
            <Button
              text={buttonLabel}
              className={SECONDARY_BUTTON}
              disabled={buttonDisabled || !selected}
              onClick={() =>
                onButtonClick && onButtonClick(selected as AddressList)
              }
            />
          )}
        </>
      ) : (
        <>
          No list yet.
          <Link
            to={'/lists/create'}
            target={'_blank'}
            className="text-indigo-800"
          >
            Want to create a list?
          </Link>
        </>
      )}
      {children}
    </div>
  );
};
