import { utils } from 'ethers';
import {
  Button,
  CryptoUnits,
  CryptoValue,
  Environment,
  Errors,
  SECONDARY_BUTTON,
  TokenStream,
  useChainId,
  useERC20Symbol,
  useStreamEmissionAmountUntil,
  useStreamEmissionEnd,
  useStreamEmissionRate,
  useStreamEmissionStart,
  useStreamEmissionTimeUnit,
  useStreamReleasedAmountUntil,
} from 'flair-sdk';
import moment from 'moment';
import { useState } from 'react';

import { SingleValueUpdaterButtonDialog } from '../../../../components/ui/SingleValueUpdater';
import { dateForDateTimeInputValue } from '../../../../util';
import { useStreamEmissionEndUpdater } from '../hooks/useStreamEmissionEndUpdater';
import { useStreamEmissionRateUpdater } from '../hooks/useStreamEmissionRateUpdater';
import { useStreamEmissionStartUpdater } from '../hooks/useStreamEmissionStartUpdater';
import { useStreamEmissionTimeUnitUpdater } from '../hooks/useStreamEmissionTimeUnitUpdater';

type Props = {
  env?: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
};

export const EmissionAdminSection = ({ env, tokenStream, refresh }: Props) => {
  const chainId = useChainId(tokenStream.chainId);
  const contractAddress = tokenStream.contractAddress;
  const contractVersion = tokenStream.presetVersion;

  const [emissionStartDialogOpen, setEmissionStartDialogOpen] = useState(false);
  const [emissionEndDialogOpen, setEmissionEndDialogOpen] = useState(false);
  const [emissionRateDialogOpen, setEmissionRateDialogOpen] = useState(false);
  const [emissionTimeUnitDialogOpen, setEmissionTimeUnitDialogOpen] =
    useState(false);
  const [simulateTargetDate, setSimulateTargetDate] = useState(
    moment().add(5, 'days').toDate().toISOString().slice(0, -8),
  );

  const {
    data: emissionStart,
    error: emissionStartError,
    isLoading: emissionStartLoading,
  } = useStreamEmissionStart({
    env,
    chainId,
    contractAddress,
  });

  const {
    data: emissionStartUpdaterData,
    error: emissionStartUpdaterError,
    isLoading: emissionStartUpdaterLoading,
    writeAndWait: setEmissionStart,
  } = useStreamEmissionStartUpdater({
    contractAddress,
    contractVersion,
  });

  const {
    data: emissionEnd,
    error: emissionEndError,
    isLoading: emissionEndLoading,
  } = useStreamEmissionEnd({
    env,
    chainId,
    contractAddress,
  });

  const {
    data: emissionEndUpdaterData,
    error: emissionEndUpdaterError,
    isLoading: emissionEndUpdaterLoading,
    writeAndWait: setEmissionEnd,
  } = useStreamEmissionEndUpdater({
    contractAddress,
    contractVersion,
  });

  const { data: emissionRate } = useStreamEmissionRate({
    env,
    chainId,
    contractAddress,
  });
  const {
    data: emissionRateUpdaterData,
    error: emissionRateUpdaterError,
    isLoading: emissionRateUpdaterLoading,
    writeAndWait: setEmissionRate,
  } = useStreamEmissionRateUpdater({
    contractAddress,
    contractVersion,
  });

  const { data: emissionTimeUnit } = useStreamEmissionTimeUnit({
    env,
    chainId,
    contractAddress,
  });
  const {
    data: emissionTimeUnitUpdaterData,
    error: emissionTimeUnitUpdaterError,
    isLoading: emissionTimeUnitUpdaterLoading,
    writeAndWait: setEmissionTimeUnit,
  } = useStreamEmissionTimeUnitUpdater({
    contractAddress,
    contractVersion,
  });

  const { data: primaryClaimTokenSymbol } = useERC20Symbol({
    contractAddress: tokenStream?.config?.primaryClaimToken,
  });

  const { data: simulateReleasedAmount } = useStreamReleasedAmountUntil({
    env,
    chainId,
    contractAddress,
    args: {
      calculateUntil: simulateTargetDate
        ? moment(simulateTargetDate).toDate().getTime() / 1000
        : undefined,
    },
  });

  const { data: simulateEmissionAmount } = useStreamEmissionAmountUntil({
    env,
    chainId,
    contractAddress,
    args: {
      calculateUntil: simulateTargetDate
        ? moment(simulateTargetDate).toDate().getTime() / 1000
        : undefined,
    },
  });

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Emission
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Manage when and how much your NFT holders can claim tokens.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:py-5 grid grid-cols-3 gap-2 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Starts at
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {!emissionStartLoading && emissionStart
                  ? new Date(
                      Number(emissionStart?.toString()) * 1000,
                    ).toLocaleString()
                  : '...'}
              </dd>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {emissionStart && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update Emission Start'}
                    dialogDescription="This will change emitted rewards and also when holders can start claiming."
                    dialogOpen={emissionStartDialogOpen}
                    setDialogOpen={setEmissionStartDialogOpen}
                    inputLabel="New emission start"
                    inputType={'datetime'}
                    inputOnSubmit={(value) => {
                      return setEmissionStart([
                        Math.floor(new Date(value).getTime() / 1000),
                      ])
                        .then(() => setEmissionStartDialogOpen(false))
                        .then(() => refresh());
                    }}
                    inputDefaultValue={dateForDateTimeInputValue(
                      new Date(Number(emissionStart?.toString()) * 1000),
                    )}
                    buttonLabel="Change emission start"
                    buttonDisabled={emissionStartUpdaterLoading}
                    data={emissionStartUpdaterData}
                    loading={emissionStartUpdaterLoading}
                    error={emissionStartUpdaterError}
                  />
                )}
              </dd>
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Ends at
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {!emissionEndLoading && Number(emissionEnd?.toString()) > 0
                  ? new Date(
                      Number(emissionEnd?.toString()) * 1000,
                    ).toUTCString()
                  : emissionEnd?.toString() === '0'
                  ? 'Never'
                  : '...'}
              </dd>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {emissionEnd && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update Emission End'}
                    dialogDescription="This will change emitted rewards and also when holders can start claiming."
                    dialogOpen={emissionEndDialogOpen}
                    setDialogOpen={setEmissionEndDialogOpen}
                    inputLabel="New emission start"
                    inputType={'datetime'}
                    inputOnSubmit={(value) =>
                      setEmissionEnd([
                        Math.floor(new Date(value).getTime() / 1000),
                      ])
                        .then(() => setEmissionEndDialogOpen(false))
                        .then(() => refresh())
                    }
                    inputDefaultValue={new Date(
                      Number(emissionEnd?.toString()) * 1000,
                    )
                      .toISOString()
                      .slice(0, -1)}
                    buttonLabel="Change emission end"
                    buttonDisabled={emissionEndUpdaterLoading}
                    data={emissionEndUpdaterData}
                    loading={emissionEndUpdaterLoading}
                    error={emissionEndUpdaterError}
                  />
                )}
                <Button
                  text={'Remove'}
                  disabled={emissionEndUpdaterLoading}
                  className={SECONDARY_BUTTON}
                  onClick={() => setEmissionEnd(['0']).then(() => refresh())}
                />
              </dd>
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Rate
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                <CryptoValue
                  value={emissionRate}
                  symbol={primaryClaimTokenSymbol?.toString()}
                  unit={CryptoUnits.WEI}
                  showPrice={false}
                />
              </dd>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {emissionRate && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update Emission Rate'}
                    dialogDescription={`How many ERC20 tokens are emitted every ${
                      Number(emissionTimeUnit?.toString()) / 3600
                    } hours.`}
                    dialogOpen={emissionRateDialogOpen}
                    setDialogOpen={setEmissionRateDialogOpen}
                    inputLabel="New emission rate"
                    inputType={'number'}
                    inputOnSubmit={(value) => {
                      if (isNaN(Number(value))) return;

                      setEmissionRate([utils.parseEther(value || '0')])
                        .then(() => setEmissionRateDialogOpen(false))
                        .then(() => refresh());
                    }}
                    inputDefaultValue={utils.formatEther(emissionRate)}
                    buttonLabel="Change rate"
                    buttonDisabled={emissionRateUpdaterLoading}
                    data={emissionRateUpdaterData}
                    loading={emissionRateUpdaterLoading}
                    error={emissionRateUpdaterError}
                  />
                )}
              </dd>
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Time unit
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {Number(emissionTimeUnit?.toString()) / 3600} hour(s)
              </dd>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                {emissionTimeUnit && (
                  <SingleValueUpdaterButtonDialog
                    dialogTitle={'Update Emission Time Unit'}
                    dialogDescription="How often are ERC20 tokens emitted (in hours unit). Due to nature of blockchains, if you set lower than 1 hour you might get unpredictable results."
                    dialogOpen={emissionTimeUnitDialogOpen}
                    setDialogOpen={setEmissionTimeUnitDialogOpen}
                    inputLabel="New time unit"
                    inputType={'number'}
                    inputOnSubmit={(value) =>
                      setEmissionTimeUnit([Math.floor(value * 3600)])
                        .then(() => setEmissionTimeUnitDialogOpen(false))
                        .then(() => refresh())
                    }
                    inputDefaultValue={Math.floor(
                      Number(emissionTimeUnit.toString()) / 3600,
                    )}
                    inputArgs={{ min: '0.4' }}
                    buttonLabel="Change time unit"
                    buttonDisabled={emissionTimeUnitUpdaterLoading}
                    data={emissionTimeUnitUpdaterData}
                    loading={emissionTimeUnitUpdaterLoading}
                    error={emissionTimeUnitUpdaterError}
                  />
                )}
              </dd>
            </div>
          </dl>
          <div className="py-4 sm:py-5 sm:px-6">
            {emissionStartError ? (
              <Errors title="emissionStartError" error={emissionStartError} />
            ) : null}
            {emissionStartUpdaterError ? (
              <Errors
                title="emissionStartUpdaterError"
                error={emissionStartUpdaterError}
              />
            ) : null}
            {emissionEndError ? (
              <Errors title="emissionEndError" error={emissionEndError} />
            ) : null}
            {emissionEndUpdaterError ? (
              <Errors
                title="emissionEndUpdaterError"
                error={emissionEndUpdaterError}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Simulate
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Calculate how much tokens will be released to all NFTs over time.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:py-5 grid grid-cols-3 gap-2 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Target date
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-1 flex gap-2 items-center">
                <input
                  type="datetime-local"
                  step="60"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={simulateTargetDate}
                  onChange={(e) => setSimulateTargetDate(e.target.value)}
                />
              </dd>
            </div>
          </dl>
          <dl>
            <div className="sm:py-5 grid grid-cols-3 gap-2 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Emission amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-2 flex gap-2 items-center">
                <CryptoValue
                  value={simulateEmissionAmount?.toString()}
                  symbol={primaryClaimTokenSymbol?.toString()}
                  unit={CryptoUnits.WEI}
                  showPrice={false}
                />
              </dd>
              <p className="text-sm text-gray-400 col-span-3">
                This is the amount emitted for the users.
              </p>
              <dt className="text-sm font-medium text-gray-500 col-span-3 sm:col-span-1 flex items-center">
                Released amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-3 sm:col-span-2 flex gap-2 items-center">
                <CryptoValue
                  value={simulateReleasedAmount?.toString()}
                  symbol={primaryClaimTokenSymbol?.toString()}
                  unit={CryptoUnits.WEI}
                  showPrice={false}
                />
              </dd>
              <p className="text-sm text-gray-400 col-span-3">
                This is the amount users can claim and receive.
              </p>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
