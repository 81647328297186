import { BytesLike } from 'ethers';
import {
  Badge,
  Button,
  CryptoSymbol,
  CryptoUnits,
  CryptoValue,
  Environment,
  Errors,
  PRIMARY_BUTTON,
  Spinner,
  TransactionLink,
  useOzOwner,
  useTokenBalances,
  ZERO_ADDRESS,
} from 'flair-sdk';
import { useCallback, useState } from 'react';
import { useAccount, useBalance, useNetwork } from 'wagmi';

import { SingleValueUpdaterButtonDialog } from '../../../components/ui/SingleValueUpdater';
import { useWithdraw } from '../hooks/useWithdraw';
import { useWithdrawByTokens } from '../hooks/useWithdrawByTokens';
import { useWithdrawRecipient } from '../hooks/useWithdrawRecipient';
import { useWithdrawRecipientLocker } from '../hooks/useWithdrawRecipientLocker';
import { useWithdrawRecipientLockStatus } from '../hooks/useWithdrawRecipientLockStatus';
import { useWithdrawRecipientUpdater } from '../hooks/useWithdrawRecipientUpdater';

type Props = {
  env?: Environment;
  chainId?: number;
  contractAddress?: string;
  refresh: () => void;
};

export const WithdrawAdminSection = ({
  env,
  chainId,
  contractAddress,
  refresh,
}: Props) => {
  const { activeChain } = useNetwork();
  const { data: account } = useAccount();

  const {
    data: simpleWithdrawData,
    error: simpleWithdrawError,
    isLoading: simpleWithdrawLoading,
    writeAndWait: simpleWithdraw,
  } = useWithdraw({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: withdrawByTokensSupported,
    data: withdrawByTokensData,
    error: withdrawByTokensError,
    isLoading: withdrawByTokensLoading,
    writeAndWait: withdrawByTokens,
  } = useWithdrawByTokens({
    env,
    chainId,
    contractAddress,
  });

  const { data: streamERC20Balances, isLoading: streamERC20BalancesLoading } =
    useTokenBalances({
      env,
      chainId,
      address: contractAddress,
    });

  const { data: streamNativeBalance } = useBalance({
    chainId,
    addressOrName: contractAddress,
  });

  const {
    data: ownerAddress,
    error: ownerError,
    isLoading: ownerLoading,
  } = useOzOwner({
    contractAddress,
  });

  const {
    data: withdrawRecipient,
    isSupported: withdrawRecipientSupported,
    error: withdrawRecipientError,
  } = useWithdrawRecipient({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: withdrawRecipientUpdaterSupported,
    data: withdrawRecipientUpdaterData,
    error: withdrawRecipientUpdaterError,
    isLoading: withdrawRecipientUpdaterLoading,
    writeAndWait: setProceedsRecipient,
  } = useWithdrawRecipientUpdater({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: withdrawRecipientLockStatusSupported,
    data: withdrawRecipientLockStatus,
    error: withdrawRecipientLockStatusError,
    isLoading: withdrawRecipientLockStatusLoading,
  } = useWithdrawRecipientLockStatus({
    env,
    chainId,
    contractAddress,
  });

  const {
    isSupported: withdrawRecipientLockerSupported,
    data: withdrawRecipientLockerData,
    error: withdrawRecipientLockerError,
    isLoading: withdrawRecipientLockerLoading,
    writeAndWait: lockProceedsRecipient,
  } = useWithdrawRecipientLocker({
    env,
    chainId,
    contractAddress,
  });

  const [recipientDialogOpen, setRecipientDialogOpen] = useState(false);

  const withdrawAll = useCallback(async () => {
    if (withdrawByTokensSupported) {
      const tokenAddresses = [
        // always include native currency
        ZERO_ADDRESS,
        ...(streamERC20Balances?.map((balance) => balance.tokenAddress) || []),
      ];

      const amounts = [
        // always include native currency
        streamNativeBalance?.value || 0,
        ...(streamERC20Balances?.map((b) => b.balance) || []),
      ];

      await withdrawByTokens({
        tokenAddresses,
        amounts,
      });
    } else {
      await simpleWithdraw([]);
    }
  }, [
    simpleWithdraw,
    streamERC20Balances,
    streamNativeBalance,
    withdrawByTokens,
    withdrawByTokensSupported,
  ]);

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Withdraw
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Pull any tokens from the contract, for example sales proceeds, or
            remaining staking supply.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Available to withdraw
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-start">
                <CryptoValue
                  symbol={activeChain?.nativeCurrency?.symbol as CryptoSymbol}
                  value={streamNativeBalance?.value}
                  unit={CryptoUnits.WEI}
                />
                {withdrawByTokensSupported ? (
                  <div className="text-gray-700 flex gap-2 flex-wrap">
                    {streamERC20BalancesLoading ? <Spinner /> : null}
                    {streamERC20Balances?.map((b) => (
                      <a
                        target={'_blank'}
                        href={`${activeChain?.blockExplorers?.default.url}/token/${b.tokenAddress}?a=${account?.address}`}
                        rel="noreferrer"
                      >
                        <CryptoValue
                          symbol={b.symbol as CryptoSymbol}
                          value={b.balance}
                          unit={CryptoUnits.WEI}
                          showPrice={false}
                        />
                      </a>
                    ))}
                  </div>
                ) : null}
              </dd>
            </div>
          </dl>
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Recipient</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {withdrawRecipientSupported
                  ? `${withdrawRecipient}`
                  : `${ownerAddress} (owner)`}
                {withdrawRecipientLockStatusSupported &&
                withdrawRecipientLockStatus
                  ? ` (locked)`
                  : ''}
                {withdrawRecipientUpdaterSupported &&
                  withdrawRecipient &&
                  (!withdrawRecipientLockStatus ||
                    !withdrawRecipientLockStatusSupported) && (
                    <SingleValueUpdaterButtonDialog
                      dialogTitle={'Update withdraw recipient'}
                      dialogDescription="The address to receive the tokens when Withdraw method is called."
                      dialogOpen={recipientDialogOpen}
                      setDialogOpen={setRecipientDialogOpen}
                      inputLabel="New recipient"
                      inputType={'address'}
                      inputOnSubmit={(value: BytesLike) =>
                        setProceedsRecipient({ recipient: value })
                          .then(() => setRecipientDialogOpen(false))
                          .then(() => refresh())
                      }
                      inputDefaultValue={withdrawRecipient}
                      buttonLabel="Change recipient"
                      buttonDisabled={withdrawRecipientUpdaterLoading}
                      data={withdrawRecipientUpdaterData}
                      loading={withdrawRecipientUpdaterLoading}
                      error={withdrawRecipientUpdaterError}
                    />
                  )}
              </dd>
            </div>
          </dl>
          <div className="py-4 sm:py-5 sm:px-6">
            {simpleWithdrawError ? (
              <Errors title="simpleWithdrawError" error={simpleWithdrawError} />
            ) : null}
            {withdrawByTokensError ? (
              <Errors
                title="withdrawByTokensError"
                error={withdrawByTokensError}
              />
            ) : null}
            {withdrawRecipientUpdaterError ? (
              <Errors
                title="withdrawRecipientUpdaterError"
                error={withdrawRecipientUpdaterError}
              />
            ) : null}
            {withdrawRecipientError ? (
              <Errors
                title="withdrawRecipientError"
                error={withdrawRecipientError}
              />
            ) : null}
            {ownerError ? (
              <Errors title="ownerError" error={ownerError} />
            ) : null}
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
          <span className="flex flex-grow gap-2 items-center">
            {ownerLoading ? (
              <>
                <Spinner /> Fetching...
              </>
            ) : null}
            {simpleWithdrawLoading && (
              <>
                <Spinner /> Withdrawing...
              </>
            )}
            {withdrawByTokensLoading && (
              <>
                <Spinner /> Withdrawing...
              </>
            )}
            {simpleWithdrawData.txReceipt || simpleWithdrawData.txResponse ? (
              <TransactionLink
                txReceipt={simpleWithdrawData.txReceipt}
                txResponse={simpleWithdrawData.txResponse}
              />
            ) : null}
            {withdrawByTokensData.txReceipt ||
            withdrawByTokensData.txResponse ? (
              <TransactionLink
                txReceipt={withdrawByTokensData.txReceipt}
                txResponse={withdrawByTokensData.txResponse}
              />
            ) : null}
          </span>

          <Button
            className={PRIMARY_BUTTON}
            text={'Withdraw all'}
            onClick={() => withdrawAll()}
            disabled={Boolean(simpleWithdrawLoading || withdrawByTokensLoading)}
          />
        </div>
      </div>

      <div className={'relative bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Lock Recipient
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            To increase trust you can lock the recipient address. Nobody can
            change the address after that.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Current state
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {withdrawRecipientLockStatusLoading && (
                  <>
                    <Spinner /> Loading...
                  </>
                )}
                {!withdrawRecipientLockStatusLoading &&
                  !withdrawRecipientLockStatusError &&
                  withdrawRecipientLockStatusSupported &&
                  !withdrawRecipientLockStatus && (
                    <Badge
                      color="yellow"
                      text={`Recipient is not locked and can be changed by owner`}
                    />
                  )}
                {!withdrawRecipientLockStatusLoading &&
                  !withdrawRecipientLockStatusError &&
                  withdrawRecipientLockStatusSupported &&
                  withdrawRecipientLockStatus && (
                    <Badge
                      color="green"
                      text={`Recipient is locked and cannot change by anyone`}
                    />
                  )}
                {!withdrawRecipientLockStatusLoading &&
                  !withdrawRecipientLockStatusError &&
                  !withdrawRecipientLockStatusSupported && (
                    <Badge
                      color="gray"
                      text={`Cannot determine if recipient is locked (an older version?)`}
                    />
                  )}
              </dd>
            </div>
          </dl>
        </div>
        <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row gap-1">
          <span className="flex flex-grow gap-2 items-center">
            {withdrawRecipientLockerLoading && (
              <>
                <Spinner /> Locking recipient...
              </>
            )}
            {withdrawRecipientLockerData.txReceipt ||
            withdrawRecipientLockerData.txResponse ? (
              <TransactionLink
                txReceipt={withdrawRecipientLockerData.txReceipt}
                txResponse={withdrawRecipientLockerData.txResponse}
              />
            ) : null}
            {withdrawRecipientLockerError && (
              <Errors error={withdrawRecipientLockerError} />
            )}
          </span>
          <Button
            className={PRIMARY_BUTTON}
            text={'Lock recipient'}
            onClick={() => lockProceedsRecipient().then(refresh)}
            disabled={
              withdrawRecipientLockerLoading ||
              withdrawRecipientLockStatusLoading ||
              (withdrawRecipientLockerSupported && withdrawRecipientLockStatus)
            }
          />
        </div>
      </div>
    </>
  );
};
