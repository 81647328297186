import { TransactionReceipt } from '@ethersproject/providers';
import * as ethers from 'ethers';
import { extractHumanReadableError } from 'flair-sdk';

import {
  ERC721OneOfOneCollectionConfig,
  ERC721OneOfOneCollectionConstructorArguments,
  ERC721OneOfOneCollectionCreationState,
} from './types';

export const isValidAddress = (address?: string) => {
  if (!address) {
    return false;
  }

  try {
    ethers.utils.getAddress(address);
  } catch (error) {
    return false;
  }

  return true;
};

export function validateIfCanSetupAndDeploy(
  creationState: ERC721OneOfOneCollectionCreationState,
  deployError?: Error | undefined,
  deployLoading?: boolean | undefined,
  txError?: Error | undefined,
  txLoading?: boolean | undefined,
  txData?: TransactionReceipt,
): [canSetup: boolean, canDeploy: boolean, reason: string | undefined] {
  if (deployError) {
    return [true, true, extractHumanReadableError(deployError)];
  }

  if (deployLoading || txLoading) {
    return [false, false, undefined];
  }

  if (txError) {
    return [true, true, extractHumanReadableError(txError)];
  }

  if (txLoading) {
    return [false, false, undefined];
  }

  if (txData && txData?.transactionHash) {
    return [false, false, undefined];
  }

  if (!creationState.collectionName) {
    return [false, false, 'Collection name is required'];
  }

  if (!creationState.collectionSymbol) {
    return [false, false, 'Collection symbol is required'];
  }

  if (!creationState.collectionImageFile) {
    return [false, false, 'Collection image is required'];
  }

  if (
    !creationState.royaltyAddress ||
    !isValidAddress(creationState.royaltyAddress)
  ) {
    return [false, false, 'Provide a valid royalty address'];
  }

  if (!creationState.collectionImageUri) {
    return [true, false, 'Collection image must be uploaded to IPFS'];
  }

  if (!creationState.collectionMetadataUri) {
    return [true, false, 'Collection metadata must be uploaded to IPFS'];
  }

  return [true, true, undefined];
}

export function generateManualDeploymentCode(
  config: ERC721OneOfOneCollectionConfig,
  constructorArguments: ERC721OneOfOneCollectionConstructorArguments,
) {
  const manualDeploymentCode = `// SPDX-License-Identifier: MIT
pragma solidity ^0.8.9;

import "@0xflair/evm-contracts/collections/ERC721/presets/ERC721OneOfOneCollection.sol";

contract MyCollection is ERC721OneOfOneCollection {
  constructor()
    ERC721OneOfOneCollection(
      Config({
        name: "${constructorArguments[0]?.name}",
        symbol: "${constructorArguments[0]?.symbol}",
        contractURI: "${constructorArguments[0]?.contractURI}",
        maxSupply: uint256(${constructorArguments[0]?.maxSupply}),
        defaultRoyaltyAddress: address(${constructorArguments[0]?.defaultRoyaltyAddress}),
        defaultRoyaltyBps: ${constructorArguments[0]?.defaultRoyaltyBps},
        trustedForwarder: ${constructorArguments[0]?.trustedForwarder}
      })
    )
  {}
}`;

  return manualDeploymentCode;
}
