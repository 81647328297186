import { ContractVersion } from '@0xflair/contracts-registry';
import { useContractAbi, useContractWriteAndWait } from '@0xflair/react-common';
import { BytesLike } from '@ethersproject/bytes';
import { Provider } from '@ethersproject/providers';
import { Signer } from 'ethers';
import { ZERO_BYTES32 } from 'flair-sdk';

type Config = {
  contractVersion?: ContractVersion;
  contractAddress?: string;
  signerOrProvider?: Signer | Provider | null;
  forwarderAddress?: BytesLike;
};

type ArgsType = [BytesLike];

export const useTrustedForwarderUpdater = ({
  contractVersion,
  contractAddress,
  signerOrProvider,
  forwarderAddress,
}: Config) => {
  const contractInterface = useContractAbi({
    contractVersion,
    contractFqn: 'common/meta-transactions/ERC2771ContextOwnable',
  });

  return useContractWriteAndWait<ArgsType>({
    contractInterface,
    contractAddress,
    signerOrProvider,
    functionName: 'setTrustedForwarder',
    args: (forwarderAddress ? [forwarderAddress] : [ZERO_BYTES32]) as ArgsType,
  });
};
