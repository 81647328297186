import { BigNumberish, BytesLike, utils } from 'ethers';
import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  LATEST_VERSION,
  PRIMARY_BUTTON,
  Spinner,
  useLoginJwt,
  useMaxSupply,
  useStickyState,
  useTokenStreamCreator,
  useTotalSupply,
} from 'flair-sdk';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ERC721EqualEmissionStreamConfig } from '../../../features/token-streams/latest/types/ERC721EqualEmissionStream.types';

export type ERC721SingleTokenEqualEmissionStreamConfig = {};

export type ERC721SingleTokenEqualEmissionStreamCreationState = {
  name?: string;
  nameDifferent?: boolean;
  publicTitle?: string;
  ticketToken: BytesLike;
  initialTotalTickets?: BigNumberish;
  initialLockedUntilTimestamp?: BigNumberish;
  initialEmissionRateEther?: number;
  initialEmissionTimeUnitHours?: number;
  initialEmissionStartDate?: string;
  initialEmissionEndDate?: string;
  initialClaimLockedUntil?: BigNumberish;
  primaryClaimToken?: string;
};

type Props = {
  env?: Environment;
};

const initialState: ERC721SingleTokenEqualEmissionStreamCreationState = {
  name: '',
  publicTitle: '',
  ticketToken: '',
  primaryClaimToken: '',
  initialTotalTickets: '',
  initialLockedUntilTimestamp: '0',
  initialEmissionRateEther: 1000,
  initialEmissionTimeUnitHours: 1,
  initialEmissionStartDate: moment().toISOString().slice(0, -5),
  initialEmissionEndDate: '',
  initialClaimLockedUntil: '0',
};

export const CreateERC721SingleTokenEqualEmissionStream = ({
  env = Environment.PROD,
}: Props) => {
  const loginJwt = useLoginJwt();
  const navigate = useNavigate();

  const [creationState, setCreationState] =
    useStickyState<ERC721SingleTokenEqualEmissionStreamCreationState>(
      initialState,
      'erc721-single-token-equal-emission-stream-creation',
    );

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: createTokenStream,
  } = useTokenStreamCreator(
    {
      name: creationState.name,
      publicTitle: creationState.publicTitle,
      presetFqn: 'streams/ERC721/presets/ERC721EqualEmissionStream',
      presetVersion: LATEST_VERSION,
      config: {
        primaryClaimToken: creationState.primaryClaimToken,
        ticketToken: creationState.ticketToken,
        initialTotalTickets: creationState.initialTotalTickets,
        initialLockedUntilTimestamp: creationState.initialLockedUntilTimestamp,
        initialEmissionRate: utils.parseEther(
          creationState.initialEmissionRateEther?.toString() || '1',
        ),
        initialEmissionTimeUnit:
          (creationState.initialEmissionTimeUnitHours || 1) * 60 * 60,
        initialEmissionStart: Math.floor(
          (creationState.initialEmissionStartDate
            ? new Date(creationState.initialEmissionStartDate)
            : new Date()
          ).getTime() / 1000,
        ),
        initialEmissionEnd: Math.floor(
          (creationState.initialEmissionEndDate &&
          Number(creationState.initialEmissionEndDate) > 0
            ? new Date(creationState.initialEmissionEndDate).getTime()
            : 0) / 1000,
        ),
        initialClaimLockedUntil: '0',
      } as ERC721EqualEmissionStreamConfig,
    },
    {
      env,
      enabled: false,
      loginJwt,
    },
  );

  const { data: ticketTokenTotalSupply } = useTotalSupply({
    contractAddress: creationState.ticketToken.toString(),
    enabled: Boolean(creationState.ticketToken.toString()),
  });

  const { data: ticketTokenMaxSupply } = useMaxSupply({
    contractAddress: creationState.ticketToken.toString(),
    enabled: Boolean(creationState.ticketToken.toString()),
  });

  const suggestedTotalTickets = Math.max(
    Number(ticketTokenTotalSupply?.toString() || 0),
    Number(ticketTokenMaxSupply?.toString() || 0),
  );

  useMemo(() => {
    if (creationState.ticketToken.toString() && suggestedTotalTickets) {
      setCreationState((x) => ({
        ...x,
        initialTotalTickets: suggestedTotalTickets,
      }));

      return suggestedTotalTickets;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationState.ticketToken]);

  const metadataSectionView = (
    <FormSection title="General" description="Information about your stream.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title{' '}
            <span className="text-gray-500 text-xs font-light">(optional)</span>
          </label>
          <input
            type="text"
            name="title"
            id="title"
            autoComplete="publicTitle"
            placeholder="Angel Rewards"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.publicTitle}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                publicTitle: event.target.value,
                ...(!x.nameDifferent ? { name: event.target.value } : {}),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            An optional public name possibly visible in wallets and
            marketplaces.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="privateName"
            className="block text-sm font-medium text-gray-700"
          >
            Private Name
          </label>
          <input
            type="text"
            name="privateName"
            id="privateName"
            autoComplete="privateName"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Angels Rewards Phase 1"
            value={creationState.name}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                name: event.target.value,
                nameDifferent: event.target.value !== x.publicTitle,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A private name only to visible you in the dashboard.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const tokensSectionView = (
    <FormSection
      title="Tokens"
      description="Configure desired ERC20 and ERC721 contract addresses. Can only be changed before deploying the contract."
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="claimToken"
            className="block text-sm font-medium text-gray-700"
          >
            Claim Token Address
          </label>
          <input
            type="text"
            name="claimToken"
            id="claimToken"
            autoComplete="tokenAddress"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="0x......"
            value={creationState.primaryClaimToken}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                primaryClaimToken: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Address for the ERC20 token you want to put in this pool to be
            claimed by NFT holders.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Wanna create a{' '}
            <Link
              target={'_blank'}
              to={`/tokens/create`}
              className="text-indigo-600"
            >
              new token now
            </Link>
            ?
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="ticketToken"
            className="block text-sm font-medium text-gray-700"
          >
            NFT Contract Address
          </label>
          <input
            type="text"
            name="ticketToken"
            id="ticketToken"
            autoComplete="nftAddress"
            placeholder="0x......."
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.ticketToken.toString()}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                ticketToken: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Contract address of the ERC721 NFT collection. Only holders of this
            collection can claim the emitted tokens.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Wanna create a{' '}
            <Link
              target={'_blank'}
              to={`/collections/create`}
              className="text-indigo-600"
            >
              new NFT collection now
            </Link>
            ?
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="initialTotalTickets"
            className="block text-sm font-medium text-gray-700"
          >
            Total NFTs
          </label>
          <input
            type="text"
            name="initialTotalTickets"
            id="initialTotalTickets"
            autoComplete="nftSupply"
            placeholder={suggestedTotalTickets?.toString() || '10000'}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.initialTotalTickets?.toString()}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialTotalTickets: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Total number of NFT tokens available in the collection. This will
            help calculate how much each NFT will get from the token emission.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const emissionSectionView = (
    <FormSection
      title="Emission"
      description="Configure when and how much tokens to distribute to NFT holders. You as contract owner can update these values even after stream is running."
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="claimStart"
            className="block text-sm font-medium text-gray-700"
          >
            Start at
          </label>
          <input
            type="datetime-local"
            name="claimStart"
            id="claimStart"
            autoComplete="emissionStart"
            step="60"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="1"
            value={creationState.initialEmissionStartDate}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionStartDate: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            When to start the emission. Holder can only claim after this time.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="emissionRate"
            className="block text-sm font-medium text-gray-700"
          >
            Emission Rate
          </label>
          <input
            type="number"
            name="emissionRate"
            id="emissionRate"
            autoComplete="emissionRate"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="1000"
            min={0}
            value={creationState.initialEmissionRateEther}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionRateEther: Number(event.target.value),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            How many ERC20 tokens to release on every emission window to
            distribute among "all" NFTs. For example 1000 tokens means in a 50
            NFT collection each will receive 200 tokens.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="emissionTimeUnit"
            className="block text-sm font-medium text-gray-700"
          >
            Emission Time Unit{' '}
            <span className="text-gray-500 text-xs font-light">(hours)</span>
          </label>
          <input
            type="number"
            name="emissionTimeUnit"
            id="emissionTimeUnit"
            autoComplete="emissionTimeUnit"
            placeholder="24"
            min={1}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.initialEmissionTimeUnitHours}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionTimeUnitHours: Number(event.target.value),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            How often to emit ERC20 tokens from the stream towards NFT holders.
            This is also how often NFT holders can claim.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const limitSectionView = (
    <FormSection
      title="Limit"
      description="Optionally configure when to finish the emission. You can update this later even after stream is running."
      enabled={Boolean(
        creationState.initialEmissionEndDate &&
          creationState.initialEmissionEndDate !== '0',
      )}
      toggleable={true}
      onEnabledChange={(enabled) =>
        setCreationState((x) => ({
          ...x,
          initialEmissionEndDate: !enabled ? '0' : x.initialEmissionEndDate,
        }))
      }
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="emissionEnd"
            className="block text-sm font-medium text-gray-700"
          >
            End at
          </label>
          <input
            type="datetime-local"
            name="emissionEnd"
            id="emissionEnd"
            autoComplete="emissionEnd"
            step="60"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="1"
            value={creationState.initialEmissionEndDate}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionEndDate: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            When to stop emitting ERC20 tokens. Holders will not receive any
            tokens after this date.
          </p>
        </div>
      </div>
    </FormSection>
  );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id) {
      window?.gtag?.('event', 'submitted_creation_form', {
        event_category: 'streams',
        event_label: creationData.presetFqn,
        transaction_id: `creation:${creationData._id}`,
      });

      navigate('/streams/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New token stream
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            A token stream to distribute a pool of ERC20 tokens to holders of a
            specific ERC721 NFT collection, vested with your pre-defined
            emission rate.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 mb-20">
        {metadataSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {tokensSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {emissionSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {limitSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Create"
                disabled={creationLoading}
                onClick={createTokenStream}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/streams/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the stream
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
