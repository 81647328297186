import { FeatureReadByTagConfig, useFeatureReadByTag } from 'flair-sdk';

export const useWithdrawRecipientLockStatus = (
  config: FeatureReadByTagConfig,
) => {
  return useFeatureReadByTag({
    tag: 'is_withdraw_recipient_locked',
    ...config,
  });
};
