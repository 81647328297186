import {
  CryptoUnits,
  CryptoValue,
  Errors,
  useERC20BalanceOf,
  useERC20Decimals,
  useERC20Name,
  useERC20Symbol,
  useERC20TotalSupply,
} from 'flair-sdk';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';

import { AddressLink } from '../../../components/ui/AddressLink';
import { FungibleToken } from '../types';

type Props = {
  fungibleToken: FungibleToken;
  refresh: () => void;
};

export const MetadataAdminSection = ({ fungibleToken, refresh }: Props) => {
  const { data: account } = useAccount();
  const contractAddress = fungibleToken.contractAddress;

  const {
    data: name,
    error: nameError,
    isLoading: nameLoading,
  } = useERC20Name({
    contractAddress,
  });

  const {
    data: symbol,
    error: symbolError,
    isLoading: symbolLoading,
  } = useERC20Symbol({
    contractAddress,
  });

  const {
    data: decimals,
    error: decimalsError,
    isLoading: decimalsLoading,
  } = useERC20Decimals({
    contractAddress,
  });

  const {
    data: balance,
    error: balanceError,
    isLoading: balanceLoading,
  } = useERC20BalanceOf({
    contractAddress,
    accountAddress: account?.address,
  });

  const {
    data: totalSupply,
    error: totalSupplyError,
    isLoading: totalSupplyLoading,
  } = useERC20TotalSupply({
    contractAddress,
  });

  const canMint = fungibleToken?.smartContract?.features?.find((f) =>
    f.tag.includes('mint'),
  );

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Metadata
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            The ERC20-compliant name, symbol and decimals of the token.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <AddressLink address={contractAddress} />
              </dd>
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {name}
              </dd>
              <dt className="text-sm font-medium text-gray-500">Symbol</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {symbol}
              </dd>
              <dt className="text-sm font-medium text-gray-500">Decimals</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                {decimals?.toString()}
              </dd>
              <dt className="text-sm font-medium text-gray-500">
                Total supply
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <CryptoValue
                  value={totalSupply?.toString()}
                  symbol={symbol?.toString()}
                  unit={CryptoUnits.WEI}
                  showPrice={false}
                />
                {canMint ? (
                  <Link
                    className="text-indigo-600"
                    to={{
                      pathname: `/tokens/${fungibleToken._id}`,
                      search: `#mint`,
                    }}
                  >
                    Mint now
                  </Link>
                ) : null}
              </dd>
            </div>
          </dl>
          <div className="py-4 sm:py-5 sm:px-6">
            {!nameLoading && nameError ? (
              <Errors title="nameError" error={nameError} />
            ) : null}
            {!symbolLoading && symbolError ? (
              <Errors title="symbolError" error={symbolError} />
            ) : null}
            {!decimalsLoading && decimalsError ? (
              <Errors title="decimalsError" error={decimalsError} />
            ) : null}
            {!totalSupplyLoading && totalSupplyError ? (
              <Errors title="totalSupplyError" error={totalSupplyError} />
            ) : null}
          </div>
        </div>
      </div>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Your balance
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Balance of your wallet in this ERC20 token.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Wallet</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <AddressLink address={account?.address} />
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Balance</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2 items-center">
                <CryptoValue
                  value={balance?.toString()}
                  symbol={symbol?.toString()}
                  unit={CryptoUnits.WEI}
                  showPrice={false}
                />
              </dd>
            </div>
          </dl>
          <div className="py-4 sm:py-5 sm:px-6">
            {!balanceLoading && balanceError ? (
              <Errors title="balanceError" error={balanceError} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
