import { useMemo } from 'react';

import { FungibleTokenConstructorFactoryRegistry } from '../registries/constructor-factory-registry';
import { FungibleToken } from '../types';

export const useFungibleTokenConstructorArguments = (
  fungibleToken?: FungibleToken<any> | null,
) => {
  const constructorArguments = useMemo(() => {
    if (!fungibleToken?.presetFqn) {
      return {};
    }

    try {
      const factory =
        FungibleTokenConstructorFactoryRegistry[fungibleToken.presetFqn];

      if (!factory) {
        return {};
      }

      return factory(fungibleToken);
    } catch (e) {
      console.warn(
        `Error while constructing arguments for FungibleToken: ${e}`,
        fungibleToken,
        e,
      );
      return {};
    }
  }, [fungibleToken]);

  return constructorArguments;
};
