import { BytesLike } from 'ethers';
import {
  ACTION_BUTTON,
  Button,
  Environment,
  Errors,
  FormSection,
  LATEST_VERSION,
  PRIMARY_BUTTON,
  Spinner,
  useStickyState,
} from 'flair-sdk';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useFungibleTokenCreator } from '../../../features/fungible-tokens/hooks/useFungibleTokenCreator';

export type ERC20LockableTokenConstructorArguments = [
  {
    name: BytesLike;
    symbol: BytesLike;
  },
];

export type ERC20LockableTokenConfig = {
  name?: string;
  symbol?: string;
};

export type ERC20LockableTokenCreationState = ERC20LockableTokenConfig;

type Props = {
  env?: Environment;
};

const initialState: ERC20LockableTokenCreationState = {
  name: '',
  symbol: '',
};

export const CreateERC20LockableToken = ({ env = Environment.PROD }: Props) => {
  const navigate = useNavigate();

  const [creationState, setCreationState] =
    useStickyState<ERC20LockableTokenCreationState>(
      initialState,
      'erc20-lockable-token-creation',
    );

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: create,
  } = useFungibleTokenCreator<ERC20LockableTokenConfig>(
    {
      presetFqn: 'tokens/presets/ERC20LockableToken',
      presetVersion: LATEST_VERSION,
      config: {
        name: creationState.name,
        symbol: creationState.symbol,
      },
    },
    {
      env,
      enabled: false,
    },
  );

  const metadataSectionView = (
    <FormSection title="General" description="Information about your token.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            autoComplete="publicTitle"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Flair"
            value={creationState.name}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                name: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A human-readable name for your token. Visible on scanners.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Symbol
          </label>
          <input
            type="text"
            name="symbol"
            id="symbol"
            autoComplete="symbol"
            placeholder="FLAIR"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.symbol}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                symbol: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Ticker symbol for your ERC20 token.
          </p>
        </div>
      </div>
    </FormSection>
  );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id) {
      window?.gtag?.('event', 'submitted_creation_form', {
        event_category: 'tokens',
        event_label: creationData.presetFqn,
        transaction_id: `creation:${creationData._id}`,
      });

      navigate('/tokens/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New lockable token
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            A lockable ERC20 token with ability to restrict transfers to certain
            or all addresses as admin.
          </p>
        </div>
      </div>

      <div className="max-w-7xl h-full mx-auto py-6 sm:px-6 lg:px-8 mb-20">
        {metadataSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Create"
                disabled={creationLoading}
                onClick={create}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/tokens/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the token
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
