import { BytesLike } from '@ethersproject/bytes';
import { Provider } from '@ethersproject/providers';
import { WriteContractConfig } from '@wagmi/core';
import { BigNumberish, Signer } from 'ethers';
import { Environment, useFeatureWrite, useFunctionFeature } from 'flair-sdk';

type Config = Partial<WriteContractConfig> & {
  env?: Environment;
  chainId?: number;
  enabled?: boolean;
  contractAddress?: string;
  signerOrProvider?: Signer | Provider | null;
} & ArgsType;

type ArgsType = {
  to?: BytesLike;
  amount?: BigNumberish;
};

export const useERC20MintAmountToAddress = ({
  env,
  chainId,
  contractAddress,
  to,
  amount,
  ...rest
}: Config) => {
  const { data: feature } = useFunctionFeature({
    env,
    chainId,
    contractAddress,
    tag: 'mint_amount_to_address',
  });

  return useFeatureWrite<ArgsType>({
    contractAddress,
    feature,
    args: { to, amount },
    ...rest,
  });
};
