import {
  Environment,
  TokenStream,
  useERC20Name,
  useERC20Symbol,
} from 'flair-sdk';
import { useState } from 'react';

import { ERC20AdminMintForm } from '../../../fungible-tokens/components/ERC20AdminMintForm';
import { ERC20TransferForm } from '../../../fungible-tokens/components/ERC20TransferForm';

type Props = {
  env?: Environment;
  tokenStream: TokenStream<any>;
  refresh: () => void;
};

export const TopUpAdminSection = ({ env, tokenStream, refresh }: Props) => {
  const [claimTokenAddress, setClaimTokenAddress] = useState(
    tokenStream.config?.primaryClaimToken ||
      tokenStream.config?.claimToken ||
      '',
  );

  const { data: claimTokenName } = useERC20Name({
    contractAddress: claimTokenAddress,
  });

  const { data: claimTokenSymbol } = useERC20Symbol({
    contractAddress: claimTokenAddress,
  });

  const finalClaimToken =
    claimTokenAddress || tokenStream?.config?.primaryClaimToken;

  return (
    <>
      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Top-up
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Deposit enough ERC20 tokens to your token stream for your NFT
            holders.
          </p>
          <dl>
            <div className="mt-6">
              <dt className="text-sm font-medium text-gray-500">
                Token address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col gap-2 items-center">
                <div className="mt-1 flex rounded-md shadow-sm w-full">
                  <input
                    type="text"
                    autoComplete="tokenAddress"
                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                    value={claimTokenAddress}
                    onChange={(e) => setClaimTokenAddress(e.target.value)}
                  />
                  <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    <span className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center gap-2">
                      {claimTokenName || '-'}{' '}
                      <small className="text-gray-500">
                        ({claimTokenSymbol || '-'})
                      </small>
                    </span>
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-3">
                  This is the token you want to distribute amongst your NFT
                  holders. You can top-up this token either if you already own
                  some or you have the permission to mint.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className={'bg-white shadow overflow-hidden rounded-lg'}>
        <div className="px-4 py-5 sm:px-6">
          <dl>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Transfer to deposit
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                You can transfer any tokens from your wallet to the stream
                contract.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <ERC20TransferForm
                chainId={tokenStream.chainId}
                contractAddress={
                  claimTokenAddress || tokenStream?.config?.primaryClaimToken
                }
                defaultTo={tokenStream.contractAddress}
                hideTo={true}
              />
            </div>
          </dl>
        </div>
      </div>

      {finalClaimToken ? (
        <div className={'bg-white shadow overflow-hidden rounded-lg'}>
          <div className="px-4 py-5 sm:px-6">
            <dl>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Mint to deposit
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  You can directly mint ERC20 tokens to the stream contract.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <ERC20AdminMintForm
                  env={env}
                  chainId={tokenStream.chainId}
                  contractAddress={
                    claimTokenAddress || tokenStream?.config?.primaryClaimToken
                  }
                  defaultTo={tokenStream.contractAddress}
                  hideTo={true}
                />
              </div>
            </dl>
          </div>
        </div>
      ) : null}
    </>
  );
};
