import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { BigNumber, BigNumberish, BytesLike, utils } from 'ethers';
import {
  ACTION_BUTTON,
  Button,
  classNames,
  Environment,
  Errors,
  FormSection,
  LATEST_VERSION,
  PRIMARY_BUTTON,
  Spinner,
  useHasAnyOfFeatures,
  useLoginJwt,
  useMaxSupply,
  useStickyState,
  useTokenStreamCreator,
  useTotalSupply,
} from 'flair-sdk';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { ERC721LockedStakingEmissionStreamConfig } from '../../../features/token-streams/latest/types/ERC721LockedStakingEmissionStream.types';

export type ERC721StakingStreamConfig = {
  ticketToken: BytesLike;
  initialLockedUntilTimestamp: BigNumberish;
  initialMinStakingDuration: BigNumberish;
  initialMaxStakingTotalDurations: BigNumberish;
  initialEmissionRate: BigNumberish;
  initialEmissionTimeUnit: BigNumberish;
  initialEmissionStart: BigNumberish;
  initialEmissionEnd: BigNumberish;
  initialTotalTickets: BigNumberish;
  initialClaimLockedUntil: BigNumberish;
};

export type ERC721StakingStreamCreationState = {
  name?: string;
  nameDifferent?: boolean;
  publicTitle?: string;
  ticketToken: BytesLike;
  stakingMode?: 'custodial' | 'lockable';
  initialTotalTickets?: BigNumberish;
  initialLockedUntilTimestamp?: BigNumberish;
  initialMinStakingDurationDays?: BigNumberish;
  initialMaxStakingTotalDurationsDays?: BigNumberish;
  initialEmissionRateEther?: number;
  initialEmissionTimeUnitHours?: number;
  initialEmissionStartDate?: string;
  initialEmissionEndDate?: string;
  initialClaimLockedUntilDate?: string;
  primaryClaimToken?: string;
};

type Props = {
  env?: Environment;
};

const initialState: ERC721StakingStreamCreationState = {
  name: '',
  publicTitle: '',
  ticketToken: '',
  primaryClaimToken: '',
  stakingMode: 'lockable',
  initialLockedUntilTimestamp: '0',
  initialMinStakingDurationDays: '10',
  initialMaxStakingTotalDurationsDays: '365',
  initialEmissionRateEther: 1,
  initialEmissionTimeUnitHours: 1,
  initialEmissionStartDate: moment().toISOString().slice(0, -5),
  initialEmissionEndDate: '',
  initialTotalTickets: undefined,
  initialClaimLockedUntilDate: '',
};

const stakingModes = [
  {
    id: 'lockable',
    title: 'Lock-based',
    description: 'NFTs will be locked in-place within user wallet.',
  },
  {
    id: 'custodial',
    title: 'Custodial',
    description: 'NFTs will be transferred to the staking contract.',
  },
];

export const CreateERC721StakingStream = ({
  env = Environment.PROD,
}: Props) => {
  const loginJwt = useLoginJwt();
  const navigate = useNavigate();
  const { activeChain } = useNetwork();

  const [creationState, setCreationState] =
    useStickyState<ERC721StakingStreamCreationState>(
      initialState,
      'erc721-single-token-staking-stream-creation',
    );

  const {
    data: creationData,
    error: creationError,
    isLoading: creationLoading,
    sendRequest: createTokenStream,
  } = useTokenStreamCreator(
    {
      name: creationState.name,
      publicTitle: creationState.publicTitle,
      presetFqn:
        creationState.stakingMode === 'lockable'
          ? 'streams/ERC721/presets/ERC721LockedStakingEmissionStream'
          : 'streams/ERC721/presets/ERC721CustodialStakingEmissionStream',
      presetVersion: LATEST_VERSION,
      config: {
        primaryClaimToken: creationState.primaryClaimToken,
        ticketToken: creationState.ticketToken,
        initialLockedUntilTimestamp: creationState.initialLockedUntilTimestamp,
        initialMinStakingDuration: Math.floor(
          Number(creationState.initialMinStakingDurationDays || '0') *
            24 *
            60 *
            60,
        ),
        initialMaxStakingTotalDurations: Math.floor(
          Number(creationState.initialMaxStakingTotalDurationsDays || '0') *
            24 *
            60 *
            60,
        ),
        initialEmissionRate: utils
          .parseEther(creationState.initialEmissionRateEther?.toString() || '1')
          .mul(
            BigNumber.from(
              creationState.initialTotalTickets?.toString() || '1',
            ),
          ),
        initialEmissionTimeUnit:
          (creationState.initialEmissionTimeUnitHours || 1) * 60 * 60,
        initialEmissionStart: Math.floor(
          (creationState.initialEmissionStartDate
            ? new Date(creationState.initialEmissionStartDate)
            : new Date()
          ).getTime() / 1000,
        ),
        initialEmissionEnd: Math.floor(
          (creationState.initialEmissionEndDate &&
          Number(creationState.initialEmissionEndDate) > 0
            ? new Date(creationState.initialEmissionEndDate).getTime()
            : 0) / 1000,
        ),
        initialTotalTickets: creationState.initialTotalTickets,
        initialClaimLockedUntil: Math.floor(
          (creationState.initialClaimLockedUntilDate &&
          Number(creationState.initialClaimLockedUntilDate) > 0
            ? new Date(creationState.initialClaimLockedUntilDate).getTime()
            : 0) / 1000,
        ),
      } as ERC721LockedStakingEmissionStreamConfig,
    },
    {
      env,
      enabled: false,
      loginJwt,
    },
  );

  const { data: ticketTokenTotalSupply } = useTotalSupply({
    contractAddress: creationState.ticketToken.toString(),
    enabled: Boolean(creationState.ticketToken.toString()),
  });

  const { data: ticketTokenMaxSupply } = useMaxSupply({
    contractAddress: creationState.ticketToken.toString(),
    enabled: Boolean(creationState.ticketToken.toString()),
  });

  const { data: hasLockableExtension, isLoading: hasLockableExtensionLoading } =
    useHasAnyOfFeatures({
      env,
      chainId: activeChain?.id,
      contractAddress: creationState.ticketToken.toString(),
      tags: [
        'erc721_lockable_extension',
        'lock_single_token',
        'unlock_single_token',
      ],
    });

  const supportedModes = useMemo(
    () => (hasLockableExtension ? ['lockable', 'custodial'] : ['custodial']),
    [hasLockableExtension],
  );

  const suggestedTotalTickets = Math.max(
    Number(ticketTokenTotalSupply?.toString() || 0),
    Number(ticketTokenMaxSupply?.toString() || 0),
  );

  useEffect(() => {
    if (
      creationState.ticketToken.toString() &&
      creationState.initialTotalTickets === undefined
    ) {
      setCreationState((x) => ({
        ...x,
        initialTotalTickets: suggestedTotalTickets,
      }));
    }
  }, [
    creationState.initialTotalTickets,
    creationState.ticketToken,
    setCreationState,
    suggestedTotalTickets,
  ]);

  useEffect(() => {
    if (
      creationState.stakingMode &&
      !supportedModes.includes(creationState.stakingMode)
    ) {
      setCreationState({ ...creationState, stakingMode: 'custodial' });
    }
  }, [creationState, setCreationState, supportedModes]);

  const metadataSectionView = (
    <FormSection title="General" description="Information about your stream.">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title{' '}
            <span className="text-gray-500 text-xs font-light">(optional)</span>
          </label>
          <input
            type="text"
            name="title"
            id="title"
            autoComplete="publicTitle"
            placeholder="Angel Rewards"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.publicTitle}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                publicTitle: event.target.value,
                ...(!x.nameDifferent ? { name: event.target.value } : {}),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            An optional public name possibly visible in wallets and
            marketplaces.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="privateName"
            className="block text-sm font-medium text-gray-700"
          >
            Private Name
          </label>
          <input
            type="text"
            name="privateName"
            id="privateName"
            autoComplete="privateName"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Angels Rewards Phase 1"
            value={creationState.name}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                name: event.target.value,
                nameDifferent: event.target.value !== x.publicTitle,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            A private name only to visible you in the dashboard.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const tokensSectionView = (
    <FormSection
      title="Tokens"
      description="Configure desired ERC20 and ERC721 contract addresses. Can only be changed before deploying the contract."
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="claimToken"
            className="block text-sm font-medium text-gray-700"
          >
            Claim Token Address
          </label>
          <input
            type="text"
            name="claimToken"
            id="claimToken"
            autoComplete="tokenAddress"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="0x......"
            value={creationState.primaryClaimToken}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                primaryClaimToken: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Address for the ERC20 token you want to put in this pool to be
            claimed by NFT holders.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Wanna create a{' '}
            <Link
              target={'_blank'}
              to={`/tokens/create`}
              className="text-indigo-600"
            >
              new token now
            </Link>
            ?
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="ticketToken"
            className="block text-sm font-medium text-gray-700"
          >
            NFT Contract Address
          </label>
          <input
            type="text"
            name="ticketToken"
            id="ticketToken"
            autoComplete="nftAddress"
            placeholder="0x......."
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.ticketToken.toString()}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                ticketToken: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Contract address of the ERC721 NFT collection. Only holders of this
            collection can claim the emitted tokens.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Wanna create a{' '}
            <Link
              target={'_blank'}
              to={`/collections/create`}
              className="text-indigo-600"
            >
              new NFT collection now
            </Link>
            ?
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="initialTotalTickets"
            className="block text-sm font-medium text-gray-700"
          >
            Total NFTs
          </label>
          <input
            type="text"
            name="initialTotalTickets"
            id="initialTotalTickets"
            autoComplete="nftSupply"
            placeholder={suggestedTotalTickets?.toString() || '10000'}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.initialTotalTickets?.toString()}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialTotalTickets: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Total number of NFT tokens available in the collection. This will
            help calculate how much each NFT will get from the token emission.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const modeSectionView = (
    <FormSection
      title="Staking mode"
      description={
        <>
          <p>
            This define what users must do for staking, they can either lock
            their NFTs in-place in their own wallet, or transfer it to the
            staking contract.
          </p>
          <p className="mt-4">
            For better user experience it's better to <b>lock NFTs in-place</b>{' '}
            so users can still see their NFTs in their wallet and prove their
            ownership in various places like Collab Land, but not all contracts
            support locking.
          </p>
        </>
      }
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <RadioGroup
            value={creationState.stakingMode}
            onChange={(value: any) => {
              setCreationState((x) => ({ ...x, stakingMode: value as any }));
            }}
          >
            <RadioGroup.Label className="text-base font-medium text-gray-900 flex gap-2 items-center">
              {creationState.ticketToken.toString() &&
              hasLockableExtensionLoading ? (
                <Spinner />
              ) : null}
              Which staking mode do you prefer?
            </RadioGroup.Label>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              {stakingModes.map((stakingMode) => (
                <RadioGroup.Option
                  key={stakingMode.id}
                  value={stakingMode.id}
                  disabled={!supportedModes.includes(stakingMode.id)}
                  className={({ checked, active, disabled }) =>
                    classNames(
                      checked ? 'border-transparent' : 'border-gray-300',
                      active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                      disabled ? 'opacity-50' : '',
                      'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex-1 flex">
                        <span className="flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className="block text-sm font-medium text-gray-900"
                          >
                            {stakingMode.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="mt-1 flex items-center text-sm text-gray-500"
                          >
                            {stakingMode.description}
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? 'invisible' : '',
                          'h-5 w-5 text-indigo-600',
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-indigo-500' : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none',
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <p className="mt-4">
            <div className="rounded-md bg-blue-50 p-2 text-sm text-blue-700">
              Locked-staking only works with collections that have locking
              extension. Flair NFT contracts do have in-place locking extension.
              You can create a{' '}
              <Link
                target={'_blank'}
                to={`/collections/create`}
                className="inline font-bold"
              >
                new NFT collection now
              </Link>{' '}
              with in-place locking extension.
            </div>
          </p>
        </div>

        {creationState.ticketToken.toString().length === 42 &&
        !hasLockableExtension ? (
          <div className="col-span-6">
            <p className="mt-2">
              <div className="rounded-md bg-yellow-50 p-2 text-sm text-yellow-700">
                Your contract on {creationState.ticketToken} does not support{' '}
                <a
                  href="https://github.com/0xflair/evm-contracts/blob/main/contracts/collections/ERC721/extensions/ERC721LockableExtension.sol"
                  target={'_blank'}
                  className="text-indigo-600"
                  rel="noreferrer"
                >
                  lock-based staking
                </a>
                . Note that all Flair collections do support this extension.
              </div>
            </p>
            <p className="mt-2 text-sm text-gray-700">
              Wanna create a{' '}
              <Link
                target={'_blank'}
                to={`/collections/create`}
                className="text-indigo-600"
              >
                new NFT collection now
              </Link>
              ?
            </p>
          </div>
        ) : null}
      </div>
    </FormSection>
  );

  const emissionSectionView = (
    <FormSection
      title="Emission"
      description="Configure when and how much tokens to distribute to NFT holders. You as contract owner can update these values even after stream is running."
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="claimStart"
            className="block text-sm font-medium text-gray-700"
          >
            Start at
          </label>
          <input
            type="datetime-local"
            name="claimStart"
            id="claimStart"
            autoComplete="emissionStart"
            step="60"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="1"
            value={creationState.initialEmissionStartDate}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionStartDate: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            When to start the emission. Holder can only stake and/or claim after
            this time.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="emissionRate"
            className="block text-sm font-medium text-gray-700"
          >
            Reward Rate
          </label>
          <input
            type="number"
            name="emissionRate"
            id="emissionRate"
            autoComplete="emissionRate"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="1000"
            min={0}
            value={creationState.initialEmissionRateEther}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionRateEther: Number(event.target.value),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            How many ERC20 tokens to release on every emission window to one
            single NFT. For example use 10 if you want each NFT to receive 10
            tokens every window. With 10k collection that means 100,000 tokens
            per window.
          </p>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="emissionTimeUnit"
            className="block text-sm font-medium text-gray-700"
          >
            Emission Window (Time Unit){' '}
            <span className="text-gray-500 text-xs font-light">(hours)</span>
          </label>
          <input
            type="number"
            name="emissionTimeUnit"
            id="emissionTimeUnit"
            autoComplete="emissionTimeUnit"
            placeholder="24"
            min={1}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.initialEmissionTimeUnitHours}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionTimeUnitHours: Number(event.target.value),
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            How often to emit ERC20 tokens from the stream towards NFT holders.
            This is also how often NFT holders can claim.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const stakingSectionView = (
    <FormSection
      title="Staking"
      description="Define how long do you expect the NFT tokens to be locked."
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="minStakingDuration"
            className="block text-sm font-medium text-gray-700"
          >
            Min lock time{' '}
            <span className="text-gray-500 text-xs font-light">(days)</span>
          </label>
          <input
            type="number"
            name="minStakingDuration"
            id="minStakingDuration"
            autoComplete="minStakingDuration"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="30"
            min={0}
            value={
              creationState.initialMinStakingDurationDays?.toString() || ''
            }
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialMinStakingDurationDays: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            How many days tokens must be staked before owners can unlock and
            unstake them. After this time owners can unstake at any time.
          </p>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="max"
            className="block text-sm font-medium text-gray-700"
          >
            Max staking duration{' '}
            <span className="text-gray-500 text-xs font-light">(days)</span>
          </label>
          <input
            type="number"
            name="initialMaxStakingTotalDurationsDays"
            id="initialMaxStakingTotalDurationsDays"
            autoComplete="initialMaxStakingTotalDurationsDays"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="365"
            min={0}
            value={
              creationState.initialMaxStakingTotalDurationsDays?.toString() ||
              ''
            }
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialMaxStakingTotalDurationsDays: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Maximum number of days staking is considered and counted. Beyond
            this limit days will not be counted. This helps to limit the total
            rewards. If owners stake/unstake multiple times, all will be summed
            together to get the total days.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const claimSectionView = (
    <FormSection
      title="Claim"
      description="Restrict when holders can start claiming their rewards."
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="initialClaimLockedUntil"
            className="block text-sm font-medium text-gray-700"
          >
            Open claiming after
          </label>
          <input
            type="datetime-local"
            name="initialClaimLockedUntil"
            id="initialClaimLockedUntil"
            autoComplete="initialClaimLockedUntil"
            step="60"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={creationState.initialClaimLockedUntilDate?.toString()}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialClaimLockedUntilDate: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            Even if holders can stake, they cannot claim before this time. This
            helps for example if you don't want ERC20 tokens to be in public
            until a certain time.
          </p>
          <p className="mt-2 text-sm text-gray-500">
            You can update this date even after the stream is deployed and
            running.
          </p>
        </div>
      </div>
    </FormSection>
  );

  const limitSectionView = (
    <FormSection
      title="Limit"
      description="Optionally configure when to finish the emission. You can update this later even after stream is running."
      enabled={Boolean(
        creationState.initialEmissionEndDate &&
          creationState.initialEmissionEndDate !== '0',
      )}
      toggleable={true}
      onEnabledChange={(enabled) =>
        setCreationState((x) => ({
          ...x,
          initialEmissionEndDate: !enabled ? '0' : x.initialEmissionEndDate,
        }))
      }
    >
      <div className="relative grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <label
            htmlFor="emissionEnd"
            className="block text-sm font-medium text-gray-700"
          >
            End at
          </label>
          <input
            type="datetime-local"
            name="emissionEnd"
            id="emissionEnd"
            autoComplete="emissionEnd"
            step="60"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="1"
            value={creationState.initialEmissionEndDate}
            onChange={(event) =>
              setCreationState((x) => ({
                ...x,
                initialEmissionEndDate: event.target.value,
              }))
            }
          />
          <p className="mt-2 text-sm text-gray-500">
            When to stop emitting ERC20 tokens. Holders cannot stake and will
            not receive any tokens after this date, even if they have not used
            their full maximum staking duration.
          </p>
        </div>
      </div>
    </FormSection>
  );

  // If collection is successfully created, redirect to the collection page
  useEffect(() => {
    if (creationData && creationData._id) {
      window?.gtag?.('event', 'submitted_creation_form', {
        event_category: 'streams',
        event_label: creationData.presetFqn,
        transaction_id: `creation:${creationData._id}`,
      });

      navigate('/streams/' + creationData._id);
    }
  }, [navigate, creationData]);

  return (
    <>
      <div className="py-6 px-4 sm:px-6 lg:px-8">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            New staking stream
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            A token stream to distribute a pool of ERC20 tokens to holders of a
            specific ERC721 NFT collection if they lock their NFTs for a certain
            period.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 mb-20">
        {metadataSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {tokensSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {modeSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {stakingSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {claimSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {emissionSectionView}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        {limitSectionView}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex"></div>
          <div className="ml-4 flex items-center md:ml-6 gap-x-3">
            {creationError ? <Errors error={creationError} /> : null}
            {creationLoading ? (
              <>
                Saving...
                <Spinner />
              </>
            ) : null}
            {!creationData?._id && (
              <Button
                className={ACTION_BUTTON}
                text="Create"
                disabled={creationLoading}
                onClick={createTokenStream}
              />
            )}
            {creationData?._id && (
              <Link
                to={`/streams/${creationData?._id}`}
                className={PRIMARY_BUTTON}
              >
                Go to the stream
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
