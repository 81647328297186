import {
  PredefinedReadContractConfig,
  useContractRead,
} from '@0xflair/react-common';

export const useTrustedForwarderAddress = (
  config: PredefinedReadContractConfig,
) => {
  return useContractRead<string>({
    contractFqn: 'common/meta-transactions/ERC2771ContextOwnable',
    functionName: '_trustedForwarder',
    ...config,
  });
};
